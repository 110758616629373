import {ReactNode, ReactNodeArray, useState} from 'react'
import {ToggleContext} from './toggle-context'

type ToggleProviderProps = {
    children: ReactNodeArray | ReactNode
}
export function ToggleProvider({children}: ToggleProviderProps): JSX.Element {
    const [value, setValue] = useState<boolean>(false)
    return <ToggleContext.Provider value={{value, setValue}}>{children}</ToggleContext.Provider>
}
