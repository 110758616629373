import {GuidType} from '../../../values/generic-type-defintions'

export enum CreationStatus {
    NOT_CREATING = 'NOT_CREATING',
    ENTER_DETAILS = 'ENTER_DETAILS',
    CONFIRM = 'CONFIRM',
    REVIEW = 'REVIEW',
    CANCELED = 'CANCELED',
}
export interface UnsavedIncidentNoteAlertModalReduxState {
    newNoteCreationStatus: CreationStatus
    editNoteCreationStatus: CreationStatus
    showModal: boolean
    newNote: string
    newNoteIncidentIdentity: GuidType | undefined
    editNoteMap: Map<GuidType, string>
    pendingNavigation: string | null
}

export const DEFAULT_UNSAVED_INCIDENT_NOTE_ALERT_MODAL_STATE: UnsavedIncidentNoteAlertModalReduxState =
    {
        newNoteCreationStatus: CreationStatus.NOT_CREATING,
        editNoteCreationStatus: CreationStatus.NOT_CREATING,
        showModal: false,
        newNote: '',
        newNoteIncidentIdentity: undefined,
        editNoteMap: new Map<GuidType, ''>(),
        pendingNavigation: null,
    }
