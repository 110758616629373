import * as Styled from './styles/actions-footer.styled'
import * as StyledAdmin from './styles/admin-sign-in-button.styled'
import {AdminProps} from '../../login-page'
import {AdminSignInButton} from './admin-sign-in-button'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {gfcFlagSelector} from '../../../../store/state/config/selectors'
import {AdminSignInButtonGFC} from './admin-sign-in-button-gfc'

export function ActionsFooter(props: AdminProps): JSX.Element {
    const appVersion = `V ${process.env.REACT_APP_MEDULLA_VERSION}${
        process.env.REACT_APP_BUILD_NUMBER ? `+${process.env.REACT_APP_BUILD_NUMBER}` : ''
    }`
    const gfcFlag = useTypedSelector(gfcFlagSelector)
    if (props.admin) {
        return (
            <Styled.ActionsFooter>
                <AdminSignInButton />
            </Styled.ActionsFooter>
        )
    }

    return (
        <Styled.ActionsFooter>
            <span id="app-version">{appVersion}</span>
            {gfcFlag ? (
                <AdminSignInButtonGFC />
            ) : (
                <StyledAdmin.AdminSignInButton id="admin-signin" target="_self" href="adminLogin">
                    Admin Sign In
                </StyledAdmin.AdminSignInButton>
            )}
        </Styled.ActionsFooter>
    )
}
