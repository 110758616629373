import ActionType from './action-type'
import {Action} from './actions'
import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'
import {IndicatorData, IndicatorMap} from '../../../values/IndicatorData'
import {DEFAULT_INDICATORS_STATE, IndicatorsReduxState} from './state'
import LoadingState from '../../../values/loading-state-enum'

const reduceArrayToDictionary = (indicators: IndicatorData[]): IndicatorMap => {
    return indicators?.reduce((dictionary: IndicatorMap, indicator: IndicatorData) => {
        dictionary.set(indicator.indicatorCode, indicator)
        return dictionary
    }, new Map<string, IndicatorData>())
}

function newIndicatorMap(): IndicatorMap {
    return new Map<string, IndicatorData>()
}

function isUpdated(currentValue: IndicatorData | undefined, newValue: IndicatorData | undefined) {
    return (
        currentValue?.title != newValue?.title ||
        currentValue?.indicatorCode != newValue?.indicatorCode ||
        currentValue?.description != newValue?.description ||
        currentValue?.mitreStep != newValue?.mitreStep ||
        currentValue?.mitreTtps != newValue?.mitreTtps
    )
}

function receiveIndicator(
    currentState: IndicatorsReduxState,
    newData: IndicatorMap,
): IndicatorsReduxState {
    const newRecords: IndicatorData[] = []
    const updatedRecords: IndicatorData[] = []
    const unchangedRecords: IndicatorData[] = []
    const receivedData = newData != undefined ? newData : newIndicatorMap()

    receivedData.forEach((indicator) => {
        const newValue = receivedData.get(indicator.indicatorCode)
        if (currentState.indicators.has(indicator.indicatorCode)) {
            const currentValue = currentState.indicators.get(indicator.indicatorCode)
            if (isUpdated(currentValue, newValue)) {
                updatedRecords.push(indicator)
            } else if (currentValue) {
                unchangedRecords.push(currentValue)
            }
        } else {
            newRecords.push(indicator)
        }
    })
    if (newRecords.length === 0 && updatedRecords.length === 0) {
        if (unchangedRecords.length === currentState?.indicators?.size) {
            if (currentState.indicatorsLoadingState === LoadingState.Loaded) {
                return currentState
            }
            return {
                ...currentState,
                indicatorsLoadingState: LoadingState.Loaded,
            }
        }
        const data = newIndicatorMap()
        unchangedRecords.forEach((unchangedRecord) =>
            data.set(unchangedRecord.indicatorCode, unchangedRecord),
        )
        return {
            ...currentState,
            indicators: data,
            indicatorsLoadingState: LoadingState.Loaded,
        }
    }
    const data = newIndicatorMap()
    newRecords.forEach((newRecord) => data.set(newRecord.indicatorCode, newRecord))
    updatedRecords.forEach((updatedRecord) => data.set(updatedRecord.indicatorCode, updatedRecord))
    unchangedRecords.forEach((unchangedRecord) =>
        data.set(unchangedRecord.indicatorCode, unchangedRecord),
    )
    return {
        ...currentState,
        indicators: data,
        indicatorsLoadingState: LoadingState.Loaded,
    }
}

function requestIndicator(currentState: IndicatorsReduxState): IndicatorsReduxState {
    if (currentState.indicatorsLoadingState === LoadingState.RequestingData) {
        return currentState
    }
    return {...currentState, indicatorsLoadingState: LoadingState.RequestingData}
}

function logout(currentState: IndicatorsReduxState): IndicatorsReduxState {
    if (
        currentState.indicatorsLoadingState === LoadingState.NotPopulated &&
        currentState?.indicators?.size === 0
    ) {
        return currentState
    }
    return DEFAULT_INDICATORS_STATE
}

export default function indicatorsReducer(
    state: IndicatorsReduxState = DEFAULT_INDICATORS_STATE,
    action: Action | LoggingOutAction,
): IndicatorsReduxState {
    switch (action.type) {
        case ActionType.REQUEST_INDICATORS:
            return requestIndicator(state)

        case ActionType.RECEIVE_INDICATORS:
            return receiveIndicator(state, reduceArrayToDictionary(action.payload))

        case SessionActionType.LOGGING_OUT:
            return logout(state)

        /* istanbul ignore next */
        default:
            return state
    }
}
