export const Colors = {
    grey1: '#e7e7e7',
    grey2: '#cccccc',
    grey3: '#999999', //secondary
    grey4: '#666666',
    grey5: '#424242', // default
    grey6: '#f7f7f7',
    grey7: '#303030',
    grey8: '#777777',
    grey9: '#f2f2f2',
    grey10: '#ededed',
    grey11: '#c9c9c9',
    grey12: '#c3c3c3',
    grey13: '#b3b3b3',
    grey14: '#707070',
    grey15: '#f0f0f0',
    white: '#ffffff',
    black: '#000000',
    yellow: '#efd443',

    orange1: '#fdedd6',
    orange2: '#f8b24b',
    orange2_30percent: '#fde8c8',
    orange3: '#fd9426',
    orange4: '#F8DC4B26',
    orange5: 'rgba(253, 239,219,1)',

    red1: '#fdd6dd',
    red2: '#fcd5db',
    red3: '#f34663',
    red3_21percent: 'rgba(243, 70, 99, 0.21)',
    red3_30percent: '#fcc7d0',
    red4: '#ff002b',
    red5: '#fee9ed',
    red6: '#ff0565',
    red7: '#400000',
    red8: 'rgba(252, 218,224,1)',

    green1: '#7ac943',
    green2: '#43a047',

    purple1: '#d1c4e9',
    purple2: '#c9bae5',
    purple3: '#b7accc',
    purple4: '#8266b4',
    purple5: '#9575cd',
    purple6: '#5e34b1',
    purple7: '#522d9b',

    blue1: '#e3f0fb',
    blue2: '#1f88e5',
    blue3: '#0d47a1',
    blue4: '#007bff',
    blue5: '#7fbdff',
    blue6: '#e7f2fd',
    blue7: '#EBF4FD',
    blue8: 'rgba(206,218,236,1)',
    blue9: '#7FB9EC',

    /*
$teal-color: #009aa6;
$teal-font-color: #0e6377;
$dark-grey-60: #666666;
$dark-grey-30: #b3b3b3;
$dark-grey-20: #cccccc;
$dark-grey-10: #494343;
$dark-grey-5: #f2f2f2;
*/
}
