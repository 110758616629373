import {Moment} from 'moment'
import {MonaEngineMode} from '../../../values/mona_engine_mode'
import LoadingState from '../../../values/loading-state-enum'

export interface MonaEngineModesReduxState {
    loading: LoadingState
    data: MonaEngineMode[]
    lastUpdate: Moment | null
}

export const DEFAULT_MONA_ENGINE_MODES_STATE: MonaEngineModesReduxState = {
    loading: LoadingState.NotPopulated,
    data: [],
    lastUpdate: null,
}
