import {PageType} from '../../../../components/vessel-filters/data-helper'
import LoadingState from '../../../../values/loading-state-enum'
import {VesselFilterReduxState} from './vessel-filter-state'

export const DEFAULT_VESSEL_FILTER_STATE: VesselFilterReduxState = {
    pageType: PageType.MY_VESSELS,
    loadingState: LoadingState.NotPopulated,
    locations: undefined,
    searchVesselTagTerm: [],
    searchVesselNameTerm: '',
    searchVesselTerm: '',
    showXButton: false,
    vesselSort: {
        field: 'vessel-name',
        direction: 'desc',
    },
    isInactive: false,
}
