import {GuidType} from '../../../values/generic-type-defintions'
import LoadingState from '../../../values/loading-state-enum'

export interface UsersReduxState {
    loadingState: LoadingState
    users: User[]
}

export interface User {
    user: GuidType
    username: string
    email: string
    emailNotificationEnabled: boolean
    active: boolean
    deleted: boolean
    roles: string[]
    internalUser: boolean
    assignedTag: GuidType | undefined
    strictMode: StrictMode | undefined
    notificationEmail: string | undefined
}

export const DEFAULT_USERS_STATE: UsersReduxState = {
    loadingState: LoadingState.NotPopulated,
    users: [],
}

export enum StrictMode {
    Strict = 'STRICT',
    DefaultVisibility = 'DEFAULT_VISIBILITY',
}
