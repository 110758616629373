import {DEFAULT_LOCATIONS_STATE} from '../../../../store/state/locations/state'
import {locationsSelector} from '../../../../store/state/locations/selectors'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {Divider, DropdownContentStyle} from './_styles/location-dropdown.styled'
import {DropDownItem} from './drop-down-item'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'

const {activeLocation: myFleet} = DEFAULT_LOCATIONS_STATE

export function LocationDropdown(): JSX.Element {
    const locations = useTypedSelector(locationsSelector)
    const {width} = useDimensions()
    return (
        <DropdownContentStyle width={width}>
            <DropDownItem location={myFleet} />
            <Divider />
            {locations.map((location) => (
                <DropDownItem key={location.location} location={location} />
            ))}
        </DropdownContentStyle>
    )
}
