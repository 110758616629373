export function extraSmallFont(): string {
    return 'font-size: 10px;line-height: 12px;'
}

export function smallFont(): string {
    return 'font-size: 12px;line-height: 17px;'
}

export function mediumFont(): string {
    return 'font-size: 18px;line-height: 25px;'
}

export function mediumSmallFont(): string {
    return 'font-size: 14px;line-height: 21px;'
}

export function mediumLargeFont(): string {
    return 'font-size: 20px;line-height: 28px;'
}

export function largeFont(): string {
    return 'font-size: 24px;line-height: 34px;'
}
export function extraLargeFont(): string {
    return 'font-size: 48px;line-height: normal;'
}
