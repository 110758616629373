import {IncidentStatus} from '../../../pages/incidents-v3/contexts/types/incident-status'
import {INCIDENT_NOTES_SORT_ORDER} from '../../../pages/incidents-v3/data-helpers'
import {IncidentSeverityValue} from '../../../values/incident-response-values'
import LoadingState from '../../../values/loading-state-enum'
import {SORT_ORDER} from '../../../values/sort-order'
import {LocationIdType} from '../locations/state'
import {SortValue} from '../vessel-filter/types/vessel-filter-state'
import {TimeRange} from '../../../values/TimeRange'
import {IncidentResponseSortType} from '../../../pages/incidents-v3/contexts/types/type-of-interfaces'
import {
    DEFAULT_INCIDENTS_DETAIL_EXPANDED,
    DetailExpandedType,
} from '../../../pages/incidents-v3/contexts/types/incidents-details-type'

export interface IncidentsFilterReduxState {
    loadingState: LoadingState
    searchIncidentNo: string | null
    orderIncidentsBy: {column: IncidentResponseSortType; direction: SORT_ORDER}
    filterByStatus: IncidentStatus[] | undefined
    filterBySeverities: IncidentSeverityValue[] | undefined
    sortByNotes: INCIDENT_NOTES_SORT_ORDER
    selectedIncidentType: string | undefined
    selectedCreatedFromTimeRange: string | null
    selectedCreatedToTimeRange: string | null
    selectedUpdatedFromTimeRange: string | null
    selectedUpdatedToTimeRange: string | null
    createdFromRelative: TimeRange | null
    createdToRelative: TimeRange | null
    updatedToRelative: TimeRange | null
    updatedFromRelative: TimeRange | null
    locations: Set<LocationIdType> | undefined
    assignedTo: string | undefined
    guestAssignedToEmail: string | undefined
    watchedByCurrentUser: boolean | null
    unseenIncidentResponses: boolean | null
    searchVesselTagTerm: string[]
    searchVesselNameTerm: string
    searchVesselTerm: string
    showXButton: boolean
    vesselSort: SortValue
    selectedFilterName: string
    assignedToVesselEmail: boolean | null
    detailExpanded: DetailExpandedType
}

export const DEFAULT_INCIDENTS_FILTER_STATE: IncidentsFilterReduxState = {
    loadingState: LoadingState.NotPopulated,
    searchIncidentNo: null,
    orderIncidentsBy: {column: IncidentResponseSortType.STATUS, direction: SORT_ORDER.DESCENDING},
    filterByStatus: undefined,
    filterBySeverities: undefined,
    sortByNotes: INCIDENT_NOTES_SORT_ORDER.NONE,
    selectedIncidentType: undefined,
    selectedCreatedFromTimeRange: null,
    selectedCreatedToTimeRange: null,
    selectedUpdatedFromTimeRange: null,
    createdFromRelative: null,
    createdToRelative: null,
    updatedToRelative: null,
    updatedFromRelative: null,
    selectedUpdatedToTimeRange: null,
    locations: undefined,
    assignedTo: undefined,
    guestAssignedToEmail: undefined,
    watchedByCurrentUser: null,
    unseenIncidentResponses: false,
    searchVesselTagTerm: [],
    searchVesselNameTerm: '',
    searchVesselTerm: '',
    showXButton: false,
    vesselSort: {
        field: 'vessel-name',
        direction: 'desc',
    },
    selectedFilterName: 'Saved filters',
    assignedToVesselEmail: null,
    detailExpanded: DEFAULT_INCIDENTS_DETAIL_EXPANDED,
}
