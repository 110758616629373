import {isEqual} from 'lodash'
import {
    IncidentsReportFilter,
    IncidentsReportFilterReduxState,
} from '../../../store/state/incidents-report-filter/state'
import {LocationIdType} from '../../../store/state/locations/state'
import {TotalNumberOfItemsByTypes} from '../../incidents-v3/contexts/types/type-of-interfaces'
import MISCONFIGURATION from '../../../@assets/icons/misconfiguration-incident-type.svg'
import NETWORK from '../../../@assets/icons/network-incident-type.svg'
import PUP from '../../../@assets/icons/pup-incident-type.svg'
import DEFAULT from '../../../@assets/icons/default-incident-type.svg'
import UNKNOWN_ASSET from '../../../@assets/icons/unknown-assets-incident-type.svg'
import ANOMALOUS_BEHAVIOUR from '../../../@assets/icons/anomalous-behaviour-incident-type.svg'
import DATA_TRANSFER from '../../../@assets/icons/data-transfer-incident-type.svg'
import MALWARE from '../../../@assets/icons/malware-incident-type.svg'
import POTENTIALLY_MALICIOUS_FILE from '../../../@assets/icons/malicious-files-incident-type.svg'
import COMPLIANCE from '../../../@assets/icons/compliance-incident-type.svg'
import ACCOUNT_ACTIVITY from '../../../@assets/icons/account_activity_incident_type.svg'
import INFORMATIONAL from '../../../@assets/icons/informational-incident-type.svg'
import CREDENTIAL_POLICY from '../../../@assets/icons/credential-policy-incident-type.svg'
import REMOTE_ACCESS from '../../../@assets/icons/remote-access-incident-type.svg'

export class FormattedNewFilterModel {
    public readonly locations: Set<LocationIdType> | LocationIdType[] | undefined
    public readonly searchVesselTagTerm: string[]
    public readonly searchVesselNameTerm: string
    public readonly fromDate: string | null
    public readonly toDate: string | null

    public constructor(newFilter: IncidentsReportFilterReduxState | IncidentsReportFilter) {
        this.locations = newFilter.locations
        this.searchVesselTagTerm = newFilter.searchVesselTagTerm
        this.searchVesselNameTerm = newFilter.searchVesselNameTerm
        this.fromDate = newFilter.fromDate
        this.toDate = newFilter.toDate
    }
}
export function compareFilters(
    currentFilter: IncidentsReportFilter,
    newFilter: IncidentsReportFilterReduxState,
): boolean {
    const formattedNewFilter = new FormattedNewFilterModel(newFilter)
    const formattedCurrentFilter = new FormattedNewFilterModel(currentFilter)
    return !isEqual(formattedNewFilter, formattedCurrentFilter)
}
export function findIfDefaultFilterIsChanged(
    currentFilter: IncidentsReportFilter,
    locations: Set<LocationIdType> | undefined,
    fromDate: string | null,
    toDate: string | null,
    searchVesselTagTerm: string[],
    searchVesselNameTerm: string,
): boolean {
    return (
        isEqual(currentFilter.locations, locations) &&
        isEqual(currentFilter.searchVesselTagTerm, searchVesselTagTerm) &&
        isEqual(currentFilter.searchVesselNameTerm, searchVesselNameTerm) &&
        currentFilter.fromDate === fromDate &&
        currentFilter.toDate === toDate
    )
}

export function transformTypesCountsToArray(
    typesCounts: TotalNumberOfItemsByTypes,
): {name: string; value: number; displayName: string}[] {
    if (!typesCounts) {
        return []
    }
    return Object.entries(typesCounts).map(([name, value]) => ({
        name,
        value,
        displayName: formatTypesName(name),
    }))
}

function formatTypesName(incidentType: string): string {
    switch (incidentType) {
        case 'NETWORK':
            return 'NT'
        case 'MISCONFIGURATION':
            return 'MS'
        case 'PUP':
            return 'PP'
        case 'UNKNOWN_ASSET':
        case 'UNKNOWN_ASSETS':
            return 'UA'
        case 'ANOMALOUS_BEHAVIOUR':
            return 'AB'
        case 'DATA_TRANSFER':
            return 'DT'
        case 'MALWARE':
            return 'ML'
        case 'POTENTIALLY_MALICIOUS_FILE':
            return 'PT'
        case 'COMPLIANCE':
            return 'CO'
        case 'ACCOUNT_ACTIVITY':
            return 'AC'
        case 'INFORMATIONAL':
            return 'IF'
        case 'REMOTE_ACCESS':
            return 'RA'
        case 'CREDENTIAL_POLICY':
            return 'CP'
        case 'DEFAULT':
        default:
            return 'DF'
    }
}
export function getIncidentTypeIconFromFormattedNamesc(incidentType: string): string {
    switch (incidentType) {
        case 'NT':
            return NETWORK
        case 'MS':
            return MISCONFIGURATION
        case 'PP':
            return PUP
        case 'UA':
            return UNKNOWN_ASSET
        case 'AB':
            return ANOMALOUS_BEHAVIOUR
        case 'DT':
            return DATA_TRANSFER
        case 'ML':
            return MALWARE
        case 'PT':
            return POTENTIALLY_MALICIOUS_FILE
        case 'CO':
            return COMPLIANCE
        case 'AC':
            return ACCOUNT_ACTIVITY
        case 'IF':
            return INFORMATIONAL
        case 'CP':
            return CREDENTIAL_POLICY
        case 'RA':
            return REMOTE_ACCESS
        case 'DF':
        default:
            return DEFAULT
    }
}
