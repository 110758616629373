import ActionType from './action-type'
import * as Actions from './actions'
import AppState from '../../types/app-state'
import {Api} from '../../../api/Api'
import {Role} from '../../../values/Role'
import {ThunkAction} from 'redux-thunk'

const ROLES_FOR_CURRENT_USER_REST_URL = '/api/v1/users/current/roles'

export function fetchRolesForCurrentUser(): ThunkAction<void, AppState, Api, Actions.Action> {
    return (dispatch, _, api: Api): void => {
        dispatch(requestRolesForCurrentUser())

        api.getAuth(ROLES_FOR_CURRENT_USER_REST_URL, (data) => {
            const roles: Role[] = data as Role[]
            dispatch(receiveRolesForCurrentUser(roles))
        })
    }
}

function requestRolesForCurrentUser(): Actions.RequestRolesForCurrentUserAction {
    return {
        type: ActionType.REQUEST_ROLES_FOR_CURRENT_USER,
    }
}

function receiveRolesForCurrentUser(roles: Role[]): Actions.ReceiveRolesForCurrentUserAction {
    return {
        type: ActionType.RECEIVE_ROLES_FOR_CURRENT_USER,
        payload: roles,
    }
}
