import {DEFAULT_EXTENDED_DATA_REQUEST_STATE, ExtendedDataRequestReduxState} from './state'
import ActionType from './action-type'
import {Action} from './actions'
import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'
import produce from 'immer'

export const extendedDataRequestReducer = produce(
    (
        draft: ExtendedDataRequestReduxState = DEFAULT_EXTENDED_DATA_REQUEST_STATE,
        action: Action | LoggingOutAction,
    ) => {
        switch (action.type) {
            case ActionType.OPEN_MODAL:
                draft.isModalOpen = true
                draft.inventory = action.payload.inventory
                draft.currentLocations = action.payload.locations
                draft.data.title = action.payload.title
                draft.data.location = null
                draft.data.dateRange = 'Last 24 hours'
                draft.data.purpose = 'Help with investigation of an incident raised by CyberOwl'
                break

            case ActionType.CLOSE_MODAL:
                draft = DEFAULT_EXTENDED_DATA_REQUEST_STATE
                break

            case ActionType.SET_LOCATION:
                draft.data.location = action.payload
                break

            case ActionType.SET_DATA_RANGE:
                draft.data.dateRange = action.payload
                break

            case ActionType.SET_REQUIREMENTS:
                if (!draft.data.requirements) {
                    draft.data.requirements = new Array<string>()
                }
                if (action.payload.value) {
                    draft.data.requirements.push(action.payload.requirement)
                } else {
                    draft.data.requirements = draft.data.requirements.filter(
                        (requirement) => requirement !== action.payload.requirement,
                    )
                }
                break

            case ActionType.SET_ADDITIONAL_REQUIREMENT:
                draft.additionalRequirement = action.payload.requirement
                draft.isAdditionalRequirementExist = action.payload.value
                if (action.payload.requirement.length === 0) {
                    draft.additionalRequirement = undefined
                }
                break

            case ActionType.SET_PURPOSE:
                draft.data.purpose = action.payload
                break

            case ActionType.SET_REFERENCE:
                draft.data.reference = action.payload
                break

            case ActionType.SET_SAVE_PROCESSING_STATUS:
                draft.isProcessingSave = action.payload
                break

            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_EXTENDED_DATA_REQUEST_STATE
                break
            /* istanbul ignore next */
            default:
                break
        }
        return draft
    },
)
