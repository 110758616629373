import {GuidType} from '../../../values/generic-type-defintions'
import LoadingState from '../../../values/loading-state-enum'
import {IncidentsFilterCriteria} from '../../../values/user-preferences/incidents-filter'
import {NetworkAssetsFilterCriteria} from '../../../values/user-preferences/network-assets-filter'
import {ReportsFilterCriteria} from '../../../values/user-preferences/reports-filter'
import {SoftwareInventoryFilterCriteria} from '../../../values/user-preferences/software-inventory-filter'
import {UsbInventoryFilterCriteria} from '../../../values/user-preferences/usb-inventory-filter'
import {IncidentsReportFilter} from '../incidents-report-filter/state'
import {MetricsBetaFilter} from '../metrics-filter-beta/state'

export interface SavedFiltersReduxState {
    loadingState: LoadingState
    savedIncidentsFilter: SavedIncidentsFilter[]
    savedMetricsBetaFilter: SavedMetricsBetaFilter[]
    savedNetworkAssetsFilter: SavedNetworkAssetsFilter[]
    savedUsbInventoryFilter: SavedUsbInventoryFilter[]
    savedSoftwareInventoryFilter: SavedSoftwareInventoryFilter[]
    savedReportsFilter: SavedReportsFilter[]
    savedIncidentsReportFilter: SavedIncidentsReportFilter[]
    isSaveFilterPopupOpen: boolean
    isNotifyMePopupopen: boolean
    resultMessage: ResultMessageType | undefined
    pageType: SavedFilterPageType
    isInactive: boolean
    currentAppliedSavedFilter: AllSavedFilterType | undefined
    allUserSubscriptions: SavedFilterSubscription[] | undefined
    userSubscriptions: SavedFilterSubscription[] | undefined
    existingSubscription: SubscriptionPayload
    newSubscription: UpdatedSubscriptionValue
    templateDefinitions: TemplateDefinitions[] | undefined
}

export interface SavedIncidentsFilter {
    criteria: IncidentsFilterCriteria
    identity: GuidType
    name: string
    page: string
    searchType: SearchType
    userId: GuidType
    createdAt: string
    updatedAt: string
}

export interface SavedMetricsBetaFilter {
    criteria: MetricsBetaFilter
    identity: GuidType
    name: string
    page: string
    searchType: SearchType
    userId: GuidType
    createdAt: string
    updatedAt: string
}

export interface SavedNetworkAssetsFilter {
    criteria: NetworkAssetsFilterCriteria
    identity: GuidType
    name: string
    page: string
    searchType: SearchType
    userId: GuidType
    createdAt: string
    updatedAt: string
}

export interface SavedUsbInventoryFilter {
    criteria: UsbInventoryFilterCriteria
    identity: GuidType
    name: string
    page: string
    searchType: SearchType
    userId: GuidType
    createdAt: string
    updatedAt: string
}

export interface SavedSoftwareInventoryFilter {
    criteria: SoftwareInventoryFilterCriteria
    identity: GuidType
    name: string
    page: string
    searchType: SearchType
    userId: GuidType
    createdAt: string
    updatedAt: string
}

export interface SavedReportsFilter {
    criteria: ReportsFilterCriteria
    identity: GuidType
    name: string
    page: string
    searchType: SearchType
    userId: GuidType
    createdAt: string
    updatedAt: string
}

export interface SavedIncidentsReportFilter {
    criteria: IncidentsReportFilter
    identity: GuidType
    name: string
    page: string
    searchType: SearchType
    userId: GuidType
    createdAt: string
    updatedAt: string
}

export enum SearchType {
    SYSTEM = 'system',
    SHARED = 'shared',
    INDIVIDUAL = 'individual',
}

export enum SavedFilterPageType {
    INCIDENTS = 'Incidents',
    METRICS = 'Metrics_Beta',
    NETWORK_ASSETS = 'Network_assets',
    USB_INVENTORY = 'Usb_inventory',
    SOFTWARE_INVENTORY = 'Software_inventory',
    REPORTS = 'Reports',
    INCIDENTS_REPORT = 'Incidents Report',
}

export enum ResultMessageType {
    SUCCESS = 'Saved successfully',
    FAILED = 'Save failed',
    NOT_SAVABLE = 'This name is already used',
}

export const DEFAULT_SAVED_FILTERS_STATE: SavedFiltersReduxState = {
    loadingState: LoadingState.NotPopulated,
    savedIncidentsFilter: [],
    savedMetricsBetaFilter: [],
    savedNetworkAssetsFilter: [],
    savedUsbInventoryFilter: [],
    savedSoftwareInventoryFilter: [],
    savedReportsFilter: [],
    savedIncidentsReportFilter: [],
    isSaveFilterPopupOpen: false,
    isNotifyMePopupopen: false,
    resultMessage: undefined,
    pageType: SavedFilterPageType.INCIDENTS,
    isInactive: false,
    currentAppliedSavedFilter: undefined,
    allUserSubscriptions: undefined,
    userSubscriptions: undefined,
    existingSubscription: {} as SubscriptionPayload,
    newSubscription: {} as UpdatedSubscriptionValue,
    templateDefinitions: undefined,
}

export type AllSavedFilterType =
    | SavedIncidentsFilter
    | SavedMetricsBetaFilter
    | SavedNetworkAssetsFilter
    | SavedUsbInventoryFilter
    | SavedSoftwareInventoryFilter
    | SavedReportsFilter
    | SavedIncidentsReportFilter

export type AllSavedFilterCriteriaType =
    | IncidentsFilterCriteria
    | NetworkAssetsFilterCriteria
    | UsbInventoryFilterCriteria
    | SoftwareInventoryFilterCriteria
    | MetricsBetaFilter
    | ReportsFilterCriteria
    | IncidentsReportFilter

export enum NotificationScheduleType {
    DAILY = 'daily',
    WEEKLY = 'weekly',
    MONTHLY = 'monthly',
    NEVER = 'never',
}

export interface SavedFilterSubscription {
    identity: GuidType
    searchDefinition: {name: string; page: string}
    searchDefinitionId: GuidType
    subjectPrefix: string
    schedule: NotificationScheduleType
    notificationEmail: string | undefined
    userId: GuidType
    lastItemTimestamp: string
    templateDefinitionId: GuidType
    notifyOnChange: boolean
}

export interface UpdatedSubscriptionValue {
    schedule: NotificationScheduleType
    notifyOnChange?: boolean
    notificationEmail?: string
    subjectPrefix?: string
}

export interface SubscriptionPayload {
    schedule: NotificationScheduleType
    searchDefinitionId: GuidType
    templateDefinitionId: GuidType
    userId: GuidType
    notificationEmail?: string
    subjectPrefix?: string
    identity?: GuidType
    notifyOnChange?: boolean
}

export interface TemplateDefinitions {
    identity: GuidType
    name:
        | 'softwareInventory'
        | 'networkAssets'
        | 'incidents'
        | 'usbInventory'
        | 'metrics'
        | 'reports'
        | 'incidents-report'
    page:
        | 'network-assets'
        | 'usb-devices'
        | 'software'
        | 'incidents'
        | 'metrics'
        | 'reports'
        | 'incidents-report'
}
