import {Aside} from '../../aside/aside'
import {Header, HeaderSize} from '../../header/header'
import * as Styled from './row-based-layout.styled'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {errorSelector} from '../../../../../store/state/session-data/selectors'
import {ReactNode, ReactNodeArray} from 'react'
import {LoginPageTemplate} from '../../../page-template/login-page-template'
import {customLogoSelector} from '../../../../../store/state/config/selectors'
import {HeaderCustomLogo} from '../../header/header-custom-logo'

export function isRowBasedLoginFormat(width: number): boolean {
    return width < 710
}

interface RowBasedLoginLayoutProps {
    children: ReactNode | ReactNodeArray
}
export function RowBasedLoginLayout({children}: RowBasedLoginLayoutProps): JSX.Element {
    const {height} = useDimensions()
    const error = useTypedSelector(errorSelector)
    const hasError = error ? error.length > 0 : false
    const customLogoFlag = useTypedSelector(customLogoSelector)

    return (
        <LoginPageTemplate>
            <Styled.Content>
                {customLogoFlag ? (
                    <HeaderCustomLogo headerSize={HeaderSize.SMALL} />
                ) : (
                    <Header headerSize={HeaderSize.SMALL} />
                )}
                <Styled.CallToActionSection showError={hasError}>
                    {children}
                </Styled.CallToActionSection>
                <Styled.AsideWrapper height={height}>
                    <Aside layout="ROW" />
                </Styled.AsideWrapper>
            </Styled.Content>
        </LoginPageTemplate>
    )
}
