import * as Styled from './_styled/hamburger-menu-container.styled'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {useRef} from 'react'
import {useOnClickOutside} from '../../../../hooks/useOnClickOutside'
import {HamburgerButton} from './hamburger-button'
import {HamburgerMenu} from './hamburger-menu'
import {useToggle} from '../../../../contexts/toggle/use-toggle'

export function HamburgerMenuContainer(): JSX.Element {
    const {width} = useDimensions()

    const {value: isOpen, setValue: setToggle} = useToggle()

    const clickOutside = useRef<HTMLDivElement>(null)
    useOnClickOutside(clickOutside, () => setToggle(false))
    return (
        <Styled.LeftContainerStyle width={width} ref={clickOutside}>
            <HamburgerButton />
            {isOpen && <HamburgerMenu />}
        </Styled.LeftContainerStyle>
    )
}
