import LoadingState from '../../../values/loading-state-enum'
import {LocationIdType} from '../locations/state'
import {SortValue} from '../vessel-filter/types/vessel-filter-state'

export interface SoftwareInventoryFilterReduxState {
    loadingState: LoadingState
    searchedFirstDetected: TimestampFilter
    searchedLastActive: TimestampFilter
    searchedCompanyName: string | undefined
    searchedProductName: string | undefined
    locations: Set<LocationIdType> | undefined
    sortColumn: SortColumnType
    searchVesselTagTerm: string[]
    searchVesselNameTerm: string
    searchedVesselTerm: string
    showXButton: boolean
    vesselSort: SortValue
    selectedFilterName: string
    selectedSoftwareStatus: SoftwareStatus | undefined
    filteredSoftwareStatus: SoftwareStatus[] | undefined
    filterExpanded: FilterExpandedType
    detailExpanded: DetailExpandedType
}

export type FilterExpandedType = {
    softwareStatuses: boolean
}

export enum FilterBarType {
    softwareInventoryStatus = 'Software inventory status',
}

export interface SortColumnType {
    orderBy: SoftwareInventorySortType
    orderAscending: boolean
}

export enum SoftwareStatus {
    unknown = 'unknown',
    trusted = 'trusted',
    unwanted = 'unwanted',
}
export interface DetailExpandedType {
    detailsExpanded: boolean
    insightsExpanded: boolean
    actionsExpanded: boolean
}
export enum SoftwareInventorySortType {
    TIMESTAMP = 'firstSeen',
    LATEST_TIMESTAMP = 'lastSeen',
    COMPANY_NAME = 'company',
    PRODUCT_NAME = 'product',
    NUMBER_OF_HOSTS_PER_ITEM = 'numberOfHostsPerItem',
    NUMBER_OF_VESSELS_PER_ITEM = 'numberOfVesselsPerItem',
    NEWEST_ACTIVE_VERSION = 'newestActiveVersion',
    OLDEST_ACTIVE_VERSION = 'oldestActiveVersion',
}

export type TimestampFilter = 'Last 24h' | 'Last 7 days' | 'Last 30 days' | 'All'

export const DEFAULT_SOFTWARE_INVENTORY_FILTER_STATE: SoftwareInventoryFilterReduxState = {
    loadingState: LoadingState.NotPopulated,
    searchedFirstDetected: 'All',
    searchedLastActive: 'All',
    searchedCompanyName: '',
    searchedProductName: '',
    locations: undefined,
    sortColumn: {
        orderBy: SoftwareInventorySortType.TIMESTAMP,
        orderAscending: false,
    },
    searchVesselTagTerm: [],
    searchVesselNameTerm: '',
    searchedVesselTerm: '',
    showXButton: false,
    vesselSort: {
        field: 'vessel-name',
        direction: 'desc',
    },
    selectedFilterName: 'Saved filters',
    selectedSoftwareStatus: SoftwareStatus.unknown,
    filteredSoftwareStatus: undefined,
    filterExpanded: {softwareStatuses: true},
    detailExpanded: {
        detailsExpanded: true,
        insightsExpanded: true,
        actionsExpanded: true,
    },
}
