import {isEqual} from 'lodash'
import {
    LocationIdType,
    OTSource,
    SourceDestinationOt,
} from '../../../../store/state/locations/state'

export interface OTSourceModalDetails {
    locationId: LocationIdType
    entry: 'new' | 'existing'
    sourceType: string
    sourceData: SourceDestinationOt[]
}

export const DEFAULT_OT_SOURCE_DATA_ARRAY = {
    source: '',
    destination: '',
} as SourceDestinationOt

export const DEFAULT_OT_SOURCE_MODAL_DETAILS = {
    locationId: '',
    entry: 'new',
    sourceType: '',
    sourceData: [DEFAULT_OT_SOURCE_DATA_ARRAY],
} as OTSourceModalDetails

export const getFormatedOTSourceData = (
    updatedFormattedData: OTSourceModalDetails,
    getLocationOtSourcesData: SourceDestinationOt[] | undefined,
): SourceDestinationOt[] => {
    if (!getLocationOtSourcesData) {
        return updatedFormattedData.sourceData
    }
    return updatedFormattedData.sourceData.length === 0
        ? getLocationOtSourcesData
        : updatedFormattedData.entry === 'new'
          ? [...getLocationOtSourcesData, ...updatedFormattedData.sourceData]
          : updatedFormattedData.sourceData
}

export function validateOTSourceModalDetails(
    details: OTSourceModalDetails,
    existingOtSources: OTSource | undefined,
): boolean {
    // Check if locationId is populated
    if (!details.locationId) {
        return false
    }

    // Check if sourceType is either 'syslog' or 'nmea'
    if (details.sourceType !== 'syslog' && details.sourceType !== 'nmea') {
        return false
    }

    // Check if sourceData is an array and each element has a source and destination
    if (!Array.isArray(details.sourceData)) {
        return false
    }

    for (const {source, destination} of details.sourceData) {
        // Check if source and destination have the correct format
        const sourceRegex = /^[^:]+:[^:]+$/ // Format: any_characters:any_characters
        if (!sourceRegex.test(source)) {
            return false
        }
        if (!sourceRegex.test(destination)) {
            return false
        }
    }

    // If entry is 'existing', check if sourceData is different from existingOtSources
    if (details.entry === 'existing' && existingOtSources) {
        const existingSources =
            details.sourceType === 'syslog' ? existingOtSources.syslog : existingOtSources.nmea
        if (!existingSources || !Array.isArray(existingSources)) {
            return false
        }

        // Compare sourceData with existing sources
        if (details.sourceData.length !== existingSources.length) {
            return false
        }
        if (isEqual(details.sourceData, existingSources)) {
            return false
        }
    }

    // All checks passed
    return true
}
