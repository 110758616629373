interface MedullaProps {
    scale?: number
}
export function Medulla({scale = 1}: MedullaProps): JSX.Element {
    const width = 290
    const height = 36

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width * scale}
            height={height * scale}
            viewBox={`0 0 ${width} ${height}`}
        >
            <g id="Group_8661" data-name="Group 8661" transform="translate(0 0)">
                <path
                    id="Path_3672"
                    data-name="Path 3672"
                    d="M4669.758-259.661h13.248l5.362,19.979h.1l5.359-19.979h13.35v35.713h-9.209v-22.377h-.1l-5.795,22.377h-7.455l-5.8-22.377h-.1v22.377h-8.964Z"
                    transform="translate(-4669.758 259.661)"
                    fill="#fff"
                />
                <path
                    id="Path_3673"
                    data-name="Path 3673"
                    d="M4692.273-259.661h27.135v8.49h-16.953v4.845h14.175v7.841h-14.175v5.6H4719.9v8.94h-27.623Z"
                    transform="translate(-4640.81 259.661)"
                    fill="#fff"
                />
                <path
                    id="Path_3674"
                    data-name="Path 3674"
                    d="M4710.161-259.661h16.078c10.032,0,17,6.594,17,17.931,0,11.387-6.624,17.781-17.437,17.781h-15.64Zm14.808,27.071c4.677,0,7.942-3.1,7.942-9.14,0-5.895-3.071-9.291-7.942-9.291h-4.625v18.43Z"
                    transform="translate(-4617.816 259.661)"
                    fill="#fff"
                />
                <path
                    id="Path_3675"
                    data-name="Path 3675"
                    d="M4729.965-237.593v-22.068h10.181V-237c0,3.3,1.755,5.342,5.508,5.342,3.458,0,5.21-2.3,5.21-5.542v-22.466h10.183v22.068c0,8.785-5.117,14.278-15.542,14.278C4735.032-223.315,4729.965-228.657,4729.965-237.593Z"
                    transform="translate(-4592.349 259.661)"
                    fill="#fff"
                />
                <path
                    id="Path_3676"
                    data-name="Path 3676"
                    d="M4749.622-259.661H4759.8v26.773h14.906v8.94h-25.088Z"
                    transform="translate(-4567.065 259.661)"
                    fill="#fff"
                />
                <path
                    id="Path_3677"
                    data-name="Path 3677"
                    d="M4765.634-259.661h10.181v26.773h14.906v8.94h-25.087Z"
                    transform="translate(-4546.472 259.661)"
                    fill="#fff"
                />
                <path
                    id="Path_3678"
                    data-name="Path 3678"
                    d="M4794.029-259.661H4804.4l12.909,35.713H4806.5l-1.706-5.245H4793l-1.705,5.245h-10.134Zm1.264,23.273h7.213l-2.191-6.991c-.539-1.746-1.317-4.594-1.317-4.594h-.1s-.732,2.847-1.316,4.594Z"
                    transform="translate(-4526.544 259.661)"
                    fill="#fff"
                />
            </g>
        </svg>
    )
}
