import {ReactNode, ReactNodeArray} from 'react'
import {Navigation} from '../../components/Navigation'
import useTypedSelector from '../../hooks/use-typed-selector'
import {activeLocationSelector} from '../../store/state/locations/selectors'
import {isActiveLocation} from '../../store/state/locations/state'
import {FixedPageSizeContainer} from './container.styled'
import {NavigationWrapper} from './navigation-wrapper.styled'

interface Props {
    children: ReactNode | ReactNodeArray
}
export function FixedPageTemplate({children}: Props): JSX.Element {
    const showingSelectedLocation = isActiveLocation(useTypedSelector(activeLocationSelector))
    return (
        <FixedPageSizeContainer showingSelectedLocation={showingSelectedLocation}>
            <NavigationWrapper>
                <Navigation />
            </NavigationWrapper>
            {children}
        </FixedPageSizeContainer>
    )
}
