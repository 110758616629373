import {ReactNode, useReducer} from 'react'
import {DEFAULT_TAG_COMPONENT_STATE} from './types/default-tag-component-state'
import {tagComponentReducer} from './state/reducer'
import {tagsComponentContext} from './tags-component-context'

type TagsComponentProviderProps = {
    children: ReactNode | ReactNode[]
}

export function TagsComponentProvider({children}: TagsComponentProviderProps): JSX.Element {
    const [state, dispatch] = useReducer(tagComponentReducer, DEFAULT_TAG_COMPONENT_STATE)

    return (
        <tagsComponentContext.Provider value={{state, dispatch}}>
            {children}
        </tagsComponentContext.Provider>
    )
}
