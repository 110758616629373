import * as Styles from './_styles/nav-bar-links.styled'
import {NavBarLink} from './nav-bar-link'
import {DashboardLink} from './dashboard-link'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {InventoryLink} from './inventory-link'
import {fleetConfigSelector} from '../../../../store/state/config/selectors'
import {isInternalUserSelector} from '../../../../store/state/current-user/selectors'
import {SystemLink} from './system-link'
import {useIncidentsWidget} from '../../../../pages/dashboard-v2/components/incident-response/contexts/use-incidents-widget'

export function NavBarLinks(): JSX.Element {
    const {hasNewIncidents} = useIncidentsWidget()
    const isInternalUser = useTypedSelector(isInternalUserSelector)
    const fleetConfig = useTypedSelector(fleetConfigSelector)
    const showInventoryLink =
        fleetConfig.networkAssets ||
        fleetConfig.usbInventory ||
        fleetConfig.softwareInventory ||
        fleetConfig.nmeaInventory

    return (
        <Styles.NavBarLinks>
            <DashboardLink />
            <NavBarLink id="navigation-my-vessels" path="/my-vessels" navLinkText="Vessels" />
            <NavBarLink
                id="navigation-incidents"
                path="/incidents"
                navLinkText="Incidents"
                showIndicator={hasNewIncidents}
            />
            {(isInternalUser || fleetConfig.reports) && (
                <NavBarLink id="navigation-reports" path="/reports" navLinkText="Reports" />
            )}
            <NavBarLink id="navigation-metrics" path="/metrics" navLinkText="Metrics" />
            {(isInternalUser || showInventoryLink) && <InventoryLink />}
            <SystemLink />
        </Styles.NavBarLinks>
    )
}
