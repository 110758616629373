import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'

export const LocationExpandedButtonStyle = styled.button`
    padding: 0;
    padding-right: ${spacing(1)};
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    transition: all 1s ease;
    color: ${(props) => props.theme.navigationResponsive.dataArea.miniTextColor};
`
