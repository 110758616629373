import {Dispatch} from 'react'
import {ActionType} from './action-type'
import * as Actions from './actions'
import {REST} from '../../../../../..'
import {
    DEFAULT_FLEET_SCORE_OVER_TIME_FILTERS,
    FleetScoreOverTimeFilter,
} from '../types/fleet-score-over-time-filter'
import {VesselTags} from '../../../../../../store/state/vessel-tags/state'

const USER_PREFS_URL = '/api/v1/userPreferences/fleet_score_over_time'

function requestFilter(): Actions.RequestFilterAction {
    return {
        type: ActionType.REQUEST_FILTER,
    }
}

function receivePrefsFilter(
    receivedFilter: FleetScoreOverTimeFilter,
): Actions.ReceivePrefsFilterAction {
    return {
        type: ActionType.RECEIVE_PREFS_FILTER,
        payload: receivedFilter,
    }
}

export function fetchFleetScoreOverTimeFilter(
    dispatch: Dispatch<Actions.AllActions>,
    assignedTag: VesselTags | undefined,
): void {
    dispatch(requestFilter())

    REST.get(USER_PREFS_URL).then(
        (response) => {
            dispatch(
                receivePrefsFilter({
                    ...DEFAULT_FLEET_SCORE_OVER_TIME_FILTERS,
                    ...response.data.value,
                    searchedVesselTagTerm: assignedTag ?? response.data.value.searchedVesselTagTerm,
                }),
            )
        },
        () => {
            dispatch(
                receivePrefsFilter({
                    ...DEFAULT_FLEET_SCORE_OVER_TIME_FILTERS,
                    searchedVesselTagTerm: assignedTag,
                }),
            )
        },
    )
}

export function showFilter(show = false): Actions.ShowFilterAction {
    return {
        type: ActionType.SHOW_FILTER,
        payload: show,
    }
}

export function setSearchVesselTagTerm(
    searchedVesselTagTerm: VesselTags | undefined,
    dispatch: Dispatch<Actions.SetSearchVesselTagTermAction>,
    currentFilter: FleetScoreOverTimeFilter,
): void {
    dispatch({
        type: ActionType.SET_SEARCH_VESSEL_TAG_TERM,
        payload: searchedVesselTagTerm,
    })
    REST.put(USER_PREFS_URL, {...currentFilter, searchedVesselTagTerm: searchedVesselTagTerm})
}
