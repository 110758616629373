enum ActionType {
    SET_PAGE_TYPE = '[SAVED FILTERS] SET PAGE TYPE',
    REQUEST_SAVED_FILTERS = '[SAVED FILTERS] REQUEST SAVED FILTERS',
    RECEIVE_SAVED_FILTERS = '[SAVED FILTERS] RECEIVE SAVED FILTERS',
    DELETE_SAVED_FILTER = '[SAVED FILTERS] DELETE SAVED FILTER',
    RECEIVED_ALL_USER_SUBSCRIPTIONS = '[SAVED FILTERS] RECEIVED ALL USER SUBSCRIPTIONS',
    RECEIVED_TEMPLATE_DEFINITIONS_SUMMARY = '[SAVED FILTERS] RECEIVED TEMPLATE DEFINITIONS SUMMARY',
    RECEIVED_REQUESTED_USER_SUBSCRIPTIONS = '[SAVED FILTERS] RECEIVED REQUESTED USER SUBSCRIPTIONS',
    CREATE_NEW_USER_SUBSCRIPTION = '[SAVED FILTERS] CREATE NEW USER SUBSCRIPTION',
    UPDATE_USER_SUBSCRIPTION = '[SAVED FILTERS] UPDATE USER SUBSCRIPTION',
    DELETE_USER_SUBSCRIPTION = '[SAVED FILTERS] DELETE USER SUBSCRIPTION',
    OPEN_SAVE_FILTER_POPUP = '[SAVED FILTERS] OPEN SAVE FILTER POPUP',
    SAVE_NEW_FILTER = '[SAVED FILTERS] SAVE NEW FILTER',
    SET_RESULT_MESSAGE = '[SAVED FILTERS] SET RESULT MESSAGE',
    SET_INACTIVE_COMPONENT = '[SAVED FILTERS] SET INACTIVE COMPONENT',
    OPEN_NOTIFY_ME_POPUP = '[SAVED FILTERS] OPEN NOTIFY ME POPUP',
    SET_CURRENT_APPLIED_SAVED_FILTER = '[SAVED FILTERS] SET CURRENT APPLIED SAVED FILTER',
    SET_CHANGE_ALERT_SCHEDULE = '[SAVED FILTERS] SET CHANGE ALERT SCHEDULE',
    SET_CHANGE_ALERT_EMAIL = '[SAVED FILTERS] SET CHANGE ALERT EMAIL',
    SET_CHANGE_ALERT_PREFIX = '[SAVED FILTERS] SET CHANGE ALERT PREFIX',
    SET_NOTIFY_ON_CHANGE = '[SAVED FILTERS] SET NOTIFY ON CHANGE',
    RESET_ALERT_CHANGES = '[SAVED FILTERS] RESET ALERT CHANGES',
}

export default ActionType
