import {NodeValue} from '../../../values/nodes/NodeData'
import {ThreatMeasure, ThreatMeasureMap} from '../../../values/ThreatMeasure'

export interface ThreatMeasuresReduxState {
    isFetchingThreatMeasures: boolean
    threatMeasures: ThreatMeasureMap
}

export interface ThreatMeasuresObject {
    HIGH?: ThreatMeasure
    MEDIUM?: ThreatMeasure
    LOW?: ThreatMeasure
}

export const DEFAULT_THREAT_MEASURES_STATE: ThreatMeasuresReduxState = {
    isFetchingThreatMeasures: true,
    threatMeasures: new Map<NodeValue, ThreatMeasure>(),
}
