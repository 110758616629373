/* eslint-disable @typescript-eslint/no-explicit-any */
import {compose} from 'redux'

const isDevEnv: () => boolean = () => process.env.NODE_ENV === 'development'

// (https://github.com/zalmoxisus/redux-devtools-extension#usage)
// tslint:disable
const composeEnhancers =
    isDevEnv() &&
    typeof window !== 'undefined' &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        : compose
// tslint:enable

export default composeEnhancers
