import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import * as Styles from './_styles/full-width-nav-bar.styled'
import {NavBarLinks} from './nav-bar-links'
import {Logo} from './logo'
import {Logout} from './logout'
import {ToggleProvider} from '../../../../contexts/toggle/toggle-provider'

export function FullWidthNavBar(): JSX.Element {
    const {width} = useDimensions()

    return (
        <Styles.NavigationBar id="fullwidth-navigation">
            <Styles.CenteredContent width={width}>
                <Logo />
                <ToggleProvider>
                    <NavBarLinks />
                </ToggleProvider>
                <Logout />
            </Styles.CenteredContent>
        </Styles.NavigationBar>
    )
}
