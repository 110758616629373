import {useToggle} from '../../../../contexts/toggle/use-toggle'
import {IndicatorDot} from './_styled/nav-bar-content.styled'
import {NavigationLinkStyle} from './_styled/nav-bar-content.styled'

interface NavBarLinkProps {
    id: string
    path: string
    navLinkText: string
    showIndicator?: boolean
}

export function NavBarLink({id, path, navLinkText, showIndicator}: NavBarLinkProps): JSX.Element {
    const {setValue: setMenuOpen} = useToggle()

    return (
        <NavigationLinkStyle
            id={id}
            to={path}
            activeClassName="activeLink"
            onClick={() => setMenuOpen(false)}
        >
            {navLinkText}
            {showIndicator && <IndicatorDot />}
        </NavigationLinkStyle>
    )
}
