import * as ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {Store} from 'redux'
import {ConnectedRouter} from 'connected-react-router'
import {enableMapSet} from 'immer'
import {makeAxios} from './api/axiosApi/axiosAPI'
import configureStore, {history} from './store/configure-store'
import AppState from './store/types/app-state'
import {unregister} from './RegisterServiceWorker'
import App from './root/App'
import {AxiosInstance} from 'axios'
import {FleetScoreOverTimeProvider} from './pages/dashboard-v2/components/fleet-score-over-time/contexts/fleet-score-over-time-provider'
import {TagsComponentProvider} from './pages/dashboard-v2/components/tags-component/contexts/tags-component-provider'
import {IncidentsWidgetProvider} from './pages/dashboard-v2/components/incident-response/contexts/incidents-widget-provider'

export let store: Store<AppState>
export let REST: AxiosInstance

const consoleStyles =
    'font-family: "Open Sans", sans-serif; margin: 10px; padding: 10px; font-size: 16px; background-color: #303030; font-weight: bold; color: #e7e7e7'

enableMapSet()

makeAxios().then((httpClient) => {
    REST = httpClient
    store = configureStore()

    // eslint-disable-next-line no-console
    console.info('%cMedulla by CyberOwl', consoleStyles)

    // eslint-disable-next-line no-console
    console.info(
        `%cVersion: ${process.env.REACT_APP_MEDULLA_VERSION}${
            process.env.REACT_APP_BUILD_NUMBER ? `+${process.env.REACT_APP_BUILD_NUMBER}` : ''
        }`,
        consoleStyles,
    )

    // eslint-disable-next-line no-console
    console.info(`%c${process.env.REACT_APP_UI_COMPONENTS_VERSION}`, consoleStyles)

    ReactDOM.render(
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <FleetScoreOverTimeProvider>
                    <TagsComponentProvider>
                        <IncidentsWidgetProvider>
                            <App />
                        </IncidentsWidgetProvider>
                    </TagsComponentProvider>
                </FleetScoreOverTimeProvider>
            </ConnectedRouter>
        </Provider>,
        document.getElementById('root') as HTMLElement,
    )
})

// https://stackoverflow.com/a/49605422/28431371
unregister()
