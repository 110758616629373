import ActionType from './action-type'
import {Action} from './actions'

import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'

import {DEFAULT_THREAT_MEASURES_STATE, ThreatMeasuresReduxState} from './state'
import {ThreatMeasure, ThreatMeasureMap} from '../../../values/ThreatMeasure'
import {NodeValue} from '../../../values/nodes/NodeData'

const reduceArrayToMap = (threatMeasures: ThreatMeasure[]): ThreatMeasureMap => {
    return threatMeasures?.reduce((dictionary: ThreatMeasureMap, threatMeasure: ThreatMeasure) => {
        dictionary.set(threatMeasure.nodeValue, threatMeasure)

        return dictionary
    }, new Map<NodeValue, ThreatMeasure>())
}
function newThreatMeasuresMap(): ThreatMeasureMap {
    return new Map<NodeValue, ThreatMeasure>()
}
function isUpdated(
    currentValue: ThreatMeasure | undefined,
    newValue: ThreatMeasure | undefined,
): boolean {
    return (
        currentValue?.nodeValue != newValue?.nodeValue ||
        currentValue?.lowerThreshold != newValue?.lowerThreshold ||
        currentValue?.upperThreshold != newValue?.upperThreshold
    )
}
function requestThreatMeasures(currentState: ThreatMeasuresReduxState): ThreatMeasuresReduxState {
    if (currentState.isFetchingThreatMeasures) {
        return currentState
    }

    return {
        ...currentState,
        isFetchingThreatMeasures: true,
    }
}
function receiveThreatMeasures(
    currentState: ThreatMeasuresReduxState,
    newData: ThreatMeasureMap,
): ThreatMeasuresReduxState {
    const newRecords: ThreatMeasure[] = []
    const updatedRecords: ThreatMeasure[] = []
    const unchangedRecords: ThreatMeasure[] = []
    const receivedData = newData != undefined ? newData : newThreatMeasuresMap()
    receivedData.forEach((threatMeasure) => {
        const newValue = receivedData.get(threatMeasure.nodeValue)

        if (currentState.threatMeasures.has(threatMeasure.nodeValue)) {
            const currentValue = currentState.threatMeasures.get(threatMeasure.nodeValue)
            if (isUpdated(currentValue, newValue)) {
                updatedRecords.push(threatMeasure)
            } else {
                unchangedRecords.push(threatMeasure)
            }
        } else {
            newRecords.push(threatMeasure)
        }
    })
    if (newRecords.length === 0 && updatedRecords.length === 0) {
        if (unchangedRecords.length === currentState.threatMeasures.size) {
            if (currentState.isFetchingThreatMeasures === false) {
                return currentState
            }

            return {
                ...currentState,
                isFetchingThreatMeasures: false,
            }
        }

        const data = newThreatMeasuresMap()
        unchangedRecords.forEach((unchangedRecord) =>
            data.set(unchangedRecord.nodeValue, unchangedRecord),
        )
        return {
            ...currentState,
            threatMeasures: data,
            isFetchingThreatMeasures: false,
        }
    }

    const data = newThreatMeasuresMap()
    newRecords.forEach((newRecord) => data.set(newRecord.nodeValue, newRecord))
    updatedRecords.forEach((updatedRecord) => data.set(updatedRecord.nodeValue, updatedRecord))
    unchangedRecords.forEach((unchangedRecord) =>
        data.set(unchangedRecord.nodeValue, unchangedRecord),
    )

    return {
        ...currentState,
        threatMeasures: data,
        isFetchingThreatMeasures: false,
    }
}
function logout(currentState: ThreatMeasuresReduxState): ThreatMeasuresReduxState {
    if (currentState.isFetchingThreatMeasures === true && currentState.threatMeasures?.size === 0) {
        return currentState
    }

    return DEFAULT_THREAT_MEASURES_STATE
}
export default function threatMeasuresReducer(
    state: ThreatMeasuresReduxState = DEFAULT_THREAT_MEASURES_STATE,
    action: Action | LoggingOutAction,
): ThreatMeasuresReduxState {
    switch (action.type) {
        case ActionType.REQUEST_THREAT_MEASURES:
            return requestThreatMeasures(state)
        case ActionType.RECEIVE_THREAT_MEASURES:
            return receiveThreatMeasures(state, reduceArrayToMap(action.payload))
        case SessionActionType.LOGGING_OUT:
            return logout(state)
        /* istanbul ignore next */
        default:
            return state
    }
}
