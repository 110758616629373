import useTypedSelector from '../../../../hooks/use-typed-selector'
import {locationsSelector} from '../../../../store/state/locations/selectors'
import {DEFAULT_LOCATIONS_STATE} from '../../../../store/state/locations/state'
import {DropDownItem} from './drop-down-item'
import {Divider, DropdownMenuStyle} from './_styled/location-expanded.styled'

const {activeLocation: myFleet} = DEFAULT_LOCATIONS_STATE

export function LocationExpanded(): JSX.Element {
    const locations = useTypedSelector(locationsSelector)

    return (
        <DropdownMenuStyle>
            <DropDownItem location={myFleet} />
            <Divider />
            {locations.map((location) => (
                <DropDownItem key={location.location} location={location} />
            ))}
        </DropdownMenuStyle>
    )
}
