import moment from 'moment'
import {LatestEventTimestampMap} from '../../store/state/latest-event-timestamps/state'
import {DEFAULT_DATE_FORMAT} from '../../helpers/consts'
import {GuidType, IpAddressType, MacAddressType} from '../generic-type-defintions'
import {LocationIdType} from '../../store/state/locations/state'

export enum NodeValues {
    LOW = 'LOW',
    MEDIUM = 'MEDIUM',
    HIGH = 'HIGH',
}

export type NodeValue = NodeValues.LOW | NodeValues.MEDIUM | NodeValues.HIGH

export interface NodeData {
    ipAddress: IpAddressType
    node: NodeId
    alias: string
    value: NodeValue
    priorProbability: number
    location: LocationIdType
    hostName: string
    macAddress?: MacAddressType
    createdAt: string
    updatedAt: string
    agentId: GuidType
    agent5Version?: string
}

export type NodeId = string

export type NodeDataMap = Map<NodeId, NodeData>

export const getNodeLatestEvent = (node: NodeId, timestamps: LatestEventTimestampMap): string => {
    if (!node || !timestamps) {
        return 'unknown'
    }

    try {
        return timestamps.has(node)
            ? moment(timestamps.get(node)).format(DEFAULT_DATE_FORMAT)
            : 'unknown'
    } catch {
        /* istanbul ignore next */
        return 'unknown'
    }
}

export const getNodeLatestEventOrNA = (
    node: NodeId,
    timestamps: LatestEventTimestampMap,
): string => {
    if (!node || !timestamps) {
        return 'N/A'
    }

    try {
        return timestamps.has(node)
            ? moment(timestamps.get(node)).format(DEFAULT_DATE_FORMAT)
            : 'N/A'
    } catch {
        /* istanbul ignore next */
        return 'Unknown'
    }
}
