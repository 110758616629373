import * as Styled from './styles/secondary-button.styled'
interface GetMoreButtonProps {
    smallText: boolean
}

export function GetMoreButton({smallText}: GetMoreButtonProps): JSX.Element {
    return (
        <Styled.SecondaryButton
            id="get-more-link"
            rel="noreferrer"
            target="_blank"
            smallText={smallText}
            href="https://share.hsforms.com/1YVGCy2J9RLyn0TFQKo5lgw20ekh"
        >
            Get More Out Of Medulla
        </Styled.SecondaryButton>
    )
}
