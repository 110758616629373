enum ActionType {
    REQUEST_FILTER = '[MY VESSELS FILTERS] REQUEST FILTER',
    RECEIVE_FILTER = '[MY VESSELS FILTERS] RECEIVE FILTER',
    TOGGLE_ALL_ASSETS = '[MY VESSELS FILTERS] TOGGLE ALL ASSET FILTERS',
    TOGGLE_VALUE_THRESHOLD = '[MY VESSELS FILTERS] TOGGLE VALUE THRESHOLD FILTER',
    TOGGLE_VESSEL = '[MY VESSELS FILTERS] TOGGLE VESSEL',
    TOGGLE_ALL_VESSELS = '[MY VESSELS FILTERS] TOGGLE ALL VESSELS',
    TOGGLE_FILTERED_VESSELS = '[MY VESSELS FILTERS] TOGGLE FILTERED VESSELS',
    SET_SEARCH_ASSET_TERM = '[MY VESSELS FILTERS] SET SEARCH ASSET TERM',
    SET_SEARCH_VESSEL_TAG_TERM = '[MY VESSELS FILTERS] SET SEARCH VESSEL TAG TERM',
    SET_SHOW_X_BUTTON = '[MY VESSELS FILTERS] SET SHOW X BUTTON',
    ASSET_SORT = '[MY VESSELS FILTERS] ASSET SORT',
    VESSEL_SORT = '[MY VESSELS FILTERS] VESSEL SORT',
    CHANGE_ZOOM_LEVEL = '[MY VESSELS FILTERS] CHANGE ZOOM LEVEL',
    RESET_FILTER = '[MY VESSELS FILTERS] RESET MY VESSELS FILTER',
    DEEP_LINK_VESSEL = '[MY VESSELS FILTERS] DEEP LINK VESSEL',
    SET_SEARCH_VESSEL_NAME_TERM = '[MY VESSELS FILTERS] SET SEARCH VESSEL NAME TERM',
    SET_SEARCH_VESSEL_TERM = '[MY VESSELS FILTERS] SET SEARCH VESSEL TERM',
}

export default ActionType
