import {isEqual} from 'lodash'
import {LocationIdType} from '../../../store/state/locations/state'
import {NodeDataMap} from '../../../values/nodes/NodeData'
import {ListOfAssociatedAssets} from './types/usb-devices-response'
import {StringUtils} from '../../../utils/Utils'
import {TimestampFilter} from '../../../store/state/usb-inventory-filter/state'

export function buildLocations(
    allLocations: Array<LocationIdType>,
    currentSelectedLocations: Set<LocationIdType> | undefined,
    location: LocationIdType | LocationIdType[],
    newValue: boolean,
): string | Array<LocationIdType> {
    const newLocations = new Set<LocationIdType>(
        currentSelectedLocations != undefined ? currentSelectedLocations : allLocations,
    )

    if (StringUtils.validString(location)) {
        if (newValue) {
            newLocations.add(location)
        } else {
            newLocations.delete(location)
        }
    } else if (Array.isArray(location)) {
        if (newValue) {
            location.forEach((e) => newLocations.add(e))
        } else {
            location.forEach((e) => newLocations.delete(e))
        }
    }

    if (newLocations.size === 0) {
        return 'all-deselected'
    }

    if (newLocations.size === allLocations?.length) {
        return 'all-selected'
    }

    return Array.from(newLocations)
}

export function populateLocations(
    locations: string | Array<LocationIdType>,
): Set<LocationIdType> | undefined {
    try {
        if (!locations || isEqual(locations, {}) || locations === 'all-selected') {
            return undefined
        }

        if (locations === 'all-deselected') {
            return new Set<LocationIdType>()
        }

        return new Set<LocationIdType>(locations)
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error({err, locations})
        return undefined
    }
}

export function getListOfVesselsForGivenAssets(
    listOfAssociatedAssets: ListOfAssociatedAssets[] | undefined,
    nodes: NodeDataMap,
): LocationIdType[] {
    let listOfLocationsIds: LocationIdType[] = []
    if (!listOfAssociatedAssets) {
        return listOfLocationsIds
    }

    listOfLocationsIds = listOfAssociatedAssets?.map(
        (element) => nodes?.get(element.identity)?.location ?? '',
    )
    const uniqueLocationIds = [...new Set(listOfLocationsIds)]
    return uniqueLocationIds.filter((element) => element !== '') ?? []
}

export function formatTimestampCriteria(searchedTimestamp: TimestampFilter): string | null {
    switch (searchedTimestamp) {
        case 'Last 24h':
            return '-24h'
        case 'Last 7 days':
            return '-168h'
        case 'Last 30 days':
            return '-720h'
        case 'All':
        default:
            return null
    }
}

export function reformatTimestampCriteria(searchedTimestamp: string | null): TimestampFilter {
    switch (searchedTimestamp) {
        case '-24h':
            return 'Last 24h'
        case '-168h':
            return 'Last 7 days'
        case '-720h':
            return 'Last 30 days'
        default:
            return 'All'
    }
}
