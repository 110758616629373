import ActionType from './action-type'
import {Action} from './actions'

import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'

import {CurrentUserReduxState, DEFAULT_CURRENT_USER_STATE} from './state'
import produce from 'immer'
import {isEqual} from 'lodash'

export const currentUserReducer = produce(
    (
        draft: CurrentUserReduxState = DEFAULT_CURRENT_USER_STATE,
        action: Action | LoggingOutAction,
    ) => {
        switch (action.type) {
            case ActionType.REQUEST_CURRENT_USER:
                draft.isFetching = true
                break

            case ActionType.RECEIVE_CURRENT_USER:
                if (!isEqual(draft.currentUser, action.payload)) {
                    draft.currentUser = action.payload
                }
                draft.isFetching = false
                break

            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_CURRENT_USER_STATE
                break

            /* istanbul ignore next */
            default:
                break
        }

        return draft
    },
)
