import {GuidType} from '../../../values/generic-type-defintions'

export interface ExtendedDataRequestReduxState {
    inventory: InventoryType | undefined
    isModalOpen: boolean
    currentLocations: GuidType[]
    data: DataRequest
    isProcessingSave: ProcessingStatus
    additionalRequirement: string | undefined
    isAdditionalRequirementExist: boolean
}

export interface DataRequest {
    location: GuidType | null
    title: string
    dateRange: string
    requirements: string[]
    purpose: string
    reference: string
}

export enum PurposeType {
    INVESTIGATION = 'Help with investigation of an incident from another source',
    DETAIL_PANEL_ACCESS = 'A regular review or audit of',
    VESSEL_SETTINGS_ACCESS = 'A one off review of',
    INTERLINK_CLICK = 'Something else',
}

export enum InventoryType {
    NETWORK = 'Networked devices',
    USB = 'USB devices',
    SOFTWARE = 'Software',
}

export enum ProcessingStatus {
    NOT_PROCESSING = 'NOT_PROCESSING',
    PROCESSING = 'PROCESSING',
    PROCESSED = 'PROCESSED',
    FAILED = 'FAILED',
}

export const DEFAULT_EXTENDED_DATA_REQUEST_STATE: ExtendedDataRequestReduxState = {
    inventory: undefined,
    isModalOpen: false,
    currentLocations: [],
    data: {} as DataRequest,
    isProcessingSave: ProcessingStatus.NOT_PROCESSING,
    additionalRequirement: undefined,
    isAdditionalRequirementExist: false,
}
