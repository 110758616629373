import {createSelector} from 'reselect'
import {Role} from '../../values/Role'

import {rolesSelector, isFetchingRolesSelector} from '../../store/state/roles/selectors'

const rolesCheckerReselector = createSelector(
    isFetchingRolesSelector,
    rolesSelector,
    (userRolesIsFetching: boolean, userRoles: Role[]) => {
        if (userRolesIsFetching) {
            return []
        }

        return userRoles
    },
)

export default rolesCheckerReselector
