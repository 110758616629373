import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {LocationExpandedButtonStyle} from './location-expanded-button.styled'

interface LocationExpandedButtonProps {
    dropdownOpen: boolean
    toggle: () => void
}

export function LocationExpandedButton({
    dropdownOpen,
    toggle,
}: LocationExpandedButtonProps): JSX.Element {
    return (
        <LocationExpandedButtonStyle onClick={toggle} id="navigation-location-toggle">
            <Icon glyph={dropdownOpen ? 'DropdownUp' : 'DropdownDown'} height={15} width={15} />
        </LocationExpandedButtonStyle>
    )
}
