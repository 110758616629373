export interface WatchIncidentsReduxState {
    watchedIncidents: string[]
    error: string | undefined
    loading: boolean
}

export const DEFAULT_WATCH_INCIDENTS_STATE: WatchIncidentsReduxState = {
    watchedIncidents: [],
    error: undefined,
    loading: true,
}
