import {LocationIdType} from '../../../../store/state/locations/state'
import {AnomalyIdType} from '../../../../values/anomalies/anomaly'
import {IpAddressType} from '../../../../values/generic-type-defintions'

export interface IsolatedAssetsType {
    identity: AnomalyIdType
    locationIdentity: LocationIdType
    ipAddresses: IpAddressType[]
    assetStatus: string
    isolationStatus: IsolationStatusType
    createdAt: string
    updatedAt: string
}
export enum IsolationStatusType {
    UNDECIDED = 'UNDECIDED',
    ALLOWED_AUTOMATICALLY_DURING_LEARNING_PERIOD = 'ALLOWED_AUTOMATICALLY_DURING_LEARNING_PERIOD',
    ALLOWED_AUTOMATICALLY_DURING_ISOLATION_OFF = 'ALLOWED_AUTOMATICALLY_DURING_ISOLATION_OFF',
    ALLOWED_AUTOMATICALLY_MANAGED_ASSET = 'ALLOWED_AUTOMATICALLY_MANAGED_ASSET',
    ALLOWED_AUTOMATICALLY_MONITORED_ASSET = 'ALLOWED_AUTOMATICALLY_MONITORED_ASSET',
    ALLOWED_ACTIVE_CONTROL_OFF = 'ALLOWED_ACTIVE_CONTROL_OFF',
    ALLOWED_SWITCHED_TO_MANAGED_ASSET = 'ALLOWED_SWITCHED_TO_MANAGED_ASSET',
    ALLOWED_SWITCHED_TO_MONITORED_ASSET = 'ALLOWED_SWITCHED_TO_MONITORED_ASSET',
    ALLOWED_SWITCHED_TO_MERGED_ASSET = 'ALLOWED_SWITCHED_TO_MERGED_ASSET',
    ALLOWED_NETWORK_RECONFIGURATION_DETECTED = 'ALLOWED_NETWORK_RECONFIGURATION_DETECTED',
    TRUSTED_MANUALLY = 'TRUSTED_MANUALLY',
    BLOCKED_AUTOMATICALLY = 'BLOCKED_AUTOMATICALLY',
    BLOCKED_MANUALLY = 'BLOCKED_MANUALLY',
    RE_BLOCKED_MANUALLY = 'RE_BLOCKED_MANUALLY',
}

export interface IsolationStatusFormatted {
    isolationStatus: IsolationStatusType
    updatedAt: string
}

export enum IsolationOperation {
    TRUST = 'Trust',
    BLOCK = 'Block',
}

export interface TotalNumberOfUnknownAssetsByStatus {
    TRUST: number
    BLOCK: number
    MANAGED: number
    UNKNOWN: number
}

export enum NetworkAssetState {
    UNKNOWN = 'unknown',
    TRUSTED = 'trusted',
    BLOCKED = 'blocked',
    MONITORED = 'monitored',
    UNDECIDED = 'undecided',
}

export enum NetworkAssetMonitoringState {
    UNKNOWN = 'unknown',
    MONITORED = 'monitored',
}
