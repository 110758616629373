import ActionType from './action-type'
import * as Actions from './actions'
import {ThunkAction} from 'redux-thunk'
import {REST} from '../../..'
import AppState from '../../types/app-state'
import {Api} from '../../../api/Api'
import {GuidType} from '../../../values/generic-type-defintions'

const WATCHED_INCIDENTS_URL = '/api/v1/incidentResponseWatch'

const setError = (error: string): Actions.SetError => ({
    type: ActionType.SET_ERROR,
    payload: error,
})

const setLoading = (): Actions.SetLoading => ({
    type: ActionType.SET_LOADING,
})

const setWatchedIncidents = (watchedIncidents: string[]): Actions.SetWatchedIncidents => ({
    type: ActionType.SET_WATCHED_INCIDENTS,
    payload: watchedIncidents,
})

export const fetchWatchedIncidents = (): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch) => {
        dispatch(setLoading())
        REST.get(WATCHED_INCIDENTS_URL)
            .then((response) => {
                dispatch(setWatchedIncidents(response.data))
            })
            .catch((err) => dispatch(setError(err)))
    }
}

export const addIncidentToWatchList = (
    incidentId: GuidType,
): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch) => {
        dispatch(setLoading())
        REST.post(`${WATCHED_INCIDENTS_URL}/${incidentId}`)
            .then(() => {
                dispatch(fetchWatchedIncidents())
            })
            .catch((err) => {
                dispatch(fetchWatchedIncidents())
                dispatch(setError(err))
            })
    }
}

export const deleteIncidentFromWatchList = (
    incidentId: GuidType,
): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch) => {
        dispatch(setLoading())
        REST.delete(`${WATCHED_INCIDENTS_URL}/${incidentId}`)
            .then(() => {
                dispatch(fetchWatchedIncidents())
            })
            .catch((err) => {
                dispatch(fetchWatchedIncidents())
                dispatch(setError(err))
            })
    }
}
