import * as Styles from './_styles/logo.styled'
import {LogoGroup} from '../../../../pages/login-v2/components/header/LogoGroup'

export function Logo(): JSX.Element {
    return (
        <Styles.Logo>
            <LogoGroup />
        </Styles.Logo>
    )
}
