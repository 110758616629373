import styled from 'styled-components'

export type TextSizeType = 'SMALL' | 'LARGE'
interface AsideProps {
    centered: boolean
    textSize: TextSizeType
}
export const Aside = styled.aside<AsideProps>`
    font-weight: 600;
    font-size: ${(props) => (props.textSize === 'LARGE' ? '20px' : '14px')};
    line-height: ${(props) => (props.textSize === 'LARGE' ? '28px' : '19px')};
    letter-spacing: ${(props) => (props.textSize === 'LARGE' ? '0.4px' : '0.24px')};
    text-align: ${(props) => (props.centered ? 'center' : 'left')};
`
