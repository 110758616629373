import {DEFAULT_METRIC_TYPES_STATE, MetricTypesReduxState} from './state'
import ActionType from './action-type'
import {Action} from './actions'
import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'
import LoadingState from '../../../values/loading-state-enum'
import produce from 'immer'
import {isEqual} from 'lodash'

export const metricTypesReducer = produce(
    (
        draft: MetricTypesReduxState = DEFAULT_METRIC_TYPES_STATE,
        action: Action | LoggingOutAction,
    ) => {
        switch (action.type) {
            case ActionType.REQUEST_METRIC_TYPES:
                draft.loading = LoadingState.RequestingData
                break

            case ActionType.RECEIVED_METRIC_TYPES:
                draft.loading = LoadingState.Loaded
                if (!isEqual(draft.dataMetricResponseMap, action.payload.dataMetricResponseMap)) {
                    draft.dataMetricResponseMap = action.payload.dataMetricResponseMap
                }
                if (!isEqual(draft.formattedMetricData, action.payload.formattedMetricData)) {
                    draft.formattedMetricData = action.payload.formattedMetricData
                }
                break

            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_METRIC_TYPES_STATE
                break
            /* istanbul ignore next */
            default:
                break
        }
        return draft
    },
)
