import LoadingState from '../../../../../../values/loading-state-enum'
import {
    IncidentCountsType,
    IncidentAssignmentsCounts,
    IncidentTrendsType,
    IncidentAvgPerformanceType,
    IncidentVesselsPerformanceType,
} from '../../../../../incidents-report/contexts/types/incidents-report-output'
import {IncidentWidgetType} from './incident-widget-type'
import {IncidentsOverview} from './incidents-overview'

import {IncidentsWidgetState, IncidentWidgetFilter} from './incidents-widget-state'

export const DEFAULT_INCIDENTS_WIDGET_FILTER: IncidentWidgetFilter = {
    locations: undefined,
    searchVesselTagTerm: [],
    incidentWidgetOnDashboard: IncidentWidgetType.LATEST_INCIDENT,
    periodWidgetOnDashboard: '30d',
}
export function defaultIncidentsOverview(): IncidentsOverview {
    return {
        latestIncident: undefined,
        newIncidentsCount: 0,
        openIncidentsCount: 0,
        averageIncidentAge: 0,
    } as IncidentsOverview
}
export const DEFAULT_INCIDENTS_WIDGET_STATE: IncidentsWidgetState = {
    loadingPopulatedDataState: {
        incidentsDetails: LoadingState.NotPopulated,
        incidentsCounts: LoadingState.NotPopulated,
        incidentsAssignments: LoadingState.NotPopulated,
        incidentsTrends: LoadingState.NotPopulated,
        incidentsAvgPerformance: LoadingState.NotPopulated,
        incidentsVesselsPerformance: LoadingState.NotPopulated,
    },
    latestIncidentOverview: defaultIncidentsOverview(),
    filteredDataCounts: {} as IncidentCountsType,
    filteredAssignementsCounts: {} as IncidentAssignmentsCounts,
    incidentsTrends: new Array<IncidentTrendsType>(),
    incidentsAvgPerformance: new Array<IncidentAvgPerformanceType>(),
    incidentsVesselsPerformance: {} as IncidentVesselsPerformanceType,
    loadingFilterState: LoadingState.NotPopulated,
    incidentWidgetFilter: DEFAULT_INCIDENTS_WIDGET_FILTER,
}
