import {Role} from '../../../values/Role'

export interface RolesReduxState {
    isFetching: boolean
    roles: Role[]
}

export const DEFAULT_ROLES_STATE: RolesReduxState = {
    isFetching: true,
    roles: new Array<Role>(),
}
