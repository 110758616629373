import styled from 'styled-components'

interface SegmentWrapperProps {
    size: number
    animationDuration: string
}

export const SegmentWrapper = styled.div<SegmentWrapperProps>`
    transform-origin: ${(props) => `${props.size}px ${props.size}px`};
    animation: loading-indicator 2.2222222222222223s linear infinite;
    opacity: 0.79;
    animation-duration: ${(props) => props.animationDuration};
`
