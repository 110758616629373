/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {Store} from 'redux'

import AppState from '../store/types/app-state'
import {CommonWebSocket} from '../websocket/commonWebSocket'
import {PathBasedMessageHandler} from '../websocket/PathBasedMessageHandler'
import {errorOccured} from '../store/state/auth/action-creators'
import {loggingOut} from '../store/state/session-data/action-creators'

// const WS_AUTHENTICATION_FAILURE = 1008

/* tslint:disable:no-unbound-method no-unsafe-any no-floating-promises no-any max-file-line-count*/
export interface QuerySpecification {
    path: string
    localId: string
    schedule: string
    // eslint-disable-next-line @typescript-eslint/ban-types
    params?: {}
}

export interface QueryOnClient {
    localId: string
}

export interface FullQuerySpecification {
    path: string
    queryOnClient: QueryOnClient
    schedule: string
    // eslint-disable-next-line @typescript-eslint/ban-types
    params?: {}
}

interface QueryCancellation {
    path: string
    localId: string
}

interface ConfigProps {
    baseUrl: string
    baseWsUrl: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Callback = (data: any) => void

const UNAUTHORIZED = 401
const THROW_ERROR: Callback = (reason: string) => {
    throw new Error(reason)
}
const INACTIVE_USER = 'Your user was deactivated. Please contact administrator.'
export const UNKNOWN_USER = 'You are are not registered. Please contact administrator.'
const EXPIRED_SESSION = 'Session expired'

function loginErrorMessage(error: ErrorMessage): string {
    switch (error.message.message) {
        case 'User inactive':
            return INACTIVE_USER
        case 'User unknown':
            return UNKNOWN_USER
        default:
            return EXPIRED_SESSION
    }
}

interface ErrorMessage {
    code: number
    message: any
}

export class Api {
    private store?: Store<AppState>
    private initialised = false
    private ws?: CommonWebSocket
    private wsInitialised = false
    // eslint-disable-next-line @typescript-eslint/ban-types
    private callbacksOnConnect: ((ev: Event) => {})[] = []
    private callbacksOnClose: ((ev: CloseEvent) => void)[] = []

    private queryCallbacks: Map<string, Callback> = new Map()
    private querySpecifications: Map<string, QuerySpecification> = new Map()

    private configPromise: Promise<ConfigProps>

    public constructor() {
        this.configPromise = fetch(
            `${process.env.PUBLIC_URL}/config/${
                process.env.REACT_APP_ALT_BACKEND ?? 'medulla-ui'
            }.json`,
        )
            .then((config) => config.json())
            .then((config) => config as ConfigProps)
        this.getJsonIfOk = this.getJsonIfOk.bind(this)
    }

    public getAuth(url: string, callback: Callback, errorCallback?: Callback): void {
        this.fetchAuth(url, 'get', callback, errorCallback)
    }

    public deleteAuth(url: string, callback: Callback, errorCallback?: Callback): void {
        this.fetchAuth(url, 'delete', callback, errorCallback)
    }

    public postAuth(url: string, callback: Callback, errorCallback?: Callback, body?: any): void {
        this.fetchAuth(url, 'post', callback, errorCallback, body)
    }

    public putAuth(url: string, callback: Callback, errorCallback?: Callback, body?: any): void {
        this.fetchAuth(url, 'put', callback, errorCallback, body)
    }

    public fetchAuth(
        url: string,
        method = 'get',
        callback: Callback,
        errorCallback?: Callback,
        body?: any,
    ): void {
        const errCallback: Callback = errorCallback ? errorCallback : THROW_ERROR

        this.fetchWithAuth(url, method, body)
            .then(this.getJsonIfOk)
            .then(callback)
            .catch((reason) => {
                if (reason.code === UNAUTHORIZED) {
                    const loginError = loginErrorMessage(reason)
                    this.redirectToLogin(loginError || 'Unspecified Error')
                } else if (reason === UNAUTHORIZED) {
                    this.redirectToLogin('Unspecified Error')
                } else {
                    errCallback(reason)
                }
            })
    }

    public fetchNotAuth(url: string, callback: Callback, errorCallback?: Callback): void {
        const errCallback: Callback = errorCallback ? errorCallback : THROW_ERROR

        this.configPromise.then((config) =>
            fetch(config.baseUrl + url)
                .then(this.getJsonIfOk)
                .then(callback)
                .catch((reason) => {
                    errCallback(reason)
                }),
        )
    }

    public init(store: Store<AppState>): void {
        this.store = store
        this.initialised = true
    }

    public initWs(): void {
        const authToken: string | undefined = this.store!.getState().auth.authToken

        if (authToken == undefined) {
            return
        }

        if (this.ws != undefined) {
            this.disconnect()
        }

        const messageHandler: PathBasedMessageHandler = new PathBasedMessageHandler({
            state: {
                accept: (message) => {
                    const callback: Callback | undefined = this.queryCallbacks.get(message.path)
                    if (callback != undefined) {
                        callback(message)
                    }
                },
            },

            keepalive: {
                accept: (_) => {
                    return // handle keep alive "pong" response
                },
            },
        })

        this.callbacksOnConnect.push((_) => {
            this.querySpecifications.forEach((value) => {
                this.sendQueryRegistration(value)
            })

            this.wsInitialised = true

            return {}
        })

        this.callbacksOnClose.push((event) => {
            // eslint-disable-next-line no-console
            console.log(`Websocket closed with the event code: ${event.code}`)
            // if (event.code === WS_AUTHENTICATION_FAILURE) {
            //     this.wsInitialised = false
            //     this.disconnect()
            //     this.redirectToLogin('Unspecified Error')
            // }
        })

        this.configPromise.then((config) => {
            this.ws = new CommonWebSocket(
                `/ws/query?Authorization=${authToken}`,
                messageHandler,
                config.baseWsUrl,
            )
            this.ws.connect(this.callbacksOnConnect, this.callbacksOnConnect, this.callbacksOnClose)
        })
    }

    public newQuery(querySpecification: QuerySpecification, callback: Callback): QueryOnClient {
        const queryOnClient: QueryOnClient = this.registerQuery(querySpecification, callback)

        if (this.wsInitialised) {
            this.sendQueryRegistration(querySpecification)
        }

        return queryOnClient
    }

    public cancelQuery(queryOnClient: QueryOnClient): void {
        this.deregisterQuery(queryOnClient)

        if (this.wsInitialised) {
            this.sendQueryCancellation(queryOnClient)
        }
    }

    public logout(): void {
        this.queryCallbacks.clear()
        this.querySpecifications.clear()
        // FIXME: rethink whether query cancellation is required
        this.disconnect()
    }

    private sendQueryRegistration(querySpecification: QuerySpecification): void {
        try {
            const fullQuerySpecification: FullQuerySpecification = {
                path: `query-new/${querySpecification.path}`,
                queryOnClient: {
                    localId: querySpecification.localId,
                },
                params: querySpecification.params,
                schedule: querySpecification.schedule,
            }

            this.ws!.send(fullQuerySpecification)
        } catch (_) {
            // noop
        }
    }

    private registerQuery(
        querySpecification: QuerySpecification,
        callback: Callback,
    ): QueryOnClient {
        this.queryCallbacks.set(querySpecification.localId, callback)
        this.querySpecifications.set(querySpecification.localId, querySpecification)

        return {
            localId: querySpecification.localId,
        }
    }

    private disconnect(): void {
        if (this.ws != undefined) {
            this.ws.disconnect()
        }
        this.wsInitialised = false
        this.ws = undefined
    }

    private deregisterQuery(queryOnClient: QueryOnClient): void {
        this.queryCallbacks.delete(queryOnClient.localId)
        this.querySpecifications.delete(queryOnClient.localId)
    }

    private sendQueryCancellation(queryOnClient: QueryOnClient): void {
        try {
            const queryCancelation: QueryCancellation = {
                path: 'query-cancel',
                localId: queryOnClient.localId,
            }

            // if (this.ws!.isOpen()) {
            this.ws!.send(queryCancelation)
            // }
        } catch (_) {
            // noop
        }
    }

    private getJsonIfOk(response: Response): PromiseLike<Response> | Response {
        const contentType = response.headers.get('Content-Type')
        const isContentTypeJson = contentType != null && contentType!.includes('application/json')

        return response.ok
            ? isContentTypeJson
                ? response.json()
                : response
            : isContentTypeJson
              ? response
                    .json()
                    .then((value) => Promise.reject({code: response.status, message: value}))
              : Promise.reject({code: response.status, message: response.statusText})
    }

    private fetchWithAuth(url: string, method = 'get', body?: any): Promise<Response> {
        if (!this.initialised) {
            throw new Error('not initialised')
        }

        if (this.store == undefined) {
            throw new Error('state initialised')
        }

        const myHeaders: Headers = new Headers()
        const authToken: string | undefined = this.store.getState().auth.authToken

        if (authToken == undefined) {
            return Promise.reject(UNAUTHORIZED)
        }

        myHeaders.append('Authorization', `Bearer ${authToken}`)
        myHeaders.append('Content-Type', 'application/json')

        const jsonBody: string | undefined = body != undefined ? JSON.stringify(body) : undefined

        return this.configPromise.then((config) =>
            fetch(config.baseUrl + url, {headers: myHeaders, method: method, body: jsonBody}),
        )
    }

    private redirectToLogin(message: string): void {
        if (message === 'Session expired') {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.store!.dispatch(loggingOut())
        } else {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.store!.dispatch(errorOccured(message))
        }
    }
}
