import ActionType from './action-type'
import {Action} from './actions'

import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'

import {
    DEFAULT_THREAT_SCORES_STATE,
    LatestEventTimestampsReduxState,
    newLatestEventTimestampMap,
} from './state'

function requestData(
    currentState: LatestEventTimestampsReduxState,
): LatestEventTimestampsReduxState {
    if (currentState.isFetchingLatestEventTimestamps === true) {
        return currentState
    }

    return {
        ...currentState,
        isFetchingLatestEventTimestamps: true,
    }
}

function resetState(
    currentState: LatestEventTimestampsReduxState,
): LatestEventTimestampsReduxState {
    if (
        currentState.isFetchingLatestEventTimestamps === false &&
        currentState.latestEventTimestamps?.size === 0
    ) {
        return currentState
    }

    return DEFAULT_THREAT_SCORES_STATE
}

interface Timestamp {
    key: string
    value: string
}

function receiveData(
    currentState: LatestEventTimestampsReduxState,
    receivedData: {[node: string]: string},
): LatestEventTimestampsReduxState {
    const newRecords: Timestamp[] = []
    const updatedRecords: Timestamp[] = []
    const unchangedRecords: Timestamp[] = []

    for (const key in receivedData) {
        const newValue = receivedData[key]

        if (currentState.latestEventTimestamps.has(key)) {
            const currentValue = currentState.latestEventTimestamps.get(key)
            if (currentValue !== newValue) {
                updatedRecords.push({key, value: newValue})
            } else {
                unchangedRecords.push({key, value: currentValue})
            }
        } else {
            newRecords.push({key, value: newValue})
        }
    }

    if (newRecords.length === 0 && updatedRecords.length === 0) {
        if (unchangedRecords.length === currentState.latestEventTimestamps.size) {
            if (currentState.isFetchingLatestEventTimestamps === false) {
                return currentState
            }

            return {
                ...currentState,
                isFetchingLatestEventTimestamps: false,
            }
        }

        const data = newLatestEventTimestampMap()
        unchangedRecords.forEach((timestamp) => data.set(timestamp.key, timestamp.value))
        return {
            ...currentState,
            latestEventTimestamps: data,
            isFetchingLatestEventTimestamps: false,
        }
    }

    const data = newLatestEventTimestampMap()
    newRecords.forEach((timestamp) => data.set(timestamp.key, timestamp.value))
    updatedRecords.forEach((timestamp) => data.set(timestamp.key, timestamp.value))
    unchangedRecords.forEach((timestamp) => data.set(timestamp.key, timestamp.value))

    return {
        ...currentState,
        latestEventTimestamps: data,
        isFetchingLatestEventTimestamps: false,
    }
}

export default function LatestEventTimestampsReducer(
    state: LatestEventTimestampsReduxState = DEFAULT_THREAT_SCORES_STATE,
    action: Action | LoggingOutAction,
): LatestEventTimestampsReduxState {
    switch (action.type) {
        case ActionType.REQUEST_LATEST_EVENTS_TIMESTAMPS:
            return requestData(state)

        case ActionType.RECEIVE_LATEST_EVENTS_TIMESTAMPS:
            return receiveData(state, action.payload)

        case SessionActionType.LOGGING_OUT:
            return resetState(state)

        /* istanbul ignore next */
        default:
            return state
    }
}
