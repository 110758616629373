import AppState from '../../types/app-state'
import {UserData} from './state'

export const currentUserSelector = (state: AppState): UserData => state.currentUser.currentUser

export const isFetchingCurrentUserSelector = (state: AppState): boolean =>
    state.currentUser.isFetching

export const isInternalUserSelector = (state: AppState): boolean =>
    state.currentUser.currentUser.internalUser
