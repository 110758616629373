import {GuidType} from '../../values/generic-type-defintions'
import {IncidentResponseSortType} from './contexts/types/type-of-interfaces'
import {IncidentStatus} from './contexts/types/incident-status'
import {IncidentHistoryOutputModel} from './models/history-record.model'
import {
    IncidentSeverityValue,
    IncidentSeverityValues,
} from './contexts/types/incident-severity-types'
import {UserData} from '../../store/state/current-user/state'
import MISCONFIGURATION from '../../@assets/icons/misconfiguration-incident-type.svg'
import NETWORK from '../../@assets/icons/network-incident-type.svg'
import PUP from '../../@assets/icons/pup-incident-type.svg'
import DEFAULT from '../../@assets/icons/default-incident-type.svg'
import UNKNOWN_ASSET from '../../@assets/icons/unknown-assets-incident-type.svg'
import ANOMALOUS_BEHAVIOUR from '../../@assets/icons/anomalous-behaviour-incident-type.svg'
import DATA_TRANSFER from '../../@assets/icons/data-transfer-incident-type.svg'
import MALWARE from '../../@assets/icons/malware-incident-type.svg'
import POTENTIALLY_MALICIOUS_FILE from '../../@assets/icons/malicious-files-incident-type.svg'
import COMPLIANCE from '../../@assets/icons/compliance-incident-type.svg'
import ACCOUNT_ACTIVITY from '../../@assets/icons/account_activity_incident_type.svg'
import INFORMATIONAL from '../../@assets/icons/informational-incident-type.svg'
import CREDENTIAL_POLICY from '../../@assets/icons/credential-policy-incident-type.svg'
import {SORT_ORDER} from '../../values/sort-order'
import {VesselTags} from '../../store/state/vessel-tags/state'
import {IncidentsFilterReduxState} from '../../store/state/incidents-filter/state'
import {IncidentsFilter} from '../../values/user-preferences/incidents-filter'
import {isEqual} from 'lodash'
import {getRelativeTimeRange} from '../../utils/Utils'
import {LinkedItemState} from './contexts/types/incident-linked-items'
import CIRCLE_CHECKED from '../../@assets/icons/circle-checked.svg'
import CIRCLE_X from '../../@assets/icons/circle-x.svg'
import CIRCLE_UNKNOWN from '../../@assets/icons/circle-unknown.svg'
import CIRCLE_MONITORED from '../../@assets/icons/circle-monitored.svg'

export function showCards(width: number): boolean {
    return width < 1260
}

export function addAnnotation(status: string): string {
    switch (status) {
        case 'CLOSED':
            return 'Resolved'
        case 'CLOSED_FALSE_POSITIVE':
            return 'No Risk'
        case 'CLOSED_MONITOR':
            return 'Monitor'
        default:
            return ''
    }
}
export function displayStatus(status: string): string {
    switch (status) {
        case 'DRAFT':
            return 'DRAFT'
        case 'NEW':
            return 'NEW'
        case 'OPEN':
            return 'OPEN'
        case 'CLOSED':
        case 'CLOSED_FALSE_POSITIVE':
        case 'CLOSED_MONITOR':
            return 'CLOSED'
        default:
            return status
    }
}
export function getNextIncidentStatuses(status: IncidentStatus): IncidentStatus[] {
    switch (status) {
        case IncidentStatus.DRAFT:
            return [IncidentStatus.NEW]
        case IncidentStatus.NEW:
            return [IncidentStatus.OPEN]
        case IncidentStatus.OPEN:
            return [
                IncidentStatus.CLOSED,
                IncidentStatus.CLOSED_FALSE_POSITIVE,
                IncidentStatus.CLOSED_MONITOR,
            ]
        case IncidentStatus.CLOSED:
        case IncidentStatus.CLOSED_FALSE_POSITIVE:
        case IncidentStatus.CLOSED_MONITOR:
            return [IncidentStatus.OPEN]
        default:
            return []
    }
}

export function getAlternativeIncidentSeverities(
    severityLevel: IncidentSeverityValue,
): IncidentSeverityValue[] {
    const severities = [
        IncidentSeverityValues.CRITICAL,
        IncidentSeverityValues.HIGH,
        IncidentSeverityValues.MEDIUM,
        IncidentSeverityValues.LOW,
    ]
    const i = severities.indexOf(severityLevel)
    if (i >= 0) {
        severities.splice(i, 1)
    }
    return severities
}

export const INCIDENT_DETAILS = 'incidentDetails'
export function wordCount(text: string | undefined | null): number {
    if (text == undefined) {
        return 0
    }
    return text.split(' ').length
}
export function truncateNotes(text: string | undefined | null): string {
    if (text == undefined) {
        return ''
    }
    return text.split(' ').splice(0, 40).join(' ')
}
export function getLines(text: string | undefined | null): string[] {
    if (text == undefined) {
        return ['Unknown']
    }
    return text.split('\n')
}
export function getSortedIncidentHistory(
    history: IncidentHistoryOutputModel[],
    sortNotes: INCIDENT_NOTES_SORT_ORDER,
): IncidentHistoryOutputModel[] {
    if (!history) {
        return []
    }
    let result = history
    if (
        sortNotes === INCIDENT_NOTES_SORT_ORDER.NEW_NOTES ||
        sortNotes === INCIDENT_NOTES_SORT_ORDER.OLD_NOTES
    ) {
        result = sortNotesByWhenCreated(history, getDescendingValue(sortNotes)).filter(
            (record) => record.noteRecord != undefined,
        )
    }
    if (sortNotes === INCIDENT_NOTES_SORT_ORDER.STATUS) {
        result = history.filter((record) => record.statusChangeRecord != undefined)
    }
    if (sortNotes === INCIDENT_NOTES_SORT_ORDER.SEVERITY) {
        result = history.filter((record) => record.severityChangeRecord != undefined)
    }
    if (sortNotes === INCIDENT_NOTES_SORT_ORDER.ASSIGNMENT_CHANGE) {
        result = history.filter((record) => record.assignmentChangeRecord != undefined)
    }

    return result
}
function dateComparison(aValue: string, bValue: string, descending: boolean): number {
    if (aValue < bValue) {
        return descending ? 1 : -1
    }

    if (aValue > bValue) {
        return descending ? -1 : 1
    }

    return 0
}
export function sortNotesByWhenCreated(
    history: IncidentHistoryOutputModel[],
    descending: boolean,
): IncidentHistoryOutputModel[] {
    return history.sort((a: IncidentHistoryOutputModel, b: IncidentHistoryOutputModel) => {
        return dateComparison(a.when, b.when, descending)
    })
}
function getDescendingValue(filterOptions: INCIDENT_NOTES_SORT_ORDER): boolean {
    switch (filterOptions) {
        case INCIDENT_NOTES_SORT_ORDER.NEW_NOTES:
            return true
        case INCIDENT_NOTES_SORT_ORDER.OLD_NOTES:
            return false
        default:
            return true
    }
}
export enum INCIDENT_NOTES_SORT_ORDER {
    NONE = 'All',
    NEW_NOTES = 'New notes first',
    OLD_NOTES = 'Old notes first',
    STATUS = 'Status change',
    SEVERITY = 'Severity change',
    ASSIGNMENT_CHANGE = 'Assignment change',
}
export function isUserAllowToEdit(user: GuidType, userToEdit: UserData): boolean {
    return user === userToEdit.user
}

export const getNewSortByDirection = (
    newlySelectedColumn: IncidentResponseSortType,
    orderIncidentsBy: {column: IncidentResponseSortType; direction: SORT_ORDER},
): SORT_ORDER => {
    if (newlySelectedColumn !== orderIncidentsBy.column) {
        return SORT_ORDER.DESCENDING
    }
    switch (orderIncidentsBy.direction) {
        case 'ASC':
            return SORT_ORDER.DESCENDING
        case 'DESC':
            return SORT_ORDER.ASCENDING
        default:
            return SORT_ORDER.DESCENDING
    }
}
export function getIncidentsSortType(selectedColumn: string): IncidentResponseSortType {
    switch (selectedColumn) {
        case 'status':
            return IncidentResponseSortType.STATUS
        case 'incidentNumber':
            return IncidentResponseSortType.CODE
        case 'title':
            return IncidentResponseSortType.TITLE
        case 'severity':
            return IncidentResponseSortType.SEVERITY
        case 'incidentType':
            return IncidentResponseSortType.TYPE
        case 'vessel':
            return IncidentResponseSortType.LOCATION_NAME
        case 'raised':
            return IncidentResponseSortType.CREATED
        case 'assignedTo':
            return IncidentResponseSortType.ASSIGNED_TO
        case 'updated':
            return IncidentResponseSortType.UPDATED
        default:
            return IncidentResponseSortType.STATUS
    }
}

export function getFormattedIncidentsSortType(
    selectedColumn: IncidentResponseSortType | undefined,
): string {
    switch (selectedColumn) {
        case IncidentResponseSortType.STATUS:
            return 'status'
        case IncidentResponseSortType.CODE:
            return 'incidentNumber'
        case IncidentResponseSortType.TITLE:
            return 'title'
        case IncidentResponseSortType.SEVERITY:
            return 'severity'
        case IncidentResponseSortType.TYPE:
            return 'incidentType'
        case IncidentResponseSortType.LOCATION_NAME:
            return 'vessel'
        case IncidentResponseSortType.CREATED:
            return 'raised'
        case IncidentResponseSortType.ASSIGNED_TO:
            return 'assignedTo'
        case IncidentResponseSortType.UPDATED:
            return 'updated'
        default:
            return 'status'
    }
}

export function getSortOrderValue(direction: SORT_ORDER): boolean {
    if (direction === SORT_ORDER.DESCENDING) {
        return false
    }
    if (direction === SORT_ORDER.ASCENDING) {
        return true
    }
    return false
}

export function getIncidentTypeIcon(incidentType: string): string {
    switch (incidentType) {
        case 'NETWORK':
            return NETWORK
        case 'MISCONFIGURATION':
            return MISCONFIGURATION
        case 'PUP':
            return PUP
        case 'UNKNOWN_ASSET':
        case 'UNKNOWN_ASSETS':
            return UNKNOWN_ASSET
        case 'ANOMALOUS_BEHAVIOUR':
            return ANOMALOUS_BEHAVIOUR
        case 'DATA_TRANSFER':
            return DATA_TRANSFER
        case 'MALWARE':
            return MALWARE
        case 'POTENTIALLY_MALICIOUS_FILE':
            return POTENTIALLY_MALICIOUS_FILE
        case 'COMPLIANCE':
            return COMPLIANCE
        case 'ACCOUNT_ACTIVITY':
            return ACCOUNT_ACTIVITY
        case 'INFORMATIONAL':
            return INFORMATIONAL
        case 'CREDENTIAL_POLICY':
            return CREDENTIAL_POLICY
        default:
            return DEFAULT
    }
}

export function findRecommendationFromEntryTag(
    existingTags: VesselTags[] | undefined,
    searchTag: string,
): VesselTags[] {
    if (!existingTags || !searchTag || !searchTag?.trim()) {
        return []
    }
    return existingTags
        ?.filter((element) =>
            element.name?.trim().toLowerCase().includes(searchTag?.trim().toLowerCase()),
        )
        .sort((a, b) => a.name?.localeCompare(b.name))
        .slice(0, 5)
}

export class FormattedNewFilterModel {
    public readonly orderIncidentsBy: {
        column: IncidentResponseSortType | undefined
        direction: SORT_ORDER
    }
    public readonly filterByStatus: IncidentStatus[] | undefined
    public readonly filterBySeverities: IncidentSeverityValue[] | undefined
    public readonly selectedIncidentType: string | undefined
    public readonly selectedCreatedFromTimeRange: string | null
    public readonly selectedCreatedToTimeRange: string | null
    public readonly selectedUpdatedFromTimeRange: string | null
    public readonly selectedUpdatedToTimeRange: string | null
    public readonly createdFromRelative: string | null
    public readonly createdToRelative: string | null
    public readonly updatedFromRelative: string | null
    public readonly updatedToRelative: string | null
    public readonly assignedTo: string | undefined
    public readonly guestAssignedToEmail: string | undefined
    public readonly watchedByCurrentUser: boolean | null
    public readonly unseenIncidentResponses: boolean | null
    public readonly assignedToVesselEmail: boolean | null

    public constructor(newFilter: IncidentsFilterReduxState | IncidentsFilter) {
        this.orderIncidentsBy = newFilter.orderIncidentsBy
        this.filterByStatus = newFilter.filterByStatus
        this.filterBySeverities = newFilter.filterBySeverities
        this.selectedIncidentType = newFilter.selectedIncidentType
        this.selectedCreatedFromTimeRange = newFilter.selectedCreatedFromTimeRange
        this.selectedCreatedToTimeRange = newFilter.selectedCreatedToTimeRange
        this.selectedUpdatedFromTimeRange = newFilter.selectedUpdatedFromTimeRange
        this.selectedUpdatedToTimeRange = newFilter.selectedUpdatedToTimeRange
        this.createdFromRelative = getRelativeTimeRange(newFilter.createdFromRelative)
        this.createdToRelative = getRelativeTimeRange(newFilter.createdToRelative)
        this.updatedFromRelative = getRelativeTimeRange(newFilter.updatedFromRelative)
        this.updatedToRelative = getRelativeTimeRange(newFilter.updatedToRelative)
        this.assignedTo = newFilter.assignedTo
        this.guestAssignedToEmail = newFilter.guestAssignedToEmail
        this.watchedByCurrentUser = newFilter.watchedByCurrentUser
        this.unseenIncidentResponses = newFilter.unseenIncidentResponses
        this.assignedToVesselEmail = newFilter.assignedToVesselEmail
    }
}

export function compareFilters(
    currentFilter: IncidentsFilter,
    newFilter: IncidentsFilterReduxState,
): boolean {
    const formattedNewFilter = new FormattedNewFilterModel(newFilter)
    const formattedCurrentFilter = new FormattedNewFilterModel(currentFilter)

    return !isEqual(formattedNewFilter, formattedCurrentFilter)
}

export function getImageForlinkedItemState(itemState: LinkedItemState): string {
    switch (itemState) {
        case LinkedItemState.TRUSTED:
            return CIRCLE_CHECKED
        case LinkedItemState.MONITORED:
            return CIRCLE_MONITORED
        case LinkedItemState.BLOCKED:
        case LinkedItemState.UNWANTED:
            return CIRCLE_X
        case LinkedItemState.UNKNOWN:
            return CIRCLE_UNKNOWN
        default:
            return ''
    }
}

export function getFormattedDuration(duration: string): string | null {
    const match = duration.match(/^PT(\d+)H$/)
    if (match) {
        const hours = parseInt(match[1], 10)
        const days = Math.ceil(hours / 24)

        return days === 1 ? '1 day' : `${days} days`
    }

    return null
}
