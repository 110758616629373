import {INCIDENT_NOTES_SORT_ORDER} from '../../../../pages/incidents-v3/data-helpers'
import {isTrendsPeriodNeeded} from '../../../../pages/metrics-beta/components/data-helper'
import {formatNetworkLabel} from '../../../../pages/unknown-assets-v2/context/helpers'
import {reformatTimestampCriteria} from '../../../../pages/usb-devices/contexts/context-helper'
import {AuditLogPageType} from '../../../../store/state/audit-log/state'
import {
    DEFAULT_INCIDENTS_REPORT_FILTER,
    IncidentsReportFilter,
} from '../../../../store/state/incidents-report-filter/state'
import {
    MetricsBetaFilter,
    DEFAULT_METRICS_BETA_FILTER,
    TimestampFilterType,
    AnalysisType,
} from '../../../../store/state/metrics-filter-beta/state'
import {SavedFilterPageType} from '../../../../store/state/saved-filters/state'
import {getTimeRangeCriteria} from '../../../../utils/Utils'
import {SORT_ORDER} from '../../../../values/sort-order'
import {
    IncidentsFilter,
    IncidentsFilterCriteria,
} from '../../../../values/user-preferences/incidents-filter'
import {
    DEFAULT_NETWORK_ASSETS_FILTER,
    NetworkAssetsFilter,
    NetworkAssetsFilterCriteria,
} from '../../../../values/user-preferences/network-assets-filter'
import {
    DEFAULT_REPORTS_FILTER,
    ReportsFilter,
    ReportsFilterCriteria,
} from '../../../../values/user-preferences/reports-filter'
import {
    SoftwareInventoryFilterCriteria,
    SoftwareInventoryFilter,
    DEFAULT_SOFTWARE_INVENTORY_FILTER,
} from '../../../../values/user-preferences/software-inventory-filter'
import {
    DEFAULT_USB_INVENTORY_FILTER,
    UsbInventoryFilter,
    UsbInventoryFilterCriteria,
} from '../../../../values/user-preferences/usb-inventory-filter'

export function getAuditPageType(pageType: SavedFilterPageType): AuditLogPageType {
    switch (pageType) {
        case SavedFilterPageType.INCIDENTS:
            return AuditLogPageType.INCIDENTS
        case SavedFilterPageType.METRICS:
            return AuditLogPageType.METRICS
        case SavedFilterPageType.NETWORK_ASSETS:
            return AuditLogPageType.NETWORK_INVENTORY
        case SavedFilterPageType.USB_INVENTORY:
            return AuditLogPageType.USB_INVENTORY
        case SavedFilterPageType.SOFTWARE_INVENTORY:
            return AuditLogPageType.SOFTWARE_INVENTORY
        case SavedFilterPageType.REPORTS:
            return AuditLogPageType.REPORTS
        case SavedFilterPageType.INCIDENTS_REPORT:
            return AuditLogPageType.INCIDENTS_REPORT
    }
}

export function getFormattedIncidentsFilter(criteria: IncidentsFilterCriteria): IncidentsFilter {
    return {
        orderIncidentsBy: {
            column: criteria.orderBy.column,
            direction: criteria.orderBy.ascending ? SORT_ORDER.ASCENDING : SORT_ORDER.DESCENDING,
        },
        filterByStatus: criteria.statuses,
        filterBySeverities: criteria.severities,
        selectedIncidentType: criteria.type,
        selectedCreatedFromTimeRange: criteria.createdFrom,
        selectedCreatedToTimeRange: criteria.createdTo,
        selectedUpdatedFromTimeRange: criteria.updatedFrom,
        selectedUpdatedToTimeRange: criteria.updatedTo,
        createdFromRelative: getTimeRangeCriteria(criteria.createdFromRelative),
        createdToRelative: getTimeRangeCriteria(criteria.createdToRelative),
        updatedFromRelative: getTimeRangeCriteria(criteria.updatedFromRelative),
        updatedToRelative: getTimeRangeCriteria(criteria.updatedToRelative),
        assignedTo: criteria.assignedTo,
        guestAssignedToEmail: criteria.guestAssignedToEmail,
        watchedByCurrentUser: criteria.watchedByCurrentUser,
        unseenIncidentResponses: criteria.unseenIncidentResponses,
        assignedToVesselEmail: criteria.assignedToVesselEmail,
        locations: undefined,
        sortByNotes: INCIDENT_NOTES_SORT_ORDER.NONE,
        searchVesselTagTerm: [],
        searchVesselNameTerm: '',
    }
}

export function getFormattedNetworkAssetsFilter(
    criteria: NetworkAssetsFilterCriteria,
): NetworkAssetsFilter {
    return {
        ...DEFAULT_NETWORK_ASSETS_FILTER,
        orderBy: criteria.orderBy,
        states: criteria.states,
        networks: criteria.networks?.map((network) => formatNetworkLabel(network)),
        behaviours: criteria.behaviours,
        properties: criteria.properties,
        pagination: criteria.pagination,
        vlan: criteria.vlan,
        macAddress: criteria.macAddress,
        fromRelativeLastSeen: criteria.fromRelativeLastSeen,
    }
}

export function getFormattedUsbInventoryFilter(
    criteria: UsbInventoryFilterCriteria,
): UsbInventoryFilter {
    return {
        ...DEFAULT_USB_INVENTORY_FILTER,
        selectedUsbDeviceStatus: criteria.deviceStatuses,
        searchedFirstDetected: reformatTimestampCriteria(criteria.fromRelativeFirstSeen),
        searchedLastActive: reformatTimestampCriteria(criteria.fromRelativeLastSeen),
        searchedVendor: criteria.vendorName ?? undefined,
        searchedProductName: criteria.productName ?? undefined,
        searchedType: criteria.deviceTypes,
        hasPurpose: criteria.hasPurpose,
        sortColumn: {
            orderBy: criteria.orderBy.column,
            orderAscending: criteria.orderBy.isAscending,
        },
        searchedVessels: undefined,
        searchVesselTagTerm: [],
        searchVesselNameTerm: '',
    }
}

export function getFormattedSoftwareInventoryFilter(
    criteria: SoftwareInventoryFilterCriteria,
): SoftwareInventoryFilter {
    return {
        ...DEFAULT_SOFTWARE_INVENTORY_FILTER,
        searchedFirstDetected: reformatTimestampCriteria(criteria.fromRelativeFirstSeen),
        searchedLastActive: reformatTimestampCriteria(criteria.fromRelativeLastSeen),
        searchedCompanyName: criteria?.company ?? undefined,
        searchedProductName: criteria?.product ?? undefined,
        filteredSoftwareStatus: criteria?.statuses ?? undefined,
        sortColumn: {
            orderBy: criteria.orderBy.column,
            orderAscending: criteria.orderBy.isAscending,
        },
        searchedVessels: undefined,
        searchVesselTagTerm: [],
        searchVesselNameTerm: '',
    }
}

export function getFormattedMetricsBetaFilter(
    criteria: MetricsBetaFilter & {
        periodForCommon?: TimestampFilterType
        periodForTarget?: TimestampFilterType
    },
): MetricsBetaFilter {
    const trendsPeriodNeeded = isTrendsPeriodNeeded(
        criteria?.analysisTypes,
        criteria?.selectedViewScreenType,
    )
    const oldFormatPeriod =
        criteria?.periodForCommon != undefined || criteria?.periodForTarget != undefined
    let selectedAnalysisPeriod: TimestampFilterType
    if (oldFormatPeriod) {
        if (trendsPeriodNeeded) {
            selectedAnalysisPeriod = criteria.periodForTarget!
        } else {
            selectedAnalysisPeriod = criteria.periodForCommon!
        }
    } else {
        selectedAnalysisPeriod =
            criteria?.selectedAnalysisPeriod ?? TimestampFilterType.LAST_30_DAYS
    }
    return {
        ...DEFAULT_METRICS_BETA_FILTER,
        metricTypes: criteria?.metricTypes ?? undefined,
        onlyAssetsWithIssues: criteria?.onlyAssetsWithIssues,
        selectedAnalysisPeriod: selectedAnalysisPeriod,
        selectedViewScreenType: criteria?.selectedViewScreenType ?? 'framework',
        orderBy: {
            column: criteria.orderBy.column,
            isAscending: criteria.orderBy.isAscending,
        },
        locations: undefined,
        searchVesselTagTerm: [],
        searchVesselNameTerm: '',
        analysisTypes: criteria?.analysisTypes ?? AnalysisType.SCORECARD,
        assetValues: criteria?.assetValues ?? undefined,
    }
}

export function getFormattedReportsFilter(criteria: ReportsFilterCriteria): ReportsFilter {
    return {
        ...DEFAULT_REPORTS_FILTER,
        selectedReportType: criteria.selectedReportType,
        selectedRating: criteria.selectedRating,
        selectedFromTimeRange: criteria.selectedFromTimeRange,
        selectedToTimeRange: criteria.selectedToTimeRange,
        includeWholeFleetReports: criteria.includeWholeFleetReports,
        locations: undefined,
        searchVesselTagTerm: [],
        searchVesselNameTerm: '',
    }
}

export function getFormattedIncidentsReportFilter(
    criteria: IncidentsReportFilter,
): IncidentsReportFilter {
    return {
        ...DEFAULT_INCIDENTS_REPORT_FILTER,
        fromDate: criteria.fromDate,
        toDate: criteria.toDate,
        locations: undefined,
        searchVesselTagTerm: [],
        searchVesselNameTerm: '',
    }
}

export function isSubscriptionUnavailable(criteria: IncidentsFilterCriteria | undefined): boolean {
    if (!criteria) {
        return false
    }

    const timestampArray = [
        criteria.createdFrom,
        criteria.createdTo,
        criteria.updatedFrom,
        criteria.updatedTo,
        criteria.createdFromRelative,
        criteria.createdToRelative,
        criteria.updatedFromRelative,
        criteria.updatedToRelative,
    ]

    return !timestampArray.every((value) => !value) || criteria.watchedByCurrentUser === true
}
