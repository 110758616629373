import {DropdownItemStyle} from './_styled/location-expanded.styled'
import {DEFAULT_ACTIVE_LOCATION_STATE, Location} from '../../../../store/state/locations/state'
import useLocationActions from '../../../../store/state/locations/use-actions'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {activeLocationSelector} from '../../../../store/state/locations/selectors'
import {useToggle} from '../../../../contexts/toggle/use-toggle'
import * as RadioStyled from '../shared/radio-button.styled'
import {logEventFor} from '../../../../store/state/audit-log/action-creators'
import {useDispatch} from 'react-redux'
interface DropDownItemProps {
    location: Location
}
export function DropDownItem({location}: DropDownItemProps): JSX.Element {
    const {setActiveLocation} = useLocationActions()
    const activeLocation = useTypedSelector(activeLocationSelector)
    const {setValue: setMenuOpen} = useToggle()
    const dispatch = useDispatch()

    const onClickWrapper = (location: Location) => {
        setActiveLocation(location)
        setMenuOpen(false)
        const formattedInfo =
            location.description === DEFAULT_ACTIVE_LOCATION_STATE.description
                ? 'FLEET_VIEW'
                : 'INDIVIDUAL_VESSEL'
        dispatch(logEventFor('selectActiveLocation', 'Menu', formattedInfo))
    }

    return (
        <DropdownItemStyle onClick={() => onClickWrapper(location)}>
            <RadioStyled.Container>
                <RadioStyled.Input
                    type="radio"
                    id={`${location.location}-radio`}
                    value={location.location}
                    checked={activeLocation && activeLocation.location === location.location}
                    onChange={() => setActiveLocation(location)}
                    disabled={false}
                />
                <RadioStyled.Label htmlFor={`${location.location}-radio`}>
                    {location?.description || 'Unknown'}
                </RadioStyled.Label>
            </RadioStyled.Container>
        </DropdownItemStyle>
    )
}
