import ActionType from './action-type'
import {Action} from './actions'

import AuthActionType from '../auth/action-type'
import {AuthTokenRetrievedAction} from '../auth/actions'

import ConfigActionType from '../config/action-type'
import {Action as ConfigAction} from '../config/actions'

import {DEFAULT_SESSION_DATA_STATE, LoginStatus, SessionDataReduxState} from './state'

function loggedIn(currentState: SessionDataReduxState): SessionDataReduxState {
    if (currentState.loginStatus === LoginStatus.LOGGED_IN && currentState.error == undefined) {
        return currentState
    }

    return {
        ...currentState,
        loginStatus: LoginStatus.LOGGED_IN,
        error: undefined,
    }
}
function loggedOut(currentState: SessionDataReduxState): SessionDataReduxState {
    if (
        currentState.loginStatus === LoginStatus.LOGGED_OUT &&
        currentState.userLogInFlow === true
    ) {
        return currentState
    }
    return {
        ...DEFAULT_SESSION_DATA_STATE,
        loginStatus: LoginStatus.LOGGED_OUT,
        userLogInFlow: true,
    }
}
function logginIn(currentState: SessionDataReduxState): SessionDataReduxState {
    if (currentState.loginStatus === LoginStatus.LOGGING_IN && currentState.error == undefined) {
        return currentState
    }

    return {
        ...currentState,
        loginStatus: LoginStatus.LOGGING_IN,
        error: undefined,
    }
}
function logginOut(currentState: SessionDataReduxState): SessionDataReduxState {
    if (currentState.loginStatus === LoginStatus.LOGGING_OUT && currentState.error == undefined) {
        return currentState
    }

    return {
        ...currentState,
        loginStatus: LoginStatus.LOGGING_OUT,
        error: undefined,
    }
}
function errorOccured(
    currentState: SessionDataReduxState,
    newError = 'No error message provided',
): SessionDataReduxState {
    if (currentState.loginStatus === LoginStatus.LOGGED_OUT && currentState.error === newError) {
        return currentState
    }
    return {
        ...DEFAULT_SESSION_DATA_STATE,
        error: newError,
        loginStatus: LoginStatus.LOGGED_OUT,
    }
}

export default function sessionDataReducer(
    state: SessionDataReduxState = DEFAULT_SESSION_DATA_STATE,
    action: Action | AuthTokenRetrievedAction | ConfigAction,
): SessionDataReduxState {
    switch (action.type) {
        case ActionType.LOGGED_IN:
            return loggedIn(state)
        case ActionType.LOGGING_IN:
        case AuthActionType.RECEIVE_AUTH_TOKEN:
            return logginIn(state)

        case ActionType.LOGGING_OUT:
            return logginOut(state)
        case ActionType.ERROR_OCCURED:
        case ConfigActionType.CONFIG_ERROR:
            return errorOccured(state, action.payload || state.error)

        case ActionType.LOGGED_OUT:
            return loggedOut(state)

        /* istanbul ignore next */
        default:
            return state
    }
}
