import AppState from '../../types/app-state'
import {LoginStatus} from './state'

export const isLoggedInSelector = (state: AppState): boolean =>
    state.sessionData.loginStatus === LoginStatus.LOGGED_IN && !state.sessionData.error

export const logInStatusSelector = (state: AppState): LoginStatus => state.sessionData.loginStatus

export const errorSelector = (state: AppState): string | undefined => state.sessionData.error

export const hasErrorSelector = (state: AppState): boolean =>
    state.sessionData.error ? state.sessionData.error?.length > 0 : false

export const userLogInFlowSelector = (state: AppState): boolean => state.sessionData.userLogInFlow
