import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {ByText} from './styles/by-text.styled'
import * as LargeHeaderLayout from './styles/large-header-layout.styled'
import * as SmallHeaderLayout from './styles/small-header-layout.styled'
import {Medulla} from './medulla'
import {OwlLogo} from './owl-logo'

export enum HeaderSize {
    SMALL = 0,
    LARGE = 1,
}

interface HeaderProps {
    headerSize: HeaderSize
}
export function Header({headerSize}: HeaderProps): JSX.Element {
    const {width} = useDimensions()

    if (headerSize === HeaderSize.SMALL) {
        return (
            <SmallHeaderLayout.FlexBox>
                <OwlLogo scale={0.75} />
                <SmallHeaderLayout.Text width={width}>
                    <Medulla scale={0.75} />
                    <SmallHeaderLayout.ByText>
                        <ByText>By Cyberowl</ByText>
                    </SmallHeaderLayout.ByText>
                </SmallHeaderLayout.Text>
            </SmallHeaderLayout.FlexBox>
        )
    }

    return (
        <LargeHeaderLayout.CssGrid>
            <LargeHeaderLayout.LogoCell>
                <OwlLogo />
            </LargeHeaderLayout.LogoCell>
            <LargeHeaderLayout.NameCell>
                <Medulla />
            </LargeHeaderLayout.NameCell>
            <LargeHeaderLayout.ByTextCell>
                <ByText>By Cyberowl</ByText>
            </LargeHeaderLayout.ByTextCell>
        </LargeHeaderLayout.CssGrid>
    )
}
