export enum IncidentsDetailType {
    History = 'Incident history',
    Recommendations = 'Recommendations',
}
export interface DetailExpandedType {
    historyExpanded: boolean
    recommendationsExpanded: boolean
}
export const DEFAULT_INCIDENTS_DETAIL_EXPANDED: DetailExpandedType = {
    historyExpanded: true,
    recommendationsExpanded: true,
}
