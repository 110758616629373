import styled from 'styled-components'
import {mediumFont, largeFont} from '../../../theme/font-styles'

export const Title = styled.h1`
    padding: 0;
    margin: 0;
    ${largeFont()}
    font-weight: ${(props) => props.theme.font.weight.bold};
`

export const Message = styled.div`
    ${mediumFont()}
`
