import {Feature} from '../../../values/MedullaUIConfig'

export type OAuthClientIdsType = {
    google?: string
    microsoft?: string
}

export enum ConfigFetchingStatus {
    UNINITIALISED = 'UNINITIALISED',
    REQUESTING = 'REQUESTING',
    INITIALISED = 'INITIALISED',
    NEEDS_RESETTING = 'NEEDS_RESETTING',
    REREQUESTING = 'REREQUESTING',
}

export enum FleetConfigKeysType {
    ACTIVE_CONTROL = 'ActiveControl',
    INCIDENTS = 'Incidents',
    REPORTS = 'Reports',
    NETWORK_ASSETS = 'NetworkAssets',
    USB_INVENTORY = 'UsbInventory',
    SOFTWARE_INVENTORY = 'SoftwareInventory',
    NMEA_INVENTORY = 'NmeaInventory',
    METRICS = 'Metrics',
    INCIDENT_REOPEN_PROCESSING = 'IncidentReopenProcessing',
    LICENSE_INCIDENTS = 'License:Incidents',
    LICENSE_ENFORCEMENT_NETWORKS = 'License:Enforcement:Networks',
    LICENSE_ENFORCEMENT_REMOVABLES = 'License:Enforcement:Removables',
    LICENSE_METRICS_ONBOARD_ENGAGEMENT = 'License:Metrics:OnboardEngagement',
}

export const allKeys: LicenseKeyType<LicenseKeysTypes>[] = [
    'License:Incidents',
    'License:Enforcement:Networks',
    'License:Metrics:OnboardEngagement',
    'License:Enforcement:Removables',
]

export type LicenseKeysTypes =
    | 'Enforcement:Networks'
    | 'Enforcement:Removables'
    | 'Incidents'
    | 'Metrics:OnboardEngagement'
export type LicenseKeyType<T extends LicenseKeysTypes> = `License:${T}`

export type License<T extends LicenseKeysTypes> = {
    identity: string
    key: LicenseKeyType<T>
    value: LicenseValue
    category: string
}

export type LicenseValue = {
    enabled: boolean
    numberOfLicenses: number | null
    endTimestamp: null | Date
}

export interface FleetConfigStatus {
    reports: boolean
    networkAssets: boolean
    usbInventory: boolean
    softwareInventory: boolean
    nmeaInventory: boolean
    incidentReopenDuration: string | undefined
    LicenseState?: {
        'License:Metrics:OnboardEngagement'?: License<'Metrics:OnboardEngagement'>
        'License:Enforcement:Networks'?: License<'Enforcement:Networks'>
        'License:Enforcement:Removables'?: License<'Enforcement:Removables'>
        'License:Incidents'?: License<'Incidents'>
    }
}

export const DEFAULT_FLEET_CONFIG_STATUS: FleetConfigStatus = {
    reports: false,
    networkAssets: false,
    usbInventory: false,
    softwareInventory: false,
    nmeaInventory: false,
    incidentReopenDuration: undefined,
    LicenseState: undefined,
}
export interface UserGuidingConfig {
    userGuidingEnabled: boolean
    userGuidingId: string
}

export interface ConfigReduxState {
    splunkDashboardUrl?: string
    features?: Feature[]
    tenantDisplayName?: string
    acEnabled: boolean
    fetchingStatus: ConfigFetchingStatus
    oauthClientIds: OAuthClientIdsType | undefined
    gfcFlag: boolean
    customLogoFlag: boolean
    fleetConfig: FleetConfigStatus
    userGuidingConfig: UserGuidingConfig | undefined
}

export const DEFAULT_CONFIG_STATE: ConfigReduxState = {
    features: [],
    acEnabled: false,
    fetchingStatus: ConfigFetchingStatus.UNINITIALISED,
    oauthClientIds: {
        google: '',
        microsoft: '',
    },
    gfcFlag: true,
    customLogoFlag: false,
    fleetConfig: DEFAULT_FLEET_CONFIG_STATUS,
    userGuidingConfig: undefined,
}
