import MicrosoftLogin from 'react-microsoft-login'
import {AuthError, AuthResponse, UserAgentApplication} from 'msal'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {authTokenRetrieved, setMSAL, clearMSAL} from '../../../../store/state/auth/action-creators'
import {oauthClientIdsSelector} from '../../../../store/state/config/selectors'
import {PrimaryButton} from './styles/primary-button.styled'
import {useDispatch} from 'react-redux'

export function SignInButton(): JSX.Element | null {
    const dispatch = useDispatch()
    function responseMicrosoftSuccess(
        error: AuthError | null,
        response?: AuthResponse,
        msal?: UserAgentApplication,
    ): void {
        dispatch(msal ? setMSAL(msal) : clearMSAL())

        if (!error && response) {
            const authToken = response.idToken.rawIdToken

            if (authToken) {
                dispatch(authTokenRetrieved(authToken))
            }
        }
    }

    const oauthClientIds = useTypedSelector(oauthClientIdsSelector)
    const isLoginEnabled = oauthClientIds != undefined

    if (!isLoginEnabled || !oauthClientIds || !oauthClientIds.microsoft) {
        return null
    }

    return (
        <MicrosoftLogin clientId={oauthClientIds.microsoft} authCallback={responseMicrosoftSuccess}>
            <PrimaryButton id="sign-button">Sign In</PrimaryButton>
        </MicrosoftLogin>
    )
}
