export enum ActionType {
    REQUEST_FILTER = '[DASHBOARD/TAGS-COMPONENT CONTEXT] REQUEST FILTER',
    RECEIVE_PREFS_FILTER = '[DASHBOARD/TAGS-COMPONENT CONTEXT] RECEIVE PREFS FILTER',
    SET_SORT_COLUMN = '[DASHBOARD/TAGS-COMPONENT CONTEXT] SET SORT COLUMN',
    SHOW_FILTER = '[DASHBOARD/TAGS-COMPONENT CONTEXT] RECEIVE FILTER',
    SET_TIME_RANGE = '[DASHBOARD/TAGS-COMPONENT CONTEXT] SET TIME RANGE',
    SET_SEARCH_VESSEL_TAG_TERM = '[DASHBOARD/TAGS-COMPONENT CONTEXT] SET SEARCH VESSEL TAG TERM',
    SET_SEARCH_VESSEL_TERM = '[DASHBOARD/TAGS-COMPONENT CONTEXT] SET SEARCH VESSEL TERM',
    TOGGLE_VESSEL_TAG = '[DASHBOARD/TAGS-COMPONENT CONTEXT] TOGGLE VESSEL TAG',
}
