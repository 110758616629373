import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'

export const Content = styled.main`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
`

interface AsideWrapperProps {
    height: number
}

export const AsideWrapper = styled.div<AsideWrapperProps>`
    margin-top: ${(props) => spacing(props.height >= 400 ? 8 : 4)};
    max-width: 398px;
`

interface MainActionsGridProps {
    useAvailableSpace?: boolean
}
export const MainActionsGrid = styled.div<MainActionsGridProps>`
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 15px;
    ${(props) => (props.useAvailableSpace ? 'flex: 1; align-content: center;' : '')}
`

interface CallToActionSectionProps {
    showError: boolean
}

export const CallToActionSection = styled.section<CallToActionSectionProps>`
    border-radius: 15px;
    max-width: 400px;
    width: 100%;
    height: 200px;
    padding: ${spacing(4)};
    border: ${(props) => (props.showError ? `2px solid ${props.theme.login.errorBorder}` : 'none')};
    background-color: ${(props) =>
        props.showError ? props.theme.login.errorBackground : '#657e98'};
    box-shadow: 1px 1px 6px -2px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
`
