import LoadingState from '../../../values/loading-state-enum'
import {LocationIdType} from '../locations/state'
import {SortValue} from '../vessel-filter/types/vessel-filter-state'

export interface ReportsFilterReduxState {
    loadingState: LoadingState
    selectedReportType: string | undefined
    selectedRating: number
    selectedFromTimeRange: string | undefined
    selectedToTimeRange: string | undefined
    includeWholeFleetReports: boolean
    locations: Set<LocationIdType> | undefined
    searchVesselTagTerm: string[]
    searchVesselNameTerm: string
    searchVesselTerm: string
    showXButton: boolean
    vesselSort: SortValue
    selectedFilterName: string
}

export const DEFAULT_REPORTS_FILTER_STATE: ReportsFilterReduxState = {
    loadingState: LoadingState.NotPopulated,
    selectedRating: 101,
    selectedFromTimeRange: '',
    selectedToTimeRange: '',
    selectedReportType: '',
    includeWholeFleetReports: true,
    locations: undefined,
    searchVesselTagTerm: [],
    searchVesselNameTerm: '',
    searchVesselTerm: '',
    showXButton: false,
    vesselSort: {
        field: 'vessel-name',
        direction: 'desc',
    },
    selectedFilterName: 'Saved filters',
}
