export interface WatchReduxState {
    nodes: string[]
    error: string
    loading: boolean
}

export const DEFAULT_WATCH_STATE: WatchReduxState = {
    nodes: [],
    error: '',
    loading: true,
}
