import {Action} from './actions'
import ActionType from './action-type'
import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'
import LoadingState from '../../../values/loading-state-enum'
import {isEqual} from 'lodash'
import produce from 'immer'
import {DEFAULT_VESSELS_BETA_FILTER, VesselsBetaFilterReduxState} from './state'

const vesselsBetaFilterReducer = produce(
    (
        draft: VesselsBetaFilterReduxState = DEFAULT_VESSELS_BETA_FILTER,
        action: Action | LoggingOutAction,
    ) => {
        switch (action.type) {
            case ActionType.REQUEST_FILTER:
                draft.loadingState = LoadingState.RequestingData
                break
            case ActionType.RECEIVE_FILTER:
                draft.loadingState = LoadingState.Loaded
                draft.analysisPeriod = action.payload.analysisPeriod
                draft.selectedColumns = action.payload.selectedColumns
                draft.sortColumn = action.payload.sortColumn
                if (!isEqual(draft.locations, action.payload.locations)) {
                    draft.locations = action.payload.locations
                }
                draft.searchVesselTagTerm = action.payload.searchVesselTagTerm
                draft.searchVesselNameTerm = action.payload.searchVesselNameTerm
                draft.searchVesselTerm = action.payload.searchVesselTerm
                break

            case ActionType.SET_ANALYSIS_PERIOD:
                draft.analysisPeriod = action.payload
                break

            case ActionType.SET_SORT_COLUMN:
                draft.sortColumn = action.payload
                break

            case ActionType.SET_ACTIVE_COLUMNS:
                const {selectedColumnType, selectedColumnNewValue} = action.payload

                if (selectedColumnNewValue) {
                    draft.selectedColumns = [...draft.selectedColumns, selectedColumnType]
                } else {
                    draft.selectedColumns = draft.selectedColumns.filter(
                        (selectedColumn) => selectedColumn !== action.payload.selectedColumnType,
                    )
                }
                break

            case ActionType.RESET_FILTER:
                draft.loadingState = LoadingState.Loaded
                draft.analysisPeriod = DEFAULT_VESSELS_BETA_FILTER.analysisPeriod
                draft.selectedColumns = DEFAULT_VESSELS_BETA_FILTER.selectedColumns
                draft.sortColumn = DEFAULT_VESSELS_BETA_FILTER.sortColumn
                draft.locations = DEFAULT_VESSELS_BETA_FILTER.locations
                draft.searchVesselTagTerm = DEFAULT_VESSELS_BETA_FILTER.searchVesselTagTerm
                draft.searchVesselNameTerm = DEFAULT_VESSELS_BETA_FILTER.searchVesselNameTerm
                break

            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_VESSELS_BETA_FILTER
                break
            /* istanbul ignore next */
            default:
                break
        }

        return draft
    },
)
export default vesselsBetaFilterReducer
