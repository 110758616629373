import ActionType from './action-type'
import {Action} from './actions'

import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'

import {DEFAULT_SFM_STATE, newSFMScoreMap, SFMReduxState} from './state'

function requestSfmScores(currentState: SFMReduxState): SFMReduxState {
    if (currentState.fetching === true) {
        return currentState
    }

    return {
        ...currentState,
        fetching: true,
    }
}

function resetState(currentState: SFMReduxState): SFMReduxState {
    if (currentState.fetching === false && currentState.scores?.size === 0) {
        return currentState
    }

    return DEFAULT_SFM_STATE
}

interface Score {
    key: string
    value: number
}

function receiveScores(
    currentState: SFMReduxState,
    receivedData: {[guid: string]: number},
): SFMReduxState {
    const newRecords: Score[] = []
    const updatedRecords: Score[] = []
    const unchangedRecords: Score[] = []

    for (const key in receivedData) {
        const newValue = receivedData[key]

        if (currentState.scores.has(key)) {
            const currentValue = currentState.scores.get(key)
            if (currentValue !== newValue) {
                updatedRecords.push({key, value: newValue})
            } else {
                unchangedRecords.push({key, value: newValue})
            }
        } else {
            newRecords.push({key, value: newValue})
        }
    }

    if (newRecords.length === 0 && updatedRecords.length === 0) {
        if (unchangedRecords.length === currentState.scores.size) {
            if (currentState.fetching === false) {
                return currentState
            }

            return {
                ...currentState,
                fetching: false,
            }
        }

        const data = newSFMScoreMap()
        unchangedRecords.forEach((score) => data.set(score.key, score.value))
        return {
            ...currentState,
            scores: data,
            fetching: false,
        }
    }

    const data = newSFMScoreMap()
    newRecords.forEach((score) => data.set(score.key, score.value))
    updatedRecords.forEach((score) => data.set(score.key, score.value))
    unchangedRecords.forEach((score) => data.set(score.key, score.value))

    return {
        ...currentState,
        scores: data,
        fetching: false,
    }
}

export default function SFMReducer(
    state: SFMReduxState = DEFAULT_SFM_STATE,
    action: Action | LoggingOutAction,
): SFMReduxState {
    switch (action.type) {
        case ActionType.REQUEST_SFM_SCORES:
            return requestSfmScores(state)

        case ActionType.RECEIVE_SFM_SCORES:
            return receiveScores(state, action.payload)

        case SessionActionType.LOGGING_OUT:
            return resetState(state)

        /* istanbul ignore next */
        default:
            return state
    }
}
