import AppState from '../../types/app-state'
import {LocationIdType} from '../locations/state'
import {ApiCreateOrAssign, ApiUpdateTag, displayErrorMessage, getTheErrorCodes} from './helper'
import {LocationTagArrayMapType, VesselTags} from './state'

export const vesselTagsSelector = (state: AppState): VesselTags[] => state.vesselTags.vesselTags

export const vesselTagsForLocationsSelector = (state: AppState): LocationTagArrayMapType =>
    state.vesselTags.tagsForLocation

export const isTheMessageErrorSelector = (state: AppState): boolean =>
    getTheErrorCodes(state.vesselTags.errorResult)

export const errorMessageSelector = (state: AppState): string =>
    displayErrorMessage(state.vesselTags.errorResult)

export const displayVesselSettingsSelector = (state: AppState): LocationIdType | undefined =>
    state.vesselTags.displayVesselSettingsForId

export const tagToCreateOrAssignSelector = (state: AppState): ApiCreateOrAssign | null =>
    state.vesselTags.tagToCreateOrAssign

export const tagToUpdateSelector = (state: AppState): ApiUpdateTag | null =>
    state.vesselTags.tagToUpdate

export const searchTermSelector = (state: AppState): string => state.vesselTags.searchedTerm

export const isTagLoadingSelector = (state: AppState): boolean => state.vesselTags.isFetchingTags
