enum ActionType {
    REQUEST_LOCATIONS = '[CORE/LOCATIONS] GET LOCATIONS',
    SET_LOCATIONS = '[CORE/LOCATIONS] SET LOCATIONS',
    SET_ACTIVE_LOCATION = '[CORE/LOCATIONS] SET ACTIVE LOCATION',
    RESET_ACTIVE_LOCATION = '[CORE/LOCATIONS] RESET ACTIVE LOCATION',
    UPDATE_VESSEL_CONFIG = '[CORE/LOCATIONS] UPDATE VESSEL CONFIG',
    CHANGE_BUSINESS_NETWORKS = '[CORE/LOCATIONS] CHANGE BUSINESS_NETWORKS',
    CHANGE_VESSEL_EMAIL = '[CORE/LOCATIONS] CHANGE VESSEL_EMAIL',
    CHANGE_OT_NETWORKS = '[CORE/LOCATIONS] CHANGE OT_NETWORKS',
    CHANGE_CREW_NETWORKS = '[CORE/LOCATIONS] CHANGE CREW_NETWORKS',
    CHANGE_DASHBOARD_AGENT_IDS = '[CORE/LOCATIONS] CHANGE DASHBOARD AGENT IDS',
    UPDATE_VESSEL_NETWORK_CONFIG = '[CORE/LOCATIONS] UPDATE VESSEL NETWORK CONFIG',
    UPDATE_VESSEL_DASHBOARD_AGENT_IDS = '[CORE/LOCATIONS] UPDATE VESSEL DASHBOARD AGENT IDS',
}

export default ActionType
