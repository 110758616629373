import {Dispatch} from 'react'
import {
    IncidentCountsType,
    IncidentAssignmentsCounts,
    IncidentTrendsType,
    IncidentAvgPerformanceType,
    IncidentVesselsPerformanceType,
} from '../../../../../incidents-report/contexts/types/incidents-report-output'
import {ActionType} from './action-type'
import * as Actions from './actions'
import {REST} from '../../../../../..'
import {IncidentsWidgetsPrefs, IncidentWidgetFilter} from '../types/incidents-widget-state'
import {LocationIdType} from '../../../../../../store/state/locations/state'
import {IncidentWidgetType} from '../types/incident-widget-type'
import {DefaultPeriodOptions} from '../../../period-filter-type-dashboard'
import {IncidentsOverview} from '../types/incidents-overview'

const USER_PREFS_URL = '/api/v1/userPreferences/incidentsReport'

export function setFilter(): Actions.SetFilterAction {
    return {type: ActionType.SET_FILTER}
}

export function receivedRequestedCountsData(
    incidentsCounts: IncidentCountsType,
): Actions.ReceivedRequestedCountsDataAction {
    return {
        type: ActionType.RECEIVED_REQUESTED_INCIDENTS_COUNTS_DATA_ACTION,
        payload: incidentsCounts,
    }
}
export function receivedRequestedAssignmentsData(
    assignementsCounts: IncidentAssignmentsCounts,
): Actions.ReceivedRequestedAssignmentsDataAction {
    return {
        type: ActionType.RECEIVED_REQUESTED_INCIDENTS_ASSIGNMENTS_DATA_ACTION,
        payload: assignementsCounts,
    }
}

export function receivedRequestedTrendsData(
    incidentsTrends: IncidentTrendsType[],
): Actions.ReceivedRequestedTrendsDataAction {
    return {
        type: ActionType.RECEIVED_REQUESTED_INCIDENTS_TRENDS_DATA_ACTION,
        payload: incidentsTrends,
    }
}

export function receivedRequestedAvgPerformanceData(
    incidentsAvgPerformance: IncidentAvgPerformanceType[],
): Actions.ReceivedRequestedAvgPerformanceDataAction {
    return {
        type: ActionType.RECEIVED_REQUESTED_INCIDENTS_AVG_PERFORMANCE_DATA_ACTION,
        payload: incidentsAvgPerformance,
    }
}

export function receivedRequestedVesselsPerformanceData(
    incidentsVesselsPerformance: IncidentVesselsPerformanceType,
): Actions.ReceivedRequestedVesselsPerformanceDataAction {
    return {
        type: ActionType.RECEIVED_REQUESTED_INCIDENTS_VESSELS_PERFORMANCE_DATA_ACTION,
        payload: incidentsVesselsPerformance,
    }
}

export function setSearchVesselTagTerm(
    searchVesselTagTerm: string[],
    dispatch: Dispatch<Actions.SetSearchVesselTagTermAction>,
    currentFilter: IncidentWidgetFilter,
    isDefaultTagUser: boolean,
): void {
    dispatch({
        type: ActionType.SET_SEARCH_VESSEL_TAG_TERM,
        payload: searchVesselTagTerm,
    })
    isDefaultTagUser
        ? REST.put(`${USER_PREFS_URL}.default_tag`, {
              default_tag: searchVesselTagTerm,
          })
        : REST.put(USER_PREFS_URL, {
              ...buildFilterPrefs({...currentFilter, searchVesselTagTerm}),
          })
}

export function requestFilter(): Actions.RequestFilterAction {
    return {
        type: ActionType.REQUEST_FILTER,
    }
}

export function receiveFilter(
    locations: LocationIdType[] | undefined,
    searchVesselTagTerm: string[],
    incidentWidgetOnDashboard: IncidentWidgetType,
    filterPeriod: DefaultPeriodOptions,
): Actions.ReceiveFilterAction {
    return {
        type: ActionType.RECEIVE_FILTER,
        payload: {locations, searchVesselTagTerm, incidentWidgetOnDashboard, filterPeriod},
    }
}

function buildFilterPrefs(state: IncidentWidgetFilter): IncidentsWidgetsPrefs {
    return {
        incidentWidgetOnDashboard: state.incidentWidgetOnDashboard,
        periodWidgetOnDashboard: state.periodWidgetOnDashboard,
        searchVesselTagTerm: state.searchVesselTagTerm,
    }
}

export function displayIncidentWidgetOnDashboard(
    incidentWidgetOnDashboard: IncidentWidgetType,
    dispatch: Dispatch<Actions.SetIncidentWidgetValueAction>,
    currentFilter: IncidentWidgetFilter,
): void {
    dispatch({
        type: ActionType.SET_INCIDENT_WIDGET_VALUE,
        payload: incidentWidgetOnDashboard,
    })
    REST.put(USER_PREFS_URL, {
        ...buildFilterPrefs({...currentFilter, incidentWidgetOnDashboard}),
    })
}

export function setPeriodWidgetOnDashboard(
    periodWidgetOnDashboard: DefaultPeriodOptions,
    dispatch: Dispatch<Actions.SetPeriodFilterOnDashboardAction>,
    currentFilter: IncidentWidgetFilter,
): void {
    dispatch({
        type: ActionType.SET_DATE_FILTER_ON_DASHBOARD,
        payload: periodWidgetOnDashboard,
    })

    REST.put(USER_PREFS_URL, {
        ...buildFilterPrefs({...currentFilter, periodWidgetOnDashboard}),
    })
}

export function receiveIncidentsOverview(
    data: IncidentsOverview,
): Actions.ReceiveIncidentsOverview {
    return {
        type: ActionType.RECEIVED_REQUESTED_INCIDENTS_OVERVIEW,
        payload: data,
    }
}
