import LoadingState from '../../../../values/loading-state-enum'
import {LocationIdType} from '../../locations/state'

export enum ZOOM_LEVEL {
    ONE = '1',
    TWO = '2',
    THREE = '3',
    FOUR = '4',
}

export type ZOOM_LEVEL_TYPE = ZOOM_LEVEL.ONE | ZOOM_LEVEL.TWO | ZOOM_LEVEL.THREE | ZOOM_LEVEL.FOUR

export type SORT_DIRECTION_TYPE = 'desc' | 'asc'

export interface AssetThresholds {
    low: boolean
    medium: boolean
    high: boolean
}

export interface SortValue {
    field: string
    direction: SORT_DIRECTION_TYPE
}

export interface VesselFiltersState {
    loadingState: LoadingState
    HIGH: AssetThresholds
    MEDIUM: AssetThresholds
    LOW: AssetThresholds
    locations: Set<LocationIdType> | undefined
    searchAssetTerm: string
    searchVesselTagTerm: string[]
    searchVesselNameTerm: string
    searchVesselTerm: string
    showXButton: boolean
    assetSort: SortValue
    vesselSort: SortValue
    zoomLevel: ZOOM_LEVEL_TYPE
}
