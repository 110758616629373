export interface EtsData {
    score: number
    highScore: number
    mediumScore: number
    lowScore: number
    timestamp?: number
}

export interface EtsValueReduxState {
    isFetching: boolean
    ets: EtsData
}

export const DEFAULT_ETS_VALUE_STATE: EtsValueReduxState = {
    isFetching: true,
    ets: {
        highScore: 0,
        mediumScore: 0,
        lowScore: 0,
        score: 0,
    },
}
