import NetworkAnomalyMatrixRecordMap from '../../../values/anomalies/network-anomaly-matrix-record-map'
import LoadingState from '../../../values/loading-state-enum'

export const DEFAULT_NETWORK_ANOMALY_MATRIX_STATE: NetworkAnomalyMatrixState = {
    loading: LoadingState.NotPopulated,
    records: null,
    errorMessage: '',
}

export interface NetworkAnomalyMatrixState {
    loading: LoadingState
    records: NetworkAnomalyMatrixRecordMap | null
    errorMessage: string
}
