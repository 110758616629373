import styled from 'styled-components'
import {spacing} from '../../../theme/spacing'

export const ScreenBackGround = styled.div`
    min-width: 100vw;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${spacing(4)};
    background-image: linear-gradient(#1d2e3e, #657e98);
`
