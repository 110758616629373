import {Action} from './actions'
import ActionType from './action-type'
import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'
import LoadingState from '../../../values/loading-state-enum'
import {isEqual} from 'lodash'
import produce from 'immer'
import {
    DEFAULT_USB_INVENTORY_FILTER_STATE,
    DeviceStatus,
    UsbInventoryFilterReduxState,
} from './state'
import {populateLocations} from '../../../utils/Utils'

const usbInventoryFilterReducer = produce(
    (
        draft: UsbInventoryFilterReduxState = DEFAULT_USB_INVENTORY_FILTER_STATE,
        action: Action | LoggingOutAction,
    ) => {
        switch (action.type) {
            case ActionType.REQUEST_FILTER:
                draft.loadingState = LoadingState.RequestingData
                break
            case ActionType.RECEIVE_FILTER:
                draft.loadingState = LoadingState.Loaded
                if (!isEqual(draft.sortColumn, action.payload)) {
                    draft.sortColumn = action.payload.sortColumn
                }
                if (
                    !isEqual(draft.selectedUsbDeviceStatus, action.payload.selectedUsbDeviceStatus)
                ) {
                    draft.selectedUsbDeviceStatus = action.payload.selectedUsbDeviceStatus
                }
                draft.searchedFirstDetected = action.payload.searchedFirstDetected
                draft.searchedLastActive = action.payload.searchedLastActive
                draft.searchedVendor = action.payload.searchedVendor
                draft.searchedProductName = action.payload.searchedProductName
                if (!isEqual(draft.searchedType, action.payload.searchedType)) {
                    draft.searchedType = action.payload.searchedType
                }
                draft.hasPurpose = action.payload.hasPurpose
                if (!isEqual(draft.locations, action.payload.locations)) {
                    draft.locations = action.payload.locations
                }
                draft.searchVesselTagTerm = action.payload.searchVesselTagTerm
                draft.searchVesselNameTerm = action.payload.searchVesselNameTerm
                draft.selectedFilterName = action.payload.selectedFilterName
                if (!isEqual(draft.detailExpanded, action.payload)) {
                    draft.detailExpanded = action.payload.detailExpanded
                }
                break

            case ActionType.SET_SORT_COLUMN:
                if (!isEqual(draft.sortColumn, action.payload)) {
                    draft.sortColumn = action.payload
                }
                break

            case ActionType.TOGGLE_USB_DEVICE_STATUS:
                const {allUsbDeviceStatus, selectedUsbDeviceStatus, usbDeviceStatusNewValue} =
                    action.payload
                if (draft.selectedUsbDeviceStatus == undefined) {
                    draft.selectedUsbDeviceStatus = [...allUsbDeviceStatus]
                }
                if (usbDeviceStatusNewValue) {
                    draft.selectedUsbDeviceStatus = [
                        ...draft.selectedUsbDeviceStatus,
                        selectedUsbDeviceStatus,
                    ]
                } else {
                    draft.selectedUsbDeviceStatus.splice(
                        draft.selectedUsbDeviceStatus.indexOf(selectedUsbDeviceStatus),
                        1,
                    )
                }
                if (draft.selectedUsbDeviceStatus.length === allUsbDeviceStatus.length) {
                    draft.selectedUsbDeviceStatus = undefined
                }
                break

            case ActionType.TOGGLE_ALL_USB_DEVICE_STATUS:
                draft.selectedUsbDeviceStatus = action.payload
                    ? undefined
                    : new Array<DeviceStatus>()
                break

            case ActionType.SET_SEARCHED_FIRST_SEEN:
                draft.searchedFirstDetected = action.payload
                break
            case ActionType.SET_SEARCHED_LAST_ACTIVE:
                draft.searchedLastActive = action.payload
                break
            case ActionType.SET_SEARCHED_VENDOR:
                draft.searchedVendor = action.payload
                break
            case ActionType.SET_SEARCHED_PRODUCT_NAME:
                draft.searchedProductName = action.payload
                break
            case ActionType.SET_SEARCHED_USB_TYPE:
                if (!isEqual(draft.searchedType, action.payload)) {
                    draft.searchedType = action.payload
                }
                break
            case ActionType.SET_SEARCHED_HAS_PURPOSE:
                draft.hasPurpose = action.payload
                break
            case ActionType.TOGGLE_FILTER_EXPANDED:
                if (!isEqual(draft.filterExpanded, action.payload)) {
                    draft.filterExpanded = action.payload
                }
                break
            case ActionType.TOGGLE_DETAIL_EXPANDED:
                if (!isEqual(draft.detailExpanded, action.payload)) {
                    draft.detailExpanded = action.payload
                }
                break
            case ActionType.RESET_FILTER:
                draft.loadingState = LoadingState.Loaded
                draft.sortColumn = DEFAULT_USB_INVENTORY_FILTER_STATE.sortColumn
                draft.selectedUsbDeviceStatus =
                    DEFAULT_USB_INVENTORY_FILTER_STATE.selectedUsbDeviceStatus
                draft.searchedFirstDetected =
                    DEFAULT_USB_INVENTORY_FILTER_STATE.searchedFirstDetected
                draft.searchedLastActive = DEFAULT_USB_INVENTORY_FILTER_STATE.searchedLastActive
                draft.searchedVendor = DEFAULT_USB_INVENTORY_FILTER_STATE.searchedVendor
                draft.searchedProductName = DEFAULT_USB_INVENTORY_FILTER_STATE.searchedProductName
                draft.searchedType = DEFAULT_USB_INVENTORY_FILTER_STATE.searchedType
                draft.hasPurpose = DEFAULT_USB_INVENTORY_FILTER_STATE.hasPurpose
                draft.locations = DEFAULT_USB_INVENTORY_FILTER_STATE.locations
                draft.searchVesselTagTerm = DEFAULT_USB_INVENTORY_FILTER_STATE.searchVesselTagTerm
                draft.searchVesselNameTerm = DEFAULT_USB_INVENTORY_FILTER_STATE.searchVesselNameTerm
                draft.selectedFilterName = DEFAULT_USB_INVENTORY_FILTER_STATE.selectedFilterName
                break

            case ActionType.BULK_ASSIGN_SAVED_FILTER:
                draft.loadingState = LoadingState.Loaded
                draft.sortColumn = action.payload.criteria.sortColumn
                draft.selectedUsbDeviceStatus = action.payload.criteria.selectedUsbDeviceStatus
                draft.searchedFirstDetected =
                    action.payload.criteria.searchedFirstDetected ??
                    DEFAULT_USB_INVENTORY_FILTER_STATE.searchedFirstDetected
                draft.searchedLastActive =
                    action.payload.criteria.searchedLastActive ??
                    DEFAULT_USB_INVENTORY_FILTER_STATE.searchedLastActive
                draft.searchedVendor =
                    action.payload.criteria.searchedVendor ??
                    DEFAULT_USB_INVENTORY_FILTER_STATE.searchedVendor
                draft.searchedProductName =
                    action.payload.criteria.searchedProductName ??
                    DEFAULT_USB_INVENTORY_FILTER_STATE.searchedProductName
                draft.searchedType =
                    action.payload.criteria.searchedType ??
                    DEFAULT_USB_INVENTORY_FILTER_STATE.searchedType
                draft.hasPurpose =
                    action.payload.criteria.hasPurpose ??
                    DEFAULT_USB_INVENTORY_FILTER_STATE.hasPurpose
                draft.locations = populateLocations(action.payload.criteria.searchedVessels)
                draft.searchVesselTagTerm =
                    action.payload.criteria.searchVesselTagTerm ??
                    DEFAULT_USB_INVENTORY_FILTER_STATE.searchVesselTagTerm
                draft.selectedFilterName = action.payload.name
                draft.detailExpanded =
                    action.payload.detailExpanded ??
                    DEFAULT_USB_INVENTORY_FILTER_STATE.detailExpanded
                break

            case ActionType.SET_SELECTED_FILTER_NAME:
                draft.selectedFilterName = action.payload ?? ''
                break

            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_USB_INVENTORY_FILTER_STATE
                break
            /* istanbul ignore next */
            default:
                break
        }

        return draft
    },
)
export default usbInventoryFilterReducer
