import {IncidentStatus} from '../../pages/incidents-v3/contexts/types/incident-status'
import {DetailExpandedType} from '../../pages/incidents-v3/contexts/types/incidents-details-type'
import {IncidentResponseSortType} from '../../pages/incidents-v3/contexts/types/type-of-interfaces'
import {INCIDENT_NOTES_SORT_ORDER} from '../../pages/incidents-v3/data-helpers'
import {LocationIdType} from '../../store/state/locations/state'
import {IncidentSeverityValue} from '../incident-response-values'
import {SORT_ORDER} from '../sort-order'
import {TimeRange} from '../TimeRange'

export interface IncidentsFilterCriteria {
    orderBy: {column: IncidentResponseSortType; ascending: boolean}
    updatedFrom: string | null
    updatedTo: string | null
    createdFrom: string | null
    createdTo: string | null
    updatedFromRelative: string | null
    updatedToRelative: string | null
    createdFromRelative: string | null
    createdToRelative: string | null
    text: string | null
    severities: IncidentSeverityValue[] | undefined
    statuses: IncidentStatus[] | undefined
    type: string | undefined
    assignedTo: string | undefined
    guestAssignedToEmail: string | undefined
    watchedByCurrentUser: boolean | null
    unseenIncidentResponses: boolean | null
    assignedToVesselEmail: boolean | null
    severity?: string | undefined
}

export const DEFAULT_INCIDENTS_FILTER_CRITERIA: IncidentsFilterCriteria = {
    orderBy: {
        column: IncidentResponseSortType.STATUS,
        ascending: false,
    },
    updatedFrom: null,
    updatedTo: null,
    createdFrom: null,
    createdTo: null,
    updatedToRelative: null,
    updatedFromRelative: null,
    createdFromRelative: null,
    createdToRelative: null,
    text: null,
    severities: undefined,
    statuses: undefined,
    type: undefined,
    assignedTo: undefined,
    guestAssignedToEmail: undefined,
    watchedByCurrentUser: null,
    unseenIncidentResponses: false,
    assignedToVesselEmail: null,
    severity: undefined,
}

export interface IncidentsFilter {
    orderIncidentsBy: {column: IncidentResponseSortType; direction: SORT_ORDER}
    filterByStatus: IncidentStatus[] | undefined
    filterBySeverities: IncidentSeverityValue[] | undefined
    sortByNotes: INCIDENT_NOTES_SORT_ORDER
    selectedIncidentType: string | undefined
    selectedCreatedFromTimeRange: string | null
    selectedCreatedToTimeRange: string | null
    selectedUpdatedFromTimeRange: string | null
    selectedUpdatedToTimeRange: string | null
    createdFromRelative: TimeRange | null
    createdToRelative: TimeRange | null
    updatedFromRelative: TimeRange | null
    updatedToRelative: TimeRange | null
    locations: Array<LocationIdType> | undefined
    assignedTo: string | undefined
    guestAssignedToEmail: string | undefined
    watchedByCurrentUser: boolean | null
    unseenIncidentResponses: boolean | null
    searchVesselTagTerm: string[]
    searchVesselNameTerm: string
    assignedToVesselEmail: boolean | null
}

export const DEFAULT_INCIDENTS_FILTER = {
    // searchIncidentNo: '',
    orderIncidentsBy: {column: IncidentResponseSortType.STATUS, direction: SORT_ORDER.DESCENDING},
    filterByStatus: undefined,
    filterBySeverities: undefined,
    sortByNotes: INCIDENT_NOTES_SORT_ORDER.NONE,
    selectedIncidentType: undefined,
    selectedCreatedFromTimeRange: null,
    selectedCreatedToTimeRange: null,
    selectedUpdatedFromTimeRange: null,
    selectedUpdatedToTimeRange: null,
    updatedToRelative: null,
    createdFromRelative: null,
    createdToRelative: null,
    updatedFromRelative: null,
    locations: undefined,
    assignedTo: undefined,
    guestAssignedToEmail: undefined,
    watchedByCurrentUser: null,
    unseenIncidentResponses: false,
    searchVesselTagTerm: [],
    searchVesselNameTerm: '',
    assignedToVesselEmail: null,
} as IncidentsFilter

export interface IncidentsFilterForUserPref {
    selectedFilterName: string
    locations: LocationIdType[] | undefined
    searchVesselTagTerm: string[]
    searchVesselNameTerm: string
    sortByNotes: INCIDENT_NOTES_SORT_ORDER
    detailExpanded: DetailExpandedType
}
