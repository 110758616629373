import {isEqual} from 'lodash'

function isUpdated<T>(currentValue: T, newValue: T): boolean {
    return !isEqual(currentValue, newValue)
}

export function getUpdatedDataArray<T>(
    newData: T[] | undefined,
    existingData: T[] | undefined,
    findPredicate: (a: T, b: T) => boolean,
): {dataUpdated: boolean; data: T[]} {
    let arrayUpdated = false

    const updatedData = (newData || []).map((newRecord) => {
        const existingRecord = existingData
            ? existingData.find((record) => findPredicate(record, newRecord))
            : null

        if (!existingRecord) {
            arrayUpdated = true
            return newRecord
        }

        if (isUpdated(existingRecord, newRecord)) {
            arrayUpdated = true
            return newRecord
        }

        return existingRecord
    })

    if (arrayUpdated || updatedData.length !== existingData?.length) {
        return {
            dataUpdated: true,
            data: updatedData,
        }
    }

    return {
        dataUpdated: false,
        data: existingData,
    }
}
