import LoadingState from '../../../values/loading-state-enum'
import {LocationIdType} from '../locations/state'
import {SortValue} from '../vessel-filter/types/vessel-filter-state'

export interface IncidentsReportFilterReduxState {
    loadingState: LoadingState
    locations: Set<LocationIdType> | undefined
    fromDate: string | null
    toDate: string | null
    searchVesselTagTerm: string[]
    searchVesselNameTerm: string
    vesselSort: SortValue
    filterExpanded: FilterExpandedType
    selectedFilterName: string
}

export interface FilterExpandedType {
    periodExpanded: boolean
    vesselExpanded: boolean
}

export enum FilterBarType {
    PeriodSelection = 'Period selection',
    Vessels = 'Vessels',
}

export const DEFAULT_INCIDENTS_REPORT_FILTER_STATE: IncidentsReportFilterReduxState = {
    loadingState: LoadingState.NotPopulated,
    locations: undefined,
    fromDate: null,
    toDate: null,
    searchVesselTagTerm: [],
    searchVesselNameTerm: '',
    vesselSort: {
        field: 'vessel-name',
        direction: 'desc',
    },
    filterExpanded: {
        periodExpanded: true,
        vesselExpanded: true,
    },
    selectedFilterName: 'Saved filters',
}

export interface IncidentsReportFilter {
    locations: LocationIdType[] | undefined
    fromDate: string | null
    toDate: string | null
    searchVesselTagTerm: string[]
    searchVesselNameTerm: string
}

export const DEFAULT_INCIDENTS_REPORT_FILTER: IncidentsReportFilter = {
    locations: undefined,
    fromDate: null,
    toDate: null,
    searchVesselTagTerm: [],
    searchVesselNameTerm: '',
}
