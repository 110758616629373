export type TimestampFilterType = '1d' | '7d' | '30d'

type TimestampToFormatMap = {
    '1d': string
    '7d': string
    '30d': string
}

export const allPeriodTypeOther: TimestampFilterType[] = ['1d', '7d']
export function getTypeDisplayName(types: TimestampFilterType): string {
    switch (types) {
        case '1d':
            return 'Last 24 hours'
        case '7d':
            return 'Last 7 days'
        case '30d':
        default:
            return 'Last 30 days'
    }
}

export function formatTimestampCriteria<T extends TimestampFilterType>(
    searchedTimestamp: T,
): TimestampToFormatMap[T] | null {
    switch (searchedTimestamp) {
        case '1d':
            return '-24h' as TimestampToFormatMap[T]
        case '7d':
            return '-168h' as TimestampToFormatMap[T]
        case '30d':
            return '-720h' as TimestampToFormatMap[T]
        default:
            return null
    }
}
export function getTableHeaderDisplayName(types: TimestampFilterType): string {
    switch (types) {
        case '1d':
            return '24 hours'
        case '7d':
            return '7 days'
        case '30d':
        default:
            return '30 days'
    }
}
export function getFormatedPreiousPeriodTimestamp(types: TimestampFilterType): {
    firstCall: {
        fromRelativeFirstSeen: string
        toRelativeFirstSeen: string | undefined
    }
    secondCall: {
        fromRelativeFirstSeen: string
        toRelativeFirstSeen: string
    }
} {
    switch (types) {
        case '1d':
            return {
                firstCall: {
                    fromRelativeFirstSeen: '-24h',
                    toRelativeFirstSeen: undefined,
                },
                secondCall: {
                    fromRelativeFirstSeen: '-48h',
                    toRelativeFirstSeen: '-24h',
                },
            }
        case '7d':
            return {
                firstCall: {
                    fromRelativeFirstSeen: '-168h',
                    toRelativeFirstSeen: undefined,
                },
                secondCall: {
                    fromRelativeFirstSeen: '-336h',
                    toRelativeFirstSeen: '-168h',
                },
            }
        case '30d':
        default:
            return {
                firstCall: {
                    fromRelativeFirstSeen: '-720h',
                    toRelativeFirstSeen: undefined,
                },
                secondCall: {
                    fromRelativeFirstSeen: '-1440h',
                    toRelativeFirstSeen: '-720h',
                },
            }
    }
}
