import * as Actions from './actions'
import ActionType from './action-type'
import {Api} from '../../../api/Api'
import {REST} from '../../../index'
import {ThunkAction} from 'redux-thunk'
import AppState from '../../types/app-state'
import {VesselFilterReduxState} from '../vessel-filter/types/vessel-filter-state'
import {DEFAULT_REPORTS_FILTER_STATE, ReportsFilterReduxState} from './state'
import {
    DEFAULT_REPORTS_FILTER,
    ReportsFilter,
} from '../../../values/user-preferences/reports-filter'
import {SavedReportsFilter} from '../saved-filters/state'
import {setCurrentAppliedSavedFilter} from '../saved-filters/action-creators'
import {AnyAction} from 'redux'
import {formattedLocationsBlockSet, populateLocationsForFilterCriteria} from '../../../utils/Utils'
import {getFormattedReportsFilter} from '../../../components/saved-search/components/shared/helper'

const USER_PREFS_URL = '/api/v1/userPreferences/reports-filter'

export const fetchReportsFilter = (
    savedFilters: SavedReportsFilter[],
    isDefaultTagUser?: boolean,
): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch) => {
        dispatch(requestFilter())
        if (isDefaultTagUser === true) {
            REST.get(USER_PREFS_URL).then(
                (response) => {
                    REST.get(`${USER_PREFS_URL}.default_tag`).then(
                        (res) => {
                            if (response.data.value.selectedFilterName === undefined) {
                                dispatch(
                                    bulkAssignSavedFilter(
                                        {
                                            ...DEFAULT_REPORTS_FILTER,
                                            locations: formattedLocationsBlockSet(
                                                response.data.value.locations,
                                            ),
                                            searchVesselTagTerm: res.data.value.default_tag ?? [],
                                            searchVesselNameTerm:
                                                response.data.value.searchVesselNameTerm ?? '',
                                        },
                                        'Saved filters',
                                        true,
                                    ),
                                )
                            } else {
                                const savedFilter =
                                    savedFilters &&
                                    savedFilters.find(
                                        (filter) =>
                                            filter.name === response.data.value.selectedFilterName,
                                    )

                                savedFilter
                                    ? dispatch(
                                          bulkAssignSavedFilter(
                                              {
                                                  ...(getFormattedReportsFilter(
                                                      savedFilter.criteria,
                                                  ) as ReportsFilter),
                                                  locations: formattedLocationsBlockSet(
                                                      response.data.value.locations,
                                                  ),
                                                  searchVesselTagTerm: res.data.value.default_tag,
                                                  searchVesselNameTerm:
                                                      response.data.value.searchVesselNameTerm,
                                              },
                                              savedFilter.name,
                                              true,
                                              savedFilter,
                                          ),
                                      )
                                    : dispatch(
                                          bulkAssignSavedFilter(
                                              {
                                                  ...DEFAULT_REPORTS_FILTER,
                                                  locations: formattedLocationsBlockSet(
                                                      response.data.value.locations,
                                                  ),
                                                  searchVesselTagTerm: res.data.value.default_tag,
                                                  searchVesselNameTerm:
                                                      response.data.value.searchVesselNameTerm,
                                              },
                                              'Saved filters',
                                              true,
                                          ),
                                      )
                            }
                            REST.delete(`${USER_PREFS_URL}.default_tag`)
                        },
                        () => {
                            dispatch(
                                bulkAssignSavedFilter(
                                    {
                                        ...DEFAULT_REPORTS_FILTER,
                                        locations: formattedLocationsBlockSet(
                                            response.data.value.locations,
                                        ),
                                        searchVesselTagTerm:
                                            response.data.value.searchVesselTagTerm,
                                        searchVesselNameTerm:
                                            response.data.value.searchVesselNameTerm,
                                    },
                                    'Saved filters',
                                ),
                            )
                        },
                    )
                },
                () => {
                    REST.get(`${USER_PREFS_URL}.default_tag`).then((res) => {
                        REST.put(USER_PREFS_URL, {
                            ...DEFAULT_REPORTS_FILTER_STATE,
                            searchVesselTagTerm: res.data.value.default_tag,
                        })
                        dispatch(
                            bulkAssignSavedFilter(
                                {
                                    ...DEFAULT_REPORTS_FILTER,
                                    searchVesselTagTerm: res.data.value.default_tag,
                                },
                                'Saved filters',
                            ),
                        )
                    })
                },
            )
        } else {
            REST.get(USER_PREFS_URL).then(
                (response) => {
                    if (response.data.value.selectedFilterName === undefined) {
                        dispatch(
                            bulkAssignSavedFilter(
                                {
                                    ...DEFAULT_REPORTS_FILTER,
                                    locations: formattedLocationsBlockSet(
                                        response.data.value.locations,
                                    ),
                                    searchVesselTagTerm:
                                        response.data.value.searchVesselTagTerm ?? [],
                                    searchVesselNameTerm:
                                        response.data.value.searchVesselNameTerm ?? '',
                                },
                                'Saved filters',
                            ),
                        )
                    } else {
                        const savedFilter =
                            savedFilters &&
                            savedFilters.find(
                                (filter) => filter.name === response.data.value.selectedFilterName,
                            )

                        savedFilter
                            ? dispatch(
                                  bulkAssignSavedFilter(
                                      {
                                          ...(getFormattedReportsFilter(
                                              savedFilter.criteria,
                                          ) as ReportsFilter),
                                          locations: formattedLocationsBlockSet(
                                              response.data.value.locations,
                                          ),
                                          searchVesselTagTerm:
                                              response.data.value.searchVesselTagTerm,
                                          searchVesselNameTerm:
                                              response.data.value.searchVesselNameTerm,
                                      },
                                      savedFilter.name,
                                      false,
                                      savedFilter,
                                  ),
                              )
                            : dispatch(
                                  bulkAssignSavedFilter(
                                      {
                                          ...DEFAULT_REPORTS_FILTER,
                                          locations: formattedLocationsBlockSet(
                                              response.data.value.locations,
                                          ),
                                          searchVesselTagTerm:
                                              response.data.value.searchVesselTagTerm,
                                          searchVesselNameTerm:
                                              response.data.value.searchVesselNameTerm,
                                      },
                                      'Saved filters',
                                  ),
                              )
                    }
                },
                () => {
                    dispatch(bulkAssignSavedFilter(DEFAULT_REPORTS_FILTER, 'Saved filters'))
                },
            )
        }
    }
}

function requestFilter(): Actions.RequestFilterAction {
    return {
        type: ActionType.REQUEST_FILTER,
    }
}

export function setSelectedReportType(
    selectedReportType: string | undefined,
): ThunkAction<void, AppState, Api, Actions.SetSelectedReportTypeAction> {
    return (dispatch) => {
        dispatch({
            type: ActionType.SET_SELECTED_REPORT_TYPE,
            payload: selectedReportType,
        })
    }
}

export function setSelectedRating(
    selectedRating: number,
): ThunkAction<void, AppState, Api, Actions.SetSelectedRatingAction> {
    return (dispatch) => {
        dispatch({
            type: ActionType.SET_SELECTED_RATING,
            payload: selectedRating,
        })
    }
}

export function setSelectedFromTimeRange(
    selectedFromTimeRange: string,
): ThunkAction<void, AppState, Api, Actions.SetSelectedTimeRangeAction> {
    return (dispatch) => {
        dispatch({
            type: ActionType.SET_SELECTED_TIME_RANGE_FROM,
            payload: selectedFromTimeRange,
        })
    }
}

export function setSelectedToTimeRange(
    selectedToTimeRange: string,
): ThunkAction<void, AppState, Api, Actions.SetSelectedTimeRangeAction> {
    return (dispatch, getState) => {
        const currentFilter = getState().reportsFilter
        const currentVesselFilter = getState().vesselFilter
        dispatch({
            type: ActionType.SET_SELECTED_TIME_RANGE_TO,
            payload: selectedToTimeRange,
        })
        REST.put(USER_PREFS_URL, {
            ...buildFilterPrefs({...currentFilter, selectedToTimeRange}, currentVesselFilter),
        })
    }
}

export function toggleWholeFleetReports(
    includeWholeFleetReports: boolean,
): ThunkAction<void, AppState, Api, Actions.ToggleWholeFleetReportsAction> {
    return (dispatch, getState) => {
        const currentFilter = getState().reportsFilter
        const currentVesselFilter = getState().vesselFilter
        dispatch({
            type: ActionType.TOGGLE_WHOLE_FLEET_REPORTS,
            payload: includeWholeFleetReports,
        })
        REST.put(USER_PREFS_URL, {
            ...buildFilterPrefs({...currentFilter, includeWholeFleetReports}, currentVesselFilter),
        })
    }
}

export function resetFilter(): ThunkAction<void, AppState, Api, AnyAction> {
    return (dispatch) => {
        dispatch({type: ActionType.RESET_FILTER})
        dispatch(setCurrentAppliedSavedFilter(undefined))
        REST.put(USER_PREFS_URL, {
            selectedFilterName: '',
            searchVesselTagTerm: [],
            searchVesselNameTerm: '',
        })
    }
}

export function bulkAssignSavedFilter(
    data: ReportsFilter,
    filterName: string,
    pref?: boolean,
    savedFilter?: SavedReportsFilter,
): ThunkAction<void, AppState, Api, AnyAction> {
    return (dispatch, getState) => {
        const currentVesselFilter = getState().vesselFilter
        dispatch({
            type: ActionType.BULK_ASSIGN_SAVED_FILTER,
            payload: {
                criteria: data,
                name: filterName,
            },
        })
        savedFilter && dispatch(setCurrentAppliedSavedFilter(savedFilter))
        pref &&
            REST.put(USER_PREFS_URL, {
                selectedFilterName: filterName,
                locations: populateLocationsForFilterCriteria(currentVesselFilter.locations),
                searchVesselTagTerm: currentVesselFilter.searchVesselTagTerm,
                searchVesselNameTerm: currentVesselFilter.searchVesselNameTerm,
            })
    }
}

export function setSelectedFilterName(
    filterName: string,
    pref?: {
        savedFilter: SavedReportsFilter
    },
): ThunkAction<void, AppState, Api, AnyAction> {
    return (dispatch, getState) => {
        const currentVesselFilter = getState().vesselFilter
        dispatch({
            type: ActionType.SET_SELECTED_FILTER_NAME,
            payload: filterName,
        })
        if (pref) {
            dispatch(setCurrentAppliedSavedFilter(pref.savedFilter))
            REST.put(USER_PREFS_URL, {
                selectedFilterName: filterName,
                locations: populateLocationsForFilterCriteria(currentVesselFilter.locations),
                searchVesselTagTerm: currentVesselFilter.searchVesselTagTerm,
                searchVesselNameTerm: currentVesselFilter.searchVesselNameTerm,
            })
        }
    }
}

function buildFilterPrefs(
    state: ReportsFilterReduxState,
    vesselFilterState: VesselFilterReduxState,
): ReportsFilter {
    return {
        selectedReportType: state.selectedReportType,
        selectedRating: state.selectedRating,
        selectedFromTimeRange: state.selectedFromTimeRange,
        selectedToTimeRange: state.selectedToTimeRange,
        includeWholeFleetReports: state.includeWholeFleetReports,
        locations: populateLocationsForFilterCriteria(vesselFilterState.locations),
        searchVesselTagTerm: vesselFilterState.searchVesselTagTerm,
        searchVesselNameTerm: vesselFilterState.searchVesselNameTerm,
    }
}
