import {Location, LocationIdType} from '../../../../../../store/state/locations/state'
import {VesselTags} from '../../../../../../store/state/vessel-tags/state'

export function filteredVesselIds(
    AllLocations: Location[],
    locations: Set<LocationIdType> | undefined,
    searchVesselTagTerm: string[],
): string[] {
    return AllLocations.filter((location) => filterVessel(location, locations))
        .filter((location) => filterVesselTags(location.tags, searchVesselTagTerm))
        .sort(compareRecord)
        .map((location) => location.location)
}
function filterVessel(location: Location, locations: Set<LocationIdType> | undefined): boolean {
    return !locations ? true : locations.has(location.location)
}

function filterVesselTags(vesselTag: VesselTags[] | undefined, searchTerm: string[]): boolean {
    if (!searchTerm || searchTerm?.length === 0) {
        return true
    }
    return searchTerm.every((searchTag) =>
        vesselTag?.map((element) => element.name).includes(searchTag),
    )
}

function compareRecord(a: Location, b: Location): number {
    return a.description.localeCompare(b.description)
}
