import {useContext} from 'react'
import {ToggleContext} from './toggle-context'
import {ReturnType} from './types/return-type'

export function useToggle(): ReturnType {
    const toggle = useContext(ToggleContext)

    if (!toggle) {
        throw new Error('useToggle must be used within a ToggleContext')
    }

    return toggle
}
