import LoadingState from '../../../../values/loading-state-enum'
import {VesselBetaTableDetailsMap} from './vessel-beta-table-details-model'

export interface PagedVesselsBetaState {
    showFilterBar: boolean
    selectedColumns: PossibleColumnsVesselBeta[]
    highlightedFilerValue: boolean
    loadingFilteredDataState: LoadingState
    tableVesselsDataMap: VesselBetaTableDetailsMap
}

export enum PossibleColumnsVesselBeta {
    NewInventoryAllTypes = 'newInventoryAllTypes',
    TotalInventoryAllTypes = 'totalInventoryAllTypes',
    UntrustedInventoryAllTypes = 'untrustedInventoryAllTypes',
    TrustedInventoryAllTypes = 'trustedInventoryAllTypes',
    NewInventoryAllTypesPreviousPeriod = 'newInventoryAllTypesPreviousPeriod',
    MissingNetworkDevicesBusinessNetwork = 'missingNetworkDevicesBusinessNetwork',
    MissingNetworkDevicesOTNetwork = 'missingNetworkDevicesOTNetwork',
    MissingNetworkDevicesMonitoredAssets = 'missingNetworkDevicesMonitoredAssets',
    NumberOfIncidentsRaised = 'numberOfIncidentsRaised',
    NumberOfCurrentOpenIncidents = 'numberOfCurrentOpenIncidents',
    NumberOfIncidentsOpen = 'numberOfIncidentsOpen',
    MonitoredAssetsInOpenIncidents = 'monitoredAssetsInOpenIncidents',
    MonitoredAssetsInOpenIncidentsAverageTime = 'monitoredAssetsInOpenIncidentsAverageTime',
    IncidentsBySeverityHigh = 'incidentsBySeverityHigh',
    OldestOpenIncident = 'oldestOpenIncident',
    IncidentsRaisedPreviousPeriod = 'incidentsRaisedPreviousPeriod',
    IncidentsRaisedComparedToWholeFleet = 'incidentsRaisedComparedToWholeFleet',
    NumberOfIncidentsAssignedToVessel = 'numberOfIncidentsAssignedToVessel',
    FrameworkProtection = 'frameworkProtection',
    FrameworkMaintenance = 'frameworkMaintenance',
    FrameworkBehaviour = 'frameworkBehaviour',
    AssetsWithWorstMetrics = 'assetsWithWorstMetrics',
    FrameworkSummaryScorecard = 'frameworkSummaryScorecard',
    FrameworkSummaryTarget = 'frameworkSummaryTarget',
    FrameworkSummaryBenchmark = 'frameworkSummaryBenchmark',
    FrameworkSummaryTrend = 'frameworkSummaryTrend',
    AssetsAtRisk = 'assetsAtRisk',
    CurrentVesselScore = 'currentVesselScore',
    NumberOfDaysSinceLastData = 'numberOfDaysSinceLastData',
    NumberOfDaysSinceLastNetworkData = 'numberOfDaysSinceLastNetworkData',
    DeploymentSummary = 'deploymentSummary',
    AssetsAtRiskHighValue = 'assetsAtRiskHighValue',
}
