import * as Actions from './actions'
import ActionType from './action-type'
import {Api} from '../../../api/Api'
import {REST} from '../../../index'
import {ThunkAction} from 'redux-thunk'
import AppState from '../../types/app-state'
import {
    DEFAULT_INCIDENTS_FILTER,
    IncidentsFilter,
    IncidentsFilterForUserPref,
} from '../../../values/user-preferences/incidents-filter'
import {IncidentSeverityValue} from '../../../values/incident-response-values'
import {INCIDENT_NOTES_SORT_ORDER} from '../../../pages/incidents-v3/data-helpers'
import {IncidentStatus} from '../../../pages/incidents-v3/contexts/types/incident-status'
import {SORT_ORDER} from '../../../values/sort-order'
import {SavedIncidentsFilter} from '../saved-filters/state'
import {
    formattedLocationsBlockSet,
    populateLocations,
    populateLocationsForFilterCriteria,
} from '../../../utils/Utils'
import {TimeRange} from '../../../values/TimeRange'
import {AnyAction} from 'redux'
import {setCurrentAppliedSavedFilter} from '../saved-filters/action-creators'
import {getFormattedIncidentsFilter} from '../../../components/saved-search/components/shared/helper'
import {IncidentResponseSortType} from '../../../pages/incidents-v3/contexts/types/type-of-interfaces'
import {
    DEFAULT_INCIDENTS_DETAIL_EXPANDED,
    DetailExpandedType,
} from '../../../pages/incidents-v3/contexts/types/incidents-details-type'

const USER_PREFS_URL = '/api/v1/userPreferences/incidents_settings'

export const fetchIncidentsFilter = (
    savedFilters: SavedIncidentsFilter[],
    isDefaultTagUser?: boolean,
): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch) => {
        dispatch(requestFilter())
        if (isDefaultTagUser === true) {
            REST.get(USER_PREFS_URL).then(
                (response) => {
                    REST.get(`${USER_PREFS_URL}.default_tag`).then(
                        (res) => {
                            if (response.data.value.selectedFilterName === undefined) {
                                dispatch(
                                    bulkAssignSavedFilter(
                                        {
                                            ...DEFAULT_INCIDENTS_FILTER,
                                            locations: formattedLocationsBlockSet(
                                                response.data.value.locations,
                                            ),
                                            searchVesselTagTerm: res.data.value.default_tag ?? [],
                                            searchVesselNameTerm:
                                                response.data.value.searchVesselNameTerm ?? '',
                                        },
                                        'Saved filters',
                                        response.data.value.sortByNotes,
                                        response.data.value.detailExpanded,
                                        true,
                                    ),
                                )
                            } else {
                                const savedFilter =
                                    savedFilters &&
                                    savedFilters.find(
                                        (filter) =>
                                            filter.name === response.data.value.selectedFilterName,
                                    )

                                savedFilter
                                    ? dispatch(
                                          bulkAssignSavedFilter(
                                              {
                                                  ...getFormattedIncidentsFilter(
                                                      savedFilter.criteria,
                                                  ),
                                                  filterBySeverities: savedFilter.criteria.severity
                                                      ? [
                                                            savedFilter.criteria
                                                                .severity as IncidentSeverityValue,
                                                        ]
                                                      : savedFilter.criteria.severities,
                                                  locations: formattedLocationsBlockSet(
                                                      response.data.value.locations,
                                                  ),
                                                  searchVesselTagTerm: res.data.value.default_tag,
                                                  searchVesselNameTerm:
                                                      response.data.value.searchVesselNameTerm,
                                              },
                                              savedFilter.name,
                                              response.data.value.sortByNotes,
                                              response.data.value.detailExpanded,
                                              true,
                                              savedFilter,
                                          ),
                                      )
                                    : dispatch(
                                          bulkAssignSavedFilter(
                                              {
                                                  ...DEFAULT_INCIDENTS_FILTER,
                                                  locations: formattedLocationsBlockSet(
                                                      response.data.value.locations,
                                                  ),
                                                  searchVesselTagTerm: res.data.value.default_tag,
                                                  searchVesselNameTerm:
                                                      response.data.value.searchVesselNameTerm,
                                              },
                                              'Saved filters',
                                              response.data.value.sortByNotes,
                                              response.data.value.detailExpanded,
                                              true,
                                          ),
                                      )
                            }
                            REST.delete(`${USER_PREFS_URL}.default_tag`)
                        },
                        () => {
                            dispatch(
                                bulkAssignSavedFilter(
                                    {
                                        ...DEFAULT_INCIDENTS_FILTER,
                                        locations: formattedLocationsBlockSet(
                                            response.data.value.locations,
                                        ),
                                        searchVesselTagTerm:
                                            response.data.value.searchVesselTagTerm,
                                        searchVesselNameTerm:
                                            response.data.value.searchVesselNameTerm,
                                    },
                                    'Saved filters',
                                    response.data.value.sortByNotes,
                                    response.data.value.detailExpanded,
                                ),
                            )
                        },
                    )
                },
                () => {
                    REST.get(`${USER_PREFS_URL}.default_tag`).then((res) => {
                        REST.put(USER_PREFS_URL, {
                            ...DEFAULT_INCIDENTS_FILTER,
                            searchVesselTagTerm: res.data.value.default_tag,
                        })
                        dispatch(
                            bulkAssignSavedFilter(
                                {
                                    ...DEFAULT_INCIDENTS_FILTER,
                                    searchVesselTagTerm: res.data.value.default_tag,
                                },
                                'Saved filters',
                                DEFAULT_INCIDENTS_FILTER.sortByNotes,
                                DEFAULT_INCIDENTS_DETAIL_EXPANDED,
                            ),
                        )
                    })
                },
            )
        } else {
            REST.get(USER_PREFS_URL).then(
                (response) => {
                    if (response.data.value.selectedFilterName === undefined) {
                        dispatch(
                            bulkAssignSavedFilter(
                                {
                                    ...DEFAULT_INCIDENTS_FILTER,
                                    locations: formattedLocationsBlockSet(
                                        response.data.value.locations,
                                    ),
                                    searchVesselTagTerm:
                                        response.data.value.searchVesselTagTerm ?? [],
                                    searchVesselNameTerm:
                                        response.data.value.searchVesselNameTerm ?? '',
                                },
                                'Saved filters',
                                response.data.value.sortByNotes,
                                response.data.value.detailExpanded,
                            ),
                        )
                    } else {
                        const savedFilter =
                            savedFilters &&
                            savedFilters.find(
                                (filter) => filter.name === response.data.value.selectedFilterName,
                            )

                        savedFilter
                            ? dispatch(
                                  bulkAssignSavedFilter(
                                      {
                                          ...(getFormattedIncidentsFilter(
                                              savedFilter.criteria,
                                          ) as IncidentsFilter),
                                          filterBySeverities: savedFilter.criteria.severity
                                              ? [
                                                    savedFilter.criteria
                                                        .severity as IncidentSeverityValue,
                                                ]
                                              : savedFilter.criteria.severities,
                                          locations: formattedLocationsBlockSet(
                                              response.data.value.locations,
                                          ),
                                          searchVesselTagTerm:
                                              response.data.value.searchVesselTagTerm,
                                          searchVesselNameTerm:
                                              response.data.value.searchVesselNameTerm,
                                      },
                                      savedFilter.name,
                                      response.data.value.sortByNotes,
                                      response.data.value.detailExpanded,
                                      false,
                                      savedFilter,
                                  ),
                              )
                            : dispatch(
                                  bulkAssignSavedFilter(
                                      {
                                          ...DEFAULT_INCIDENTS_FILTER,
                                          locations: formattedLocationsBlockSet(
                                              response.data.value.locations,
                                          ),
                                          searchVesselTagTerm:
                                              response.data.value.searchVesselTagTerm,
                                          searchVesselNameTerm:
                                              response.data.value.searchVesselNameTerm,
                                      },
                                      'Saved filters',
                                      response.data.value.sortByNotes,
                                      response.data.value.detailExpanded,
                                  ),
                              )
                    }
                },
                () => {
                    dispatch(
                        bulkAssignSavedFilter(
                            DEFAULT_INCIDENTS_FILTER,
                            'Saved filters',
                            DEFAULT_INCIDENTS_FILTER.sortByNotes,
                            DEFAULT_INCIDENTS_DETAIL_EXPANDED,
                        ),
                    )
                },
            )
        }
    }
}

export const fetchIncidentsFilterForDefaultTag = (): ThunkAction<
    void,
    AppState,
    Api,
    Actions.Action
> => {
    return (dispatch) => {
        dispatch(requestFilter())
        REST.get(USER_PREFS_URL).then((response) => {
            dispatch(
                receiveFilter({
                    ...response.data.value,
                    locations: populateLocations(response.data.value?.locations),
                }),
            )
        })
    }
}

function requestFilter(): Actions.RequestFilterAction {
    return {
        type: ActionType.REQUEST_FILTER,
    }
}

function receiveFilter(payload: IncidentsFilterForUserPref): Actions.ReceiveFilterAction {
    return {
        type: ActionType.RECEIVE_FILTER,
        payload,
    }
}

export const setSortByColumn = (
    column: IncidentResponseSortType,
    direction: SORT_ORDER,
): ThunkAction<void, AppState, Api, Actions.SetSortField> => {
    return (dispatch) => {
        const orderIncidentsBy = {
            column,
            direction,
        }
        dispatch({
            type: ActionType.COLUMN_SORT,
            payload: orderIncidentsBy,
        })
    }
}

export const setSearchIncidentNo = (incidentNo: string | null): Actions.SetSearchIncidentNo => {
    return {
        type: ActionType.SET_SEARCH_INCIDENT_NO,
        payload: incidentNo,
    }
}

export function setFilteredIncidentStatus(
    filterByStatus: IncidentStatus[],
): ThunkAction<void, AppState, Api, Actions.SetFilterByStatus> {
    return (dispatch) => {
        dispatch({
            type: ActionType.FILTER_BY_STATUS,
            payload: filterByStatus,
        })
    }
}

export function setFilteredIncidentSeverity(
    filterBySeverity: IncidentSeverityValue[],
): ThunkAction<void, AppState, Api, Actions.SetFilterBySeverity> {
    return (dispatch) => {
        dispatch({
            type: ActionType.FILTER_BY_SEVERITY,
            payload: filterBySeverity,
        })
    }
}

export function resetFilter(): ThunkAction<void, AppState, Api, AnyAction> {
    return (dispatch, getState) => {
        const currentFilter = getState().incidentsFilter
        dispatch({type: ActionType.RESET_FILTER})
        dispatch(setCurrentAppliedSavedFilter(undefined))
        REST.put(USER_PREFS_URL, {
            sortByNotes: currentFilter.sortByNotes,
            detailExpanded: currentFilter.detailExpanded,
            selectedFilterName: '',
            searchVesselTagTerm: [],
            searchVesselNameTerm: '',
            createdToRelative: null,
            createdFromRelative: null,
            updatedToRelative: null,
            updatedFromRelative: null,
        })
    }
}

export const setSortByNotesField = (
    sortByNotes: INCIDENT_NOTES_SORT_ORDER,
): ThunkAction<void, AppState, Api, Actions.SetSortByNotesField> => {
    return (dispatch, getState) => {
        const currentFilter = getState().incidentsFilter
        const currentVesselFilter = getState().vesselFilter
        dispatch({
            type: ActionType.SORT_BY_NOTES,
            payload: sortByNotes,
        })
        REST.put(USER_PREFS_URL, {
            sortByNotes: sortByNotes,
            selectedFilterName: currentFilter.selectedFilterName,
            locations: currentVesselFilter.locations,
            searchVesselTagTerm: currentVesselFilter.searchVesselTagTerm,
            searchVesselNameTerm: currentVesselFilter.searchVesselNameTerm,
            detailExpanded: currentFilter.detailExpanded,
        })
    }
}

export function toggleDetailExpanded(
    detailExpanded: DetailExpandedType,
): ThunkAction<void, AppState, Api, Actions.ToggleDetailExpandedAction> {
    return (dispatch, getState) => {
        const currentFilter = getState().incidentsFilter
        const currentVesselFilter = getState().vesselFilter
        dispatch({
            type: ActionType.TOGGLE_DETAIL_EXPANDED,
            payload: detailExpanded,
        })
        REST.put(USER_PREFS_URL, {
            sortByNotes: currentFilter.sortByNotes,
            selectedFilterName: currentFilter.selectedFilterName,
            locations: currentVesselFilter.locations,
            searchVesselTagTerm: currentVesselFilter.searchVesselTagTerm,
            searchVesselNameTerm: currentVesselFilter.searchVesselNameTerm,
            detailExpanded: detailExpanded,
        })
    }
}

export function setSelectedIncidentType(
    selectedIncidentType: string | undefined,
): ThunkAction<void, AppState, Api, Actions.SetSelectedIncidentType> {
    return (dispatch) => {
        dispatch({
            type: ActionType.SET_SELECTED_INCIDENT_TYPE,
            payload: selectedIncidentType,
        })
    }
}
export function setSelectedCreatedTimeRange(
    startDate: string | null,
    endDate: string | null,
): ThunkAction<void, AppState, Api, Actions.SetSelectedTimeRange> {
    return (dispatch) => {
        dispatch({
            type: ActionType.SET_SELECTED_CREATED_TIME_RANGE,
            payload: [startDate, endDate],
        })
    }
}

export function setSelectedCreatedRelativeFromRange(
    time: TimeRange | null,
): ThunkAction<void, AppState, Api, Actions.SetSelectedRelativeTime> {
    return (dispatch) => {
        dispatch({
            type: ActionType.SET_SELECTED_CREATED_FROM_RELATIVE,
            payload: time,
        })
    }
}

export function setSelectedCreatedRelativeToRange(
    time: TimeRange | null,
): ThunkAction<void, AppState, Api, Actions.SetSelectedRelativeTime> {
    return (dispatch) => {
        dispatch({
            type: ActionType.SET_SELECTED_CREATED_TO_RELATIVE,
            payload: time,
        })
    }
}

export function setSelectedUpdatedRelativeFromRange(
    time: TimeRange | null,
): ThunkAction<void, AppState, Api, Actions.SetSelectedRelativeTime> {
    return (dispatch) => {
        dispatch({
            type: ActionType.SET_SELECTED_UPDATED_FROM_RELATIVE,
            payload: time,
        })
    }
}

export function setSelectedUpdatedRelativeToRange(
    time: TimeRange | null,
): ThunkAction<void, AppState, Api, Actions.SetSelectedRelativeTime> {
    return (dispatch) => {
        dispatch({
            type: ActionType.SET_SELECTED_UPDATED_TO_RELATIVE,
            payload: time,
        })
    }
}

export function setSelectedUpdatedTimeRange(
    startDate: string | null,
    endDate: string | null,
): ThunkAction<void, AppState, Api, Actions.SetSelectedTimeRange> {
    return (dispatch) => {
        dispatch({
            type: ActionType.SET_SELECTED_UPDATED_TIME_RANGE,
            payload: [startDate, endDate],
        })
    }
}

export function setSelectedAssigngToUser(
    assignedTo: string,
): ThunkAction<void, AppState, Api, Actions.SetSelectedAssignedTo> {
    return (dispatch) => {
        dispatch({
            type: ActionType.SET_SELECTED_ASSIGNED_TO_USER,
            payload: assignedTo,
        })
    }
}
export function setSelectedAssigngToGuest(
    guestAssignedToEmail: string,
): ThunkAction<void, AppState, Api, Actions.SetSelectedAssignedTo> {
    return (dispatch) => {
        dispatch({
            type: ActionType.SET_SELECTED_ASSIGNED_TO_GUEST,
            payload: guestAssignedToEmail,
        })
    }
}

export function setVesselEmail(
    assignedToVesselEmail: boolean | null,
): ThunkAction<void, AppState, Api, Actions.SetVesselEmail> {
    return (dispatch) => {
        dispatch({
            type: ActionType.SET_SELECTED_VESSEL_ASSIGNED_EMAIL,
            payload: assignedToVesselEmail,
        })
    }
}

export function watchIncidentsOfCurrentUser(
    watchedByCurrentUser: boolean,
): ThunkAction<void, AppState, Api, Actions.WatchIncidentsByCurrentUser> {
    return (dispatch) => {
        dispatch({
            type: ActionType.SET_WATCH_INCIDENTS,
            payload: watchedByCurrentUser,
        })
    }
}

export function setIncidentsWithNewNotes(
    hasNewNotes: boolean,
): ThunkAction<void, AppState, Api, Actions.SetIncidentsWithNewNotesAction> {
    return (dispatch) => {
        dispatch({
            type: ActionType.SET_INCIDENTS_WITH_NEW_NOTES,
            payload: hasNewNotes,
        })
    }
}

export function bulkAssignSavedFilter(
    data: IncidentsFilter,
    filterName: string,
    sortByNotes: INCIDENT_NOTES_SORT_ORDER,
    detailExpanded: DetailExpandedType,
    pref?: boolean,
    savedFilter?: SavedIncidentsFilter,
): ThunkAction<void, AppState, Api, AnyAction> {
    return (dispatch, getState) => {
        const currentVesselFilter = getState().vesselFilter
        dispatch({
            type: ActionType.BULK_ASSIGN_SAVED_FILTER,
            payload: {
                criteria: data,
                name: filterName,
                sortByNotes: sortByNotes,
                detailExpanded: detailExpanded,
            },
        })
        savedFilter && dispatch(setCurrentAppliedSavedFilter(savedFilter))
        pref &&
            REST.put(USER_PREFS_URL, {
                sortByNotes: sortByNotes,
                detailExpanded: detailExpanded,
                selectedFilterName: filterName,
                locations: populateLocationsForFilterCriteria(currentVesselFilter.locations),
                searchVesselTagTerm: currentVesselFilter.searchVesselTagTerm,
                searchVesselNameTerm: currentVesselFilter.searchVesselNameTerm,
            })
    }
}

export function setSelectedFilterName(
    filterName: string,
    pref?: {
        savedFilter: SavedIncidentsFilter
        sortByNotes: INCIDENT_NOTES_SORT_ORDER
        detailExpanded: DetailExpandedType
    },
): ThunkAction<void, AppState, Api, AnyAction> {
    return (dispatch, getState) => {
        const currentVesselFilter = getState().vesselFilter
        dispatch({
            type: ActionType.SET_SELECTED_FILTER_NAME,
            payload: filterName,
        })
        if (pref) {
            dispatch(setCurrentAppliedSavedFilter(pref.savedFilter))
            REST.put(USER_PREFS_URL, {
                sortByNotes: pref.sortByNotes,
                detailExpanded: pref.detailExpanded,
                selectedFilterName: filterName,
                locations: populateLocationsForFilterCriteria(currentVesselFilter.locations),
                searchVesselTagTerm: currentVesselFilter.searchVesselTagTerm,
                searchVesselNameTerm: currentVesselFilter.searchVesselNameTerm,
            })
        }
    }
}
