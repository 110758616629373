export type LatestEventTimestampMap = Map<string, string>

export interface LatestEventTimestampsReduxState {
    isFetchingLatestEventTimestamps: boolean
    latestEventTimestamps: LatestEventTimestampMap
}

export function newLatestEventTimestampMap(): LatestEventTimestampMap {
    return new Map<string, string>()
}

export const DEFAULT_THREAT_SCORES_STATE: LatestEventTimestampsReduxState = {
    isFetchingLatestEventTimestamps: false,
    latestEventTimestamps: newLatestEventTimestampMap(),
}
