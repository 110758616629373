import {LocationIdType} from '../../../../store/state/locations/state'

export interface TotalNumberOfItemsBySeverity {
    LOW: number
    MEDIUM: number
    HIGH: number
    CRITICAL: number
}

export const DEFAULT_COUNTS_BY_SEVERITY: TotalNumberOfItemsBySeverity = {
    LOW: 0,
    MEDIUM: 0,
    HIGH: 0,
    CRITICAL: 0,
}
export interface TotalNumberOfItemsByStatus {
    DRAFT: number
    NEW: number
    OPEN: number
    CLOSED: number
    CLOSED_FALSE_POSITIVE: number
    CLOSED_MONITOR: number
}
export const DEFAULT_COUNTS_BY_STATUS: TotalNumberOfItemsByStatus = {
    DRAFT: 0,
    NEW: 0,
    OPEN: 0,
    CLOSED: 0,
    CLOSED_FALSE_POSITIVE: 0,
    CLOSED_MONITOR: 0,
}
export interface TotalNumberOfItemsByTypes {
    DEFAULT: number
    NETWORK: number
    PUP: number
    MISCONFIGURATION: number
    UNKNOWN_ASSETS: number
    MALWARE: number
    POTENTIALLY_MALICIOUS_FILE: number
    ANOMALOUS_BEHAVIOUR: number
    COMPLIANCE: number
    REMOTE_ACCESS: number
    DATA_TRANSFER: number
    ACCOUNT_ACTIVITY: number
    INFORMATIONAL: number
}
export const DEFAULT_COUNTS_BY_TYPE: TotalNumberOfItemsByTypes = {
    DEFAULT: 0,
    NETWORK: 0,
    PUP: 0,
    MISCONFIGURATION: 0,
    UNKNOWN_ASSETS: 0,
    MALWARE: 0,
    POTENTIALLY_MALICIOUS_FILE: 0,
    ANOMALOUS_BEHAVIOUR: 0,
    COMPLIANCE: 0,
    REMOTE_ACCESS: 0,
    DATA_TRANSFER: 0,
    ACCOUNT_ACTIVITY: 0,
    INFORMATIONAL: 0,
}
export enum IncidentResponseSortType {
    CREATED = 'CREATED',
    UPDATED = 'UPDATED',
    LOCATION_NAME = 'LOCATION_NAME',
    STATUS = 'STATUS',
    SEVERITY = 'SEVERITY',
    TYPE = 'TYPE',
    CODE = 'CODE',
    TITLE = 'TITLE',
    ASSIGNED_TO = 'ASSIGNED_TO',
}
export enum ProcessingStatus {
    NOT_PROCESSING = 'NOT_PROCESSING',
    PROCESSING = 'PROCESSING',
    PROCESSED = 'PROCESSED',
    FAILED = 'FAILED',
}

export interface GuestEmails {
    guestEmail: string
    locations: LocationIdType[]
}
export interface AssignedTo {
    id: string | undefined
    name: string
}
