enum ActionType {
    SET_PAGE_TYPE = '[VESSEL FILTER] SET PAGE TYPE',
    SET_VESSEL_FILTER_PREF = '[VESSEL FILTER] SET VESSEL FILTER PREF',
    TOGGLE_VESSEL = '[VESSEL FILTER] TOGGLE VESSEL',
    SET_VESSEL_FOR_INTERLINKING = '[VESSEL FILTER] SET VESSEL FOR INTERLINKING',
    TOGGLE_ALL_VESSELS = '[VESSEL FILTER] TOGGLE ALL VESSELS',
    TOGGLE_FILTERED_VESSELS = '[VESSEL FILTER] TOGGLE FILTERED VESSELS',
    SET_SEARCH_VESSEL_TAG_TERM = '[VESSEL FILTER] SET SEARCH VESSEL TAG TERM',
    SET_SEARCH_VESSEL_NAME_TERM = '[VESSEL FILTER] SET SEARCH VESSEL NAME TERM',
    SET_SEARCH_VESSEL_TERM = '[VESSEL FILTER] SET SEARCH VESSEL TERM',
    SET_SHOW_X_BUTTON = '[VESSEL FILTER] SET SHOW X BUTTON',
    VESSEL_SORT = '[VESSEL FILTER] VESSEL SORT',
    RESET_VESSEL_FILTER = '[VESSEL FILTER] RESET VESSEL FILTER',
}

export default ActionType
