enum ActionType {
    REQUEST_FILTER = '[VESSEL_BETA_FILTER] REQUEST FILTER',
    RECEIVE_FILTER = '[VESSEL_BETA_FILTER] RECEIVE FILTER',
    SET_ANALYSIS_PERIOD = '[VESSEL_BETA_FILTER] SET ANALYSIS PERIOD',
    SET_SORT_COLUMN = '[VESSEL_BETA_FILTER] SET SORT COLUMN',
    SET_ACTIVE_COLUMNS = '[VESSEL_BETA_FILTER] SET ACTIVE COLUMNS',
    RESET_FILTER = '[VESSEL_BETA_FILTER] RESET FILTER',
}

export default ActionType
