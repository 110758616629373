import {ReactNode, ReactNodeArray, useEffect, useState} from 'react'
import {DimensionsContext} from './dimensions-context'
import {Dimensions} from './types/dimensions'
import {throttle} from 'lodash'

type DimensionsProviderProps = {
    children: ReactNodeArray | ReactNode
}
export function DimensionsProvider({children}: DimensionsProviderProps): JSX.Element {
    const [dimensions, setDimensions] = useState<Dimensions>({
        width: window.innerWidth,
        height: window.innerHeight,
    })

    useEffect(() => {
        const throttledHandleWindowResize = throttle(function handleResize() {
            setDimensions({
                width: window.innerWidth,
                height: window.innerHeight,
            })
        }, 250)
        window.addEventListener('resize', throttledHandleWindowResize)
        return () => {
            window.removeEventListener('resize', throttledHandleWindowResize)
        }
    }, [])

    return <DimensionsContext.Provider value={dimensions}>{children}</DimensionsContext.Provider>
}
