import LoadingState from '../../../values/loading-state-enum'
import {EmailRecipientData} from './types/email-recipient-data'

export interface CurrentEmailRecipientsReduxState {
    isFetching: LoadingState
    emailRecipientData: EmailRecipientData[]
}

export const DEFAULT_EMAIL_RECIPIENTS_STATE: CurrentEmailRecipientsReduxState = {
    isFetching: LoadingState.NotPopulated,
    emailRecipientData: [],
}
