import ActionType from './action-type'
import {Action} from './actions'

import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'
import {
    UnsavedIncidentNoteAlertModalReduxState,
    CreationStatus,
    DEFAULT_UNSAVED_INCIDENT_NOTE_ALERT_MODAL_STATE,
} from './state'
import {GuidType} from '../../../values/generic-type-defintions'

export default function unsavedIncidentNoteAlertModalReducer(
    state: UnsavedIncidentNoteAlertModalReduxState = DEFAULT_UNSAVED_INCIDENT_NOTE_ALERT_MODAL_STATE,
    action: Action | LoggingOutAction,
): UnsavedIncidentNoteAlertModalReduxState {
    switch (action.type) {
        case ActionType.PROCESSING_NEW_NOTE:
            return {
                ...state,
                newNoteCreationStatus: CreationStatus.ENTER_DETAILS,
                newNote: action.payload.newNoteText,
                newNoteIncidentIdentity: action.payload.incidentIdentity,
            }
        case ActionType.PROCESSING_EDIT_NOTE:
            return {
                ...state,
                editNoteCreationStatus: CreationStatus.ENTER_DETAILS,
                editNoteMap: state.editNoteMap.set(
                    action.payload.noteIdentity,
                    action.payload.noteToEdit,
                ),
            }
        case ActionType.REVIEW_NOTE:
            return {
                ...state,
                newNoteCreationStatus:
                    state.newNote !== '' ? CreationStatus.REVIEW : state.newNoteCreationStatus,
                editNoteCreationStatus:
                    state.editNoteMap.size !== 0
                        ? CreationStatus.REVIEW
                        : state.editNoteCreationStatus,
                showModal: false,
            }

        case ActionType.SAVE_EDIT_NOTE:
            return {
                ...state,
                editNoteCreationStatus: CreationStatus.CONFIRM,
                editNoteMap: state.editNoteMap.set(action.payload, ''),
            }
        case ActionType.SAVE_NEW_NOTE:
            return {
                ...state,
                newNoteCreationStatus: CreationStatus.CONFIRM,
                newNote: '',
                newNoteIncidentIdentity: undefined,
            }
        case ActionType.DISPLAY_MODAL:
            return {
                ...state,
                showModal: true,
            }
        case ActionType.CLOSE_MODAL:
            return {
                ...state,
                newNoteCreationStatus: CreationStatus.NOT_CREATING,
                editNoteCreationStatus: CreationStatus.NOT_CREATING,
                showModal: false,
                newNote: '',
                newNoteIncidentIdentity: undefined,
                editNoteMap: new Map<GuidType, ''>(),
            }
        case ActionType.SET_PENDING_NAVIGATION:
            return {
                ...state,
                pendingNavigation: action.payload,
            }
        case SessionActionType.LOGGING_OUT:
            return DEFAULT_UNSAVED_INCIDENT_NOTE_ALERT_MODAL_STATE
        /* istanbul ignore next */
        default:
            return state
    }
}
