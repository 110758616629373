import {UserAgentApplication} from 'msal'

export interface AuthReduxState {
    authToken?: string
    msal?: UserAgentApplication
}

export const DEFAULT_AUTH_STATE: AuthReduxState = {
    authToken: undefined,
    msal: undefined,
}
