enum ActionType {
    OPEN_MODAL = '[EXTENDED DATA REQUEST] OPEN MODAL',
    CLOSE_MODAL = '[EXTENDED DATA REQUEST] CLOSE MODAL',
    SET_LOCATION = '[EXTENDED DATA REQUEST] SET LOCATION',
    SET_DATA_RANGE = '[EXTENDED DATA REQUEST] SET DATA RANGE',
    SET_REQUIREMENTS = '[EXTENDED DATA REQUEST] SET REQUIREMENTS',
    SET_ADDITIONAL_REQUIREMENT = '[EXTENDED DATA REQUEST] SET ADDITIONAL REQUIREMENTS',
    SET_PURPOSE = '[EXTENDED DATA REQUEST] SET PURPOSE',
    SET_REFERENCE = '[EXTENDED DATA REQUEST] SET REFERENCE',
    SET_SAVE_PROCESSING_STATUS = '[EXTENDED DATA REQUEST] SET SAVE PROCESSING STATUS',
}

export default ActionType
