import {ReactNodeArray} from 'react'
import {ReactNode} from 'react'
import {Navigation} from '../../components/Navigation'
import useTypedSelector from '../../hooks/use-typed-selector'
import {activeLocationSelector} from '../../store/state/locations/selectors'
import {isActiveLocation} from '../../store/state/locations/state'
import {ScrollablePage} from './scrollable-page.styled'

interface Props {
    onClick?: () => void
    children: ReactNode | ReactNodeArray
}

export function ScrollablePageTemplate({children, onClick}: Props): JSX.Element {
    const showingSelectedLocation = isActiveLocation(useTypedSelector(activeLocationSelector))

    if (onClick) {
        return (
            <ScrollablePage
                onClick={onClick}
                showingSelectedLocation={showingSelectedLocation}
                id="scrollable-page"
            >
                <Navigation />
                {children}
            </ScrollablePage>
        )
    }

    return (
        <ScrollablePage showingSelectedLocation={showingSelectedLocation} id="scrollable-page">
            <Navigation />
            {children}
        </ScrollablePage>
    )
}
