import ActionType from './action-type'
import {Action} from './actions'
import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'
import {UsersReduxState, DEFAULT_USERS_STATE, User} from './state'
import LoadingState from '../../../values/loading-state-enum'

function setLoading(currentState: UsersReduxState): UsersReduxState {
    if (currentState.loadingState === LoadingState.RequestingData) {
        return currentState
    }

    return {
        ...currentState,
        loadingState: LoadingState.RequestingData,
    }
}

function rolesAreNotSame(existingRoles: string[], newRoles: string[]): boolean {
    if (existingRoles?.length !== newRoles?.length) {
        return true
    }

    for (let i = 0; i < existingRoles.length; i++) {
        if (!newRoles.includes(existingRoles[i])) {
            return true
        }
    }

    return false
}

function recordNeedsUpdating(existingRecord: User, newRecord: User): boolean {
    return (
        existingRecord.username !== newRecord.username ||
        existingRecord.email !== newRecord.email ||
        existingRecord.emailNotificationEnabled !== newRecord.emailNotificationEnabled ||
        existingRecord.active !== newRecord.active ||
        existingRecord.deleted !== newRecord.deleted ||
        rolesAreNotSame(existingRecord.roles, newRecord.roles) ||
        existingRecord.internalUser !== newRecord.internalUser ||
        existingRecord.assignedTag !== newRecord.assignedTag ||
        existingRecord.strictMode !== newRecord.strictMode ||
        existingRecord.notificationEmail !== newRecord.notificationEmail
    )
}

function updateUserArray(
    existingUsers: User[],
    receivedUsers: User[],
    keepOld = false,
): {mappedData: User[]; changesApplied: boolean} {
    const newRecords: User[] = []
    const updatedRecords: User[] = []
    const unchangedRecords: User[] = []
    let changesApplied = false

    receivedUsers.forEach((record) => {
        const existingRecord = existingUsers?.find((user) => user.user === record.user)

        if (!existingRecord) {
            changesApplied = true
            newRecords.push(record)
        } else {
            if (recordNeedsUpdating(existingRecord, record)) {
                changesApplied = true
                updatedRecords.push(record)
            } else {
                unchangedRecords.push(existingRecord)
            }
        }
    })

    const noNewData = newRecords.length === 0 && updatedRecords.length === 0

    let noRecordsToRemove = true

    if (!keepOld) {
        noRecordsToRemove = unchangedRecords.length === existingUsers?.length
    }

    if (noNewData && noRecordsToRemove) {
        return {mappedData: existingUsers, changesApplied: false}
    }

    const mappedData: User[] = []

    newRecords.forEach((record) => {
        changesApplied = true
        mappedData.push(record)
    })

    updatedRecords.forEach((record) => {
        changesApplied = true
        mappedData.push(record)
    })

    unchangedRecords.forEach((record) => {
        mappedData.push(record)
    })

    if (keepOld) {
        existingUsers.forEach((record) => {
            if (!mappedData?.find((user) => user.user === record.user)) {
                mappedData.push(record)
            }
        })
    } else {
        changesApplied = true
    }

    return {mappedData, changesApplied}
}

function setUsers(currentState: UsersReduxState, receivedData: User[]): UsersReduxState {
    const {mappedData, changesApplied} = updateUserArray(currentState.users, receivedData)

    if (!changesApplied) {
        if (currentState.loadingState === LoadingState.Loaded) {
            return currentState
        }

        return {
            ...currentState,
            loadingState: LoadingState.Loaded,
        }
    }

    return {
        ...currentState,
        loadingState: LoadingState.Loaded,
        users: mappedData,
    }
}

function logout(currentState: UsersReduxState): UsersReduxState {
    if (
        currentState.loadingState === LoadingState.NotPopulated &&
        currentState.users &&
        currentState.users.length === 0
    ) {
        return currentState
    }

    return DEFAULT_USERS_STATE
}

const UsersReducer = (
    state: UsersReduxState = DEFAULT_USERS_STATE,
    action: Action | LoggingOutAction,
): UsersReduxState => {
    switch (action.type) {
        case ActionType.REQUEST_USERS:
            return setLoading(state)

        case ActionType.SET_USERS:
            return setUsers(state, action.payload)

        case SessionActionType.LOGGING_OUT:
            return logout(state)

        default:
            return state
    }
}

export default UsersReducer
