import ActionType from './action-type'
import * as Actions from './actions'
import AppState from '../../types/app-state'
import {Api} from '../../../api/Api'
import {ThunkAction} from 'redux-thunk'
import {MedullaUIConfig} from '../../../values/MedullaUIConfig'
import {REST} from '../../..'
import {FleetConfigKeysType, License, UserGuidingConfig} from './state'
import {AxiosResponse} from 'axios'

function requestConfig(): Actions.RequestConfigAction {
    return {
        type: ActionType.REQUEST_CONFIG,
    }
}

function configRetrieved(config: MedullaUIConfig): Actions.ConfigRetrievedAction {
    return {
        type: ActionType.RECEIVE_CONFIG,
        payload: config,
    }
}

export function configError(reason: string): Actions.ConfigErrorAction {
    return {
        type: ActionType.CONFIG_ERROR,
        payload: reason,
    }
}

export function fetchConfig(): ThunkAction<void, AppState, Api, Actions.Action> {
    return (dispatch, _, api: Api): void => {
        dispatch(requestConfig())

        api.fetchNotAuth(
            '/api/v1/configs/medulla',
            (config) => {
                dispatch(configRetrieved(config as MedullaUIConfig))
            },
            (reason) => {
                dispatch(configError(reason))
            },
        )
    }
}

export function setEnableReportsPage(value: boolean): Actions.SetEnableReportsPageAction {
    return {
        type: ActionType.SET_ENABLE_REPORTS_PAGE,
        payload: value,
    }
}

export function setEnableNetworkAssetsPage(
    value: boolean,
): Actions.SetEnableNetworkAssetsPageAction {
    return {
        type: ActionType.SET_ENABLE_NETWORK_ASSETS_PAGE,
        payload: value,
    }
}

export function setEnableUsbInventoryPage(value: boolean): Actions.SetEnableUsbInventoryPageAction {
    return {
        type: ActionType.SET_ENABLE_USB_INVENTORY_PAGE,
        payload: value,
    }
}

export function setEnableSoftwareInventoryPage(
    value: boolean,
): Actions.SetEnableSoftwareInventoryPageAction {
    return {
        type: ActionType.SET_ENABLE_SOFTWARE_INVENTORY_PAGE,
        payload: value,
    }
}

export function setIncidentLicense(value: License<'Incidents'>): Actions.SetIncidentsLicenceAction {
    return {
        type: ActionType.SET_LICENSE_INCIDENTS,
        payload: value,
    }
}

export function setEnforcementRemovablesLicense(
    value: License<'Enforcement:Removables'> | null,
): Actions.SetEnforcementRemovablesLicenceAction {
    return {
        type: ActionType.SET_LICENSE_ENFORCEMENT_REMOVABLES,
        payload: value,
    }
}

export function setEnforcementNetworksLicense(
    value: License<'Enforcement:Networks'> | null,
): Actions.SetEnforcementNetworksLicenceAction {
    return {
        type: ActionType.SET_LICENSE_ENFORCEMENT_NETWORKS,
        payload: value,
    }
}

export function setMetricsOnboardEngagementLicense(
    value: License<'Metrics:OnboardEngagement'> | null,
): Actions.SetMetricsLicenceAction {
    return {
        type: ActionType.SET_LICENSE_METRICS_ONBOARD_ENGAGEMENT,
        payload: value,
    }
}

export function setEnableNmeaInventoryPage(
    value: boolean,
): Actions.SetEnableNmeaInventoryPageAction {
    return {
        type: ActionType.SET_ENABLE_NMEA_INVENTORY_PAGE,
        payload: value,
    }
}

export function setIncidentReopenDuration(
    value: string | undefined,
): Actions.SetIncidentReopenDurationAction {
    return {
        type: ActionType.SET_INCIDENT_REOPEN_DURATION,
        payload: value,
    }
}

const GET_FLEET_CONFIG_FOR_KEY = 'api/v1/fleetConfiguration/key'

export function fetchIncidentLicense(): ThunkAction<void, AppState, Api, Actions.Action> {
    return (dispatch) => {
        REST.get(`${GET_FLEET_CONFIG_FOR_KEY}/${FleetConfigKeysType.LICENSE_INCIDENTS}`).then(
            (response) => {
                parseLicenseDate(response)
                dispatch(setIncidentLicense(response.data))
            },
        )
    }
}

export function fetchMetricsOnboardEngagementLicense(): ThunkAction<
    void,
    AppState,
    Api,
    Actions.Action
> {
    return async (dispatch) => {
        try {
            const response = await REST.get(
                `${GET_FLEET_CONFIG_FOR_KEY}/${FleetConfigKeysType.LICENSE_METRICS_ONBOARD_ENGAGEMENT}`,
            )
            parseLicenseDate(response)
            dispatch(setMetricsOnboardEngagementLicense(response.data))
        } catch {
            dispatch(setMetricsOnboardEngagementLicense(null))
        }
    }
}

export function fetchEnforcementNetworksLicense(): ThunkAction<
    void,
    AppState,
    Api,
    Actions.Action
> {
    return async (dispatch) => {
        try {
            const response = await REST.get(
                `${GET_FLEET_CONFIG_FOR_KEY}/${FleetConfigKeysType.LICENSE_ENFORCEMENT_NETWORKS}`,
            )
            parseLicenseDate(response)
            dispatch(setEnforcementNetworksLicense(response.data))
        } catch {
            dispatch(setEnforcementNetworksLicense(null))
        }
    }
}

export function fetchEnforcementRemovablesLicense(): ThunkAction<
    void,
    AppState,
    Api,
    Actions.Action
> {
    return async (dispatch) => {
        try {
            const response = await REST.get(
                `${GET_FLEET_CONFIG_FOR_KEY}/${FleetConfigKeysType.LICENSE_ENFORCEMENT_REMOVABLES}`,
            )
            parseLicenseDate(response)
            dispatch(setEnforcementRemovablesLicense(response.data))
        } catch {
            dispatch(setEnforcementRemovablesLicense(null))
        }
    }
}

function parseLicenseDate(responseData: AxiosResponse): void {
    const date = responseData.data?.value?.endTimestamp
    if (typeof date === 'string') {
        responseData.data.value.endTimestamp = new Date(date)
    }
}

export function fetchFleetConfigForReports(): ThunkAction<void, AppState, Api, Actions.Action> {
    return (dispatch) => {
        REST.get(`${GET_FLEET_CONFIG_FOR_KEY}/${FleetConfigKeysType.REPORTS}`)
            .then((response) => {
                if (response.data.value.enabled) {
                    dispatch(setEnableReportsPage(true))
                } else {
                    dispatch(setEnableReportsPage(false))
                }
            })
            .catch(() => {
                dispatch(setEnableReportsPage(true))
            })
    }
}

export function fetchFleetConfigForNetworkAssets(): ThunkAction<
    void,
    AppState,
    Api,
    Actions.Action
> {
    return (dispatch) => {
        REST.get(`${GET_FLEET_CONFIG_FOR_KEY}/${FleetConfigKeysType.NETWORK_ASSETS}`)
            .then((response) => {
                if (response.data.value.enabled) {
                    dispatch(setEnableNetworkAssetsPage(true))
                } else {
                    dispatch(setEnableNetworkAssetsPage(false))
                }
            })
            .catch(() => dispatch(setEnableNetworkAssetsPage(true)))
    }
}

export function fetchFleetConfigForUsbInventory(): ThunkAction<
    void,
    AppState,
    Api,
    Actions.Action
> {
    return (dispatch) => {
        REST.get(`${GET_FLEET_CONFIG_FOR_KEY}/${FleetConfigKeysType.USB_INVENTORY}`)
            .then((response) => {
                if (response.data.value.enabled) {
                    dispatch(setEnableUsbInventoryPage(true))
                } else {
                    dispatch(setEnableUsbInventoryPage(false))
                }
            })
            .catch(() => dispatch(setEnableUsbInventoryPage(true)))
    }
}

export function fetchFleetConfigForSoftwareInventory(): ThunkAction<
    void,
    AppState,
    Api,
    Actions.Action
> {
    return (dispatch) => {
        REST.get(`${GET_FLEET_CONFIG_FOR_KEY}/${FleetConfigKeysType.SOFTWARE_INVENTORY}`)
            .then((response) => {
                if (response.data.value.enabled) {
                    dispatch(setEnableSoftwareInventoryPage(true))
                } else {
                    dispatch(setEnableSoftwareInventoryPage(false))
                }
            })
            .catch(() => dispatch(setEnableSoftwareInventoryPage(true)))
    }
}

export function fetchFleetConfigForNmeaInventory(): ThunkAction<
    void,
    AppState,
    Api,
    Actions.Action
> {
    return (dispatch) => {
        REST.get(`${GET_FLEET_CONFIG_FOR_KEY}/${FleetConfigKeysType.NMEA_INVENTORY}`)
            .then((response) => {
                if (response.data.value.enabled) {
                    dispatch(setEnableNmeaInventoryPage(true))
                } else {
                    dispatch(setEnableNmeaInventoryPage(false))
                }
            })
            .catch(() => dispatch(setEnableNmeaInventoryPage(true)))
    }
}

export function fetchFleetConfigForIncidentReopenProcessing(): ThunkAction<
    void,
    AppState,
    Api,
    Actions.Action
> {
    return (dispatch) => {
        REST.get(`${GET_FLEET_CONFIG_FOR_KEY}/${FleetConfigKeysType.INCIDENT_REOPEN_PROCESSING}`)
            .then((response) => {
                if (response.data.value.duration) {
                    dispatch(setIncidentReopenDuration(response.data.value.duration))
                } else {
                    dispatch(setIncidentReopenDuration(undefined))
                }
            })
            .catch(() => dispatch(setIncidentReopenDuration(undefined)))
    }
}

export function setGfcFlag(value: boolean): Actions.SetGfcFlagAction {
    return {
        type: ActionType.SET_GFC_FLAG,
        payload: value,
    }
}

export function setCustomLogoFlag(value: boolean): Actions.SetCustomLogoFlagAction {
    return {
        type: ActionType.SET_CUSTOM_LOGO_FLAG,
        payload: value,
    }
}

export function setUserGuiding(value: UserGuidingConfig): Actions.SetUserGuidingAction {
    return {
        type: ActionType.SET_USER_GUIDING,
        payload: value,
    }
}
