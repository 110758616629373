import ActionType from './action-type'
import * as Actions from './actions'
import AppState from '../../types/app-state'
import {Api} from '../../../api/Api'
import {ThreatMeasure} from '../../../values/ThreatMeasure'
import {ThunkDispatch, ThunkAction} from 'redux-thunk'

const THREAT_MEASURES_REST_URL = '/api/v1/threatMeasures'

export function fetchThreatMeasures(): ThunkAction<void, AppState, Api, Actions.Action> {
    return (dispatch: ThunkDispatch<AppState, Api, Actions.Action>, _, api: Api): void => {
        dispatch(requestThreatMeasuresAction())
        getThreatMeasures(api, dispatch)
    }
}

function getThreatMeasures(api: Api, dispatch: ThunkDispatch<AppState, Api, Actions.Action>): void {
    api.getAuth(THREAT_MEASURES_REST_URL, (data) => {
        const threatMeasures: ThreatMeasure[] = data as ThreatMeasure[]
        dispatch(receiveThreatMeasuresAction(threatMeasures))
    })
}

function requestThreatMeasuresAction(): Actions.RequestThreatMeasuresAction {
    return {
        type: ActionType.REQUEST_THREAT_MEASURES,
    }
}

function receiveThreatMeasuresAction(
    threatMeasures: ThreatMeasure[],
): Actions.ReceiveThreatMeasuresAction {
    return {
        type: ActionType.RECEIVE_THREAT_MEASURES,
        payload: threatMeasures,
    }
}
