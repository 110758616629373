import {LatestAssetData, latestAssetDataDefaultState} from './types/latest-unknown-asset'

export const enum QUERY_PATH {
    ASSET_AGGREGATION_ENDPOINT = 'latestAssetSummary',
    BG_QUERY = 'latestMonaAnomalies',
}
export interface LatestUnknownDataReduxState {
    loading: boolean
    unknownAssetData: LatestAssetData | null
}
export const DEFAULT_LATEST_UNKNOWN_ASSET_DATA_STATE: LatestUnknownDataReduxState = {
    loading: true,
    unknownAssetData: latestAssetDataDefaultState(),
}
