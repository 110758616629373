import LoadingState from '../../../values/loading-state-enum'
import {
    SelfMonitoringEngineRule,
    SelfMonitoringEngineRuleIdType,
    SelfMonitoringEngineRuleMap,
} from '../../../values/self-monitoring-rule'
export interface SelfMonitoringEngineRulesReduxState {
    loadingRules: LoadingState
    data: SelfMonitoringEngineRuleMap
}
export const DEFAULT_SELF_MONITORING_ENGINE_RULES_STATE: SelfMonitoringEngineRulesReduxState = {
    loadingRules: LoadingState.NotPopulated,
    data: new Map<SelfMonitoringEngineRuleIdType, SelfMonitoringEngineRule>(),
}
