import * as Actions from './actions'
import ActionType from './action-type'
import {Api} from '../../../api/Api'
import {REST} from '../../../index'
import {ThunkAction} from 'redux-thunk'
import AppState from '../../types/app-state'
import {
    DEFAULT_USB_INVENTORY_FILTER_STATE,
    DetailExpandedType,
    DeviceStatus,
    FilterExpandedType,
    SortColumnType,
    TimestampFilter,
    USBDeviceType,
} from './state'
import {
    DEFAULT_USB_INVENTORY_FILTER,
    UsbInventoryFilter,
} from '../../../values/user-preferences/usb-inventory-filter'
import {SavedUsbInventoryFilter} from '../saved-filters/state'
import {formattedLocationsBlockSet, populateLocationsForFilterCriteria} from '../../../utils/Utils'
import {AnyAction} from 'redux'
import {setCurrentAppliedSavedFilter} from '../saved-filters/action-creators'
import {getFormattedUsbInventoryFilter} from '../../../components/saved-search/components/shared/helper'

const USER_PREFS_URL = '/api/v1/userPreferences/usb_devices'

export const fetchUsbInventoryFilter = (
    savedFilters: SavedUsbInventoryFilter[],
    isDefaultTagUser?: boolean,
): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch) => {
        dispatch(requestFilter())
        if (isDefaultTagUser === true) {
            REST.get(USER_PREFS_URL).then(
                (response) => {
                    REST.get(`${USER_PREFS_URL}.default_tag`).then(
                        (res) => {
                            if (response.data.value.selectedFilterName === undefined) {
                                dispatch(
                                    bulkAssignSavedFilter(
                                        {
                                            ...DEFAULT_USB_INVENTORY_FILTER,
                                            searchedVessels: formattedLocationsBlockSet(
                                                response.data.value.locations,
                                            ),
                                            searchVesselTagTerm: res.data.value.default_tag ?? [],
                                            searchVesselNameTerm:
                                                response.data.value.searchVesselNameTerm ?? '',
                                        },
                                        'Saved filters',
                                        response.data.value.detailExpanded,
                                        true,
                                    ),
                                )
                            } else {
                                const savedFilter =
                                    savedFilters &&
                                    savedFilters.find(
                                        (filter) =>
                                            filter.name === response.data.value.selectedFilterName,
                                    )

                                savedFilter
                                    ? dispatch(
                                          bulkAssignSavedFilter(
                                              {
                                                  ...(getFormattedUsbInventoryFilter(
                                                      savedFilter.criteria,
                                                  ) as UsbInventoryFilter),
                                                  searchedVessels: formattedLocationsBlockSet(
                                                      response.data.value.locations,
                                                  ),
                                                  searchVesselTagTerm: res.data.value.default_tag,
                                                  searchVesselNameTerm:
                                                      response.data.value.searchVesselNameTerm,
                                              },
                                              savedFilter.name,
                                              response.data.value.detailExpanded,
                                              true,
                                              savedFilter,
                                          ),
                                      )
                                    : dispatch(
                                          bulkAssignSavedFilter(
                                              {
                                                  ...DEFAULT_USB_INVENTORY_FILTER,
                                                  searchedVessels: formattedLocationsBlockSet(
                                                      response.data.value.locations,
                                                  ),
                                                  searchVesselTagTerm: res.data.value.default_tag,
                                                  searchVesselNameTerm:
                                                      response.data.value.searchVesselNameTerm,
                                              },
                                              'Saved filters',
                                              response.data.value.detailExpanded,
                                              true,
                                          ),
                                      )
                            }
                            REST.delete(`${USER_PREFS_URL}.default_tag`)
                        },
                        () => {
                            dispatch(
                                bulkAssignSavedFilter(
                                    {
                                        ...DEFAULT_USB_INVENTORY_FILTER,
                                        searchedVessels: formattedLocationsBlockSet(
                                            response.data.value.locations,
                                        ),
                                        searchVesselTagTerm:
                                            response.data.value.searchVesselTagTerm,
                                        searchVesselNameTerm:
                                            response.data.value.searchVesselNameTerm,
                                    },
                                    'Saved filters',
                                    response.data.value.detailExpanded,
                                ),
                            )
                        },
                    )
                },
                () => {
                    REST.get(`${USER_PREFS_URL}.default_tag`).then((res) => {
                        REST.put(USER_PREFS_URL, {
                            ...DEFAULT_USB_INVENTORY_FILTER_STATE,
                            searchVesselTagTerm: res.data.value.default_tag,
                        })
                        dispatch(
                            bulkAssignSavedFilter(
                                {
                                    ...DEFAULT_USB_INVENTORY_FILTER,
                                    searchVesselTagTerm: res.data.value.default_tag,
                                },
                                'Saved filters',
                                DEFAULT_USB_INVENTORY_FILTER_STATE.detailExpanded,
                            ),
                        )
                    })
                },
            )
        } else {
            REST.get(USER_PREFS_URL).then(
                (response) => {
                    if (response.data.value.selectedFilterName === undefined) {
                        dispatch(
                            bulkAssignSavedFilter(
                                {
                                    ...DEFAULT_USB_INVENTORY_FILTER,
                                    searchedVessels: formattedLocationsBlockSet(
                                        response.data.value.locations,
                                    ),
                                    searchVesselTagTerm:
                                        response.data.value.searchVesselTagTerm ?? [],
                                    searchVesselNameTerm:
                                        response.data.value.searchVesselNameTerm ?? '',
                                },
                                'Saved filters',
                                response.data.value.detailExpanded,
                            ),
                        )
                    } else {
                        const savedFilter =
                            savedFilters &&
                            savedFilters.find(
                                (filter) => filter.name === response.data.value.selectedFilterName,
                            )

                        savedFilter
                            ? dispatch(
                                  bulkAssignSavedFilter(
                                      {
                                          ...(getFormattedUsbInventoryFilter(
                                              savedFilter.criteria,
                                          ) as UsbInventoryFilter),
                                          searchedVessels: formattedLocationsBlockSet(
                                              response.data.value.locations,
                                          ),
                                          searchVesselTagTerm:
                                              response.data.value.searchVesselTagTerm,
                                          searchVesselNameTerm:
                                              response.data.value.searchVesselNameTerm,
                                      },
                                      savedFilter.name,
                                      response.data.value.detailExpanded,
                                      false,
                                      savedFilter,
                                  ),
                              )
                            : dispatch(
                                  bulkAssignSavedFilter(
                                      {
                                          ...DEFAULT_USB_INVENTORY_FILTER,
                                          searchedVessels: formattedLocationsBlockSet(
                                              response.data.value.locations,
                                          ),
                                          searchVesselTagTerm:
                                              response.data.value.searchVesselTagTerm,
                                          searchVesselNameTerm:
                                              response.data.value.searchVesselNameTerm,
                                      },
                                      'Saved filters',
                                      response.data.value.detailExpanded,
                                  ),
                              )
                    }
                },
                () => {
                    dispatch(
                        bulkAssignSavedFilter(
                            DEFAULT_USB_INVENTORY_FILTER,
                            'Saved filters',
                            DEFAULT_USB_INVENTORY_FILTER_STATE.detailExpanded,
                        ),
                    )
                },
            )
        }
    }
}

function requestFilter(): Actions.RequestFilterAction {
    return {
        type: ActionType.REQUEST_FILTER,
    }
}

export function setSortColumn(
    sortColumn: SortColumnType,
): ThunkAction<void, AppState, Api, Actions.SetSortColumnAction> {
    return (dispatch) => {
        dispatch({
            type: ActionType.SET_SORT_COLUMN,
            payload: sortColumn,
        })
    }
}

export function toggleUsbDeviceStatus(
    allUsbDeviceStatus: DeviceStatus[],
    selectedUsbDeviceStatus: DeviceStatus,
    usbDeviceStatusNewValue: boolean,
): ThunkAction<void, AppState, Api, Actions.ToggleUsbDeviceStatusAction> {
    return (dispatch) => {
        dispatch({
            type: ActionType.TOGGLE_USB_DEVICE_STATUS,
            payload: {allUsbDeviceStatus, selectedUsbDeviceStatus, usbDeviceStatusNewValue},
        })
    }
}

export function ToggleAllUsbDeviceStatus(
    selected: boolean,
): ThunkAction<void, AppState, Api, Actions.ToggleAllUsbDeviceStatusAction> {
    return (dispatch) => {
        dispatch({
            type: ActionType.TOGGLE_ALL_USB_DEVICE_STATUS,
            payload: selected,
        })
    }
}

export function setSearchedFirstDetected(
    selectedTimeRange: TimestampFilter,
): ThunkAction<void, AppState, Api, Actions.SetSearchedTimeRange> {
    return (dispatch) => {
        dispatch({
            type: ActionType.SET_SEARCHED_FIRST_SEEN,
            payload: selectedTimeRange,
        })
    }
}

export function setSearchedLastSeen(
    selectedTimeRange: TimestampFilter,
): ThunkAction<void, AppState, Api, Actions.SetSearchedTimeRange> {
    return (dispatch) => {
        dispatch({
            type: ActionType.SET_SEARCHED_LAST_ACTIVE,
            payload: selectedTimeRange,
        })
    }
}

export function setSearchedVendor(
    searchedVendor: string,
): ThunkAction<void, AppState, Api, Actions.SearchVendor> {
    return (dispatch) => {
        dispatch({
            type: ActionType.SET_SEARCHED_VENDOR,
            payload: searchedVendor,
        })
    }
}

export function setSearchedProductName(
    searchedProductName: string,
): ThunkAction<void, AppState, Api, Actions.SearchProductName> {
    return (dispatch) => {
        dispatch({
            type: ActionType.SET_SEARCHED_PRODUCT_NAME,
            payload: searchedProductName,
        })
    }
}

export function setSearchedUSBType(
    searchedType: USBDeviceType[],
): ThunkAction<void, AppState, Api, Actions.SearchUSBType> {
    return (dispatch) => {
        dispatch({
            type: ActionType.SET_SEARCHED_USB_TYPE,
            payload: searchedType,
        })
    }
}

export function setSearchedUSBHasPurpose(
    hasPurpose: boolean | undefined,
): ThunkAction<void, AppState, Api, Actions.SearchUSBHasPurpose> {
    return (dispatch) => {
        dispatch({
            type: ActionType.SET_SEARCHED_HAS_PURPOSE,
            payload: hasPurpose,
        })
    }
}

export function toggleFilterExpanded(
    filterExpanded: FilterExpandedType,
): ThunkAction<void, AppState, Api, Actions.ToggleFilterExpandedAction> {
    return (dispatch, getState) => {
        const currentFilter = getState().usbInventoryFilter
        const currentVesselFilter = getState().vesselFilter
        dispatch({
            type: ActionType.TOGGLE_FILTER_EXPANDED,
            payload: filterExpanded,
        })
        REST.put(USER_PREFS_URL, {
            selectedFilterName: currentFilter.selectedFilterName,
            locations: currentVesselFilter.locations,
            searchVesselTagTerm: currentVesselFilter.searchVesselTagTerm,
            searchVesselNameTerm: currentVesselFilter.searchVesselNameTerm,
            filterExpanded,
            detailExpanded: currentFilter.detailExpanded,
        })
    }
}

export function toggleModalDetailsExpanded(
    detailExpanded: DetailExpandedType,
): ThunkAction<void, AppState, Api, Actions.ToggleDetailExpandedAction> {
    return (dispatch, getState) => {
        const currentFilter = getState().usbInventoryFilter
        const currentVesselFilter = getState().vesselFilter
        dispatch({
            type: ActionType.TOGGLE_DETAIL_EXPANDED,
            payload: detailExpanded,
        })
        REST.put(USER_PREFS_URL, {
            selectedFilterName: currentFilter.selectedFilterName,
            locations: currentVesselFilter.locations,
            searchVesselTagTerm: currentVesselFilter.searchVesselTagTerm,
            searchVesselNameTerm: currentVesselFilter.searchVesselNameTerm,
            filterExpanded: currentFilter.filterExpanded,
            detailExpanded,
        })
    }
}

export function resetFilter(): ThunkAction<void, AppState, Api, AnyAction> {
    return (dispatch, getState) => {
        REST.get(USER_PREFS_URL).then((response) => {
            dispatch(toggleModalDetailsExpanded(response.data.value.detailExpanded))
        })
        const currentFilter = getState().softwareInventoryFilter
        dispatch({type: ActionType.RESET_FILTER})
        dispatch(setCurrentAppliedSavedFilter(undefined))
        REST.put(USER_PREFS_URL, {
            selectedFilterName: '',
            searchVesselTagTerm: [],
            searchVesselNameTerm: '',
            detailExpanded: currentFilter.detailExpanded,
        })
    }
}

export function bulkAssignSavedFilter(
    data: UsbInventoryFilter,
    filterName: string,
    detailExpanded: DetailExpandedType,
    pref?: boolean,
    savedFilter?: SavedUsbInventoryFilter,
): ThunkAction<void, AppState, Api, AnyAction> {
    return (dispatch, getState) => {
        const currentVesselFilter = getState().vesselFilter
        dispatch({
            type: ActionType.BULK_ASSIGN_SAVED_FILTER,
            payload: {
                criteria: data,
                name: filterName,
                detailExpanded: detailExpanded,
            },
        })
        savedFilter && dispatch(setCurrentAppliedSavedFilter(savedFilter))
        pref &&
            REST.put(USER_PREFS_URL, {
                selectedFilterName: filterName,
                locations: populateLocationsForFilterCriteria(currentVesselFilter.locations),
                searchVesselTagTerm: currentVesselFilter.searchVesselTagTerm,
                searchVesselNameTerm: currentVesselFilter.searchVesselNameTerm,
                filterExpanded: DEFAULT_USB_INVENTORY_FILTER_STATE.filterExpanded,
                detailExpanded: detailExpanded,
            })
    }
}

export function setSelectedFilterName(
    filterName: string,
    pref?: {
        savedFilter?: SavedUsbInventoryFilter
        detailExpanded: DetailExpandedType
    },
): ThunkAction<void, AppState, Api, AnyAction> {
    return (dispatch, getState) => {
        const currentVesselFilter = getState().vesselFilter
        dispatch({
            type: ActionType.SET_SELECTED_FILTER_NAME,
            payload: filterName,
        })
        if (pref) {
            dispatch(setCurrentAppliedSavedFilter(pref.savedFilter))
            REST.put(USER_PREFS_URL, {
                selectedFilterName: filterName,
                locations: populateLocationsForFilterCriteria(currentVesselFilter.locations),
                searchVesselTagTerm: currentVesselFilter.searchVesselTagTerm,
                searchVesselNameTerm: currentVesselFilter.searchVesselNameTerm,
                filterExpanded: DEFAULT_USB_INVENTORY_FILTER_STATE.filterExpanded,
                detailExpanded: pref.detailExpanded,
            })
        }
    }
}
