/* eslint-disable prefer-rest-params */
export const injectUserGuiding = (userGuidingConfig) => {
    if (!userGuidingConfig?.userGuidingEnabled) {
        return
    }

    return new Promise((resolve, reject) => {
        ;(function (g, u, i, d, e, s) {
            g[e] = g[e] || []
            const f = u.getElementsByTagName(i)[0]
            const k = u.createElement(i)
            k.async = true
            k.src = 'https://static.userguiding.com/media/user-guiding-' + s + '-embedded.js'
            k.onload = () => resolve()
            k.onerror = () => reject(new Error('User Guiding script failed to load'))
            f.parentNode.insertBefore(k, f)

            if (g[d]) {
                resolve()
                return
            }

            const ug = (g[d] = {q: []})
            ug.c = function (n) {
                return function () {
                    ug.q.push([n, arguments])
                }
            }

            const m = [
                'previewGuide',
                'finishPreview',
                'track',
                'identify',
                'triggerNps',
                'hideChecklist',
                'launchChecklist',
            ]
            for (let j = 0; j < m.length; j += 1) {
                ug[m[j]] = ug.c(m[j])
            }
        })(
            window,
            document,
            'script',
            'userGuiding',
            'userGuidingLayer',
            userGuidingConfig.userGuidingId,
        )
    })
}
