import {LocationTagScore} from '../../../values/location-tag-score'

export interface LocationTagThreatScoreReduxState {
    isFetchingLast12H: boolean
    isFetchingLast24H: boolean
    isFetchingLast7D: boolean
    isFetchingLast30D: boolean
    isFetchingLast90D: boolean
    trendLast12H: LocationTagScore[]
    trendLast24H: LocationTagScore[]
    trendLast7D: LocationTagScore[]
    trendLast30D: LocationTagScore[]
    trendLast90D: LocationTagScore[]
}

export const DEFAULT_LOCATION_TAG_THREAT_SCORE_STATE: LocationTagThreatScoreReduxState = {
    isFetchingLast12H: true,
    isFetchingLast24H: true,
    isFetchingLast7D: true,
    isFetchingLast30D: true,
    isFetchingLast90D: true,
    trendLast12H: [],
    trendLast24H: [],
    trendLast7D: [],
    trendLast30D: [],
    trendLast90D: [],
}
