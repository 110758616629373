export interface LatestLocationThreatScoresReduxState {
    isFetchingLatestLocationThreatScores: boolean
    latestLocationThreatScores: LatestLocationThreatScores
}

export const DEFAULT_LATEST_LOCATION_THREAT_SCORES_STATE: LatestLocationThreatScoresReduxState = {
    isFetchingLatestLocationThreatScores: false,
    latestLocationThreatScores: {},
}

export interface LatestLocationThreatScores {
    [location: string]: {
        score: number
        timestamp: number
    }
}
