import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'

export const Button = styled.button`
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: none;
    color: ${(props) => props.theme.navigationResponsive.dataArea.textColor};
    border: none;
    padding: ${spacing(1)};
    cursor: pointer;
    outline: inherit;
    border-radius: 100%;
    text-decoration: none;
    height: 26px;
    width: 26px;

    &:hover {
        background-color: ${(props) => props.theme.navigationResponsive.dataArea.miniBackground};
    }
    &:active,
    &:focus {
        background-color: ${(props) => props.theme.navigationResponsive.active.background};
    }
`
