import {Moment} from 'moment'
import LoadingState from '../../../values/loading-state-enum'
import {SelfMonitoringEngineEvent} from '../../../values/self-monitoring-event'

export interface SelfMonitoringEngineEventsReduxState {
    loadingEvents: LoadingState
    data: SelfMonitoringEngineEvent[]
    lastUpdate: Moment | null
}

export const DEFAULT_SELF_MONITORING_ENGINE_EVENTS_STATE: SelfMonitoringEngineEventsReduxState = {
    loadingEvents: LoadingState.NotPopulated,
    data: [],
    lastUpdate: null,
}
