import {Moment} from 'moment'
import {EngineStatus} from '../../../values/engine_status/engine-status'
import LoadingState from '../../../values/loading-state-enum'

export interface EngineStatusReduxState {
    loading: LoadingState | undefined
    data: EngineStatus[] | undefined
    allEnginesRunning: boolean | undefined
    lastUpdate: Moment | undefined | null
}

export const DEFAULT_ENGINE_STATUS_STATE: EngineStatusReduxState = {
    loading: LoadingState.NotPopulated,
    data: [],
    allEnginesRunning: false,
    lastUpdate: null,
}
