import {LoadingSpinner} from '../loading/loading'
import * as styled from './data-loading.styled'

interface DataLoadingProps {
    size?: number
}

export const DataLoading = ({size}: DataLoadingProps): JSX.Element => (
    <styled.DataLoading>
        <LoadingSpinner size={size ?? 100} />
    </styled.DataLoading>
)
