import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'

export const DropdownMenuStyle = styled.div`
    background-color: ${(props) => props.theme.navigationResponsive.dataArea.locationExpandedArea};
    padding: 0 ${spacing(2)} ${spacing(1)} ${spacing(2)};
    font-weight: ${(props) => props.theme.font.weight.normal};
    overflow-y: auto;
    &:link,
    &:visited,
    &:hover,
    &:active,
    &:focus {
        outline: 0;
        cursor: default;
    }
`
export const DropdownItemStyle = styled.div`
    color: white;
    padding: ${spacing(1)} 0;
    border: none transparent !important;
    font-size: 14px;
    line-height: 21px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    &:link,
    &:visited,
    &:hover,
    &:active,
    &:focus {
        border: none transparent !important;
        border-radius: 0;
        outline: 0;
        cursor: pointer;
    }
`
export const Divider = styled.div`
    margin: 6.5px 0;
    border-top: 1px solid ${(props) => props.theme.navigationResponsive.dataArea.miniTextColor};
`
export const RadioItemStyle = styled.div`
    &:link,
    &:visited,
    &:hover,
    &:active,
    &:focus {
        cursor: pointer;
    }
`
export const LabelItemStyle = styled.div`
    &:link,
    &:visited,
    &:hover,
    &:active,
    &:focus {
        cursor: pointer;
    }
`
