import * as Styled from './column-based-layout.styled'
import {SignOutButton} from '../../actions/sign-out-button'
import * as ErrorStyles from '../../error.styled'
import {GetHelpButton} from '../../actions/get-help-button'
import {
    ColumnBasedLoginLayout,
    needsReducedHeight,
    needsReducedWidth,
} from './column-based-login-layout'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'

export function AuthenticationIssue(): JSX.Element {
    const {width, height} = useDimensions()

    const reducedHeight = needsReducedHeight(height)
    const reducedWidth = needsReducedWidth(width)

    return (
        <ColumnBasedLoginLayout>
            <Styled.MainActions addBottomMargin={false}>
                <div style={{flex: 1, justifyItems: 'center'}}>
                    <ErrorStyles.Message>
                        We&#39;re sorry, but we cannot sign you in.
                    </ErrorStyles.Message>
                </div>
                <Styled.MainActionsGrid reducedGap={true}>
                    <SignOutButton buttonText="Continue" />
                    <GetHelpButton smallText={reducedWidth || reducedHeight} />
                </Styled.MainActionsGrid>
            </Styled.MainActions>
        </ColumnBasedLoginLayout>
    )
}
