import ActionType from './action-type'
import {Action} from './actions'
import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'
import produce from 'immer'
import {DEFAULT_WATCH_INCIDENTS_STATE, WatchIncidentsReduxState} from './state'
import {isEqual} from 'lodash'

const watchedIncidentsReducer = produce(
    (
        draft: WatchIncidentsReduxState = DEFAULT_WATCH_INCIDENTS_STATE,
        action: Action | LoggingOutAction,
    ) => {
        switch (action.type) {
            case ActionType.SET_WATCHED_INCIDENTS:
                draft.loading = false
                if (!isEqual(draft.watchedIncidents, action.payload)) {
                    draft.watchedIncidents = action.payload
                }
                break

            case ActionType.SET_LOADING:
                draft.loading = true
                break

            case ActionType.SET_ERROR:
                draft.error = action.payload
                break

            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_WATCH_INCIDENTS_STATE
                break

            /* istanbul ignore next */
            default:
                return draft
        }
        return draft
    },
)
export default watchedIncidentsReducer
