import ActionType from './action-type'
import * as Actions from './actions'

import * as uuid from 'uuid'
import {ThunkAction} from 'redux-thunk'

import {Api, QuerySpecification} from '../../../api/Api'
import {QueryCancellation} from '../../../websocket/Queries'
import AppState from '../../types/app-state'

const QUERY_PATH = 'latestEventTimestamps'
const QUERY_SCHEDULE = '1m'

function latestEventTimestampsQuery(): QuerySpecification {
    return {
        path: QUERY_PATH,
        localId: `${QUERY_PATH}-${uuid.v4()}`,
        schedule: QUERY_SCHEDULE,
    }
}

export function registerLatestEventTimestampsQuery(): ThunkAction<
    void,
    AppState,
    Api,
    Actions.Action
> {
    return (dispatch, _, api: Api): QueryCancellation => {
        dispatch(requestLatestEventTimestamps())

        const query: QuerySpecification = latestEventTimestampsQuery()

        // eslint-disable-next-line @typescript-eslint/ban-types
        api.newQuery(query, (data: {items: {}}) => {
            dispatch(receiveLatestEventTimestamps(data.items))
        })

        return () => {
            api.cancelQuery({localId: query.localId})
        }
    }
}

function requestLatestEventTimestamps(): Actions.RequestLatestEventTimestampsAction {
    return {
        type: ActionType.REQUEST_LATEST_EVENTS_TIMESTAMPS,
    }
}

function receiveLatestEventTimestamps(latestEventTimestamps: {
    [node: string]: string
}): Actions.ReceiveLatestEventTimestampsAction {
    return {
        type: ActionType.RECEIVE_LATEST_EVENTS_TIMESTAMPS,
        payload: latestEventTimestamps,
    }
}
