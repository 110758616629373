import {createSelector} from 'reselect'
import {isFetchingRolesSelector} from '../../../../store/state/roles/selectors'
import {isTagLoadingSelector} from '../../../../store/state/vessel-tags/selectors'
import {isFetchingCurrentUserSelector} from '../../../../store/state/current-user/selectors'
import {locationsLoadingSelector} from '../../../../store/state/locations/selectors'

export const loginInDataLoadedReselector = createSelector(
    isFetchingRolesSelector,
    isTagLoadingSelector,
    isFetchingCurrentUserSelector,
    locationsLoadingSelector,
    (userRolesLoading, existingTagsLoading, currentUserLoading, locationsLoading): boolean => {
        return userRolesLoading || existingTagsLoading || currentUserLoading || locationsLoading
            ? false
            : true
    },
)
