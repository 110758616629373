import {
    DEFAULT_SELF_MONITORING_ENGINE_RULES_STATE,
    SelfMonitoringEngineRulesReduxState,
} from './state'
import ActionType from './action-type'
import {Action} from './actions'

import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'
import produce from 'immer'
import {
    SelfMonitoringEngineRule,
    SelfMonitoringEngineRuleIdType,
    SelfMonitoringEngineRuleMap,
} from '../../../values/self-monitoring-rule'
import LoadingState from '../../../values/loading-state-enum'
import {isEqual} from 'lodash'

const reduceArrayToMap = (
    currentMap: SelfMonitoringEngineRuleMap,
    newSmrArray: SelfMonitoringEngineRule[],
): {dataUpdated: boolean; data: SelfMonitoringEngineRuleMap} => {
    let dataUpdated = false
    const newMap = new Map<SelfMonitoringEngineRuleIdType, SelfMonitoringEngineRule>()

    newSmrArray.forEach((newSmr) => {
        const currentSmr = currentMap.get(newSmr.identity)
        if (!currentSmr || !isEqual(newSmr, currentSmr)) {
            dataUpdated = true
            newMap.set(newSmr.identity, newSmr)
        } else {
            newMap.set(currentSmr.identity, currentSmr)
        }
    })

    if (dataUpdated || currentMap?.size !== newMap.size) {
        return {
            dataUpdated: true,
            data: newMap,
        }
    }

    return {
        dataUpdated: false,
        data: currentMap,
    }
}

export const selfMonitoringEngineRulesReducer = produce(
    (
        draft: SelfMonitoringEngineRulesReduxState = DEFAULT_SELF_MONITORING_ENGINE_RULES_STATE,
        action: Action | LoggingOutAction,
    ) => {
        switch (action.type) {
            case ActionType.REQUEST_SELF_MONITORING_ENGINE_RULES: {
                draft.loadingRules = LoadingState.RequestingData
                if (draft.data?.size !== 0) {
                    draft.data = new Map<SelfMonitoringEngineRuleIdType, SelfMonitoringEngineRule>()
                }
                break
            }

            case ActionType.RECEIVED_SELF_MONITORING_ENGINE_RULES: {
                draft.loadingRules = LoadingState.Loaded
                const {dataUpdated, data} = reduceArrayToMap(draft.data, action.payload)
                if (dataUpdated) {
                    draft.data = data
                }
                break
            }
            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_SELF_MONITORING_ENGINE_RULES_STATE
                break
            /* istanbul ignore next */
            default:
                return draft
        }
        return draft
    },
)
