enum ActionType {
    REQUEST_FILTER = '[METRICS BETA FILTER] REQUEST FILTER',
    RECEIVE_FILTER = '[METRICS BETA FILTER] RECEIVE FILTER',
    SET_SORT_COLUMN = '[METRICS BETA FILTER] SET SORT COLUMN',
    TOGGLE_METRIC_TYPE = '[METRICS BETA FILTER] TOGGLE METRIC TYPE',
    TOGGLE_ALL_METRIC_TYPE = '[METRICS BETA FILTER] TOGGLE ALL METRIC TYPE',
    TOGGLE_MAIN_METRICS_TYPE = '[METRICS BETA FILTER] TOGGLE MAIN METRICS TYPE',
    SET_ANALYSIS_PERIOD = '[METRICS BETA FILTER] SET ANALYSIS PERIOD',
    SET_ANALYSIS_TYPES = '[METRICS BETA FILTER] SET ANALYSIS TYPES',
    TOGGLE_ASSET_WITH_ISSUE = '[METRICS BETA FILTER] TOGGLE ASSET WITH ISSUE',
    TOGGLE_FILTER_EXPANDED = '[METRICS BETA FILTER] TOGGLE FILTER EXPANDED',
    RESET_FILTER = '[METRICS BETA FILTER] RESET FILTER',
    BULK_ASSIGN_SAVED_FILTER = '[METRICS BETA FILTER] BULK ASSIGN SAVED FILTER',
    SET_SELECTED_FILTER_NAME = '[METRICS BETA FILTER] SET SELECTED FILTER NAME',
    SET_SELECTED_VIEW_SCREEN_DROPDOWN = '[METRICS BETA FILTER] SET SELECTED VIEW SCREEN DROPDOWN',
    FILTER_MAIN_FRAMEWORK_CARD = '[METRICS BETA FILTER] FILTER MAIN FRAMEWORK CARD',
    TOGGLE_ALL_ASSETS = '[[METRICS BETA FILTER] TOGGLE ALL ASSET FILTERS',
    TOGGLE_VALUE_THRESHOLD = '[[METRICS BETA FILTER] TOGGLE VALUE THRESHOLD FILTER',
    SET_TRENDS_BENCHMARK_TYPE = '[METRICS BETA FILTER] SET TRENDS BENCHMARK TYPE',
}

export default ActionType
