import {isEqual} from 'lodash'
import {LocationIdType} from '../../../store/state/locations/state'
import {NodeData, NodeDataMap} from '../../../values/nodes/NodeData'
import {AssetVersion} from './types/software-inventory-response'
import {
    SoftwareInventoryFilter,
    SoftwareInventoryFilterForCriteria,
    TimestampFilter,
} from './types/software-inventory-filter'
import {StringUtils} from '../../../utils/Utils'

export function buildFilterPrefs(
    state: SoftwareInventoryFilter,
): SoftwareInventoryFilterForCriteria {
    return {
        searchedFirstDetected: state.searchedFirstDetected,
        searchedLastActive: state.searchedLastActive,
        searchedCustomerName: state.searchedCompanyName,
        searchedProductName: state.searchedProductName,
        searchedVessels: populateLocationsForFilterCriteria(state.searchedVessels),
        sortColumn: state.sortColumn,
        searchedVesselTagTerm: state.searchedVesselTagTerm,
        searchedVesselNameTerm: state.searchedVesselNameTerm,
    }
}

function populateLocationsForFilterCriteria(
    locations: Set<LocationIdType> | undefined,
): Array<LocationIdType> | undefined {
    try {
        if (!locations) {
            return undefined
        }
        return Array.from(locations)
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error({err, locations})
        return undefined
    }
}

export function buildLocations(
    allLocations: Array<LocationIdType>,
    currentSelectedLocations: Set<LocationIdType> | undefined,
    location: LocationIdType | LocationIdType[],
    newValue: boolean,
): string | Array<LocationIdType> {
    const newLocations = new Set<LocationIdType>(
        currentSelectedLocations != undefined ? currentSelectedLocations : allLocations,
    )

    if (StringUtils.validString(location)) {
        if (newValue) {
            newLocations.add(location)
        } else {
            newLocations.delete(location)
        }
    } else if (Array.isArray(location)) {
        if (newValue) {
            location.forEach((e) => newLocations.add(e))
        } else {
            location.forEach((e) => newLocations.delete(e))
        }
    }

    if (newLocations.size === 0) {
        return 'all-deselected'
    }

    if (newLocations.size === allLocations?.length) {
        return 'all-selected'
    }

    return Array.from(newLocations)
}

export function populateLocations(
    locations: string | Array<LocationIdType>,
): Set<LocationIdType> | undefined {
    try {
        if (!locations || isEqual(locations, {}) || locations === 'all-selected') {
            return undefined
        }

        if (locations === 'all-deselected') {
            return new Set<LocationIdType>()
        }

        return new Set<LocationIdType>(locations)
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error({err, locations})
        return undefined
    }
}

export function getVesselForGivenAsset(
    assetVersion: AssetVersion | undefined,
    nodes: NodeDataMap,
): NodeData | null {
    const identity = assetVersion?.identity
    if (!identity || !nodes.has(identity)) {
        return null
    }
    return nodes.get(identity) ?? null
}

export function formatTimestampCriteria(searchedTimestamp: TimestampFilter): string | null {
    switch (searchedTimestamp) {
        case 'Last 24h':
            return '-24h'
        case 'Last 7 days':
            return '-168h'
        case 'Last 30 days':
            return '-720h'
        case 'All':
        default:
            return null
    }
}
