import {FC, Fragment} from 'react'
import {RoleCheckerProps} from './types'
import rolesCheckerReselector from './roles-checker-reselector'
import {isUserAllowed} from '../../values/Role'
import usedTypeSelector from '../../hooks/use-typed-selector'

const RoleChecker: FC<RoleCheckerProps> = ({rolesAllowed, children}) => {
    if (!isUserAllowed(usedTypeSelector(rolesCheckerReselector), rolesAllowed)) {
        return null
    }

    return <Fragment>{children}</Fragment>
}

export default RoleChecker
