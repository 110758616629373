import ActionType from './action-type'
import * as Actions from './actions'

export function loggingIn(): Actions.LoggingInAction {
    return {
        type: ActionType.LOGGING_IN,
    }
}

export function loggedIn(): Actions.LoggedInAction {
    return {
        type: ActionType.LOGGED_IN,
    }
}

export function loggingOut(): Actions.LoggingOutAction {
    return {
        type: ActionType.LOGGING_OUT,
    }
}

export function loggedOut(): Actions.LoggedOutAction {
    return {
        type: ActionType.LOGGED_OUT,
    }
}

export function ErrorOccurred(message: string): Actions.ErrorOccurredAction {
    return {
        type: ActionType.ERROR_OCCURED,
        payload: message,
    }
}
