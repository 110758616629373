import {DEFAULT_NETWORK_ANOMALY_MATRIX_STATE, NetworkAnomalyMatrixState} from './state'
import {Action} from './actions'
import ActionType from './action-type'

import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'

import LoadingState from '../../../values/loading-state-enum'
import {NetworkAnomalyMatrixApiRecord} from '../../../values/anomalies/network-anomaly-matrix-api-record'
import NetworkAnomalyMatrixRecord from '../../../values/anomalies/network-anomaly-matrix-record'
import NetworkAnomalyMatrixRecordMap from '../../../values/anomalies/network-anomaly-matrix-record-map'
import NetworkAnomalyMatrixRecordId from '../../../values/anomalies/network-anomaly-matrix-record-id'

function mapToNetworkAnomalyMatrixRecord(
    record: NetworkAnomalyMatrixApiRecord,
): NetworkAnomalyMatrixRecord {
    return {
        id: record.anomalyMatrixScore,
        anySource: record.identifier.anySource,
        anyDestination: record.identifier.anyDestination,
        anyPort: record.identifier.anyPort,
        score: record.score,
    }
}

function mutateStateForRequestingData(
    existingState: NetworkAnomalyMatrixState,
): NetworkAnomalyMatrixState {
    const newLoadingState = LoadingState.RequestingData

    if (existingState.loading === newLoadingState) {
        return existingState
    }

    return {
        ...existingState,
        loading: newLoadingState,
        errorMessage: '',
    }
}

function mutateStateForDataRequestError(
    existingState: NetworkAnomalyMatrixState,
    errorMessage: string,
): NetworkAnomalyMatrixState {
    const parsedMessage = errorMessage && errorMessage.length > 0 ? errorMessage : 'Unknown Error'

    if (
        existingState.loading === LoadingState.Errored &&
        existingState.errorMessage === parsedMessage
    ) {
        return existingState
    }

    return {
        ...existingState,
        loading: LoadingState.Errored,
        errorMessage: parsedMessage,
    }
}

function recordNeedsUpdating(
    existingRecord: NetworkAnomalyMatrixRecord,
    apiRecord: NetworkAnomalyMatrixApiRecord,
): boolean {
    return (
        existingRecord.anyDestination !== apiRecord?.identifier?.anyDestination ||
        existingRecord.anyPort !== apiRecord?.identifier?.anyPort ||
        existingRecord.anySource !== apiRecord?.identifier?.anySource ||
        existingRecord.score !== apiRecord.score
    )
}

function mutateStateForReceivedData(
    existingState: NetworkAnomalyMatrixState,
    apiData: NetworkAnomalyMatrixApiRecord[],
): NetworkAnomalyMatrixState {
    const newRecords: NetworkAnomalyMatrixRecord[] = []
    const updatedRecords: NetworkAnomalyMatrixRecord[] = []
    const unchangedRecords: NetworkAnomalyMatrixRecord[] = []

    apiData.forEach((apiRecord) => {
        const existingRecord =
            existingState &&
            existingState.records &&
            existingState.records.get(apiRecord.anomalyMatrixScore)
        if (!existingRecord) {
            newRecords.push(mapToNetworkAnomalyMatrixRecord(apiRecord))
        } else {
            if (recordNeedsUpdating(existingRecord, apiRecord)) {
                updatedRecords.push(mapToNetworkAnomalyMatrixRecord(apiRecord))
            } else {
                unchangedRecords.push(existingRecord)
            }
        }
    })

    const noNewData = newRecords.length === 0 && updatedRecords.length === 0
    const noRecordsToRemove =
        existingState.records && unchangedRecords.length === existingState.records.size

    if (noNewData && noRecordsToRemove) {
        if (existingState.loading === LoadingState.Loaded) {
            return existingState
        }

        return {
            ...existingState,
            loading: LoadingState.Loaded,
            errorMessage: '',
        }
    }

    const mappedData: NetworkAnomalyMatrixRecordMap = new Map<
        NetworkAnomalyMatrixRecordId,
        NetworkAnomalyMatrixRecord
    >()

    newRecords.forEach((record) => {
        mappedData.set(record.id, record)
    })

    updatedRecords.forEach((record) => {
        mappedData.set(record.id, record)
    })

    unchangedRecords.forEach((record) => {
        mappedData.set(record.id, record)
    })

    return {
        ...existingState,
        loading: LoadingState.Loaded,
        errorMessage: '',
        records: mappedData,
    }
}

export default function networkAnomalyMatrixReducer(
    state: NetworkAnomalyMatrixState = DEFAULT_NETWORK_ANOMALY_MATRIX_STATE,
    action: Action | LoggingOutAction,
): NetworkAnomalyMatrixState {
    switch (action.type) {
        case ActionType.REQUEST_DATA:
            return mutateStateForRequestingData(state)

        case ActionType.RECEIVE_DATA:
            return mutateStateForReceivedData(state, action.payload)

        case ActionType.DATA_REQUEST_ERROR:
            return mutateStateForDataRequestError(state, action.payload)

        case SessionActionType.LOGGING_OUT:
            return DEFAULT_NETWORK_ANOMALY_MATRIX_STATE

        default:
            return state
    }
}
