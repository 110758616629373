import {GetMoreButton} from '../../actions/get-more-button'
import {SignInButton} from '../../actions/sign-in-button'
import {ActionsFooter} from '../../actions/actions-footer'
import * as Styled from './row-based-layout.styled'
import {RowBasedLoginLayout} from './row-based-login-layout'
import {AdminProps} from '../../../login-page'

export function LoginPanel(props: AdminProps): JSX.Element {
    return (
        <RowBasedLoginLayout>
            {!props.admin && (
                <Styled.MainActionsGrid useAvailableSpace={true}>
                    <>
                        <SignInButton />
                        <GetMoreButton smallText={true} />
                    </>
                </Styled.MainActionsGrid>
            )}
            <ActionsFooter admin={props.admin} />
        </RowBasedLoginLayout>
    )
}
