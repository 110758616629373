import {MetricType} from '../../../pages/metrics-beta/contexts/types/metrics-response'
import LoadingState from '../../../values/loading-state-enum'

export interface MetricTypesReduxState {
    loading: LoadingState
    dataMetricResponseMap: MetricTypeResponseMap | undefined
    formattedMetricData: FormattedMetricData[]
}

export const DEFAULT_METRIC_TYPES_STATE: MetricTypesReduxState = {
    loading: LoadingState.NotPopulated,
    dataMetricResponseMap: undefined,
    formattedMetricData: new Array<FormattedMetricData>(),
}
export type MetricTypeResponseMap = Map<MetricType, MetricType[]>
export interface FormattedMetricData {
    name: MetricType
    displayName: string
    metricGroup: string
}
