import {LocationIdType} from '../../store/state/locations/state'
import {
    DetailExpandedType,
    DeviceStatus,
    SortColumnType,
    TimestampFilter,
    USBDeviceType,
    USBDevicesSortType,
} from '../../store/state/usb-inventory-filter/state'

export interface UsbInventoryFilterCriteria {
    orderBy: {column: USBDevicesSortType; isAscending: boolean}
    deviceStatuses: DeviceStatus[] | undefined
    fromRelativeFirstSeen: string | null
    fromRelativeLastSeen: string | null
    vendorName: string | null
    productName: string | null
    deviceTypes: USBDeviceType[] | undefined
    hasPurpose: boolean | undefined
    pagination: {pageSize: 10; pageOffset: 0}
}

export const DEFAULT_USB_INVENTORY_FILTER_CRITERIA: UsbInventoryFilterCriteria = {
    orderBy: {
        column: USBDevicesSortType.TIMESTAMP,
        isAscending: false,
    },
    deviceStatuses: undefined,
    fromRelativeFirstSeen: null,
    fromRelativeLastSeen: null,
    vendorName: null,
    productName: null,
    deviceTypes: [],
    hasPurpose: undefined,
    pagination: {pageSize: 10, pageOffset: 0},
}

export interface UsbInventoryFilter {
    selectedUsbDeviceStatus: DeviceStatus[] | undefined
    searchedFirstDetected: TimestampFilter | undefined
    searchedLastActive: TimestampFilter | undefined
    searchedVendor: string | undefined
    searchedProductName: string | undefined
    searchedType: USBDeviceType[] | undefined
    hasPurpose: boolean | undefined
    searchedVessels: Array<LocationIdType> | undefined
    sortColumn: SortColumnType
    searchVesselTagTerm: string[] | undefined
    searchVesselNameTerm: string | undefined
}

export const DEFAULT_USB_INVENTORY_FILTER: UsbInventoryFilter = {
    selectedUsbDeviceStatus: undefined,
    searchedFirstDetected: undefined,
    searchedLastActive: undefined,
    searchedVendor: undefined,
    searchedProductName: undefined,
    searchedType: undefined,
    hasPurpose: undefined,
    sortColumn: {
        orderBy: USBDevicesSortType.TIMESTAMP,
        orderAscending: false,
    },
    searchedVessels: undefined,
    searchVesselTagTerm: [],
    searchVesselNameTerm: '',
}

export interface UsbInventoryFilterForUserPref {
    selectedFilterName: string
    locations: LocationIdType[] | undefined
    searchVesselTagTerm: string[]
    searchVesselNameTerm: string
    detailExpanded: DetailExpandedType
}
