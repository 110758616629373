import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'

interface LeftContainerStyleProps {
    width: number
}

export const LeftContainerStyle = styled.div<LeftContainerStyleProps>`
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: ${spacing(3)};
    justify-content: flex-start;
`
