import {GuidType} from '../../../values/generic-type-defintions'
import {Role} from '../../../values/Role'
import {StrictMode} from '../users/state'

export interface CurrentUserReduxState {
    isFetching: boolean
    currentUser: UserData
}
export interface UserData {
    user: GuidType
    username: string
    email: string
    emailNotificationEnabled: boolean
    active: boolean
    deleted: boolean
    internalUser: boolean
    roles: Role[]
    assignedTag: GuidType | undefined
    strictMode: StrictMode | undefined
    notificationEmail: string | undefined
}
export const DEFAULT_USER_DATA = {
    user: '',
    username: '',
    email: '',
    emailNotificationEnabled: false,
    active: false,
    deleted: false,
    internalUser: false,
    roles: [],
    assignedTag: undefined,
    strictMode: undefined,
    notificationEmail: undefined,
}

export const DEFAULT_CURRENT_USER_STATE: CurrentUserReduxState = {
    isFetching: true,
    currentUser: DEFAULT_USER_DATA,
}
