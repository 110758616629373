import {Action} from './actions'
import ActionType from './action-type'
import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'
import LoadingState from '../../../values/loading-state-enum'
import produce from 'immer'
import {
    DEFAULT_SAVED_FILTERS_STATE,
    SavedFilterPageType,
    SavedIncidentsFilter,
    SavedFiltersReduxState,
    SavedNetworkAssetsFilter,
    SavedUsbInventoryFilter,
    SavedSoftwareInventoryFilter,
    SavedMetricsBetaFilter,
    SavedReportsFilter,
    SavedIncidentsReportFilter,
} from './state'

const savedFiltersReducer = produce(
    (
        draft: SavedFiltersReduxState = DEFAULT_SAVED_FILTERS_STATE,
        action: Action | LoggingOutAction,
    ) => {
        switch (action.type) {
            case ActionType.SET_PAGE_TYPE:
                draft.pageType = action.payload
                break
            case ActionType.REQUEST_SAVED_FILTERS:
                draft.loadingState = LoadingState.RequestingData
                break
            case ActionType.RECEIVE_SAVED_FILTERS:
                draft.loadingState = LoadingState.Loaded
                const newSavedIncidentsFilter = new Array<SavedIncidentsFilter>()
                const newSavedNetworkAssetsFilter = new Array<SavedNetworkAssetsFilter>()
                const newSavedUsbInventoryFilter = new Array<SavedUsbInventoryFilter>()
                const newSavedSoftwareInventoryFilter = new Array<SavedSoftwareInventoryFilter>()
                const newSavedMetricsBetaFilter = new Array<SavedMetricsBetaFilter>()
                const newSavedReportsFilter = new Array<SavedReportsFilter>()
                const newSavedIncidentsReportFilter = new Array<SavedIncidentsReportFilter>()
                action.payload.map((el) => {
                    if (el.page === SavedFilterPageType.INCIDENTS) {
                        newSavedIncidentsFilter.push(el as SavedIncidentsFilter)
                    } else if (el.page === SavedFilterPageType.NETWORK_ASSETS) {
                        newSavedNetworkAssetsFilter.push(el as SavedNetworkAssetsFilter)
                    } else if (el.page === SavedFilterPageType.USB_INVENTORY) {
                        newSavedUsbInventoryFilter.push(el as SavedUsbInventoryFilter)
                    } else if (el.page === SavedFilterPageType.SOFTWARE_INVENTORY) {
                        newSavedSoftwareInventoryFilter.push(el as SavedSoftwareInventoryFilter)
                    } else if (el.page === SavedFilterPageType.METRICS) {
                        newSavedMetricsBetaFilter.push(el as SavedMetricsBetaFilter)
                    } else if (el.page === SavedFilterPageType.REPORTS) {
                        newSavedReportsFilter.push(el as SavedReportsFilter)
                    } else if (el.page === SavedFilterPageType.INCIDENTS_REPORT) {
                        newSavedIncidentsReportFilter.push(el as SavedIncidentsReportFilter)
                    }
                })
                draft.savedIncidentsFilter = newSavedIncidentsFilter
                draft.savedNetworkAssetsFilter = newSavedNetworkAssetsFilter
                draft.savedUsbInventoryFilter = newSavedUsbInventoryFilter
                draft.savedSoftwareInventoryFilter = newSavedSoftwareInventoryFilter
                draft.savedMetricsBetaFilter = newSavedMetricsBetaFilter
                draft.savedReportsFilter = newSavedReportsFilter
                draft.savedIncidentsReportFilter = newSavedIncidentsReportFilter
                break
            case ActionType.RECEIVED_ALL_USER_SUBSCRIPTIONS:
                draft.allUserSubscriptions = action.payload
                break
            case ActionType.RECEIVED_TEMPLATE_DEFINITIONS_SUMMARY:
                draft.templateDefinitions = action.payload
                break
            case ActionType.RECEIVED_REQUESTED_USER_SUBSCRIPTIONS:
                draft.userSubscriptions = action.payload
                break
            case ActionType.OPEN_SAVE_FILTER_POPUP:
                draft.isSaveFilterPopupOpen = action.payload
                break
            case ActionType.SET_RESULT_MESSAGE:
                draft.resultMessage = action.payload
                break
            case ActionType.SET_INACTIVE_COMPONENT:
                draft.isInactive = action.payload
                break
            case ActionType.OPEN_NOTIFY_ME_POPUP:
                draft.isNotifyMePopupopen = action.payload
                break
            case ActionType.SET_CURRENT_APPLIED_SAVED_FILTER:
                draft.currentAppliedSavedFilter = action.payload
                break
            case ActionType.SET_CHANGE_ALERT_SCHEDULE:
                draft.newSubscription.schedule = action.payload
                break
            case ActionType.SET_CHANGE_ALERT_EMAIL:
                draft.newSubscription.notificationEmail = action.payload
                break
            case ActionType.SET_CHANGE_ALERT_PREFIX:
                draft.newSubscription.subjectPrefix = action.payload
                break
            case ActionType.SET_NOTIFY_ON_CHANGE:
                draft.newSubscription.notifyOnChange = action.payload
                break
            case ActionType.RESET_ALERT_CHANGES:
                draft.newSubscription = DEFAULT_SAVED_FILTERS_STATE.newSubscription
                break
            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_SAVED_FILTERS_STATE
                break
            /* istanbul ignore next */
            default:
                break
        }

        return draft
    },
)
export default savedFiltersReducer
