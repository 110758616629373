import * as Styled from './_styled/logo-container.styled'
import {LogoGroup} from '../../../../pages/login-v2/components/header/LogoGroup'

export function LogoContainer(): JSX.Element {
    return (
        <Styled.LeftContainerStyle>
            <Styled.LogoStyle>
                <LogoGroup />
            </Styled.LogoStyle>
        </Styled.LeftContainerStyle>
    )
}
