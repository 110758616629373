import {IndicatorData, IndicatorMap} from '../../../values/IndicatorData'
import LoadingState from '../../../values/loading-state-enum'

export interface IndicatorsReduxState {
    indicatorsLoadingState: LoadingState
    indicators: IndicatorMap
}

export const DEFAULT_INDICATORS_STATE: IndicatorsReduxState = {
    indicatorsLoadingState: LoadingState.NotPopulated,
    indicators: new Map<string, IndicatorData>(),
}
