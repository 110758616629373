import * as Styles from './_styles/nav-bar-link.styled'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {useRef, useState} from 'react'
import {useOnClickOutside} from '../../../../hooks/useOnClickOutside'
import {DropdownMenuWrap} from './_styles/location-dropdown.styled'
import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {LocationDropDownButtonStyle} from './location-dropdown-button/location-dropdown-button.styled'
import {NavBarLink} from './nav-bar-link'
import {useLocation} from 'react-router-dom'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {fleetConfigSelector} from '../../../../store/state/config/selectors'
import {isInternalUserSelector} from '../../../../store/state/current-user/selectors'

export function InventoryLink(): JSX.Element {
    const [inventoryDropDown, setInventoryDropDown] = useState(false)
    const isInternalUser = useTypedSelector(isInternalUserSelector)
    const fleetConfig = useTypedSelector(fleetConfigSelector)

    const {width} = useDimensions()

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const clickOutside = useRef() as any
    useOnClickOutside(clickOutside, () => setInventoryDropDown(false))

    const pathname = useLocation().pathname
    return (
        <Styles.NavigationLinkDiv
            id="navigation-inventory"
            width={width}
            activeLink={
                pathname.startsWith('/network-assets') ||
                pathname.startsWith('/usb-devices') ||
                pathname.startsWith('/software') ||
                pathname.startsWith('/nmea') ||
                pathname.startsWith('/ot-inventory')
            }
            onClick={() => setInventoryDropDown(!inventoryDropDown)}
            ref={clickOutside}
        >
            Inventory
            <DropdownMenuWrap>
                <LocationDropDownButtonStyle id="navigation-inventory-toggle">
                    <Icon
                        glyph={inventoryDropDown ? 'DropdownUp' : 'DropdownDown'}
                        height={15}
                        width={15}
                    />
                </LocationDropDownButtonStyle>
                {inventoryDropDown && (
                    <Styles.DropdownContentStyle width={width}>
                        {(isInternalUser || fleetConfig.networkAssets) && (
                            <NavBarLink
                                id="navigation-network-assets"
                                navLinkText=" Network Assets"
                                path="/network-assets"
                            />
                        )}
                        {(isInternalUser || fleetConfig.usbInventory) && (
                            <NavBarLink
                                id="navigation-usb-devices"
                                navLinkText="USB Devices"
                                path="/usb-devices"
                            />
                        )}
                        {(isInternalUser || fleetConfig.softwareInventory) && (
                            <NavBarLink
                                id="navigation-software"
                                navLinkText="Software"
                                path="/software"
                            />
                        )}
                        {(isInternalUser || fleetConfig.nmeaInventory) && (
                            <NavBarLink id="navigation-nmea" navLinkText="NMEA" path="/nmea" />
                        )}
                        {(isInternalUser ||
                            true) /* TODO: Should be replaced with fleet config when it's ready */ && (
                            <NavBarLink
                                id="navigation-ot-inventory"
                                navLinkText="OT Inventory"
                                path="/ot-inventory-vessels"
                            />
                        )}
                    </Styles.DropdownContentStyle>
                )}
            </DropdownMenuWrap>
        </Styles.NavigationLinkDiv>
    )
}
