import styled from 'styled-components'

export const CssGrid = styled.header`
    display: grid;
    grid-template-columns: 44px 293px;
    column-gap: 33px;
    row-gap: 10px;
`

export const LogoCell = styled.div`
    grid-column: 1;
    grid-row: 1;
`

export const NameCell = styled.div`
    grid-column: 2;
    grid-row: 1;
    align-self: center;
`

export const ByTextCell = styled.div`
    grid-column: 2;
    grid-row: 2;
`

export const CustomLogoCssGrid = styled.header`
    display: grid;
    grid-template-columns: fit-content(100%) 290px;
    column-gap: 30px;
    row-gap: 10px;
`

export const CustomLogoLogoCell = styled.div`
    grid-column: 1;
    grid-row: 1;
    align-self: center;
`

export const CustomLogoNameCell = styled.div`
    grid-column: 2;
    grid-row: 1;
    align-self: center;
    justify-self: end;
`

export const CustomLogoByTextCell = styled.div`
    grid-column: 2;
    grid-row: 2;
    align-self: end;
    justify-self: end;
`
