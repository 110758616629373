import styled from 'styled-components'
import {NavLink} from 'react-router-dom'
import {spacing} from '../../../../../theme/spacing'

const breakPoint1 = 1400
const breakPoint2 = 1200
interface NavigationLinkStyleProps {
    width: number
}

export const NavigationLink = styled(NavLink)<NavigationLinkStyleProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: ${(props) => props.theme.font.weight.bold};
    font-size: ${(props) => (props.width > breakPoint2 ? '16px' : '14px')};
    line-height: ${(props) => (props.width > breakPoint2 ? '22px' : '19px')};
    color: ${(props) => props.theme.navigationResponsive.dataArea.textColor};
    padding-left: ${(props) => (props.width > breakPoint1 ? spacing(5) : spacing(4))};
    padding-right: ${(props) => (props.width > breakPoint1 ? spacing(5) : spacing(4))};
    cursor: pointer;
    transition: all 1s ease;
    border-bottom: 5px solid transparent;
    border-top: 5px solid transparent;
    :hover {
        color: ${(props) => props.theme.navigationResponsive.active.underLine};
        text-decoration: inherit;
        button {
            color: ${(props) => props.theme.navigationResponsive.active.underLine};
        }
    }
    &.${(props) => props.activeClassName} {
        border-bottom: 5px solid ${(props) => props.theme.navigationResponsive.active.underLine};
        color: ${(props) => props.theme.navigationResponsive.active.underLine};
        button {
            color: ${(props) => props.theme.navigationResponsive.active.underLine};
        }
    }
`

export const IndicatorDot = styled.div`
    display: inline-block;
    width: 15px;
    margin-left: ${spacing(1)};
    height: 15px;
    background-color: ${(props) => props.theme.navigationResponsive.active.background};
    border-radius: 50%;
`
interface NavigationDivStyleProps {
    width: number
    activeLink: boolean
}
export const NavigationLinkDiv = styled.div<NavigationDivStyleProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: ${(props) => props.theme.font.weight.bold};
    font-size: ${(props) => (props.width > breakPoint2 ? '16px' : '14px')};
    line-height: ${(props) => (props.width > breakPoint2 ? '22px' : '19px')};
    color: ${(props) => props.theme.navigationResponsive.dataArea.textColor};
    padding-left: ${(props) =>
        props.width > breakPoint1
            ? spacing(8)
            : props.width > breakPoint2
              ? spacing(6)
              : spacing(4)};
    padding-right: ${(props) =>
        props.width > breakPoint1
            ? spacing(8)
            : props.width > breakPoint2
              ? spacing(6)
              : spacing(4)};
    cursor: pointer;
    transition: all 1s ease;
    border-bottom: 5px solid transparent;
    border-top: 5px solid transparent;
    :hover {
        color: ${(props) => props.theme.navigationResponsive.active.underLine};
        text-decoration: inherit;
        button {
            color: ${(props) => props.theme.navigationResponsive.active.underLine};
        }
    }
    ${(props) =>
        props.activeLink &&
        `
        border-bottom: 5px solid ${props.theme.navigationResponsive.active.underLine};
        color: ${props.theme.navigationResponsive.active.underLine};
        button {
            color: ${props.theme.navigationResponsive.active.underLine};
        }`}
`
interface DropdownMenuContentProps {
    width: number
    wide?: boolean
}
export const DropdownContentStyle = styled.div<DropdownMenuContentProps>`
    background-color: ${(props) => props.theme.navigationResponsive.dataArea.background};
    border: none;
    border-radius: 0 0 3px 3px;
    margin-left: ${(props) => (props.width > breakPoint2 ? `${spacing(-23)}` : `${spacing(-18)}`)};
    margin-top: ${(props) => (props.width > breakPoint1 ? `${spacing(4)}` : `${spacing(3.6)}`)};
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    font-weight: ${(props) => props.theme.font.weight.normal};
    position: absolute;
    top: 100%;
    z-index: 1000;
    float: left;
    width: ${(props) => (props.wide ? '300px' : '250px')};
    &:link,
    &:visited,
    &:hover,
    &:active,
    &:focus {
        outline: 0;
        cursor: default;
    }
`
