import {LocationIdType} from '../../store/state/locations/state'

export interface ReportsFilterCriteria {
    selectedReportType: string | undefined
    selectedRating: number
    selectedFromTimeRange: string | undefined
    selectedToTimeRange: string | undefined
    includeWholeFleetReports: boolean
}

export const DEFAULT_NETWORK_ASSETS_FILTER_CRITERIA: ReportsFilterCriteria = {
    selectedReportType: undefined,
    selectedRating: 1,
    selectedFromTimeRange: undefined,
    selectedToTimeRange: undefined,
    includeWholeFleetReports: true,
}

export interface ReportsFilter {
    selectedReportType: string | undefined
    selectedRating: number
    selectedFromTimeRange: string | undefined
    selectedToTimeRange: string | undefined
    includeWholeFleetReports: boolean
    locations: LocationIdType[] | undefined
    searchVesselTagTerm: string[]
    searchVesselNameTerm: string
}

export const DEFAULT_REPORTS_FILTER: ReportsFilter = {
    selectedReportType: undefined,
    selectedRating: 101,
    selectedFromTimeRange: undefined,
    selectedToTimeRange: undefined,
    includeWholeFleetReports: true,
    locations: undefined,
    searchVesselTagTerm: [],
    searchVesselNameTerm: '',
}

export interface ReportsFilterForUserPref {
    selectedFilterName: string
    locations: LocationIdType[] | undefined
    searchVesselTagTerm: string[]
    searchVesselNameTerm: string
}
