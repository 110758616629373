import {GuidType} from '../../../values/generic-type-defintions'
import {LocationIdType} from '../locations/state'
import {ErrorObject} from './action-creators'
import {ApiCreateOrAssign, ApiUpdateTag, VesselTagsAPI} from './helper'

export type LocationTagArrayMapType = Map<LocationIdType, Array<VesselTagsAPI>>
export interface VesselTags {
    identity: GuidType
    name: string
}
export interface VesselTagReduxState {
    isFetchingTags: boolean
    vesselTags: VesselTags[]
    tagsForLocation: LocationTagArrayMapType
    errorResult: ErrorObject | undefined
    displayVesselSettingsForId: LocationIdType | undefined
    tagToCreateOrAssign: ApiCreateOrAssign | null
    tagToUpdate: ApiUpdateTag | null
    searchedTerm: string
}

export const DEFAULT_VESSEL_TAGS_STATE: VesselTagReduxState = {
    isFetchingTags: true,
    vesselTags: new Array<VesselTags>(),
    tagsForLocation: new Map<LocationIdType, Array<VesselTagsAPI>>(),
    errorResult: undefined,
    displayVesselSettingsForId: undefined,
    tagToCreateOrAssign: null,
    tagToUpdate: null,
    searchedTerm: '',
}
