import {NetworkAssetState} from '../../../pages/unknown-assets-v2/context/types/isolated-assets-type'
import {NetworkType} from '../../../pages/unknown-assets-v2/context/types/network-type'
import {GuidType} from '../../../values/generic-type-defintions'
import LoadingState from '../../../values/loading-state-enum'
import {LocationIdType} from '../locations/state'
import {SortValue} from '../vessel-filter/types/vessel-filter-state'

export interface SortColumnType {
    column: NetworkAssetsSortType
    isAscending: boolean
}

export enum NetworkAssetsSortType {
    TIMESTAMP = 'firstSeenTimestamp',
    LATEST_TIMESTAMP = 'lastSeenTimestamp',
    LOCATION = 'location',
    LOCATION_DESCRIPTION = 'locationDescription',
    IP_ADDRESS = 'ipAddress',
    MAC_ADDRESS = 'macAddress',
    MAC_DESCRIPTION = 'macVendor',
    VLAN = 'vlan',
    NETWORK = 'network',
    HOSTNAME = 'hostname',
    ISOLATION_STATE = 'isolationState',
    AIMEE_TIMESTAMP = 'timestamp',
}

export interface FilterExpandedType {
    assetExpanded: boolean
    vesselExpanded: boolean
    networkExpanded: boolean
    behavioursExpanded: boolean
    propertiesExpanded: boolean
}

export enum HeadingRowType {
    FilterBarAssetState = 'Asset Status',
    FilterBarNetworks = 'Networks',
    FilterBarBehaviours = 'Behaviours',
    FilterBarProperties = 'Properties',
    FilterBarVessels = 'Vessels',
}
export enum NetworkAssetsDetailType {
    AssetDetailsState = 'Details',
    AssetInsightsState = 'Insights',
    AssetActionsState = 'Actions',
}
export interface DetailExpandedType {
    detailsExpanded: boolean
    insightsExpanded: boolean
    actionsExpanded: boolean
}

export enum TimestampFilterType {
    LAST_24_H = '-24h',
    LAST_7_DAYS = '-168h',
    LAST_30_DAYS = '-720h',
    MORE_7_DAYS = '-168hd',
    MORE_30_DAYS = '-720hd',
}

export interface NetworkAssetsFilterReduxState {
    loadingState: LoadingState
    searchedIpAddress: string | undefined
    searchedMacDescription: string | undefined
    searchedHostname: string | undefined
    fromRelativeLastSeen: TimestampFilterType | undefined
    properties: string | undefined
    orderBy: SortColumnType
    states: NetworkAssetState[] | undefined
    networks: NetworkType[] | undefined
    behaviours: GuidType[] | undefined
    filterExpanded: FilterExpandedType
    detailExpanded: DetailExpandedType
    locations: Set<LocationIdType> | undefined
    searchVesselTagTerm: string[]
    searchVesselNameTerm: string
    searchVesselTerm: string
    showXButton: boolean
    vesselSort: SortValue
    selectedFilterName: string
}

export const DEFAULT_NETWORK_ASSETS_FILTER_STATE: NetworkAssetsFilterReduxState = {
    loadingState: LoadingState.NotPopulated,
    searchedIpAddress: undefined,
    searchedMacDescription: undefined,
    searchedHostname: undefined,
    fromRelativeLastSeen: undefined,
    properties: undefined,
    orderBy: {
        column: NetworkAssetsSortType.TIMESTAMP,
        isAscending: false,
    },
    states: undefined,
    networks: undefined,
    behaviours: undefined,
    filterExpanded: {
        assetExpanded: true,
        vesselExpanded: true,
        networkExpanded: true,
        behavioursExpanded: true,
        propertiesExpanded: true,
    },
    detailExpanded: {
        detailsExpanded: true,
        insightsExpanded: true,
        actionsExpanded: true,
    },
    locations: undefined,
    searchVesselTagTerm: [],
    searchVesselNameTerm: '',
    searchVesselTerm: '',
    showXButton: false,
    vesselSort: {
        field: 'vessel-name',
        direction: 'desc',
    },
    selectedFilterName: 'Saved filters',
}
