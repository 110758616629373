import LoadingState from '../../../values/loading-state-enum'
import {LocationIdType} from '../locations/state'
import {SortValue} from '../vessel-filter/types/vessel-filter-state'

export interface UsbInventoryFilterReduxState {
    loadingState: LoadingState
    searchedFirstDetected: TimestampFilter
    searchedLastActive: TimestampFilter
    searchedVendor: string | undefined
    searchedProductName: string | undefined
    searchedType: USBDeviceType[]
    searchedHostnames: string | undefined
    hasPurpose: boolean | undefined
    locations: Set<LocationIdType> | undefined
    sortColumn: SortColumnType
    selectedUsbDeviceStatus: DeviceStatus[] | undefined
    searchVesselTagTerm: string[]
    searchVesselNameTerm: string
    searchedVesselTerm: string
    showXButton: boolean
    vesselSort: SortValue
    selectedFilterName: string
    filterExpanded: FilterExpandedType
    detailExpanded: DetailExpandedType
}

export interface SortColumnType {
    orderBy: USBDevicesSortType
    orderAscending: boolean
}

export enum USBDevicesSortType {
    TIMESTAMP = 'firstSeenTimestamp',
    LATEST_TIMESTAMP = 'lastSeenTimestamp',
    VENDOR = 'vendorName',
    PRODUCT_NAME = 'productName',
    DEVICE_ID = 'deviceId',
    TYPE = 'deviceType',
    NUMBER_OF_HOSTS_PER_ITEM = 'numberOfHostsPerItem',
    NUMBER_OF_VESSELS_PER_ITEM = 'numberOfVesselsPerItem',
}

export enum DeviceStatus {
    unknown = 'unknown',
    trusted = 'trusted',
    rejected = 'rejected',
}

export type USBDeviceType = 'storage' | 'other'

export type TimestampFilter = 'Last 24h' | 'Last 7 days' | 'Last 30 days' | 'All'
export interface DetailExpandedType {
    detailsExpanded: boolean
    insightsExpanded: boolean
    actionsExpanded: boolean
}

export enum UsbDevicesDetailType {
    Details = 'Details',
    Insights = 'Insights',
    Actions = 'Actions',
}
export interface FilterExpandedType {
    deviceStatus: boolean
    vesselExpanded: boolean
}

export enum FilterBarType {
    UsbDeviceStatus = 'USB device status',
    Vessels = 'Vessels',
}

export const DEFAULT_USB_INVENTORY_FILTER_STATE: UsbInventoryFilterReduxState = {
    loadingState: LoadingState.NotPopulated,
    searchedFirstDetected: 'All',
    searchedLastActive: 'All',
    searchedVendor: '',
    searchedProductName: '',
    searchedType: ['storage', 'other'],
    searchedHostnames: '',
    locations: undefined,
    sortColumn: {
        orderBy: USBDevicesSortType.TIMESTAMP,
        orderAscending: false,
    },
    selectedUsbDeviceStatus: undefined,
    searchVesselTagTerm: [],
    searchVesselNameTerm: '',
    searchedVesselTerm: '',
    hasPurpose: undefined,
    showXButton: false,
    vesselSort: {
        field: 'vessel-name',
        direction: 'desc',
    },
    selectedFilterName: 'Saved filters',
    filterExpanded: {deviceStatus: true, vesselExpanded: true},
    detailExpanded: {
        detailsExpanded: true,
        insightsExpanded: true,
        actionsExpanded: true,
    },
}
