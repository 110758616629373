import LoadingState from '../../../../values/loading-state-enum'
import {VesselFiltersState, ZOOM_LEVEL} from './my-vessel-filter-state'

export const DEFAULT_MY_VESSELS_FILTERS_STATE: VesselFiltersState = {
    loadingState: LoadingState.NotPopulated,
    HIGH: {
        low: true,
        medium: true,
        high: true,
    },
    MEDIUM: {
        low: true,
        medium: true,
        high: true,
    },
    LOW: {
        low: true,
        medium: true,
        high: true,
    },
    locations: undefined,
    searchAssetTerm: '',
    searchVesselTagTerm: [],
    searchVesselNameTerm: '',
    searchVesselTerm: '',
    showXButton: false,
    assetSort: {
        field: 'asset-value',
        direction: 'desc',
    },
    vesselSort: {
        field: 'vessel-name',
        direction: 'desc',
    },
    zoomLevel: ZOOM_LEVEL.ONE,
}
