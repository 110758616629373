import {ActionType} from './action-type'
import {
    DEFAULT_TAGS_COMPONENT_FILTERS,
    SortColumnType,
    TagsComponentFilter,
} from '../types/tags-component-filter'
import {Dispatch} from 'react'
import {REST} from '../../../../../../index'
import * as Actions from './actions'
import {TIME_RANGE_VALUES} from '../../../../../../values/TimeRangeValues'
import {VesselTags} from '../../../../../../store/state/vessel-tags/state'

const USER_PREFS_URL = '/api/v1/userPreferences/tags_component'

export function fetchTagsComponentFilter(
    dispatch: Dispatch<Actions.AllActions>,
    assignedTagName: string | undefined,
): void {
    dispatch(requestFilter())

    REST.get(USER_PREFS_URL).then(
        (response) => {
            dispatch(
                receivePrefsFilter({
                    ...DEFAULT_TAGS_COMPONENT_FILTERS,
                    ...response.data.value,
                    searchVesselTagTerm: response.data.value.searchVesselTagTerm.includes(
                        assignedTagName,
                    )
                        ? [...response.data.value.searchVesselTagTerm]
                        : assignedTagName
                          ? [...response.data.value.searchVesselTagTerm, assignedTagName]
                          : [...response.data.value.searchVesselTagTerm],
                }),
            )
        },
        () => {
            dispatch(
                receivePrefsFilter({
                    ...DEFAULT_TAGS_COMPONENT_FILTERS,
                    searchVesselTagTerm: assignedTagName ? [assignedTagName] : [],
                }),
            )
        },
    )
}

export function setSortColumn(
    sortColumn: SortColumnType,
    dispatch: Dispatch<Actions.SetSortColumnAction>,
    currentFilter: TagsComponentFilter,
): void {
    dispatch({
        type: ActionType.SET_SORT_COLUMN,
        payload: sortColumn,
    })
    REST.put(USER_PREFS_URL, {...currentFilter, sortColumn: sortColumn})
}

export function setTimeRange(
    timeRange: TIME_RANGE_VALUES,
    dispatch: Dispatch<Actions.RequestTimeRange>,
    currentFilter: TagsComponentFilter,
): void {
    dispatch({
        type: ActionType.SET_TIME_RANGE,
        payload: timeRange,
    })
    REST.put(USER_PREFS_URL, {...currentFilter, timeRange: timeRange})
}

function receivePrefsFilter(
    receivedTagsComponentFiler: TagsComponentFilter,
): Actions.ReceivePrefsFilterAction {
    return {
        type: ActionType.RECEIVE_PREFS_FILTER,
        payload: receivedTagsComponentFiler,
    }
}

function requestFilter(): Actions.RequestFilterAction {
    return {
        type: ActionType.REQUEST_FILTER,
    }
}

export function showFilter(show = false): Actions.ShowFilterAction {
    return {
        type: ActionType.SHOW_FILTER,
        payload: show,
    }
}

export function setSearchVesselTagTerm(
    searchedVesselTagTerm: string[],
    dispatch: Dispatch<Actions.SetSearchVesselTagTermAction>,
    currentFilter: TagsComponentFilter,
): void {
    dispatch({
        type: ActionType.SET_SEARCH_VESSEL_TAG_TERM,
        payload: searchedVesselTagTerm,
    })
    REST.put(USER_PREFS_URL, {...currentFilter, searchVesselTagTerm: searchedVesselTagTerm})
}

export function setSearchVesselTerm(
    searchTerm: string,
    dispatch: Dispatch<Actions.SetSearchVesselTermAction>,
): void {
    dispatch({
        type: ActionType.SET_SEARCH_VESSEL_TERM,
        payload: searchTerm,
    })
}

export function toggleVesselTag(
    allTags: VesselTags[],
    tag: string,
    newValue: boolean,
    dispatch: Dispatch<Actions.ToggleVesselTagAction>,
    currentFilter: TagsComponentFilter,
): void {
    dispatch({
        type: ActionType.TOGGLE_VESSEL_TAG,
        payload: {
            allTags,
            tag,
            newValue,
        },
    })
    const searchedVesselTagTerm = newValue
        ? currentFilter.searchVesselTagTerm.length === 0
            ? new Array(tag)
            : [...currentFilter.searchVesselTagTerm, tag]
        : currentFilter.searchVesselTagTerm.filter((tagTerm) => tagTerm !== tag)
    REST.put(USER_PREFS_URL, {...currentFilter, searchVesselTagTerm: searchedVesselTagTerm})
}
