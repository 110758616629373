import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import * as Styles from './_styles/nav-bar-link.styled'

interface NavBarLinkProps {
    id: string
    path: string
    navLinkText: string
    showIndicator?: boolean
}

export function NavBarLink({id, path, navLinkText, showIndicator}: NavBarLinkProps): JSX.Element {
    const {width} = useDimensions()

    return (
        <Styles.NavigationLink id={id} to={path} activeClassName="activeLink" width={width}>
            {navLinkText}
            {showIndicator && <Styles.IndicatorDot />}
        </Styles.NavigationLink>
    )
}
