enum ActionType {
    REQUEST_ALL_LOCATION_TAGS_BY_LOCATION = '[VESSEL_TAGS] REQUEST ALL LOCATION TAGS BY LOCATION',
    RECEIVE_ALL_LOCATION_TAGS_BY_LOCATION = '[VESSEL_TAGS] RECEIVE ALL LOCATION TAGS BY LOCATION',
    RECEIVE_ALL_TAGS = '[VESSEL_TAGS] RECEIVE ALL TAGS',
    SET_ERROR_RESULT = '[VESSEL_TAGS] SET_ERROR_RESULT',
    DISPLAY_VESSEL_SETTINGS_FOR_ID = '[VESSEL_TAG] DISPLAY VESSEL SETTINGS FOR ID',
    SET_TAG_TO_CREATE_OR_ASSIGN = '[VESSEL_TAGS] SET TAG TO CREATE OR ASSIGN',
    SET_TAG_TO_UPDATE = '[VESSEL_TAGS] SET TAG TO UPDATE',
    SET_SEARCH_TERM = '[VESSEL_TAGS] SET SEARCH TERM',
}

export default ActionType
