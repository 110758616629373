import styled from 'styled-components'
import {MAX_SCREEN_WIDTH} from '../../theme/theme'

interface Props {
    showingSelectedLocation: boolean
}

export const ScrollablePage = styled.div<Props>`
    background-color: ${(props) =>
        props.showingSelectedLocation
            ? props.theme.colors.background.selectedLocationPage
            : props.theme.colors.background.page};
`

export interface ScrollablePageContentProps {
    centered?: boolean
}

export const ScrollablePageContent = styled.div<ScrollablePageContentProps>`
    max-width: ${MAX_SCREEN_WIDTH}px;
    padding: 3.75rem 0 0 0;
    margin: ${(props) => (props.centered ? '0 auto' : '0')};
`
