import {Action} from './actions'
import ActionType from './action-type'
import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'
import LoadingState from '../../../values/loading-state-enum'
import {isEqual} from 'lodash'
import produce from 'immer'
import {
    DEFAULT_METRICS_BETA_FILTER_STATE,
    MetricsBetaFilterReduxState,
    MetricsSortType,
} from './state'
import {LocationIdType} from '../locations/state'
import {MetricType} from '../../../pages/metrics-beta/contexts/types/metrics-response'
import {NodeValue, NodeValues} from '../../../values/nodes/NodeData'

export function populateLocations(
    locations: string | LocationIdType[] | undefined,
): Set<LocationIdType> | undefined {
    if (!locations || locations === 'all-selected') {
        return undefined
    } else if (Array.isArray(locations) && locations.length === 0) {
        return undefined
    } else if (locations === 'all-deselected') {
        return new Set<LocationIdType>()
    } else if (Array.isArray(locations) && locations.length !== 0) {
        return new Set<LocationIdType>(locations)
    }
    return undefined
}

const metricsBetaFilterReducer = produce(
    (
        draft: MetricsBetaFilterReduxState = DEFAULT_METRICS_BETA_FILTER_STATE,
        action: Action | LoggingOutAction,
    ) => {
        switch (action.type) {
            case ActionType.REQUEST_FILTER:
                draft.loadingState = LoadingState.RequestingData
                break
            case ActionType.RECEIVE_FILTER:
                draft.loadingState = LoadingState.Loaded
                draft.selectedFilterName = action.payload.selectedFilterName
                if (!isEqual(draft.locations, action.payload.locations)) {
                    draft.locations = populateLocations(action.payload.locations)
                }
                draft.searchVesselTagTerm = action.payload.searchVesselTagTerm
                draft.searchVesselNameTerm = action.payload.searchVesselNameTerm
                break

            case ActionType.SET_SORT_COLUMN:
                if (!isEqual(draft.orderBy, action.payload)) {
                    draft.orderBy = action.payload
                }
                break

            case ActionType.TOGGLE_METRIC_TYPE:
                const {allMetricType, selectedMetricType, metricTypeNewValue} = action.payload
                if (draft.metricTypes == undefined) {
                    draft.metricTypes = [...allMetricType]
                }
                if (metricTypeNewValue) {
                    draft.metricTypes = [...draft.metricTypes, selectedMetricType]
                } else {
                    draft.metricTypes.splice(draft.metricTypes.indexOf(selectedMetricType), 1)
                }
                if (draft.metricTypes.length === allMetricType.length) {
                    draft.metricTypes = undefined
                }
                break
            case ActionType.TOGGLE_MAIN_METRICS_TYPE:
                const {allMainMetricsType, selectedMainMetricsType, toggleMainMetrics} =
                    action.payload
                if (draft.metricTypes == undefined) {
                    draft.metricTypes = [...allMainMetricsType]
                }
                if (toggleMainMetrics) {
                    const formattedMetrics: MetricType[] = [
                        ...draft.metricTypes,
                        ...selectedMainMetricsType,
                    ]
                    draft.metricTypes = [...new Set(formattedMetrics)]
                } else {
                    draft.metricTypes = [
                        ...draft.metricTypes.filter(
                            (item) => !selectedMainMetricsType.includes(item),
                        ),
                    ]
                }
                break
            case ActionType.FILTER_MAIN_FRAMEWORK_CARD:
                draft.metricTypes = action.payload
                break
            case ActionType.TOGGLE_ALL_METRIC_TYPE:
                draft.metricTypes = action.payload ? undefined : new Array<MetricType>()
                break
            case ActionType.TOGGLE_ALL_ASSETS:
                draft.assetValues = action.payload ? undefined : new Array<NodeValue>()
                break
            case ActionType.TOGGLE_VALUE_THRESHOLD:
                const allAssetValues = [NodeValues.LOW, NodeValues.MEDIUM, NodeValues.HIGH]
                const {selectedNodeValue, selectedOption} = action.payload
                if (draft.assetValues == undefined) {
                    draft.assetValues = allAssetValues
                }
                if (selectedOption) {
                    const outputAssets = [...draft.assetValues, selectedNodeValue]
                    draft.assetValues = [...new Set(outputAssets as NodeValue[])]
                } else {
                    draft.assetValues = [
                        ...draft.assetValues.filter((item) => selectedNodeValue !== item),
                    ]
                }
                break
            case ActionType.SET_ANALYSIS_PERIOD:
                draft.selectedAnalysisPeriod = action.payload
                break
            case ActionType.SET_ANALYSIS_TYPES:
                draft.analysisTypes = action.payload
                break
            case ActionType.TOGGLE_ASSET_WITH_ISSUE:
                draft.onlyAssetsWithIssues = action.payload
                if (draft.orderBy.column === MetricsSortType.TIME_WITH_CONTROL_ISSUE) {
                    draft.orderBy = DEFAULT_METRICS_BETA_FILTER_STATE.orderBy
                }
                break

            case ActionType.TOGGLE_FILTER_EXPANDED:
                if (!isEqual(draft.filterExpanded, action.payload)) {
                    draft.filterExpanded = action.payload
                }
                break
            case ActionType.SET_SELECTED_VIEW_SCREEN_DROPDOWN:
                draft.selectedViewScreenType = action.payload
                break
            case ActionType.RESET_FILTER:
                draft.loadingState = LoadingState.Loaded
                draft.orderBy = DEFAULT_METRICS_BETA_FILTER_STATE.orderBy
                draft.metricTypes = DEFAULT_METRICS_BETA_FILTER_STATE.metricTypes
                draft.selectedAnalysisPeriod =
                    DEFAULT_METRICS_BETA_FILTER_STATE.selectedAnalysisPeriod
                draft.onlyAssetsWithIssues = DEFAULT_METRICS_BETA_FILTER_STATE.onlyAssetsWithIssues
                draft.locations = DEFAULT_METRICS_BETA_FILTER_STATE.locations
                draft.searchVesselTagTerm = DEFAULT_METRICS_BETA_FILTER_STATE.searchVesselTagTerm
                draft.searchVesselNameTerm = DEFAULT_METRICS_BETA_FILTER_STATE.searchVesselNameTerm
                draft.selectedFilterName = DEFAULT_METRICS_BETA_FILTER_STATE.selectedFilterName
                draft.analysisTypes = DEFAULT_METRICS_BETA_FILTER_STATE.analysisTypes
                draft.selectedViewScreenType =
                    DEFAULT_METRICS_BETA_FILTER_STATE.selectedViewScreenType
                draft.assetValues = DEFAULT_METRICS_BETA_FILTER_STATE.assetValues
                draft.benchmarkType = DEFAULT_METRICS_BETA_FILTER_STATE.benchmarkType
                break

            case ActionType.BULK_ASSIGN_SAVED_FILTER:
                draft.loadingState = LoadingState.Loaded
                draft.orderBy =
                    action.payload.criteria.orderBy.column === MetricsSortType.METRIC
                        ? DEFAULT_METRICS_BETA_FILTER_STATE.orderBy
                        : action.payload.criteria.orderBy
                draft.metricTypes = action.payload.criteria.metricTypes
                draft.selectedAnalysisPeriod = action.payload.criteria.selectedAnalysisPeriod
                draft.onlyAssetsWithIssues = action.payload.criteria.onlyAssetsWithIssues
                draft.locations = populateLocations(action.payload.criteria.locations)
                draft.searchVesselTagTerm = action.payload.criteria.searchVesselTagTerm
                draft.selectedFilterName = action.payload.name
                draft.selectedViewScreenType = action.payload.criteria.selectedViewScreenType
                draft.analysisTypes = action.payload.criteria.analysisTypes
                draft.filterExpanded = action.payload.criteria.filterExpanded
                draft.assetValues = action.payload.criteria.assetValues
                break

            case ActionType.SET_SELECTED_FILTER_NAME:
                draft.selectedFilterName = action.payload ?? ''
                break
            case ActionType.SET_TRENDS_BENCHMARK_TYPE:
                draft.benchmarkType = action.payload
                break
            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_METRICS_BETA_FILTER_STATE
                break
            /* istanbul ignore next */
            default:
                break
        }

        return draft
    },
)
export default metricsBetaFilterReducer
