enum ActionType {
    DISPLAY_MODAL = '[UNSAVED_INCIDENT_NOTE_ALERT_MODAL] DISPLAY MODAL',
    CLOSE_MODAL = '[UNSAVED_INCIDENT_NOTE_ALERT_MODAL] CLOSE MODAL',
    REVIEW_NOTE = '[UNSAVED_INCIDENT_NOTE_ALERT_MODAL] REVIEW_NOTE',
    PROCESSING_EDIT_NOTE = '[UNSAVED_INCIDENT_NOTE_ALERT_MODAL] PROCESSING_EDIT_NOTE',
    SAVE_EDIT_NOTE = '[UNSAVED_INCIDENT_NOTE_ALERT_MODAL] SAVE_EDIT_NOTE',
    PROCESSING_NEW_NOTE = '[UNSAVED_INCIDENT_NOTE_ALERT_MODAL] PROCESSING_NEW_NOTE',
    SAVE_NEW_NOTE = '[UNSAVED_INCIDENT_NOTE_ALERT_MODAL] SAVE_NEW_NOTE',
    SET_PENDING_NAVIGATION = '[UNSAVED_INCIDENT_NOTE_ALERT_MODAL] SET_PENDING_NAVIGATION',
}

export default ActionType
