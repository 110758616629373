enum ActionType {
    REQUEST_FILTER = '[SOFTWARE INVENTORY FILTER] REQUEST FILTER',
    RECEIVE_FILTER = '[SOFTWARE INVENTORY FILTER] RECEIVE FILTER',
    SET_SORT_COLUMN = '[SOFTWARE INVENTORY FILTER] SET SORT COLUMN',
    SET_SEARCHED_FIRST_SEEN = '[SOFTWARE INVENTORY FILTER] SET SEARCHED FIRST SEEN',
    SET_SEARCHED_LAST_ACTIVE = '[SOFTWARE INVENTORY FILTER] SET SEARCHED LAST ACTIVE',
    SET_SEARCHED_COMPANY_NAME = '[SOFTWARE INVENTORY FILTER] SET SEARCHED COMPANY NAME',
    SET_SEARCHED_PRODUCT_NAME = '[SOFTWARE INVENTORY FILTER] SET SEARCHED PRODUCT NAME',
    TOGGLE_FILTER_EXPANDED = '[SOFTWARE INVENTORY FILTER] TOGGLE FILTER EXPANDED',
    RESET_FILTER = '[SOFTWARE INVENTORY FILTER] RESET FILTER',
    BULK_ASSIGN_SAVED_FILTER = '[SOFTWARE INVENTORY FILTER] BULK ASSIGN SAVED FILTER',
    SET_SELECTED_FILTER_NAME = '[SOFTWARE INVENTORY FILTER] SET SELECTED FILTER NAME',
    TOGGLE_ALL_SOFTWARE_STATUSES = '[SOFTWARE INVENTORY FILTER] TOGGLE ALL FILTER STATUSES',
    TOGGLE_SOFTWARE_STATUS = '[SOFTWARE INVENTORY FILTER] TOGGLE FILTER STATUSES',
    TOGGLE_DETAIL_EXPANDED = '[SOFTWARE INVENTORY FILTER] TOGGLE DETAIL EXPANDED',
}

export default ActionType
