import * as Styled from './_styled/hamburger-button.styled'
import {Menu, X} from 'react-feather'
import {useToggle} from '../../../../contexts/toggle/use-toggle'

export function HamburgerButton(): JSX.Element {
    const {value: isOpen, setValue: setOpen} = useToggle()
    return (
        <Styled.RightContainerStyle onClick={() => setOpen(!isOpen)}>
            <Styled.ButtonStyle id="hamburger_button">
                {isOpen ? <X /> : <Menu />}
            </Styled.ButtonStyle>
        </Styled.RightContainerStyle>
    )
}
