import ActionType from './action-type'
import {Action} from './actions'

import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'

import {DEFAULT_WATCH_STATE, WatchReduxState} from './state'

function updateWatchedNodes(
    currentState: WatchReduxState,
    receivedData: string[],
): WatchReduxState {
    const newNodes: string[] = []
    const unchangedNodes: string[] = []

    receivedData.forEach((node) => {
        const existingNode =
            currentState && currentState.nodes && currentState.nodes.indexOf(node) !== -1
        if (!existingNode) {
            newNodes.push(node)
        } else {
            unchangedNodes.push(node)
        }
    })

    const noNewNodes = newNodes.length === 0
    const noNodesToRemove =
        currentState.nodes && unchangedNodes.length === currentState.nodes.length

    if (noNewNodes && noNodesToRemove) {
        if (!currentState.loading && currentState.error === '') {
            return currentState
        }

        return {
            ...currentState,
            loading: false,
            error: '',
        }
    }

    const updatedNodes: string[] = []

    newNodes.forEach((node) => {
        updatedNodes.push(node)
    })

    unchangedNodes.forEach((node) => {
        updatedNodes.push(node)
    })

    return {
        ...currentState,
        loading: false,
        error: '',
        nodes: updatedNodes,
    }
}

function setLoading(currentState: WatchReduxState): WatchReduxState {
    if (currentState.loading && currentState.error === '') {
        return currentState
    }

    return {
        ...currentState,
        loading: true,
        error: '',
    }
}

function setError(currentState: WatchReduxState, newError: string): WatchReduxState {
    if (!currentState.loading && currentState.error === newError) {
        return currentState
    }

    return {
        ...currentState,
        loading: false,
        error: newError,
    }
}

function logout(currentState: WatchReduxState): WatchReduxState {
    if (
        currentState.error === '' &&
        currentState.loading === true &&
        currentState.nodes &&
        currentState.nodes.length === 0
    ) {
        return currentState
    }

    return DEFAULT_WATCH_STATE
}

export default function watchedNodesReducer(
    state: WatchReduxState = DEFAULT_WATCH_STATE,
    action: Action | LoggingOutAction,
): WatchReduxState {
    switch (action.type) {
        case ActionType.SET_WATCHED_NODES:
            return updateWatchedNodes(state, action.payload)

        case ActionType.SET_LOADING:
            return setLoading(state)

        case ActionType.SET_ERROR:
            return setError(state, action.payload)

        case SessionActionType.LOGGING_OUT:
            return logout(state)

        /* istanbul ignore next */
        default:
            return state
    }
}
