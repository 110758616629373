import ActionType from './action-type'
import * as Actions from './actions'
import * as uuid from 'uuid'
import {ThunkAction} from 'redux-thunk'

import {Api, QuerySpecification} from '../../../api/Api'
import {QueryCancellation} from '../../../websocket/Queries'
import AppState from '../../types/app-state'
import {LatestLocationThreatScores} from './state'

const QUERY_PATH = 'latestLocationThreatScores'
const QUERY_SCHEDULE = '15s'

function latestLocationThreatScoresQuery(): QuerySpecification {
    return {
        path: QUERY_PATH,
        localId: `${QUERY_PATH}-${uuid.v4()}`,
        schedule: QUERY_SCHEDULE,
    }
}

export function registerLatestLocationThreatScoresQuery(): ThunkAction<
    void,
    AppState,
    Api,
    Actions.Action
> {
    return (dispatch, _, api: Api): QueryCancellation => {
        dispatch(requestLatestLocationThreatScores())

        const query: QuerySpecification = latestLocationThreatScoresQuery()

        api.newQuery(query, (data) => {
            dispatch(receiveLatestLocationThreatScores((data as LatestLocationThreatScores) ?? {}))
        })

        return () => {
            api.cancelQuery({localId: query.localId})
        }
    }
}

function requestLatestLocationThreatScores(): Actions.RequestLatestLocationThreatScoresAction {
    return {
        type: ActionType.REQUEST_LATEST_LOCATION_THREAT_SCORES,
    }
}

function receiveLatestLocationThreatScores(
    latestLocationThreatScores: LatestLocationThreatScores,
): Actions.ReceiveLatestLocationThreatScoresAction {
    return {
        type: ActionType.RECEIVE_LATEST_LOCATION_THREAT_SCORES,
        payload: latestLocationThreatScores,
    }
}
