import {UseFleetScoreOverTimeOutput} from './use-fleet-score-over-time-output'
import {useContext} from 'react'
import {fleetScoreOverTimeContext} from './fleet-score-over-time-context'
import {warn} from '../../../../../helpers/logging'
import * as ActionCreators from './state/action-creators'
import {VesselTags} from '../../../../../store/state/vessel-tags/state'

export function useFleetScoreOverTime(): UseFleetScoreOverTimeOutput {
    const {state, dispatch} = useContext(fleetScoreOverTimeContext)
    if (state == undefined || dispatch == undefined) {
        throw new Error('useFleetScoreOverTime must be used within a FleetScoreOverTimeContext')
    }

    function fetchFilter(assignedTag: VesselTags | undefined): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        ActionCreators.fetchFleetScoreOverTimeFilter(dispatch, assignedTag)
    }

    function setShowFilter(show = false): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.showFilter(show))
    }

    function setSearchVesselTagTerm(value: VesselTags | undefined): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        ActionCreators.setSearchVesselTagTerm(value, dispatch, state.fleetScoreOverTimeFilter)
    }

    return {
        loadingFilterState: state.loadingFilterState,
        fetchFilter,
        showFilter: state.showFilter,
        setShowFilter,
        searchedVesselTagTerm: state.fleetScoreOverTimeFilter.searchedVesselTagTerm,
        setSearchVesselTagTerm,
    }
}
