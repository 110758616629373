import {IncidentType} from '../../../pages/incidents-v3/contexts/types/incident-type'
import LoadingState from '../../../values/loading-state-enum'

export interface IncidentTypesReduxState {
    loading: LoadingState
    data: IncidentType[]
}

export const DEFAULT_INCIDENT_TYPES_STATE: IncidentTypesReduxState = {
    loading: LoadingState.NotPopulated,
    data: [],
}
