import {Location, LocationMap, Network} from './state'
import AppState from '../../types/app-state'
import {Moment} from 'moment'

export const locationsLoadingSelector = (state: AppState): boolean => state.locations.isFetching

export const locationsSelector = (state: AppState): Location[] => state.locations.locations

export const locationMapSelector = (state: AppState): LocationMap => state.locations.locationMap

export const activeLocationSelector = (state: AppState): Location => state.locations.activeLocation

export const lastUpdatedSelector = (state: AppState): Moment | null => state.locations.lastUpdate

export const hasLocationsSelector = (state: AppState): boolean =>
    state.locations.locations?.length > 0 || false
export const networkConfigurationSelector = (state: AppState): Network | undefined =>
    state.locations.updatedNetworkDataObject

export const vesselDashboardAgentIdsSelector = (state: AppState): string[] | undefined =>
    state.locations.updatedvesselDashboardAgentIds

export const updatedEmailSelector = (state: AppState): string | null | undefined =>
    state.locations.updatedEmailLocation
