import {oauthClientIdsSelector} from '../../../../store/state/config/selectors'
import useTypedSelector from '../../../../hooks/use-typed-selector'

export function AdminSignInButtonGFC(): JSX.Element | null {
    const oauthClientIds = useTypedSelector(oauthClientIdsSelector)
    const isLoginEnabled = oauthClientIds != undefined

    if (!isLoginEnabled || !oauthClientIds || !oauthClientIds.google) {
        return null
    }

    return <div />
}
