import * as Actions from './actions'
import ActionType from './action-type'
import {Api} from '../../../api/Api'
import {REST} from '../../../index'
import {ThunkAction} from 'redux-thunk'
import AppState from '../../types/app-state'
import {
    DEFAULT_NETWORK_ASSETS_FILTER_STATE,
    DetailExpandedType,
    FilterExpandedType,
    SortColumnType,
    TimestampFilterType,
} from './state'
import {NetworkAssetState} from '../../../pages/unknown-assets-v2/context/types/isolated-assets-type'
import {NetworkType} from '../../../pages/unknown-assets-v2/context/types/network-type'
import {
    DEFAULT_NETWORK_ASSETS_FILTER,
    NetworkAssetsFilter,
} from '../../../values/user-preferences/network-assets-filter'
import {SavedNetworkAssetsFilter} from '../saved-filters/state'
import {formattedLocationsBlockSet, populateLocationsForFilterCriteria} from '../../../utils/Utils'
import {AnyAction} from 'redux'
import {setCurrentAppliedSavedFilter} from '../saved-filters/action-creators'
import {formatNetworkLabel} from '../../../pages/unknown-assets-v2/context/helpers'
import {GuidType} from '../../../values/generic-type-defintions'

const USER_PREFS_URL = '/api/v1/userPreferences/unknown_assets_settings_v2'

export const fetchNetworkAssetsFilter = (
    savedFilters: SavedNetworkAssetsFilter[],
    isDefaultTagUser?: boolean,
): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch) => {
        dispatch(requestFilter())
        if (isDefaultTagUser === true) {
            REST.get(USER_PREFS_URL).then(
                (response) => {
                    REST.get(`${USER_PREFS_URL}.default_tag`).then(
                        (res) => {
                            if (response.data.value.selectedFilterName === undefined) {
                                dispatch(
                                    bulkAssignSavedFilter(
                                        {
                                            ...DEFAULT_NETWORK_ASSETS_FILTER,
                                            selectedVessels: formattedLocationsBlockSet(
                                                response.data.value.locations,
                                            ),
                                            searchVesselTagTerm: res.data.value.default_tag ?? [],
                                            searchVesselNameTerm:
                                                response.data.value.searchVesselNameTerm ?? '',
                                        },
                                        'Saved filters',
                                        response.data.value.detailExpanded,
                                        true,
                                    ),
                                )
                            } else {
                                const savedFilter =
                                    savedFilters &&
                                    savedFilters.find(
                                        (filter) =>
                                            filter.name === response.data.value.selectedFilterName,
                                    )

                                savedFilter
                                    ? dispatch(
                                          bulkAssignSavedFilter(
                                              {
                                                  ...(savedFilter.criteria as NetworkAssetsFilter),
                                                  networks: savedFilter.criteria.networks?.map(
                                                      (network) => formatNetworkLabel(network),
                                                  ),
                                                  behaviours: savedFilter.criteria.behaviours,
                                                  selectedVessels: formattedLocationsBlockSet(
                                                      response.data.value.locations,
                                                  ),
                                                  searchVesselTagTerm: res.data.value.default_tag,
                                                  searchVesselNameTerm:
                                                      response.data.value.searchVesselNameTerm,
                                              },
                                              savedFilter.name,
                                              response.data.value.detailExpanded,
                                              true,
                                              savedFilter,
                                          ),
                                      )
                                    : dispatch(
                                          bulkAssignSavedFilter(
                                              {
                                                  ...DEFAULT_NETWORK_ASSETS_FILTER,
                                                  selectedVessels: formattedLocationsBlockSet(
                                                      response.data.value.locations,
                                                  ),
                                                  searchVesselTagTerm: res.data.value.default_tag,
                                                  searchVesselNameTerm:
                                                      response.data.value.searchVesselNameTerm,
                                              },
                                              'Saved filters',
                                              response.data.value.detailExpanded,
                                              true,
                                          ),
                                      )
                            }
                            REST.delete(`${USER_PREFS_URL}.default_tag`)
                        },
                        () => {
                            dispatch(
                                bulkAssignSavedFilter(
                                    {
                                        ...DEFAULT_NETWORK_ASSETS_FILTER,
                                        selectedVessels: formattedLocationsBlockSet(
                                            response.data.value.locations,
                                        ),
                                        searchVesselTagTerm:
                                            response.data.value.searchVesselTagTerm,
                                        searchVesselNameTerm:
                                            response.data.value.searchVesselNameTerm,
                                    },
                                    'Saved filters',
                                    response.data.value.detailExpanded,
                                ),
                            )
                        },
                    )
                },
                () => {
                    REST.get(`${USER_PREFS_URL}.default_tag`).then((res) => {
                        REST.put(USER_PREFS_URL, {
                            ...DEFAULT_NETWORK_ASSETS_FILTER_STATE,
                            searchVesselTagTerm: res.data.value.default_tag,
                        })
                        dispatch(
                            bulkAssignSavedFilter(
                                {
                                    ...DEFAULT_NETWORK_ASSETS_FILTER,
                                    searchVesselTagTerm: res.data.value.default_tag,
                                },
                                'Saved filters',
                                DEFAULT_NETWORK_ASSETS_FILTER.detailExpanded,
                            ),
                        )
                    })
                },
            )
        } else {
            REST.get(USER_PREFS_URL).then(
                (response) => {
                    if (response.data.value.selectedFilterName === undefined) {
                        dispatch(
                            bulkAssignSavedFilter(
                                {
                                    ...DEFAULT_NETWORK_ASSETS_FILTER,
                                    selectedVessels: formattedLocationsBlockSet(
                                        response.data.value.locations,
                                    ),
                                    searchVesselTagTerm:
                                        response.data.value.searchVesselTagTerm ?? [],
                                    searchVesselNameTerm:
                                        response.data.value.searchVesselNameTerm ?? '',
                                },
                                'Saved filters',
                                response.data.value.detailExpanded,
                            ),
                        )
                    } else {
                        const savedFilter =
                            savedFilters &&
                            savedFilters.find(
                                (filter) => filter.name === response.data.value.selectedFilterName,
                            )

                        savedFilter
                            ? dispatch(
                                  bulkAssignSavedFilter(
                                      {
                                          ...(savedFilter.criteria as NetworkAssetsFilter),
                                          networks: savedFilter.criteria.networks?.map((network) =>
                                              formatNetworkLabel(network),
                                          ),
                                          behaviours: savedFilter.criteria.behaviours,
                                          selectedVessels: formattedLocationsBlockSet(
                                              response.data.value.locations,
                                          ),
                                          searchVesselTagTerm:
                                              response.data.value.searchVesselTagTerm,
                                          searchVesselNameTerm:
                                              response.data.value.searchVesselNameTerm,
                                      },
                                      savedFilter.name,
                                      response.data.value.detailExpanded,
                                      false,
                                      savedFilter,
                                  ),
                              )
                            : dispatch(
                                  bulkAssignSavedFilter(
                                      {
                                          ...DEFAULT_NETWORK_ASSETS_FILTER,
                                          selectedVessels: formattedLocationsBlockSet(
                                              response.data.value.locations,
                                          ),
                                          searchVesselTagTerm:
                                              response.data.value.searchVesselTagTerm,
                                          searchVesselNameTerm:
                                              response.data.value.searchVesselNameTerm,
                                      },
                                      'Saved filters',
                                      response.data.value.detailExpanded,
                                  ),
                              )
                    }
                },
                () => {
                    dispatch(
                        bulkAssignSavedFilter(
                            DEFAULT_NETWORK_ASSETS_FILTER,
                            'Saved filters',
                            DEFAULT_NETWORK_ASSETS_FILTER.detailExpanded,
                        ),
                    )
                },
            )
        }
    }
}

function requestFilter(): Actions.RequestFilterAction {
    return {
        type: ActionType.REQUEST_FILTER,
    }
}

export function setSortColumn(
    sortColumn: SortColumnType,
): ThunkAction<void, AppState, Api, Actions.SetSortColumnAction> {
    return (dispatch) => {
        dispatch({
            type: ActionType.SET_SORT_COLUMN,
            payload: sortColumn,
        })
    }
}

export function setSearchHostname(
    hostname: string,
): ThunkAction<void, AppState, Api, Actions.SetSearchedHostnameAction> {
    return (dispatch) => {
        dispatch({
            type: ActionType.SET_SEARCHED_HOSTNAME,
            payload: hostname,
        })
    }
}

export function setSearchProperties(
    properties: string,
): ThunkAction<void, AppState, Api, Actions.SetSearchedPropertiesAction> {
    return (dispatch) => {
        dispatch({
            type: ActionType.SET_SEARCHED_PROPERTIES,
            payload: properties,
        })
    }
}

export function setSearchIpFilter(
    ipFilter: string,
): ThunkAction<void, AppState, Api, Actions.SetSearchedIpAddressAction> {
    return (dispatch) => {
        dispatch({
            type: ActionType.SET_SEARCHED_IP_ADDRESS,
            payload: ipFilter,
        })
    }
}

export function setSearchNicVendor(
    nicVendor: string,
): ThunkAction<void, AppState, Api, Actions.SetSearchedMacDescriptionAction> {
    return (dispatch) => {
        dispatch({
            type: ActionType.SET_SEARCHED_MAC_DESCRIPTION,
            payload: nicVendor,
        })
    }
}

export function setSearchedLastSeen(
    selectedTimeRange: TimestampFilterType | undefined,
): ThunkAction<void, AppState, Api, Actions.SetSearchedTimeRange> {
    return (dispatch) => {
        dispatch({
            type: ActionType.SET_SEARCHED_LAST_ACTIVE,
            payload: selectedTimeRange,
        })
    }
}

export function toggleNetworkAssetState(
    allNetworkAssetState: NetworkAssetState[],
    selectedNetworkAssetState: NetworkAssetState,
    networkAssetStateNewValue: boolean,
    acEnabled: boolean,
): ThunkAction<void, AppState, Api, Actions.ToggleNetworkAssetStateAction> {
    return (dispatch) => {
        dispatch({
            type: ActionType.TOGGLE_NETWORK_ASSET_STATE,
            payload: {
                allNetworkAssetState,
                selectedNetworkAssetState,
                networkAssetStateNewValue,
                acEnabled,
            },
        })
    }
}

export function toggleAllNetworkAssetState(
    selected: boolean,
): ThunkAction<void, AppState, Api, Actions.ToggleAllNetworkAssetStateAction> {
    return (dispatch) => {
        dispatch({
            type: ActionType.TOGGLE_ALL_NETWORK_ASSET_STATE,
            payload: selected,
        })
    }
}

export function toggleNetwork(
    allNetworks: NetworkType[],
    selectedNetwork: NetworkType,
    networkNewValue: boolean,
): ThunkAction<void, AppState, Api, Actions.ToggleNetworkAction> {
    return (dispatch) => {
        dispatch({
            type: ActionType.TOGGLE_NETWORK,
            payload: {allNetworks, selectedNetwork, networkNewValue},
        })
    }
}

export function toggleAllNetworks(
    selected: boolean,
): ThunkAction<void, AppState, Api, Actions.ToggleAllNetworksAction> {
    return (dispatch) => {
        dispatch({
            type: ActionType.TOGGLE_ALL_NETWORKS,
            payload: selected,
        })
    }
}

export function toggleBehaviour(
    allBehaviours: GuidType[],
    selectedBehaviour: GuidType,
    behaviourNewValue: boolean,
): ThunkAction<void, AppState, Api, Actions.ToggleBehaviourAction> {
    return (dispatch) => {
        dispatch({
            type: ActionType.TOGGLE_BEHAVIOUR,
            payload: {
                allBehaviours,
                selectedBehaviour,
                behaviourNewValue,
            },
        })
    }
}

export function toggleAllBehaviours(
    selected: boolean,
): ThunkAction<void, AppState, Api, Actions.ToggleAllBehavioursAction> {
    return (dispatch) => {
        dispatch({
            type: ActionType.TOGGLE_ALL_BEHAVIOURS,
            payload: selected,
        })
    }
}

export function selectBehaviour(
    allBehaviours: GuidType[],
    selectedBehaviour: GuidType,
    behaviourNewValue: boolean,
): ThunkAction<void, AppState, Api, Actions.SelectBehaviourAction> {
    return (dispatch) => {
        dispatch({
            type: ActionType.SELECT_BEHAVIOUR,
            payload: {
                allBehaviours,
                selectedBehaviour,
                behaviourNewValue,
            },
        })
    }
}

export function toggleFilterExpanded(
    filterExpanded: FilterExpandedType,
): ThunkAction<void, AppState, Api, Actions.ToggleFilterExpandedAction> {
    return (dispatch, getState) => {
        const currentFilter = getState().networkAssetsFilter
        const currentVesselFilter = getState().vesselFilter
        dispatch({
            type: ActionType.TOGGLE_FILTER_EXPANDED,
            payload: filterExpanded,
        })
        REST.put(USER_PREFS_URL, {
            selectedFilterName: currentFilter.selectedFilterName,
            locations: currentVesselFilter.locations,
            searchVesselTagTerm: currentVesselFilter.searchVesselTagTerm,
            searchVesselNameTerm: currentVesselFilter.searchVesselNameTerm,
            detailExpanded: currentFilter.detailExpanded,
            filterExpanded,
        })
    }
}

export function toggleModalDetailsExpanded(
    detailExpanded: DetailExpandedType,
): ThunkAction<void, AppState, Api, Actions.ToggleDetailExpandedAction> {
    return (dispatch, getState) => {
        const currentFilter = getState().networkAssetsFilter
        dispatch({
            type: ActionType.TOGGLE_DETAIL_EXPANDED,
            payload: detailExpanded,
        })
        REST.put(USER_PREFS_URL, {
            selectedFilterName: currentFilter.selectedFilterName,
            detailExpanded,
            filterExpanded: currentFilter.filterExpanded,
        })
    }
}

export function resetFilter(): ThunkAction<void, AppState, Api, AnyAction> {
    return (dispatch, getState) => {
        REST.get(USER_PREFS_URL).then((response) => {
            dispatch(toggleModalDetailsExpanded(response.data.value.detailExpanded))
        })
        const currentFilter = getState().networkAssetsFilter
        dispatch({type: ActionType.RESET_FILTER})
        dispatch(setCurrentAppliedSavedFilter(undefined))
        REST.put(USER_PREFS_URL, {
            selectedFilterName: '',
            searchVesselTagTerm: [],
            searchVesselNameTerm: '',
            detailExpanded: currentFilter.detailExpanded,
        })
    }
}

export function bulkAssignSavedFilter(
    data: NetworkAssetsFilter,
    filterName: string,
    detailExpanded: DetailExpandedType,
    pref?: boolean,
    savedFilter?: SavedNetworkAssetsFilter,
): ThunkAction<void, AppState, Api, AnyAction> {
    return (dispatch, getState) => {
        const currentVesselFilter = getState().vesselFilter
        dispatch({
            type: ActionType.BULK_ASSIGN_SAVED_FILTER,
            payload: {
                criteria: data,
                name: filterName,
                detailExpanded: detailExpanded,
            },
        })
        savedFilter && dispatch(setCurrentAppliedSavedFilter(savedFilter))
        pref &&
            REST.put(USER_PREFS_URL, {
                selectedFilterName: filterName,
                locations: populateLocationsForFilterCriteria(currentVesselFilter.locations),
                searchVesselTagTerm: currentVesselFilter.searchVesselTagTerm,
                searchVesselNameTerm: currentVesselFilter.searchVesselNameTerm,
                detailExpanded: detailExpanded,
            })
    }
}

export function setSelectedFilterName(
    filterName: string,
    pref?: {
        savedFilter: SavedNetworkAssetsFilter
        detailExpanded: DetailExpandedType
    },
): ThunkAction<void, AppState, Api, AnyAction> {
    return (dispatch, getState) => {
        const currentVesselFilter = getState().vesselFilter
        dispatch({
            type: ActionType.SET_SELECTED_FILTER_NAME,
            payload: filterName,
        })
        if (pref) {
            dispatch(setCurrentAppliedSavedFilter(pref.savedFilter))
            REST.put(USER_PREFS_URL, {
                selectedFilterName: filterName,
                detailExpanded: pref.detailExpanded,
                locations: populateLocationsForFilterCriteria(currentVesselFilter.locations),
                searchVesselTagTerm: currentVesselFilter.searchVesselTagTerm,
                searchVesselNameTerm: currentVesselFilter.searchVesselNameTerm,
            })
        }
    }
}
