import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import * as Styled from './aside.styled'

export type LayoutType = 'COLUMN' | 'ROW'
interface AsideProps {
    layout?: LayoutType
}

function getTextSize(layout: string, height: number): Styled.TextSizeType {
    if (layout === 'ROW') {
        return height >= 600 ? 'LARGE' : 'SMALL'
    }

    return height >= 420 ? 'LARGE' : 'SMALL'
}

export function Aside({layout = 'COLUMN'}: AsideProps): JSX.Element {
    const {height} = useDimensions()

    return (
        <Styled.Aside textSize={getTextSize(layout, height)} centered={layout === 'ROW'}>
            CyberOwl&apos;s Medulla is a cybersecurity monitoring solution. It is an important part
            of your cyber risk management approach and Safety Management System. Medulla helps you
            gain visibility of your onboard systems, cybersecure them and comply with IMO2021.
        </Styled.Aside>
    )
}
