export type SFMScoreMap = Map<string, number>

export interface SFMReduxState {
    fetching: boolean
    scores: SFMScoreMap
}

export function newSFMScoreMap(): SFMScoreMap {
    return new Map<string, number>()
}

export const DEFAULT_SFM_STATE: SFMReduxState = {
    fetching: false,
    scores: newSFMScoreMap(),
}
