import ActionType from './action-type'
import {Action} from './actions'

import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'

import {CurrentEmailRecipientsReduxState, DEFAULT_EMAIL_RECIPIENTS_STATE} from './state'
import produce from 'immer'
import {isEqual} from 'lodash'
import LoadingState from '../../../values/loading-state-enum'

export const emailRecipientsReducer = produce(
    (
        draft: CurrentEmailRecipientsReduxState = DEFAULT_EMAIL_RECIPIENTS_STATE,
        action: Action | LoggingOutAction,
    ) => {
        switch (action.type) {
            case ActionType.REQUEST_EMAIL_RECIPIENTS:
                draft.isFetching = LoadingState.RequestingData
                break

            case ActionType.RECEIVE_EMAIL_RECIPIENTS:
                if (!isEqual(draft.emailRecipientData, action.payload)) {
                    draft.emailRecipientData = action.payload
                }
                draft.isFetching = LoadingState.Loaded
                break

            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_EMAIL_RECIPIENTS_STATE
                break

            /* istanbul ignore next */
            default:
                break
        }

        return draft
    },
)
