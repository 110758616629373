import {Action} from './actions'
import ActionType from './action-type'
import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'
import LoadingState from '../../../values/loading-state-enum'
import {isEqual} from 'lodash'
import produce from 'immer'
import {DEFAULT_REPORTS_FILTER_STATE, ReportsFilterReduxState} from './state'
import {populateLocations} from '../../../utils/Utils'

const reportsFilterReducer = produce(
    (
        draft: ReportsFilterReduxState = DEFAULT_REPORTS_FILTER_STATE,
        action: Action | LoggingOutAction,
    ) => {
        switch (action.type) {
            case ActionType.REQUEST_FILTER:
                draft.loadingState = LoadingState.RequestingData
                break
            case ActionType.RECEIVE_FILTER:
                draft.loadingState = LoadingState.Loaded
                draft.selectedReportType = action.payload.selectedReportType
                draft.selectedRating = action.payload.selectedRating
                draft.selectedFromTimeRange = action.payload.selectedFromTimeRange
                draft.selectedToTimeRange = action.payload.selectedToTimeRange
                draft.includeWholeFleetReports = action.payload.includeWholeFleetReports
                if (!isEqual(draft.locations, action.payload.locations)) {
                    draft.locations = action.payload.locations
                }
                draft.searchVesselTagTerm = action.payload.searchVesselTagTerm
                draft.searchVesselNameTerm = action.payload.searchVesselNameTerm
                break

            case ActionType.SET_SELECTED_REPORT_TYPE:
                draft.selectedReportType = action.payload
                break

            case ActionType.SET_SELECTED_RATING:
                draft.selectedRating = action.payload
                break

            case ActionType.SET_SELECTED_TIME_RANGE_FROM:
                draft.selectedFromTimeRange = action.payload
                break

            case ActionType.SET_SELECTED_TIME_RANGE_TO:
                draft.selectedToTimeRange = action.payload
                break

            case ActionType.TOGGLE_WHOLE_FLEET_REPORTS:
                draft.includeWholeFleetReports = action.payload
                break

            case ActionType.RESET_FILTER:
                draft.loadingState = LoadingState.Loaded
                draft.selectedReportType = DEFAULT_REPORTS_FILTER_STATE.selectedReportType
                draft.selectedRating = DEFAULT_REPORTS_FILTER_STATE.selectedRating
                draft.selectedFromTimeRange = DEFAULT_REPORTS_FILTER_STATE.selectedFromTimeRange
                draft.selectedToTimeRange = DEFAULT_REPORTS_FILTER_STATE.selectedToTimeRange
                draft.includeWholeFleetReports =
                    DEFAULT_REPORTS_FILTER_STATE.includeWholeFleetReports
                draft.locations = DEFAULT_REPORTS_FILTER_STATE.locations
                draft.searchVesselTagTerm = DEFAULT_REPORTS_FILTER_STATE.searchVesselTagTerm
                draft.searchVesselNameTerm = DEFAULT_REPORTS_FILTER_STATE.searchVesselNameTerm
                draft.selectedFilterName = DEFAULT_REPORTS_FILTER_STATE.selectedFilterName
                break

            case ActionType.BULK_ASSIGN_SAVED_FILTER:
                draft.loadingState = LoadingState.Loaded
                draft.selectedReportType = action.payload.criteria.selectedReportType
                draft.selectedRating = action.payload.criteria.selectedRating
                draft.selectedFromTimeRange =
                    action.payload.criteria.selectedFromTimeRange ??
                    DEFAULT_REPORTS_FILTER_STATE.selectedFromTimeRange
                draft.selectedToTimeRange =
                    action.payload.criteria.selectedToTimeRange ??
                    DEFAULT_REPORTS_FILTER_STATE.selectedToTimeRange
                draft.includeWholeFleetReports = action.payload.criteria.includeWholeFleetReports
                draft.locations = populateLocations(action.payload.criteria.locations)
                draft.searchVesselTagTerm =
                    action.payload.criteria.searchVesselTagTerm ??
                    DEFAULT_REPORTS_FILTER_STATE.searchVesselTagTerm
                draft.selectedFilterName = action.payload.name

                break

            case ActionType.SET_SELECTED_FILTER_NAME:
                draft.selectedFilterName = action.payload ?? ''
                break

            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_REPORTS_FILTER_STATE
                break
            /* istanbul ignore next */
            default:
                break
        }

        return draft
    },
)
export default reportsFilterReducer
