import ActionType from './action-type'
import {Action} from './actions'

import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'
import produce from 'immer'
import {DEFAULT_VESSEL_TAGS_STATE, VesselTagReduxState, VesselTags} from './state'
import {getUpdatedDataArray} from '../reducer-helpers'
import {isEqual} from 'lodash'

export const vesselTagsReducer = produce(
    (draft: VesselTagReduxState = DEFAULT_VESSEL_TAGS_STATE, action: Action | LoggingOutAction) => {
        switch (action.type) {
            case ActionType.REQUEST_ALL_LOCATION_TAGS_BY_LOCATION:
                draft.isFetchingTags = true
                break

            case ActionType.RECEIVE_ALL_LOCATION_TAGS_BY_LOCATION:
                draft.tagsForLocation = action.payload
                draft.isFetchingTags = false
                break
            case ActionType.RECEIVE_ALL_TAGS:
                const {dataUpdated, data} = getUpdatedDataArray<VesselTags>(
                    action.payload,
                    draft.vesselTags,
                    (a, b) => a.identity === b.identity,
                )
                if (dataUpdated) {
                    draft.vesselTags = data
                }
                draft.isFetchingTags = false

                break
            case ActionType.SET_ERROR_RESULT:
                draft.errorResult = action.payload
                break
            case ActionType.DISPLAY_VESSEL_SETTINGS_FOR_ID:
                draft.displayVesselSettingsForId = action.payload
                draft.tagToCreateOrAssign = null
                draft.tagToUpdate = null
                draft.errorResult = undefined
                draft.searchedTerm = ''

                break
            case ActionType.SET_TAG_TO_CREATE_OR_ASSIGN:
                if (!isEqual(draft.tagToCreateOrAssign, action.payload)) {
                    draft.tagToCreateOrAssign = action.payload
                }
                break
            case ActionType.SET_TAG_TO_UPDATE:
                if (!isEqual(draft.tagToUpdate, action.payload)) {
                    draft.tagToUpdate = action.payload
                }
                break
            case ActionType.SET_SEARCH_TERM:
                draft.searchedTerm = action.payload
                break
            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_VESSEL_TAGS_STATE
                break

            /* istanbul ignore next */
            default:
                break
        }

        return draft
    },
)
