import LoadingState from '../../../values/loading-state-enum'
import {LocationIdType} from '../locations/state'
import {SortValue} from '../vessel-filter/types/vessel-filter-state'

export interface VesselManagementFilterReduxState {
    loadingState: LoadingState
    hasDeploymentIssues: boolean | undefined
    locations: Set<LocationIdType> | undefined
    searchVesselTagTerm: string[]
    searchVesselNameTerm: string
    searchVesselTerm: string
    showXButton: boolean
    vesselSort: SortValue
}

export const DEFAULT_VESSEL_MANAGEMENT_FILTER_STATE: VesselManagementFilterReduxState = {
    loadingState: LoadingState.NotPopulated,
    hasDeploymentIssues: undefined,
    locations: undefined,
    searchVesselTagTerm: [],
    searchVesselNameTerm: '',
    searchVesselTerm: '',
    showXButton: false,
    vesselSort: {
        field: 'vessel-name',
        direction: 'desc',
    },
}
