export enum ActionType {
    RECEIVED_REQUESTED_INCIDENTS_COUNTS_DATA_ACTION = '[INCIDENTS WIDGET CONTEXT] RECEIVED REQUESTED INCIDENTS_COUNTS DATA ACTION',
    RECEIVED_REQUESTED_INCIDENTS_ASSIGNMENTS_DATA_ACTION = '[INCIDENTS WIDGET CONTEXT] RECEIVED REQUESTED INCIDENTS_ASSIGNMENTS DATA ACTION',
    RECEIVED_REQUESTED_INCIDENTS_TRENDS_DATA_ACTION = '[INCIDENTS WIDGET CONTEXT] RECEIVED REQUESTED INCIDENTS_TRENDS DATA ACTION',
    RECEIVED_REQUESTED_INCIDENTS_AVG_PERFORMANCE_DATA_ACTION = '[INCIDENTS WIDGET CONTEXT] RECEIVED REQUESTED AVG_PERFORMANCE DATA ACTION',
    RECEIVED_REQUESTED_INCIDENTS_VESSELS_PERFORMANCE_DATA_ACTION = '[INCIDENTS WIDGET CONTEXT] RECEIVED REQUESTED VESSELS_PERFORMANCE DATA ACTION',
    RECEIVED_REQUESTED_INCIDENTS_OVERVIEW = '[INCIDENTS WIDGET CONTEXT] RECEIVED REQUESTED INCIDENTS_OVERVIEW ACTION',
    SET_FILTER = '[INCIDENTS WIDGET CONTEXT] SET FILTER',
    SET_SEARCH_VESSEL_TAG_TERM = '[INCIDENTS WIDGET CONTEXT] SET SEARCH VESSEL TAG TERM',
    SET_INCIDENT_WIDGET_VALUE = '[INCIDENTS WIDGET CONTEXT] SET INCIDENT WIDGET VALUE',
    SET_DATE_FILTER_ON_DASHBOARD = '[INCIDENTS WIDGET CONTEXT] SET DATE FILTER ON DASHBOARD',
    REQUEST_FILTER = '[INCIDENTS WIDGET CONTEXT] REQUEST FILTER',
    RECEIVE_FILTER = '[INCIDENTS WIDGET CONTEXT] RECEIVE FILTER',
    SET_EXTERNAL_PERIOD = '[INCIDENTS WIDGET CONTEXT] SET EXTERNAL PERIOD',
    SET_EXTERNAL_INCIDENT_WIDGET = '[INCIDENTS WIDGET CONTEXT] SET EXTERNAL INCIDENT WIDGET',
}
