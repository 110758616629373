import styled from 'styled-components'

export const ActionsFooter = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.28px;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: -7.5px;
    justify-content: center;
`
