import {TIME_RANGE_VALUES} from '../../../../../../values/TimeRangeValues'

export enum TagsComponentSortType {
    TAG_NAME = 'tagName',
    AVG_SCORE = 'avgScore',
    MAX_SCORE = 'maxScore',
    INCIDENTS = 'incidents',
    ASSET_DISCOVERY = 'assetDiscovery',
}

export interface SortColumnType {
    orderBy: TagsComponentSortType
    orderAscending: boolean
}

export interface TagsComponentFilter {
    sortColumn: SortColumnType
    timeRange: TIME_RANGE_VALUES
    searchVesselTagTerm: string[]
    searchVesselTerm: string
}

export const DEFAULT_TAGS_COMPONENT_FILTERS: TagsComponentFilter = {
    sortColumn: {
        orderBy: TagsComponentSortType.TAG_NAME,
        orderAscending: true,
    },
    timeRange: TIME_RANGE_VALUES.PT720H,
    searchVesselTagTerm: [],
    searchVesselTerm: '',
}
