enum ActionType {
    REQUEST_FILTER = '[INCIDENTS FILTER] REQUEST FILTER',
    RECEIVE_FILTER = '[INCIDENTS FILTER] RECEIVE FILTER',
    COLUMN_SORT = '[INCIDENTS FILTER] COLUMN SORT',
    SET_SEARCH_INCIDENT_NO = '[INCIDENTS FILTER] SET SEARCH INCIDENT NO',
    FILTER_BY_STATUS = '[INCIDENTS FILTER] FILTER_BY_STATUS',
    FILTER_BY_SEVERITY = '[INCIDENTS FILTER] FILTER_BY_SEVERITY',
    SORT_BY_NOTES = '[INCIDENTS FILTER] SORT_BY_NOTES',
    RESET_FILTER = '[INCIDENTS FILTER] RESET FILTER',
    SET_SELECTED_INCIDENT_TYPE = '[INCIDENTS FILTER] SET SELECTED INCIDENT TYPE',

    SET_SELECTED_CREATED_FROM_RELATIVE = '[INCIDENTS FILTER] SET SELECTED FROM RELATIVE TIME',
    SET_SELECTED_CREATED_TO_RELATIVE = '[INCIDENTS FILTER] SET SELECTED TO RELATIVE TIME',
    SET_SELECTED_UPDATED_FROM_RELATIVE = '[INCIDENTS FILTER] SET SELECTED TO UPDATE RELATIVE TIME',
    SET_SELECTED_UPDATED_TO_RELATIVE = '[INCIDENTS FILTER] SET SELECTED TO UPDATE TO RELATIVE TIME',

    SET_SELECTED_CREATED_TIME_RANGE = '[INCIDENTS FILTER] SET SELECTED CREATED TIME RANGE',
    SET_SELECTED_UPDATED_TIME_RANGE = '[INCIDENTS FILTER] SET SELECTED UPDATED TIME RANGE',
    SET_SELECTED_ASSIGNED_TO_USER = '[INCIDENTS FILTER] SET SELECTED ASSIGNED TO_USER',
    SET_SELECTED_VESSEL_ASSIGNED_EMAIL = '[INCIDENTS FILTER] SET SELECTED ASSIGNED VESSEL EMAIL',
    SET_SELECTED_ASSIGNED_TO_GUEST = '[INCIDENTS FILTER] SET SELECTED ASSIGNED TO_GUEST',
    SET_INCIDENTS_WITH_NEW_NOTES = '[INCIDENTS FILTER] SET WATCH INCIDENTS',
    SET_WATCH_INCIDENTS = '[INCIDENTS FILTER] SET INCIDENTS WITH NEW ITEMS',
    BULK_ASSIGN_SAVED_FILTER = '[INCIDENTS FILTER] BULK ASSIGN SAVED FILTER',
    SET_SELECTED_FILTER_NAME = '[INCIDENTS FILTER] SET SELECTED FILTER NAME',
    TOGGLE_DETAIL_EXPANDED = '[INCIDENTS FILTER] TOGGLE DETAIL EXPANDED',
}

export default ActionType
