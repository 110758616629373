import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import useTypedSelector from '../../../hooks/use-typed-selector'
import {loggedOut} from '../../../store/state/session-data/action-creators'
import {fetchConfig} from '../../../store/state/config/action-creators'
import {fetchingStatusSelector} from '../../../store/state/config/selectors'
import {ConfigFetchingStatus} from '../../../store/state/config/state'
import {SpinnerPanel} from './spinner-panel'
import {logout} from '../../../store/state/auth/action-creators'
import {hasAuthTokenSelector, msalSelector} from '../../../store/state/auth/selectors'
import {useAuthenticatedSemaphore} from '../../../hooks/use-authenticated-semaphore'

export function LoggingOutIndicator(): JSX.Element {
    const configFetchingStatus = useTypedSelector(fetchingStatusSelector)
    const dispatch = useDispatch()
    const hasAuthToken = useTypedSelector(hasAuthTokenSelector)
    const {clearSemaphore} = useAuthenticatedSemaphore()
    const msal = useTypedSelector(msalSelector)

    useEffect(() => {
        if (hasAuthToken) {
            dispatch(logout(msal))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasAuthToken, dispatch])

    useEffect(() => {
        if (configFetchingStatus === ConfigFetchingStatus.NEEDS_RESETTING) {
            dispatch(fetchConfig())
        }
    }, [configFetchingStatus, dispatch])

    useEffect(() => {
        if (!hasAuthToken && configFetchingStatus === ConfigFetchingStatus.INITIALISED) {
            dispatch(loggedOut())
            clearSemaphore()
        }
    }, [hasAuthToken, configFetchingStatus, clearSemaphore, dispatch])

    return <SpinnerPanel title="Logging Out" />
}
