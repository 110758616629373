import {SegmentWrapper} from './segment-wrapper.styled'

interface SegmentProps {
    segmentSize: number
    color: string
    animationDuration: string
}

export function Segment({segmentSize, color, animationDuration}: SegmentProps): JSX.Element {
    const segmentRadius = segmentSize * 0.95
    const remainder = segmentSize - segmentRadius

    return (
        <SegmentWrapper size={segmentSize} animationDuration={animationDuration}>
            <div
                style={{
                    background: color,
                    transform: 'rotate(0deg)',
                    position: 'absolute',
                    left: `${remainder}px`,
                    top: `${remainder}px`,
                    width: `${segmentRadius}px`,
                    height: `${segmentRadius}px`,
                    borderRadius: `${segmentRadius}px 0 0 0`,
                    transformOrigin: `${segmentSize}px ${segmentSize}px`,
                }}
            />
        </SegmentWrapper>
    )
}
