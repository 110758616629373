import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'

export const CustomLogoFlexBox = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${spacing(4)};
    align-items: center;
    row-gap: ${spacing(2)};
`
export const CustomLogoText = styled.div`
    padding-top: ${spacing(2)};
`
export const CustomLogoByText = styled.div`
    padding-right: ${spacing(11)};
    align-self: flex-end;
`
