import {LocationIdType} from '../../store/state/locations/state'
import {
    DetailExpandedType,
    SoftwareInventorySortType,
    SoftwareStatus,
    SortColumnType,
    TimestampFilter,
} from '../../store/state/software-inventory-filter/state'

export interface SoftwareInventoryFilterCriteria {
    orderBy: {column: SoftwareInventorySortType; isAscending: boolean}
    fromRelativeFirstSeen: string | null
    fromRelativeLastSeen: string | null
    company: string | null
    product: string | null
    pagination: {pageSize: 10; pageOffset: 0}
    statuses: SoftwareStatus[] | undefined
}

export const DEFAULT_NETWORK_ASSETS_FILTER_CRITERIA: SoftwareInventoryFilterCriteria = {
    orderBy: {
        column: SoftwareInventorySortType.TIMESTAMP,
        isAscending: false,
    },
    fromRelativeFirstSeen: null,
    fromRelativeLastSeen: null,
    company: null,
    product: null,
    pagination: {pageSize: 10, pageOffset: 0},
    statuses: undefined,
}

export interface SoftwareInventoryFilter {
    searchedFirstDetected: TimestampFilter | undefined
    searchedLastActive: TimestampFilter | undefined
    searchedCompanyName: string | undefined
    searchedProductName: string | undefined
    filteredSoftwareStatus: SoftwareStatus[] | undefined
    searchedVessels: Array<LocationIdType> | undefined
    sortColumn: SortColumnType
    searchVesselTagTerm: string[] | undefined
    searchVesselNameTerm: string | undefined
}

export const DEFAULT_SOFTWARE_INVENTORY_FILTER: SoftwareInventoryFilter = {
    searchedFirstDetected: undefined,
    searchedLastActive: undefined,
    searchedCompanyName: undefined,
    searchedProductName: undefined,
    filteredSoftwareStatus: undefined,
    sortColumn: {
        orderBy: SoftwareInventorySortType.TIMESTAMP,
        orderAscending: false,
    },
    searchedVessels: undefined,
    searchVesselTagTerm: [],
    searchVesselNameTerm: '',
}

export interface SoftwareInventoryFilterForUserPref {
    selectedFilterName: string
    locations: LocationIdType[] | undefined
    searchVesselTagTerm: string[]
    searchVesselNameTerm: string
    detailExpanded: DetailExpandedType
}
