import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'

const BREAK_POINT_TEXT = 1200
const BREAK_POINT_VIEW = 1400

export const DropdownMenuWrap = styled.div`
    position: relative;
`
interface DropdownMenuContentProps {
    width: number
}
export const DropdownContentStyle = styled.div<DropdownMenuContentProps>`
    background-color: ${(props) => props.theme.navigationResponsive.dataArea.background};
    border: none;
    border-radius: 0 0 3px 3px;
    padding: ${spacing(3)};
    margin-left: ${(props) =>
        props.width > BREAK_POINT_TEXT ? `${spacing(-21)}` : `${spacing(-18)}`};
    margin-top: ${(props) =>
        props.width > BREAK_POINT_VIEW ? `${spacing(4)}` : `${spacing(3.6)}`};
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    font-weight: ${(props) => props.theme.font.weight.normal};
    display: block;
    position: absolute;
    top: 100%;
    z-index: 1000;
    float: left;
    min-width: ${spacing(32)};
    max-width: ${spacing(64)};
    &:link,
    &:visited,
    &:hover,
    &:active,
    &:focus {
        outline: 0;
        cursor: default;
    }
`
interface DropDownItemProps {
    width: number
}
export const DropdownItemStyle = styled.div<DropDownItemProps>`
    color: ${(props) => props.theme.navigationResponsive.dataArea.textColor};
    padding: ${spacing(1)} 0;
    font-size: ${(props) => (props.width > BREAK_POINT_TEXT ? '16px' : '14px')};
    line-height: ${(props) => (props.width > BREAK_POINT_TEXT ? '22px' : '19px')};
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:link,
    &:visited,
    &:hover,
    &:active,
    &:focus {
        border-radius: 0;
        outline: 0;
        cursor: pointer;
    }
`
export const Divider = styled.div`
    margin: 6.5px 0;
    border-top: 1px solid ${(props) => props.theme.navigationResponsive.dataArea.miniTextColor};
`
export const RadioItemStyle = styled.div`
    &:link,
    &:visited,
    &:hover,
    &:active,
    &:focus {
        cursor: pointer;
    }
`
export const LabelItemStyle = styled.div`
    &:link,
    &:visited,
    &:hover,
    &:active,
    &:focus {
        cursor: pointer;
    }
`
