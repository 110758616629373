import styled from 'styled-components'

interface ContainerProps {
    size: number
}
export const Container = styled.div<ContainerProps>`
    width: ${(props) => `${props.size}px`};
    height: ${(props) => `${props.size}px`};
    overflow: hidden;
    background: none;
`
