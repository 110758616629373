import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {useToggle} from '../../../../../contexts/toggle/use-toggle'
import {LocationDropDownButtonStyle} from './location-dropdown-button.styled'

interface LocationDropDownButtonProps {
    dropdownOpen: boolean
}

export function LocationDropDownButton({dropdownOpen}: LocationDropDownButtonProps): JSX.Element {
    const {setValue: setDropdownOpen} = useToggle()
    return (
        <LocationDropDownButtonStyle
            onClick={() => setDropdownOpen(!dropdownOpen)}
            id="navigation-location-toggle"
        >
            <Icon glyph={dropdownOpen ? 'DropdownUp' : 'DropdownDown'} height={15} width={15} />
        </LocationDropDownButtonStyle>
    )
}
