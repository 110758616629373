import {
    DEFAULT_COUNTS_BY_SEVERITY,
    DEFAULT_COUNTS_BY_STATUS,
    DEFAULT_COUNTS_BY_TYPE,
    TotalNumberOfItemsBySeverity,
    TotalNumberOfItemsByStatus,
    TotalNumberOfItemsByTypes,
} from '../../../incidents-v3/contexts/types/type-of-interfaces'

export interface IncidentCountsType {
    countsByStatus: TotalNumberOfItemsByStatus
    countsBySeverity: TotalNumberOfItemsBySeverity
    countsByType: TotalNumberOfItemsByTypes
}

export const DEFAULT_INCIDENTS_COUNTS: IncidentCountsType = {
    countsByStatus: DEFAULT_COUNTS_BY_STATUS,
    countsBySeverity: DEFAULT_COUNTS_BY_SEVERITY,
    countsByType: DEFAULT_COUNTS_BY_TYPE,
}
export interface IncidentAssignmentsCounts {
    assignedToColleagues: number
    assignedToCurrentUser: number
    assignedToVessel: number
    unassigned: number
}
export const DEFAULT_ASSIGNMENTS_COUNTS: IncidentAssignmentsCounts = {
    assignedToColleagues: 0,
    assignedToCurrentUser: 0,
    assignedToVessel: 0,
    unassigned: 0,
}
export interface IncidentTrendsType {
    label: string
    lowSeverityIncidentsCount: number
    mediumSeverityIncidentsCount: number
    highSeverityIncidentsCount: number
    criticalSeverityIncidentsCount: number
}

export interface IncidentAvgPerformanceType {
    label: string
    averageTimeToOpen: string
    averageTimeToClose: string
}

export interface IncidentVesselsPerformanceType {
    assignedToVessel: {
        viewedByVessel: {
            notResolved: {
                CLOSED: number
                NEW: number
                OPEN: number
            }
            resolved: {
                CLOSED: number
                NEW: number
                OPEN: number
            }
        }
        notViewedByVessel: {
            notResolved: {
                CLOSED: number
                NEW: number
                OPEN: number
            }
            resolved: {
                CLOSED: number
                NEW: number
                OPEN: number
            }
        }
    }
}
export const DEFAULT_VESSELS_PERFORMANCE_TYPE: IncidentVesselsPerformanceType = {
    assignedToVessel: {
        viewedByVessel: {
            notResolved: {
                CLOSED: 0,
                NEW: 0,
                OPEN: 0,
            },
            resolved: {
                CLOSED: 0,
                NEW: 0,
                OPEN: 0,
            },
        },
        notViewedByVessel: {
            notResolved: {
                CLOSED: 0,
                NEW: 0,
                OPEN: 0,
            },
            resolved: {
                CLOSED: 0,
                NEW: 0,
                OPEN: 0,
            },
        },
    },
}
