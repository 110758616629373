import styled from 'styled-components'
interface SecondaryButtonProps {
    smallText: boolean
}
export const SecondaryButton = styled.a<SecondaryButtonProps>`
    padding: 7.5px;
    width: 100%;
    color: white;
    background-color: transparent;
    border: 1px solid white;
    font-size: ${(props) => (props.smallText ? '14px' : '17px')};
    line-height: ${(props) => (props.smallText ? '19px' : '28px')};
    letter-spacing: ${(props) => (props.smallText ? '0.28px' : '0.4px')};
    font-weight: 600;
    border-radius: 6px;
    text-align: center;

    &:hover {
        text-decoration: none;
        color: white;
    }
`
