import ActionType from './action-type'
import {Action} from './actions'

import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'

import {DEFAULT_ETS_TREND_STATE, EtsTrendReduxState} from './state'
import {LAST_12H, LAST_24H, LAST_30D, LAST_7D, LAST_90D, TimeRange} from '../../../values/TimeRange'
import {EtsTrendScore} from '../../../values/ets-trend-score/EtsTrendScore'
import {getUpdatedDataArray} from '../reducer-helpers'

function requestTrend(currentState: EtsTrendReduxState, timeRange: TimeRange): EtsTrendReduxState {
    switch (timeRange) {
        case LAST_12H:
            if (currentState.isFetchingLast12H) {
                return currentState
            }

            return {
                ...currentState,
                isFetchingLast12H: true,
            }

        case LAST_24H:
            if (currentState.isFetchingLast24H) {
                return currentState
            }

            return {
                ...currentState,
                isFetchingLast24H: true,
            }

        case LAST_7D:
            if (currentState.isFetchingLast7D) {
                return currentState
            }

            return {
                ...currentState,
                isFetchingLast7D: true,
            }

        case LAST_30D:
            if (currentState.isFetchingLast30D) {
                return currentState
            }

            return {
                ...currentState,
                isFetchingLast30D: true,
            }
        case LAST_90D:
            if (currentState.isFetchingLast90D) {
                return currentState
            }

            return {
                ...currentState,
                isFetchingLast90D: true,
            }
        default:
            return currentState
    }
}
function receiveTrends(
    currentTrends: EtsTrendScore[],
    receivedData: EtsTrendScore[],
): EtsTrendScore[] {
    const {dataUpdated, data} = getUpdatedDataArray<EtsTrendScore>(
        receivedData,
        currentTrends,
        (a, b) => a.time === b.time,
    )

    if (!dataUpdated && data.length === currentTrends?.length) {
        return currentTrends
    }

    return data
}

function receive12HourTrendData(
    currentState: EtsTrendReduxState,
    receivedTrends: EtsTrendScore[],
): EtsTrendReduxState {
    const updatedTrends = receiveTrends(currentState.trendLast12H, receivedTrends)
    if (updatedTrends === currentState.trendLast12H && !currentState.isFetchingLast12H) {
        return currentState
    }

    return {
        ...currentState,
        isFetchingLast12H: false,
        trendLast12H: updatedTrends,
    }
}

export default function etsTrendReducer(
    state: EtsTrendReduxState = DEFAULT_ETS_TREND_STATE,
    action: Action | LoggingOutAction,
): EtsTrendReduxState {
    switch (action.type) {
        case ActionType.REQUEST_TREND:
            return requestTrend(state, action.payload)

        case ActionType.RECEIVE_TREND:
            const {timeRange, scores} = action.payload

            switch (timeRange) {
                case LAST_12H:
                    return receive12HourTrendData(state, scores)

                case LAST_24H: {
                    const updatedTrends = receiveTrends(state.trendLast24H, scores)
                    if (updatedTrends === state.trendLast24H && !state.isFetchingLast24H) {
                        return state
                    }

                    return {
                        ...state,
                        isFetchingLast24H: false,
                        trendLast24H: updatedTrends,
                    }
                }

                case LAST_7D: {
                    const updatedTrends = receiveTrends(state.trendLast7D, scores)
                    if (updatedTrends === state.trendLast7D && !state.isFetchingLast7D) {
                        return state
                    }

                    return {
                        ...state,
                        isFetchingLast7D: false,
                        trendLast7D: updatedTrends,
                    }
                }

                case LAST_30D: {
                    const updatedTrends = receiveTrends(state.trendLast30D, scores)
                    if (updatedTrends === state.trendLast30D && !state.isFetchingLast30D) {
                        return state
                    }
                    return {
                        ...state,
                        isFetchingLast30D: false,
                        trendLast30D: updatedTrends,
                    }
                }
                case LAST_90D: {
                    const updatedTrends = receiveTrends(state.trendLast90D, scores)
                    if (updatedTrends === state.trendLast90D && !state.isFetchingLast90D) {
                        return state
                    }
                    return {
                        ...state,
                        isFetchingLast90D: false,
                        trendLast90D: updatedTrends,
                    }
                }
                default:
                    return state
            }

        case SessionActionType.LOGGING_OUT:
            return DEFAULT_ETS_TREND_STATE

        /* istanbul ignore next */
        default:
            return state
    }
}
