import {UseTagsComponentOutput} from './use-tags-component-output'
import {useContext} from 'react'
import {tagsComponentContext} from './tags-component-context'
import {SortColumnType} from './types/tags-component-filter'
import {warn} from '../../../../../helpers/logging'
import * as ActionCreators from './state/action-creators'
import {TIME_RANGE_VALUES} from '../../../../../values/TimeRangeValues'
import {VesselTags} from '../../../../../store/state/vessel-tags/state'

export function useTagsComponent(): UseTagsComponentOutput {
    const {state, dispatch} = useContext(tagsComponentContext)
    if (state == undefined || dispatch == undefined) {
        throw new Error('useTagsComponent must be used within a TagsComponentContext')
    }

    function setNewSortColumn(sortColumn: SortColumnType): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        ActionCreators.setSortColumn(sortColumn, dispatch, state.tagsComponentFilter)
    }

    function setNewTimerange(timeRange: TIME_RANGE_VALUES): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        ActionCreators.setTimeRange(timeRange, dispatch, state.tagsComponentFilter)
    }

    function setSearchVesselTagTerm(value: string[]): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        ActionCreators.setSearchVesselTagTerm(value, dispatch, state.tagsComponentFilter)
    }

    function setSearchVesselTerm(value: string): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        ActionCreators.setSearchVesselTerm(value, dispatch)
    }

    function fetchFilter(assignedTagName: string | undefined): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        ActionCreators.fetchTagsComponentFilter(dispatch, assignedTagName)
    }

    function setShowFilter(show = false): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.showFilter(show))
    }

    function toggleVesselTag(allTags: VesselTags[], tags: string, newValue: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        ActionCreators.toggleVesselTag(allTags, tags, newValue, dispatch, state.tagsComponentFilter)
    }

    return {
        sortColumn: state.tagsComponentFilter.sortColumn,
        loadingFilterState: state.loadingFilterState,
        setNewSortColumn,
        fetchFilter,
        setShowFilter,
        setSearchVesselTagTerm,
        searchedVesselTagTerm: state.tagsComponentFilter.searchVesselTagTerm,
        setSearchVesselTerm,
        searchedVesselTerm: state.tagsComponentFilter.searchVesselTerm,
        showFilter: state.showFilter,
        setNewTimerange,
        timeRange: state.tagsComponentFilter.timeRange,
        toggleVesselTag,
    }
}
