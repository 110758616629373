import {ReactNode, useReducer} from 'react'
import {DEFAULT_FLEET_SCORE_OVER_TIME_STATE} from './types/default-fleet-score-over-time-state'
import {fleetScoreOverTimeReducer} from './state/reducer'
import {fleetScoreOverTimeContext} from './fleet-score-over-time-context'

type FleetScoreOverTimeProviderProps = {
    children: ReactNode | ReactNode[]
}

export function FleetScoreOverTimeProvider({
    children,
}: FleetScoreOverTimeProviderProps): JSX.Element {
    const [state, dispatch] = useReducer(
        fleetScoreOverTimeReducer,
        DEFAULT_FLEET_SCORE_OVER_TIME_STATE,
    )

    return (
        <fleetScoreOverTimeContext.Provider value={{state, dispatch}}>
            {children}
        </fleetScoreOverTimeContext.Provider>
    )
}
