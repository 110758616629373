import {useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {bindActionCreators} from 'redux'
import * as actions from './action-creators'

const useActions = (): typeof actions => {
    const dispatch = useDispatch()

    return useMemo(() => bindActionCreators(actions, dispatch), [dispatch])
}

export default useActions
