export enum IncidentSeverityValues {
    CRITICAL = 'CRITICAL',
    HIGH = 'HIGH',
    MEDIUM = 'MEDIUM',
    LOW = 'LOW',
}
export type IncidentSeverityValue =
    | IncidentSeverityValues.CRITICAL
    | IncidentSeverityValues.HIGH
    | IncidentSeverityValues.MEDIUM
    | IncidentSeverityValues.LOW
