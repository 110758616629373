import moment, {Moment} from 'moment'
import {
    getLocationDescription,
    Location,
    isActiveLocation,
    LocationMap,
} from '../../../../store/state/locations/state'
import {Level} from '../../../../values/location-level-enum'
import {NodeDataMap} from '../../../../values/nodes/NodeData'
import {IncidentAssociatedAssets} from '../../../incidents-v3/contexts/types/incident-associated-assets'
import {IncidentResponse} from '../../../incidents-v3/contexts/types/incident-response'

function getAssetAliases(
    associatedAssets: IncidentAssociatedAssets[],
    nodes: NodeDataMap,
): string | null {
    if (associatedAssets?.length === 0) {
        return null
    }

    return associatedAssets
        .map((associatedAsset) => {
            return nodes.get(associatedAsset.node)?.alias
        })
        .join(', ')
}

export class WidgetOutputModel {
    public readonly level: Level
    public readonly dateRaised: Moment | null
    public readonly dateUpdated: Moment | null
    public readonly vessel: string
    public readonly asset: string | null
    public readonly identity: string | null
    public readonly incidentCode: string | null

    public constructor(
        incidentResponse: IncidentResponse | null | undefined,
        locationMap: LocationMap,
        activeLocation: Location,
        nodes: NodeDataMap,
    ) {
        this.level = isActiveLocation(activeLocation) ? Level.VESSEL : Level.FLEET
        this.dateRaised = incidentResponse ? moment(incidentResponse.createdState.when) : null
        this.dateUpdated = incidentResponse ? moment(incidentResponse.updatedState.when) : null
        this.vessel = incidentResponse
            ? getLocationDescription(locationMap, incidentResponse.location)
            : 'Unknown'
        this.asset = incidentResponse
            ? getAssetAliases(incidentResponse.associatedAssets, nodes)
            : null
        this.identity = incidentResponse ? incidentResponse.identity : null
        this.incidentCode = incidentResponse ? incidentResponse.incidentCode : null
    }
}
