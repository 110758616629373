export type Role =
    | 'APP'
    | 'GATEWAY'
    | 'NEBULA'
    | 'BEACON'
    | 'VESSEL'
    | 'DEVELOPER'
    | 'THREAT_ANALYST'
    | 'CUSTOMER_ADMIN'
    | 'USER'

export type ExternalRole = 'CUSTOMER_ADMIN' | 'USER'

export const notSelectableRole = ['APP', 'GATEWAY', 'NEBULA', 'BEACON', 'VESSEL']

export const isUserAllowed = (userRoles: Role[] | undefined, rolesAllowed: Role[]): boolean => {
    if (!userRoles || !rolesAllowed) {
        return false
    }

    return rolesAllowed.some((allowedRole: Role) => {
        return userRoles.some((userRole: Role) => userRole === allowedRole)
    })
}
