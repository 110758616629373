import {GuidType} from '../../../values/generic-type-defintions'
import {ErrorObject} from './action-creators'
import {VesselTags} from './state'

type TagBase = {
    locationIdentity: string
}

type assignNodeToTag = TagBase & {
    locationTagIdentity: string
}

type createTag = TagBase & {
    tagDataDefinition: {name: string}
}
export type ApiCreateOrAssign = assignNodeToTag | createTag

export type ApiUpdateTag = {
    identity: string
    name: string
}

export interface VesselTagsAPI {
    tagIdentity: GuidType
    tagName: string
}

export function formatVesseltagsForUI(vesselTags: VesselTagsAPI): VesselTags {
    return {
        identity: vesselTags.tagIdentity,
        name: vesselTags.tagName,
    }
}

export function getTheErrorCodes(errorResult: ErrorObject | undefined): boolean {
    if (!errorResult?.error) {
        return false
    }

    switch (Object.keys(errorResult.error)?.[0]) {
        case '1002':
        case '1004':
        case '1006':
            return true
        default:
            return false
    }
}

export function displayErrorMessage(data?: ErrorObject): string {
    if (!data?.error) {
        return ''
    }

    return data?.error[Object.keys(data?.error)[0]]
}
