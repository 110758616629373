import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {ByText} from './styles/by-text.styled'
import * as LargeHeaderLayout from './styles/large-header-layout.styled'
import * as MediumHeaderLayout from './styles/medium-header-layout.styled'
import * as SmallHeaderLayout from './styles/small-header-layout.styled'
import {Medulla} from './medulla'
import {CustomLogo} from './custom-logo'
import {ASIDE_BREAK_POINT} from '../formats/column/column-based-layout.styled'
import {HeaderSize} from './header'

interface HeaderProps {
    headerSize: HeaderSize
}

export function HeaderCustomLogo({headerSize}: HeaderProps): JSX.Element {
    const {width} = useDimensions()
    const midiumView = width <= ASIDE_BREAK_POINT

    if (headerSize === HeaderSize.SMALL) {
        return (
            <SmallHeaderLayout.CustomLogoFlexBox>
                <CustomLogo />
                <SmallHeaderLayout.CustomLogoText>
                    <Medulla />
                </SmallHeaderLayout.CustomLogoText>
                <SmallHeaderLayout.CustomLogoByText>
                    <ByText>Powered By CyberOwl</ByText>
                </SmallHeaderLayout.CustomLogoByText>
            </SmallHeaderLayout.CustomLogoFlexBox>
        )
    }

    if (headerSize === HeaderSize.LARGE && midiumView) {
        return (
            <MediumHeaderLayout.CustomLogoFlexBox>
                <CustomLogo />
                <MediumHeaderLayout.CustomLogoText>
                    <Medulla />
                </MediumHeaderLayout.CustomLogoText>
                <MediumHeaderLayout.CustomLogoByText>
                    <ByText>Powered By CyberOwl</ByText>
                </MediumHeaderLayout.CustomLogoByText>
            </MediumHeaderLayout.CustomLogoFlexBox>
        )
    }

    return (
        <LargeHeaderLayout.CustomLogoCssGrid>
            <LargeHeaderLayout.CustomLogoLogoCell>
                <CustomLogo />
            </LargeHeaderLayout.CustomLogoLogoCell>
            <LargeHeaderLayout.CustomLogoNameCell>
                <Medulla />
            </LargeHeaderLayout.CustomLogoNameCell>
            <LargeHeaderLayout.CustomLogoByTextCell>
                <ByText>Powered By CyberOwl</ByText>
            </LargeHeaderLayout.CustomLogoByTextCell>
        </LargeHeaderLayout.CustomLogoCssGrid>
    )
}
