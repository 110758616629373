enum ActionType {
    REQUEST_FILTER = '[INCIDENTS REPORT FILTER] REQUEST FILTER',
    SET_DATE_FILTER = '[INCIDENTS REPORT FILTER] SET DATE FILTER',
    TOGGLE_FILTER_EXPANDED = '[INCIDENTS REPORT FILTER] TOGGLE FILTER EXPANDED',
    RESET_FILTER = '[INCIDENTS REPORT FILTER] RESET FILTER',
    BULK_ASSIGN_SAVED_FILTER = '[INCIDENTS REPORT FILTER] BULK ASSIGN SAVED FILTER',
    SET_SELECTED_FILTER_NAME = '[INCIDENTS REPORT FILTER] SET SELECTED FILTER NAME',
}

export default ActionType
