import {PossibleColumnsVesselBeta} from '../../../pages/vessels-beta/contexts/types/paged-vessels-beta-state'
import {SortColumnType} from '../../../pages/vessels-beta/contexts/types/sort-column'
import {TimestampFilterType} from '../../../pages/vessels-beta/contexts/types/timestamp-filter'
import LoadingState from '../../../values/loading-state-enum'
import {LocationIdType} from '../locations/state'
import {SortValue} from '../vessel-filter/types/vessel-filter-state'

export interface VesselsBetaFilterReduxState {
    loadingState: LoadingState
    analysisPeriod: TimestampFilterType
    selectedColumns: PossibleColumnsVesselBeta[]
    sortColumn: SortColumnType
    locations: Set<LocationIdType> | undefined
    searchVesselTagTerm: string[]
    searchVesselNameTerm: string
    searchVesselTerm: string
    vesselSort: SortValue
}

export const DEFAULT_VESSELS_BETA_FILTER: VesselsBetaFilterReduxState = {
    loadingState: LoadingState.NotPopulated,
    analysisPeriod: '30d',
    selectedColumns: [],
    sortColumn: {
        orderBy: PossibleColumnsVesselBeta.CurrentVesselScore,
        orderAscending: true,
    },
    locations: undefined,
    searchVesselTagTerm: [],
    searchVesselNameTerm: '',
    searchVesselTerm: '',
    vesselSort: {
        field: 'vessel-name',
        direction: 'desc',
    },
}
export const DEFAULT_USER_PREFS: {
    selectedColumns: PossibleColumnsVesselBeta[]
    sortColumn: SortColumnType
    analysisPeriod: TimestampFilterType
} = {
    selectedColumns: [],
    sortColumn: {
        orderBy: PossibleColumnsVesselBeta.CurrentVesselScore,
        orderAscending: true,
    },
    analysisPeriod: '30d',
}
