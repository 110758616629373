import {AuditLogReduxState, DEFAULT_AUDIT_LOG_STATE} from './state'
import ActionType from './action-type'
import {Action} from './actions'
import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'
import LoadingState from '../../../values/loading-state-enum'
import produce from 'immer'

export const auditLogReducer = produce(
    (draft: AuditLogReduxState = DEFAULT_AUDIT_LOG_STATE, action: Action | LoggingOutAction) => {
        switch (action.type) {
            case ActionType.REQUEST_AUDIT_LOG:
                draft.loading = LoadingState.RequestingData
                break

            case ActionType.RECEIVED_AUDIT_LOG:
                draft.loading = LoadingState.Loaded
                draft.data = action.payload
                break

            case ActionType.LOG_PAGE_ACCESS:
                break

            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_AUDIT_LOG_STATE
                break
            /* istanbul ignore next */
            default:
                break
        }
        return draft
    },
)
