import {LocationIdType} from '../../store/state/locations/state'
import {VesselFiltersState} from '../../store/state/my-vessels-filters/types/my-vessel-filter-state'
import {VesselTags} from '../../store/state/vessel-tags/state'
import {MyVesselsFilter} from '../../values/user-preferences/my-vessels-filter'

export enum PageType {
    MY_VESSELS = 'my-vessels-filter',
    INCIDENTS = 'incidents_settings',
    REPORTS = 'reports-filter',
    NETWORK_ASSETS = 'unknown_assets_settings_v2',
    USB_DEVICES = 'usb_devices',
    SOFTWARE_INVENTORY = 'software_inventory',
    NMEA_INVENTORY = 'nmea_inventory',
    METRICS = 'metrics_beta',
    VESSELS_BETA = 'vessels_beta',
    VESSEL_MANAGEMENT = 'vessel_management',
    INCIDENTS_REPORT = 'incidents_report',
    OT_INVENTORY = 'ot_inventory',
}

export function findRecommendationFromEntryTag(
    existingTags: VesselTags[] | undefined,
    searchTag: string,
): VesselTags[] {
    if (!existingTags || !searchTag || !searchTag?.trim()) {
        return []
    }
    return existingTags
        ?.filter((element) =>
            element.name?.trim().toLowerCase().includes(searchTag?.trim().toLowerCase()),
        )
        .sort((a, b) => a.name?.localeCompare(b.name))
        .slice(0, 5)
}

function populateLocationsForFilterCriteria(
    locations: Set<LocationIdType> | undefined,
): LocationIdType[] | undefined {
    try {
        if (!locations) {
            return undefined
        }
        return Array.from(locations)
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error({err, locations})
        return undefined
    }
}

export function buildMyVesselsFilterPrefs(state: VesselFiltersState): MyVesselsFilter {
    return {
        zoomLevel: state.zoomLevel,
        vesselSort: state.vesselSort,
        assetSort: state.assetSort,
        HIGH: state.HIGH,
        MEDIUM: state.MEDIUM,
        LOW: state.LOW,
        locations: populateLocationsForFilterCriteria(state.locations),
        searchVesselTagTerm: state.searchVesselTagTerm,
        searchVesselNameTerm: state.searchVesselNameTerm,
    }
}
