export enum IncidentWidgetType {
    LATEST_INCIDENT = 'latestIncident',
    CURRENT_STATUS = 'currentStatus',
    CURRENT_ASSIGNMENT = 'currentAssignment',
    TYPES_INCIDENTS = 'typesIncidents',
    SEVERITY_INCIDENTS = 'severityIncidents',
    RAISED_INCIDENTS_TRENDS = 'raisedIncidentsTrends',
    INCIDENT_RESPONSE_PERFORMANCE = 'incidentsResponsePerformance',
    VESSELS_RESPONSE_PERFORMANCE = 'vesselsResponsePerformance',
    INCIDENT_CLOSURE_RATE = 'incidentClosureRate',
}
