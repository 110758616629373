import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'

export const FlexBox = styled.div`
    display: flex;
    margin-bottom: ${spacing(8)};
`

interface TextProps {
    width: number
}

export const Text = styled.div<TextProps>`
    display: flex;
    flex-direction: column;
    margin-left: ${(props) => spacing(props.width > 340 ? 7 : 4)};
`

export const ByText = styled.div`
    margin-top: ${spacing(1)};
`

export const CustomLogoFlexBox = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${spacing(8)};
    align-items: center;
    row-gap: ${spacing(2)};
`
export const CustomLogoText = styled.div`
    padding-top: ${spacing(2)};
`
export const CustomLogoByText = styled.div`
    align-self: flex-start;
`
