import {LoadingSpinner} from '../../../components/loading/loading'
import {useDimensions} from '../../../contexts/dimensions/use-dimensions'
import {Panel, SpinnerContainer} from './common-panel.styled'
import {ColumnBasedLoginLayout} from './formats/column/column-based-login-layout'
import {isRowBasedLoginFormat, RowBasedLoginLayout} from './formats/row/row-based-login-layout'

function content(title: string): JSX.Element {
    return (
        <Panel>
            <h2>{title}</h2>
            <SpinnerContainer>
                <LoadingSpinner />
            </SpinnerContainer>
        </Panel>
    )
}

interface SpinnerPanelProps {
    title: string
}

export function SpinnerPanel({title}: SpinnerPanelProps): JSX.Element {
    const {width} = useDimensions()

    return isRowBasedLoginFormat(width) ? (
        <RowBasedLoginLayout>{content(title)}</RowBasedLoginLayout>
    ) : (
        <ColumnBasedLoginLayout>{content(title)}</ColumnBasedLoginLayout>
    )
}
