/* eslint-disable @typescript-eslint/no-explicit-any */
import {createSelector} from 'reselect'
import qs from 'qs'
import AppState from './types/app-state'
import {RouterLocation} from 'connected-react-router'
import {LocationState, Search} from 'history'

const location = (state: AppState): RouterLocation<LocationState> | undefined =>
    state?.router?.location

const queryString = (state: AppState): Search | undefined => state?.router?.location?.search
export const locationSelector = createSelector(location, (locationState) => {
    return locationState
})

export const queryStringObjectSelector = createSelector(queryString, (queryStringState) => {
    if (queryStringState) {
        return qs.parse(queryStringState, {ignoreQueryPrefix: true})
    }

    return undefined
})
