import {PropsWithChildren, Suspense} from 'react'
import {DataLoading} from '../components/data-loading/data-loading'
import styled from 'styled-components'

interface Props {
    fullScreen?: boolean
}

const FullScreen = styled.div`
    height: 100vh;
`

export function WrapSuspense(props: PropsWithChildren<Props>) {
    if (props.fullScreen) {
        return (
            <Suspense
                fallback={
                    <FullScreen>
                        <DataLoading />
                    </FullScreen>
                }
            >
                {props.children}
            </Suspense>
        )
    }

    return <Suspense fallback={<DataLoading />}>{props.children}</Suspense>
}
