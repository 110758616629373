import {GuidType} from '../../../../values/generic-type-defintions'

export interface LatestAssetData {
    latestAsset: LatestAsset | null
    numberOfAssetsByDuration: {
        PT24H: number | null
        PT168H: number | null
        PT720H: number | null
    }
}
export function latestAssetDataDefaultState(): LatestAssetData {
    return {
        latestAsset: null,
        numberOfAssetsByDuration: {
            PT24H: null,
            PT168H: null,
            PT720H: null,
        },
    }
}

export interface LatestAsset {
    identity: GuidType
    location: GuidType
    macAddress: string
    macVendor: string
    mainIpAddress: string
    firstSeen: string
    lastSeen: string
}
