import {EtsTrendScore} from '../../../values/ets-trend-score/EtsTrendScore'

export interface EtsTrendReduxState {
    isFetchingLast12H: boolean
    isFetchingLast24H: boolean
    isFetchingLast7D: boolean
    isFetchingLast30D: boolean
    isFetchingLast90D: boolean
    trendLast12H: EtsTrendScore[]
    trendLast24H: EtsTrendScore[]
    trendLast7D: EtsTrendScore[]
    trendLast30D: EtsTrendScore[]
    trendLast90D: EtsTrendScore[]
}

export const DEFAULT_ETS_TREND_STATE: EtsTrendReduxState = {
    isFetchingLast12H: true,
    isFetchingLast24H: true,
    isFetchingLast7D: true,
    isFetchingLast30D: true,
    isFetchingLast90D: true,
    trendLast12H: [],
    trendLast24H: [],
    trendLast7D: [],
    trendLast30D: [],
    trendLast90D: [],
}
