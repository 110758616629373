import {ScreenBackGround} from './screen-background.styled'
import {ReactNode, ReactNodeArray} from 'react'

interface LoginPageTemplateProps {
    children: ReactNode | ReactNodeArray
}

export function LoginPageTemplate({children}: LoginPageTemplateProps): JSX.Element {
    return <ScreenBackGround>{children}</ScreenBackGround>
}
