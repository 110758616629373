import {GuidType} from '../../../values/generic-type-defintions'
import {Moment} from 'moment'
import {VesselTags} from '../vessel-tags/state'

export const FLEET_VIEW_IDENTIFIER = 'fleet-view'
export type LocationIdType = GuidType | typeof FLEET_VIEW_IDENTIFIER

export interface MetaData {
    imo: string | undefined
    network?: Network
    deployment?: Deployment
    vesselDashboardAgentIds?: string[]
    otSources?: OTSource
}

export interface ReportConfiguration {
    allow: Allow
    target: Target
}

export interface Allow {
    OTDevices: string[] | undefined
    OTCrossings: string[] | undefined
    BusinessDevices: string[] | undefined
    BusinessCrossings: string[] | undefined
    Email: string[] | undefined
    HostLogExceptions: string[] | undefined
    NetworkLogExceptions: string[] | undefined
    USBHosts: string[] | undefined
    USBDevices: string[] | undefined
    AdminLogons: string[] | undefined
    RemoteAccess: string[] | undefined
}

export interface Target {
    EncryptionRatio: string | undefined
    MonitoringCoverage: string | undefined
    MaxOSAge: string | undefined
    MaxScore: number | undefined
}

export interface Network {
    BusinessNetworks: string[] | undefined
    OTNetworks: string[] | undefined
    CrewNetworks: string[] | undefined
    Gateway: {IP: string | undefined; MAC: string | undefined}
}

export interface Deployment {
    Incus: {IP: string | undefined; MAC: string | undefined}
    Sensor: {IP: string | undefined; MAC: string | undefined}
}

export interface SourceDestinationOt {
    source: string
    destination: string
}

export interface OTSource {
    syslog: SourceDestinationOt[] | undefined
    nmea: SourceDestinationOt[] | undefined
}

export type locationStatus = 'ACTIVE' | 'ARCHIVED' | 'NOT_READY'

export interface Location {
    location: LocationIdType
    code: string
    description: string
    type?: string
    customerLocationCode: string
    metaData?: MetaData
    reportConfiguration?: ReportConfiguration
    tags?: VesselTags[]
    emailAddress?: string
    vesselDashboard?: boolean
    status: locationStatus
}

export type LocationOperationMerge = {location: LocationIdType} & {
    operating: boolean | null
    upgradeAssets:
        | {
              identity: GuidType
              alias?: string
          }[]
        | null
    trafficStatus: string[] | null
    vesselDashboard: boolean | null
}

export type LocationMap = Map<LocationIdType, Location>
export interface LocationsReduxState {
    isFetching: boolean
    locations: Location[]
    locationMap: LocationMap
    activeLocation: Location
    lastUpdate: Moment | null
    updatedNetworkDataObject?: Network
    updatedvesselDashboardAgentIds?: GuidType[]
    updatedEmailLocation: string | null | undefined
}

export const DEFAULT_ACTIVE_LOCATION_STATE: Location = {
    location: FLEET_VIEW_IDENTIFIER,
    code: '',
    customerLocationCode: '',
    description: 'Fleet View',
    status: 'NOT_READY',
}

export const DEFAULT_LOCATIONS_STATE: LocationsReduxState = {
    isFetching: true,
    locations: [],
    locationMap: new Map<LocationIdType, Location>(),
    activeLocation: DEFAULT_ACTIVE_LOCATION_STATE,
    lastUpdate: null,
    updatedEmailLocation: undefined,
}

export function isActiveLocation(location: Location): boolean {
    return location && location.location !== FLEET_VIEW_IDENTIFIER
}

const UNKNOWN_VALUE = 'Unknown'
export const getLocationDescription = (
    locations: LocationMap,
    location: LocationIdType,
): string | typeof UNKNOWN_VALUE => {
    try {
        return locations?.get(location)?.description || UNKNOWN_VALUE
    } catch {
        // eslint-disable-next-line no-console
        console.error(`location ${location} does not match a location in the database`)
        return UNKNOWN_VALUE
    }
}

export const getLocationEmail = (
    locations: LocationMap,
    location: LocationIdType,
): string | null => {
    try {
        return locations?.get(location)?.emailAddress ?? null
    } catch {
        // eslint-disable-next-line no-console
        console.error(`location ${location} does not match a location in the database`)
        return null
    }
}

export const getLocationCode = (
    locations: LocationMap,
    location: LocationIdType,
): string | typeof UNKNOWN_VALUE => {
    try {
        return locations?.get(location)?.code ?? UNKNOWN_VALUE
    } catch {
        // eslint-disable-next-line no-console
        console.error(`location ${location} does not match a location in the database`)
        return UNKNOWN_VALUE
    }
}

export const isOnboardDashboard = (locations: LocationMap, location: LocationIdType): boolean => {
    try {
        return locations?.get(location)?.vesselDashboard ?? false
    } catch {
        // eslint-disable-next-line no-console
        console.error(`location ${location} does not match a location in the database`)
        return false
    }
}
