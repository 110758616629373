import {useCallback} from 'react'
import createPersistedState from 'use-persisted-state'

const usePersistedState = createPersistedState('is-authenticated')

interface ReturnType {
    authenticationSemaphoreStatus: boolean
    setSemaphore: () => void
    clearSemaphore: () => void
}

export const useAuthenticatedSemaphore = (): ReturnType => {
    const [authenticationSemaphoreStatus, setAuthenticationSemaphoreStatus] =
        usePersistedState(false)

    const setSemaphore = useCallback((): void => {
        if (!authenticationSemaphoreStatus) {
            setAuthenticationSemaphoreStatus(true)
        }
    }, [authenticationSemaphoreStatus, setAuthenticationSemaphoreStatus])

    const clearSemaphore = useCallback((): void => {
        if (authenticationSemaphoreStatus) {
            setAuthenticationSemaphoreStatus(false)
        }
    }, [authenticationSemaphoreStatus, setAuthenticationSemaphoreStatus])

    return {
        authenticationSemaphoreStatus,
        setSemaphore,
        clearSemaphore,
    }
}
