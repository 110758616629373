enum ActionType {
    REQUEST_FILTER = '[USB INVENTORY FILTER] REQUEST FILTER',
    RECEIVE_FILTER = '[USB INVENTORY FILTER] RECEIVE FILTER',
    SET_SORT_COLUMN = '[USB INVENTORY FILTER] SET SORT COLUMN',
    TOGGLE_USB_DEVICE_STATUS = '[USB INVENTORY FILTER] TOGGLE USB DEVICE STATUS',
    TOGGLE_ALL_USB_DEVICE_STATUS = '[USB INVENTORY FILTER] TOGGLE ALL USB DEVICE STATUS',
    SET_SEARCHED_FIRST_SEEN = '[USB INVENTORY FILTER] SET SEARCHED FIRST SEEN',
    SET_SEARCHED_LAST_ACTIVE = '[USB INVENTORY FILTER] SET SEARCHED LAST ACTIVE',
    SET_SEARCHED_VENDOR = '[USB INVENTORY FILTER] SET SEARCHED VENDOR',
    SET_SEARCHED_PRODUCT_NAME = '[USB INVENTORY FILTER] SET SEARCHED PRODUCT NAME',
    SET_SEARCHED_USB_TYPE = '[USB INVENTORY FILTER] SET SEARCHED USB TYPE',
    SET_SEARCHED_HAS_PURPOSE = '[USB INVENTORY FILTER] SET SEARCHED USB HAS PURPOSE',
    TOGGLE_FILTER_EXPANDED = '[USB INVENTORY FILTER] TOGGLE FILTER EXPANDED',
    RESET_FILTER = '[USB INVENTORY FILTER] RESET FILTER',
    BULK_ASSIGN_SAVED_FILTER = '[USB INVENTORY FILTER] BULK ASSIGN SAVED FILTER',
    SET_SELECTED_FILTER_NAME = '[USB INVENTORY FILTER] SET SELECTED FILTER NAME',
    TOGGLE_DETAIL_EXPANDED = '[USB INVENTORY FILTER] TOGGLE DETAIL EXPANDED',
}

export default ActionType
