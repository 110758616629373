import {useState} from 'react'
import * as Styled from './_styled/nav-bar-content.styled'
import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {DropdownMenuStyle} from './_styled/location-expanded.styled'
import {NavBarLink} from './nav-bar-link'
import {useLocation} from 'react-router-dom'
import {LocationExpandedButtonStyle} from './location-expanded-button/location-expanded-button.styled'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {fleetConfigSelector} from '../../../../store/state/config/selectors'
import {isInternalUserSelector} from '../../../../store/state/current-user/selectors'

export function InventoryLink(): JSX.Element {
    const [inventoryDropDown, setInventoryDropDown] = useState(false)
    const isInternalUser = useTypedSelector(isInternalUserSelector)
    const fleetConfig = useTypedSelector(fleetConfigSelector)

    const pathname = useLocation().pathname

    return (
        <Styled.DashboardLinkStyle onClick={() => setInventoryDropDown(!inventoryDropDown)}>
            <Styled.NavigationLinkDiv
                id="navigation-inventory"
                activeLink={
                    pathname.startsWith('/network-assets') ||
                    pathname.startsWith('/usb-devices') ||
                    pathname.startsWith('/software') ||
                    pathname.startsWith('/nmea')
                }
            >
                <LocationExpandedButtonStyle id="navigation-inventory-toggle">
                    <Icon
                        glyph={inventoryDropDown ? 'DropdownUp' : 'DropdownDown'}
                        height={15}
                        width={15}
                    />
                </LocationExpandedButtonStyle>
                Inventory
            </Styled.NavigationLinkDiv>
            {inventoryDropDown && (
                <DropdownMenuStyle>
                    {(isInternalUser || fleetConfig.networkAssets) && (
                        <NavBarLink
                            id="navigation-network-assets"
                            path="/network-assets"
                            navLinkText="Network Assets"
                        />
                    )}
                    {(isInternalUser || fleetConfig.usbInventory) && (
                        <NavBarLink
                            id="navigation-usb-devices"
                            path="/usb-devices"
                            navLinkText="USB Devices"
                        />
                    )}
                    {(isInternalUser || fleetConfig.softwareInventory) && (
                        <NavBarLink
                            id="navigation-software"
                            navLinkText="Software"
                            path="/software"
                        />
                    )}
                    {(isInternalUser || fleetConfig.nmeaInventory) && (
                        <NavBarLink id="navigation-nmea" navLinkText="NMEA" path="/nmea" />
                    )}
                    {(isInternalUser ||
                        true) /* TODO: Should be replaced with fleet config when it's ready */ && (
                        <NavBarLink
                            id="navigation-ot-inventory"
                            navLinkText="OT Inventory"
                            path="/ot-inventory-vessels"
                        />
                    )}
                </DropdownMenuStyle>
            )}
        </Styled.DashboardLinkStyle>
    )
}
