import {
    ConfigFetchingStatus,
    ConfigReduxState,
    FleetConfigStatus,
    OAuthClientIdsType,
} from './state'
import AppState from '../../types/app-state'

export const configSelector = (state: AppState): ConfigReduxState => state.config
export const configTenantDisplayNameSelector = (state: AppState): string | undefined =>
    state.config.tenantDisplayName

export const acEnabledSelector = (state: AppState): boolean => state.config.acEnabled

export const fetchingStatusSelector = (state: AppState): ConfigFetchingStatus =>
    state.config.fetchingStatus

export const oauthClientIdsSelector = (state: AppState): OAuthClientIdsType | undefined =>
    state.config.oauthClientIds

export const gfcFlagSelector = (state: AppState): boolean => state.config.gfcFlag

export const customLogoSelector = (state: AppState): boolean => state.config.customLogoFlag

export const fleetConfigSelector = (state: AppState): FleetConfigStatus => state.config.fleetConfig
