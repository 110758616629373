import {combineReducers} from 'redux'
import {connectRouter} from 'connected-react-router'
import {History} from 'history'
import staticReducers from './static-reducers'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const rootReducer = (history: History, asyncReducers = {}) =>
    combineReducers({
        router: connectRouter(history as History),
        ...staticReducers,
        ...asyncReducers,
    })

export default rootReducer
