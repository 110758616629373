import * as Actions from './actions'
import ActionType from './action-type'
import {Api} from '../../../api/Api'
import {REST} from '../../../index'
import {ThunkAction} from 'redux-thunk'
import AppState from '../../types/app-state'
import {
    AnalysisType,
    BenchmarkTrendsType,
    DEFAULT_METRICS_BETA_FILTER,
    DEFAULT_METRICS_BETA_FILTER_STATE,
    FilterExpandedType,
    MetricsBetaFilter,
    MetricsBetaFilterReduxState,
    SortColumnType,
    TimestampFilterType,
    ViewScreenLevelType,
} from './state'
import {SavedMetricsBetaFilter} from '../saved-filters/state'
import {formattedLocationsBlockSet, populateLocationsForFilterCriteria} from '../../../utils/Utils'
import {AnyAction} from 'redux'
import {setCurrentAppliedSavedFilter} from '../saved-filters/action-creators'
import {MetricType} from '../../../pages/metrics-beta/contexts/types/metrics-response'
import {getFormattedMetricsBetaFilter} from '../../../components/saved-search/components/shared/helper'
import {NodeValue, NodeValues} from '../../../values/nodes/NodeData'
import {VesselFilterReduxState} from '../vessel-filter/types/vessel-filter-state'
import {MetricsBetaFilterForUserPref} from '../../../values/user-preferences/metrics-beta-filter'
import {DEFAULT_VESSEL_FILTER_STATE} from '../vessel-filter/types/default-vessel-filter-state'

const USER_PREFS_URL = '/api/v1/userPreferences/metrics_beta'

export const fetchMetricsBetaFilter = (
    savedFilters: SavedMetricsBetaFilter[],
    isDefaultTagUser: boolean,
): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch) => {
        dispatch(requestFilter())
        if (isDefaultTagUser === true) {
            REST.get(USER_PREFS_URL).then(
                (response) => {
                    REST.get(`${USER_PREFS_URL}.default_tag`).then(
                        (res) => {
                            if (response.data.value.selectedFilterName === undefined) {
                                dispatch(
                                    bulkAssignSavedFilter(
                                        {
                                            ...DEFAULT_METRICS_BETA_FILTER,
                                            locations: formattedLocationsBlockSet(
                                                response.data.value.locations,
                                            ),
                                            searchVesselTagTerm: res.data.value.default_tag ?? [],
                                            searchVesselNameTerm:
                                                response.data.value.searchVesselNameTerm ?? '',
                                            selectedAnalysisPeriod:
                                                response.data.value.selectedAnalysisPeriod ??
                                                TimestampFilterType.LAST_30_DAYS,
                                            analysisTypes:
                                                response.data.value.analysisTypes ??
                                                AnalysisType.SCORECARD,
                                            selectedViewScreenType:
                                                response.data.value.selectedViewScreenType ??
                                                'framework',
                                            filterExpanded:
                                                response.data.value.filterExpanded ??
                                                DEFAULT_METRICS_BETA_FILTER.filterExpanded,
                                            metricTypes:
                                                response.data.value.metricTypes ?? undefined,
                                            assetValues:
                                                response.data.value.assetValues ?? undefined,
                                        },
                                        'Saved filters',
                                        true,
                                    ),
                                )
                            } else {
                                const savedFilter =
                                    savedFilters &&
                                    savedFilters.find(
                                        (filter) =>
                                            filter.name === response.data.value.selectedFilterName,
                                    )

                                savedFilter
                                    ? dispatch(
                                          bulkAssignSavedFilter(
                                              {
                                                  ...getFormattedMetricsBetaFilter(
                                                      savedFilter.criteria as MetricsBetaFilter,
                                                  ),
                                                  locations: formattedLocationsBlockSet(
                                                      response.data.value.locations,
                                                  ),
                                                  searchVesselTagTerm: res.data.value.default_tag,
                                                  searchVesselNameTerm:
                                                      response.data.value.searchVesselNameTerm,
                                              },
                                              savedFilter.name,
                                              true,
                                              savedFilter,
                                          ),
                                      )
                                    : dispatch(
                                          bulkAssignSavedFilter(
                                              {
                                                  ...DEFAULT_METRICS_BETA_FILTER,
                                                  locations: formattedLocationsBlockSet(
                                                      response.data.value.locations,
                                                  ),
                                                  searchVesselTagTerm: res.data.value.default_tag,
                                                  searchVesselNameTerm:
                                                      response.data.value.searchVesselNameTerm,
                                                  selectedAnalysisPeriod:
                                                      response.data.value.selectedAnalysisPeriod ??
                                                      TimestampFilterType.LAST_30_DAYS,
                                                  analysisTypes:
                                                      response.data.value.analysisTypes ??
                                                      AnalysisType.SCORECARD,
                                                  selectedViewScreenType:
                                                      response.data.value.selectedViewScreenType ??
                                                      'framework',
                                                  filterExpanded:
                                                      response.data.value.filterExpanded ??
                                                      DEFAULT_METRICS_BETA_FILTER.filterExpanded,
                                                  metricTypes:
                                                      response.data.value.metricTypes ?? undefined,
                                                  assetValues:
                                                      response.data.value.assetValues ?? undefined,
                                              },
                                              'Saved filters',
                                              true,
                                          ),
                                      )
                            }
                            REST.delete(`${USER_PREFS_URL}.default_tag`)
                        },
                        () => {
                            dispatch(
                                bulkAssignSavedFilter(
                                    {
                                        ...DEFAULT_METRICS_BETA_FILTER,
                                        locations: formattedLocationsBlockSet(
                                            response.data.value.locations,
                                        ),
                                        searchVesselTagTerm:
                                            response.data.value.searchVesselTagTerm ?? [],
                                        searchVesselNameTerm:
                                            response.data.value.searchVesselNameTerm ?? '',
                                        selectedAnalysisPeriod:
                                            response.data.value.selectedAnalysisPeriod ??
                                            TimestampFilterType.LAST_30_DAYS,
                                        analysisTypes:
                                            response.data.value.analysisTypes ??
                                            AnalysisType.SCORECARD,
                                        selectedViewScreenType:
                                            response.data.value.selectedViewScreenType ??
                                            'framework',
                                        filterExpanded:
                                            response.data.value.filterExpanded ??
                                            DEFAULT_METRICS_BETA_FILTER.filterExpanded,
                                        metricTypes: response.data.value.metricTypes ?? undefined,
                                        assetValues: response.data.value.assetValues ?? undefined,
                                    },
                                    'Saved filters',
                                    true,
                                ),
                            )
                        },
                    )
                },
                () => {
                    REST.get(`${USER_PREFS_URL}.default_tag`).then((res) => {
                        REST.put(USER_PREFS_URL, {
                            ...DEFAULT_METRICS_BETA_FILTER,
                            searchVesselTagTerm: res.data.value.default_tag,
                        })
                        dispatch(
                            bulkAssignSavedFilter(
                                {
                                    ...DEFAULT_METRICS_BETA_FILTER,
                                    searchVesselTagTerm: res.data.value.default_tag,
                                },
                                'Saved filters',
                            ),
                        )
                    })
                },
            )
        } else {
            REST.get(USER_PREFS_URL).then(
                (response) => {
                    if (response.data.value.selectedFilterName == undefined) {
                        dispatch(
                            bulkAssignSavedFilter(
                                {
                                    ...DEFAULT_METRICS_BETA_FILTER,
                                    locations: formattedLocationsBlockSet(
                                        response.data.value.locations,
                                    ),
                                    searchVesselTagTerm:
                                        response.data.value.searchVesselTagTerm ?? [],
                                    searchVesselNameTerm:
                                        response.data.value.searchVesselNameTerm ?? '',
                                    selectedAnalysisPeriod:
                                        response.data.value.selectedAnalysisPeriod ??
                                        TimestampFilterType.LAST_30_DAYS,
                                    analysisTypes:
                                        response.data.value.analysisTypes ?? AnalysisType.SCORECARD,
                                    selectedViewScreenType:
                                        response.data.value.selectedViewScreenType ?? 'framework',
                                    filterExpanded:
                                        response.data.value.filterExpanded ??
                                        DEFAULT_METRICS_BETA_FILTER.filterExpanded,
                                    metricTypes: response.data.value.metricTypes ?? undefined,
                                    assetValues: response.data.value.assetValues ?? undefined,
                                },
                                'Saved filters',
                            ),
                        )
                    } else {
                        const savedFilter =
                            savedFilters &&
                            savedFilters.find(
                                (filter) => filter.name === response.data.value.selectedFilterName,
                            )
                        savedFilter
                            ? dispatch(
                                  bulkAssignSavedFilter(
                                      {
                                          ...getFormattedMetricsBetaFilter(
                                              savedFilter.criteria as MetricsBetaFilter,
                                          ),
                                          locations: formattedLocationsBlockSet(
                                              response.data.value.locations,
                                          ),
                                          searchVesselTagTerm:
                                              response.data.value.searchVesselTagTerm ?? [],
                                          searchVesselNameTerm:
                                              response.data.value.searchVesselNameTerm ?? '',
                                      },
                                      savedFilter.name,
                                      false,
                                      savedFilter,
                                  ),
                              )
                            : dispatch(
                                  bulkAssignSavedFilter(
                                      {
                                          ...DEFAULT_METRICS_BETA_FILTER,
                                          locations: formattedLocationsBlockSet(
                                              response.data.value.locations,
                                          ),
                                          searchVesselTagTerm:
                                              response.data.value.searchVesselTagTerm ?? [],
                                          searchVesselNameTerm:
                                              response.data.value.searchVesselNameTerm ?? '',
                                          selectedAnalysisPeriod:
                                              response.data.value.selectedAnalysisPeriod ??
                                              TimestampFilterType.LAST_30_DAYS,
                                          analysisTypes:
                                              response.data.value.analysisTypes ??
                                              AnalysisType.SCORECARD,
                                          selectedViewScreenType:
                                              response.data.value.selectedViewScreenType ??
                                              'framework',
                                          filterExpanded:
                                              response.data.value.filterExpanded ??
                                              DEFAULT_METRICS_BETA_FILTER.filterExpanded,
                                          metricTypes: response.data.value.metricTypes ?? undefined,
                                          assetValues: response.data.value.assetValues ?? undefined,
                                      },
                                      'Saved filters',
                                  ),
                              )
                    }
                },
                () => {
                    dispatch(
                        bulkAssignSavedFilter(DEFAULT_METRICS_BETA_FILTER, 'Saved filters', true),
                    )
                },
            )
        }
    }
}

function requestFilter(): Actions.RequestFilterAction {
    return {
        type: ActionType.REQUEST_FILTER,
    }
}

export function setSortColumn(
    orderBy: SortColumnType,
): ThunkAction<void, AppState, Api, Actions.SetSortColumnAction> {
    return (dispatch) => {
        dispatch({
            type: ActionType.SET_SORT_COLUMN,
            payload: orderBy,
        })
    }
}

export function toggleMetricType(
    allMetricType: MetricType[],
    selectedMetricType: MetricType,
    metricTypeNewValue: boolean,
): ThunkAction<void, AppState, Api, Actions.ToggleMetricTypeAction> {
    return (dispatch, getState) => {
        const currentFilter = getState().metricsBetaFilter
        const currentVesselFilter = getState().vesselFilter
        dispatch({
            type: ActionType.TOGGLE_METRIC_TYPE,
            payload: {
                allMetricType,
                selectedMetricType,
                metricTypeNewValue,
            },
        })
        REST.put(USER_PREFS_URL, {
            ...buildFilterPrefs(
                {
                    ...currentFilter,
                    metricTypes: populateMetricTypeValueForUserPrefs(
                        allMetricType,
                        currentFilter.metricTypes,
                        selectedMetricType,
                        metricTypeNewValue,
                    ),
                },
                currentVesselFilter,
            ),
        })
    }
}

export function toggleMainMetricsType(
    allMainMetricsType: MetricType[],
    selectedMainMetricsType: MetricType[],
    toggleMainMetrics: boolean,
): ThunkAction<void, AppState, Api, Actions.ToggleMainMetricsTypeAction> {
    return (dispatch, getState) => {
        const currentFilter = getState().metricsBetaFilter
        const currentVesselFilter = getState().vesselFilter
        dispatch({
            type: ActionType.TOGGLE_MAIN_METRICS_TYPE,
            payload: {
                allMainMetricsType,
                selectedMainMetricsType,
                toggleMainMetrics,
            },
        })
        REST.put(USER_PREFS_URL, {
            ...buildFilterPrefs(
                {
                    ...currentFilter,
                    metricTypes: populateMainMetricsTypeValueForUserPrefs(
                        allMainMetricsType,
                        currentFilter.metricTypes,
                        selectedMainMetricsType,
                        toggleMainMetrics,
                    ),
                },
                currentVesselFilter,
            ),
        })
    }
}

export function toggleAllMetricType(
    selected: boolean,
): ThunkAction<void, AppState, Api, Actions.ToggleAllMetricTypeAction> {
    return (dispatch, getState) => {
        const currentFilter = getState().metricsBetaFilter
        const currentVesselFilter = getState().vesselFilter
        const newMetricsValue = selected ? undefined : new Array<MetricType>()

        dispatch({
            type: ActionType.TOGGLE_ALL_METRIC_TYPE,
            payload: selected,
        })
        REST.put(USER_PREFS_URL, {
            ...buildFilterPrefs(
                {...currentFilter, metricTypes: newMetricsValue},
                currentVesselFilter,
            ),
        })
    }
}

export function toggleAllAssetsValue(
    selected: boolean,
): ThunkAction<void, AppState, Api, Actions.FilterAssetsAllValueAction> {
    return (dispatch, getState) => {
        const currentFilter = getState().metricsBetaFilter
        const currentVesselFilter = getState().vesselFilter
        const newAssetsValue = selected ? undefined : new Array<NodeValue>()
        dispatch({
            type: ActionType.TOGGLE_ALL_ASSETS,
            payload: selected,
        })
        REST.put(USER_PREFS_URL, {
            ...buildFilterPrefs(
                {...currentFilter, assetValues: newAssetsValue},
                currentVesselFilter,
            ),
        })
    }
}

export function toggleValueThreshold(
    selectedNodeValue: NodeValue,
    selectedOption: boolean,
): ThunkAction<void, AppState, Api, Actions.ToggleAssetValueAction> {
    return (dispatch, getState) => {
        const currentFilter = getState().metricsBetaFilter
        const currentVesselFilter = getState().vesselFilter
        dispatch({
            type: ActionType.TOGGLE_VALUE_THRESHOLD,
            payload: {
                selectedNodeValue,
                selectedOption,
            },
        })
        REST.put(USER_PREFS_URL, {
            ...buildFilterPrefs(
                {
                    ...currentFilter,
                    assetValues: populateAssetsValueForUserPrefs(
                        currentFilter.assetValues,
                        selectedNodeValue,
                        selectedOption,
                    ),
                },
                currentVesselFilter,
            ),
        })
    }
}

export function setAnalysisPeriod(
    selectedAnalysisPeriod: TimestampFilterType,
    needUpdate = true,
): ThunkAction<void, AppState, Api, Actions.SetPeriodCommonAction> {
    return (dispatch, getState) => {
        const currentFilter = getState().metricsBetaFilter
        const currentVesselFilter = getState().vesselFilter
        dispatch({
            type: ActionType.SET_ANALYSIS_PERIOD,
            payload: selectedAnalysisPeriod,
        })
        needUpdate &&
            REST.put(USER_PREFS_URL, {
                ...buildFilterPrefs(
                    {...currentFilter, selectedAnalysisPeriod},
                    currentVesselFilter,
                ),
            })
    }
}

export function setAnalysisType(
    analysisTypes: AnalysisType,
): ThunkAction<void, AppState, Api, Actions.SetAnalysisTypesAction> {
    return (dispatch, getState) => {
        const currentFilter = getState().metricsBetaFilter
        const currentVesselFilter = getState().vesselFilter
        dispatch({
            type: ActionType.SET_ANALYSIS_TYPES,
            payload: analysisTypes,
        })
        REST.put(USER_PREFS_URL, {
            ...buildFilterPrefs({...currentFilter, analysisTypes}, currentVesselFilter),
        })
    }
}

export function setBenchmarkTrendsType(
    value: BenchmarkTrendsType,
): ThunkAction<void, AppState, Api, Actions.SetBenchmarkTypeAction> {
    return (dispatch) => {
        dispatch({
            type: ActionType.SET_TRENDS_BENCHMARK_TYPE,
            payload: value,
        })
    }
}

export function ToggleAssetWithIssue(
    value: boolean,
): ThunkAction<void, AppState, Api, Actions.ToggleAssetWithIssueAction> {
    return (dispatch) => {
        dispatch({
            type: ActionType.TOGGLE_ASSET_WITH_ISSUE,
            payload: value,
        })
    }
}

export function toggleFilterExpanded(
    filterExpanded: FilterExpandedType,
): ThunkAction<void, AppState, Api, Actions.ToggleFilterExpandedAction> {
    return (dispatch, getState) => {
        const currentFilter = getState().metricsBetaFilter
        const currentVesselFilter = getState().vesselFilter
        dispatch({
            type: ActionType.TOGGLE_FILTER_EXPANDED,
            payload: filterExpanded,
        })
        REST.put(USER_PREFS_URL, {
            ...buildFilterPrefs({...currentFilter, filterExpanded}, currentVesselFilter),
        })
    }
}

export function resetFilter(): ThunkAction<void, AppState, Api, AnyAction> {
    return (dispatch) => {
        dispatch({type: ActionType.RESET_FILTER})
        dispatch(setCurrentAppliedSavedFilter(undefined))
        REST.put(
            USER_PREFS_URL,
            buildFilterPrefs(DEFAULT_METRICS_BETA_FILTER_STATE, DEFAULT_VESSEL_FILTER_STATE),
        )
    }
}

export function bulkAssignSavedFilter(
    data: MetricsBetaFilter,
    filterName: string,
    pref?: boolean,
    savedFilter?: SavedMetricsBetaFilter,
): ThunkAction<void, AppState, Api, AnyAction> {
    return (dispatch, getState) => {
        const currentVesselFilter = getState().vesselFilter
        dispatch({
            type: ActionType.BULK_ASSIGN_SAVED_FILTER,
            payload: {
                criteria: data,
                name: filterName,
            },
        })
        savedFilter && dispatch(setCurrentAppliedSavedFilter(savedFilter))
        pref &&
            REST.put(USER_PREFS_URL, {
                selectedFilterName: filterName,
                locations: populateLocationsForFilterCriteria(currentVesselFilter.locations),
                searchVesselTagTerm: currentVesselFilter.searchVesselTagTerm,
                searchVesselNameTerm: currentVesselFilter.searchVesselNameTerm,
                selectedAnalysisPeriod: data.selectedAnalysisPeriod,
                analysisTypes: data.analysisTypes,
                selectedViewScreenType: data.selectedViewScreenType,
                filterExpanded: data.filterExpanded,
                metricTypes: data.metricTypes,
                assetValues: data.assetValues,
            })
    }
}
export function setSelectedFilterName(
    filterName: string,
    pref?: {
        savedFilter: SavedMetricsBetaFilter
    },
): ThunkAction<void, AppState, Api, AnyAction> {
    return (dispatch, getState) => {
        const currentVesselFilter = getState().vesselFilter
        dispatch({
            type: ActionType.SET_SELECTED_FILTER_NAME,
            payload: filterName,
        })
        if (pref) {
            dispatch(setCurrentAppliedSavedFilter(pref.savedFilter))
            REST.put(USER_PREFS_URL, {
                selectedFilterName: filterName,
                locations: populateLocationsForFilterCriteria(currentVesselFilter.locations),
                searchVesselTagTerm: currentVesselFilter.searchVesselTagTerm,
                filterExpanded: pref.savedFilter.criteria.filterExpanded,
            })
        }
    }
}
export function setSelectedViewScreen(
    selectedViewScreenType: ViewScreenLevelType,
): ThunkAction<void, AppState, Api, Actions.SetSelectedViewScreenTypeAction> {
    return (dispatch, getState) => {
        const currentFilter = getState().metricsBetaFilter
        const currentVesselFilter = getState().vesselFilter
        dispatch({
            type: ActionType.SET_SELECTED_VIEW_SCREEN_DROPDOWN,
            payload: selectedViewScreenType,
        })
        REST.put(USER_PREFS_URL, {
            ...buildFilterPrefs({...currentFilter, selectedViewScreenType}, currentVesselFilter),
        })
    }
}
export function filterMainFrameworkCard(
    payload: MetricType[] | undefined,
): Actions.FilterMainFrameworkAction {
    return {
        type: ActionType.FILTER_MAIN_FRAMEWORK_CARD,
        payload,
    }
}
function buildFilterPrefs(
    state: MetricsBetaFilterReduxState,
    vesselFilterState: VesselFilterReduxState,
): MetricsBetaFilterForUserPref {
    return {
        selectedFilterName: state.selectedFilterName,
        locations: populateLocationsForFilterCriteria(vesselFilterState.locations),
        searchVesselTagTerm: vesselFilterState.searchVesselTagTerm,
        searchVesselNameTerm: vesselFilterState.searchVesselNameTerm,
        selectedAnalysisPeriod: state.selectedAnalysisPeriod,
        analysisTypes: state.analysisTypes,
        selectedViewScreenType: state.selectedViewScreenType,
        filterExpanded: state.filterExpanded,
        metricTypes: state.metricTypes,
        assetValues: state.assetValues,
    }
}

function populateAssetsValueForUserPrefs(
    assetValues: NodeValue[] | undefined,
    selectedNodeValue: NodeValue,
    selectedOption: boolean,
): NodeValue[] {
    const allAssetValues = [NodeValues.LOW, NodeValues.MEDIUM, NodeValues.HIGH]
    if (assetValues == undefined) {
        assetValues = allAssetValues
    }
    if (selectedOption) {
        const outputAssets = [...assetValues, selectedNodeValue]
        assetValues = [...new Set(outputAssets as NodeValue[])]
    } else {
        assetValues = [...assetValues.filter((item) => selectedNodeValue !== item)]
    }
    return assetValues
}

function populateMetricTypeValueForUserPrefs(
    allMetricType: MetricType[],
    stateMetricTypes: MetricType[] | undefined,
    selectedMetricType: MetricType,
    metricTypeNewValue: boolean,
): MetricType[] | undefined {
    if (stateMetricTypes == undefined) {
        stateMetricTypes = [...allMetricType]
    }
    if (metricTypeNewValue) {
        stateMetricTypes = [...stateMetricTypes, selectedMetricType]
    } else {
        stateMetricTypes.splice(stateMetricTypes.indexOf(selectedMetricType), 1)
    }
    if (stateMetricTypes.length === allMetricType.length) {
        stateMetricTypes = undefined
    }
    return stateMetricTypes
}

function populateMainMetricsTypeValueForUserPrefs(
    allMainMetricsType: MetricType[],
    stateMetricTypes: MetricType[] | undefined,
    selectedMainMetricsType: MetricType[],
    toggleMainMetrics: boolean,
): MetricType[] | undefined {
    if (stateMetricTypes == undefined) {
        stateMetricTypes = [...allMainMetricsType]
    }
    if (toggleMainMetrics) {
        const formattedMetrics: MetricType[] = [...stateMetricTypes, ...selectedMainMetricsType]
        stateMetricTypes = [...new Set(formattedMetrics)]
    } else {
        stateMetricTypes = [
            ...stateMetricTypes.filter((item) => !selectedMainMetricsType.includes(item)),
        ]
    }
    return stateMetricTypes
}
