import * as Styled from './row-based-layout.styled'
import {SignOutButton} from '../../actions/sign-out-button'
import {GetHelpButton} from '../../actions/get-help-button'
import * as ErrorStyles from '../../error.styled'
import {RowBasedLoginLayout} from './row-based-login-layout'

export function AuthenticationIssue(): JSX.Element {
    return (
        <RowBasedLoginLayout>
            <Styled.MainActionsGrid>
                <div style={{flex: 1, justifyItems: 'center'}}>
                    <ErrorStyles.Message>
                        We&#39;re sorry, but we cannot sign you in.
                    </ErrorStyles.Message>
                </div>
                <SignOutButton buttonText="Continue" />
                <GetHelpButton smallText={true} />
            </Styled.MainActionsGrid>
        </RowBasedLoginLayout>
    )
}
