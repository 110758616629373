import {CyberOwlTheme} from './theme'
import {Colors} from './colours'

export const DarkTheme: CyberOwlTheme = {
    colors: {
        text: {
            default: Colors.white,
            deemphasize: Colors.grey5,
            error: Colors.red2,
            warning: Colors.red3,
            success: Colors.green1,
        },
        background: {
            selectedLocationPage: Colors.grey4,
            page: Colors.grey8,
            default: Colors.grey5,
            lowContrast: Colors.grey6,
            fade: Colors.grey15,
        },
        boxShadow: {
            highContrast: '#00000034',
        },
        link: {
            primary: Colors.blue2,
            disabled: Colors.grey3,
        },
        border: {
            lowContrast: Colors.grey2,
            active: Colors.blue2,
            default: Colors.grey3,
        },
        newIndicator: {
            text: Colors.red5,
            flash: Colors.red6,
            background: Colors.red3,
        },
        graph: {
            primary: Colors.grey5,
            secondary: Colors.blue9,
        },
    },
    login: {
        errorBorder: Colors.red3,
        errorBackground: Colors.red3_21percent,
        errorContent: Colors.white,
    },
    loadingPage: {
        background: Colors.grey5,
    },
    threatScores: {
        title: {
            unknown: {
                background: Colors.grey5,
                text: Colors.grey5,
            },
            low: {
                background: Colors.purple1,
                text: Colors.purple6,
            },
            medium: {
                background: Colors.purple5,
                text: Colors.white,
            },
            high: {
                background: Colors.purple6,
                text: Colors.white,
            },
        },
        score: {
            unknown: {
                background: Colors.white,
                text: Colors.white,
            },
            lowScoreLowValue: {
                background: Colors.purple1,
                text: Colors.purple6,
            },
            lowScoreMediumValue: {
                background: Colors.purple5,
                text: Colors.white,
            },
            lowScoreHighValue: {
                background: Colors.purple6,
                text: Colors.white,
            },
            low: {
                background: Colors.blue3,
                text: Colors.white,
            },
            medium: {
                background: Colors.orange2,
                background30p: Colors.orange2_30percent,
                text: Colors.grey5,
            },
            high: {
                background: Colors.red3,
                background30p: Colors.red3_30percent,
                text: Colors.white,
            },
        },
    },
    modalDialog: {
        background: Colors.grey7,
        text: Colors.white,
        overlay: 'rgba(0, 0, 0, 0.25)',
        divider: Colors.grey4,
        dropShadow: Colors.grey7,
        dataRow: {
            text: Colors.white,
            background: Colors.grey7,
            border: '#000000aa',
        },
        dataArea: {
            background: Colors.grey5,
        },
        tabs: {
            selected: {
                text: Colors.blue2,
                background: Colors.grey6,
            },
            notSelected: {
                text: Colors.grey6,
                background: Colors.grey5,
            },
        },
    },
    incidents: {
        dataArea: {
            background: Colors.grey5,
            historyBackground: Colors.grey1,
        },
        dataRow: {
            text: Colors.white,
            editText: 'rgba(133, 133, 133, 1)',
            background: Colors.grey7,
            newBackground: Colors.red7,
            separator: Colors.grey2,
            statusBar: {
                new: Colors.red3,
                open: Colors.orange2,
                closed: Colors.green1,
                draft: Colors.grey4,
            },
            selectedRow: Colors.grey2,
            saveNoteBackground: Colors.orange4,
            hoverNoteBackground: 'rgba(196, 196, 196, 0.22)',
            editNoteBackground: 'rgba(31, 136, 229, 0.1)',
            errorModal: Colors.grey14,
        },
        incidentStatus: {
            new: Colors.red3,
            open: Colors.orange2,
            closed: Colors.green1,
            draft: Colors.grey4,
        },
        incidentStatusPopup: {
            background: Colors.white,
            border: Colors.grey2,
        },
        incidentSeverity: {
            active: {
                low: Colors.blue3,
                medium: Colors.orange2,
                high: Colors.red3,
                critical: Colors.red3,
            },
            opacity: {
                low: Colors.blue8,
                medium: Colors.orange5,
                high: Colors.red8,
                critical: Colors.red8,
            },
        },
        resetButton: {
            text: Colors.white,
            background: Colors.blue2,
        },
        incidentAsset: {
            text: Colors.white,
        },
        newRecordLabel: {
            background: Colors.red3,
            text: Colors.grey5,
        },
        filterBar: {
            background: Colors.grey7,
            boxShadow: 'rgba(0, 0, 0, 0.6)',
            titleSeparator: Colors.grey8,
            headingHighlight: Colors.grey3,
            search: {
                text: Colors.grey5,
                border: Colors.grey2,
            },
            sortOrder: {
                text: Colors.grey3,
                border: Colors.grey2,
                arrow: {icon: Colors.white, background: Colors.blue2},
                direction: Colors.blue2,
            },
            closeButton: {
                text: Colors.white,
                background: Colors.blue2,
                border: Colors.blue2,
            },
            savedFilters: {
                detailBackground: Colors.blue8,
            },
        },
    },
    systemPage: {
        dataArea: {
            background: Colors.grey5,
        },
        dataRow: {
            text: Colors.white,
            background: Colors.grey7,
            dropShadow: 'rgba(0, 0, 0, 0.3)',
        },
        link: {
            primary: Colors.blue2,
            hoverBackground: Colors.blue6,
        },
    },
    vessels: {
        dataArea: {
            background: Colors.grey5,
        },
        dataRow: {
            text: Colors.white,
            background: Colors.grey7,
            dropShadow: 'rgba(0, 0, 0, 0.3)',
        },
        link: {
            primary: Colors.blue2,
            hoverBackground: Colors.blue6,
        },
        modal: {
            boxShadow: 'rgba(0, 0, 0, 0.5)',
            hoverBackgroundColor: Colors.blue2,
            hoverTextColor: Colors.white,
        },
        button: {
            hoverBorderColor: 'transparent',
            hoverBackgroundColor: Colors.blue4,
            hoverTextColor: Colors.white,
        },
        list: {hoverBackgroundColor: Colors.blue2, hoverTextColor: Colors.white},
    },
    navigationResponsive: {
        dataArea: {
            background: Colors.grey7,
            miniBackground: Colors.grey5,
            locationExpandedArea: Colors.grey4,
            textColor: Colors.grey1,
            miniTextColor: Colors.grey11,
        },
        active: {
            background: Colors.red3,
            underLine: Colors.orange2,
        },
    },
    unknownAssets: {
        dataArea: {
            background: Colors.grey5,
        },
        dataRow: {
            text: Colors.white,
            background: Colors.grey7,
            selectedRow: Colors.grey2,
        },
        resetButton: {
            text: Colors.white,
            background: Colors.blue2,
        },
        filterBar: {
            boxShadow: 'rgba(0, 0, 0, 0.16)',
            closeButton: {
                text: Colors.white,
                background: Colors.blue2,
                border: Colors.blue2,
            },
            titleSeparator: Colors.grey8,
        },
    },
    userManagement: {
        dataArea: {
            background: Colors.grey5,
        },
        dataRow: {
            text: Colors.white,
            background: Colors.grey7,
            selectedRow: Colors.grey2,
            dropShadow: 'rgba(0, 0, 0, 0.3)',
        },
        resetButton: {
            text: Colors.white,
            background: Colors.blue2,
        },
    },
    vesselFilter: {
        filterBar: {
            background: Colors.grey7,
            boxShadow: 'rgba(0, 0, 0, 0.6)',
            titleSeparator: Colors.grey8,
            headingHighlight: Colors.grey3,
            search: {
                text: Colors.grey5,
                border: Colors.grey2,
            },
            sortOrder: {
                text: Colors.grey3,
                border: Colors.grey2,
                arrow: {icon: Colors.white, background: Colors.blue2},
                direction: Colors.blue2,
            },
            closeButton: {
                text: Colors.white,
                background: Colors.blue2,
                border: Colors.blue2,
            },
        },
        dataRow: {
            text: Colors.white,
            background: Colors.grey7,
            separator: Colors.grey2,
        },
    },
    myVessels: {
        dataArea: {
            background: Colors.grey8,
        },
        actionButton: {
            text: Colors.blue2,
            background: Colors.grey7,
            border: Colors.blue2,
        },
        resetButton: {
            text: Colors.white,
            background: Colors.blue2,
        },
        zoomLevel: {
            border: Colors.grey8,
            selected: {
                text: Colors.grey7,
                background: Colors.blue2,
            },
            unselected: {
                hoverBackground: Colors.blue1,
                background: Colors.grey7,
            },
        },
        filterBar: {
            background: Colors.grey7,
            boxShadow: 'rgba(0, 0, 0, 0.6)',
            titleSeparator: Colors.grey8,
            headingHighlight: Colors.grey3,
            search: {
                text: Colors.grey5,
                border: Colors.grey2,
            },
            sortOrder: {
                text: Colors.grey3,
                border: Colors.grey2,
                arrow: {icon: Colors.white, background: Colors.blue2},
                direction: Colors.blue2,
            },
            closeButton: {
                text: Colors.white,
                background: Colors.blue2,
                border: Colors.blue2,
            },
        },
        cards: {
            background: Colors.grey7,
            lowRiskText: Colors.white,
            lowRiskBackground: Colors.grey7,
            mediumRiskText: Colors.grey7,
            mediumRiskBackground: Colors.orange2_30percent,
            highRiskText: Colors.white,
            highRiskBackground: Colors.red7,
            boxShadow: 'rgba(0, 0, 0, 0.6)',
        },
    },
    reports: {
        dataArea: {
            background: Colors.grey5,
            boxShadow: 'rgba(0, 0, 0, 0.3)',
        },
        dataRow: {
            text: Colors.white,
            background: Colors.grey7,
            separator: Colors.grey2,
        },
        resetButton: {
            text: Colors.white,
            background: Colors.blue2,
        },
        actionButton: {
            text: Colors.blue2,
            background: Colors.grey7,
            border: Colors.blue2,
        },
        starRating: {
            selected: {
                fill: Colors.white,
                background: Colors.blue2,
            },
            unSelected: {
                fill: Colors.grey5,
                background: Colors.white,
            },
            hover: {
                fill: Colors.blue2,
                background: Colors.blue6,
            },
            staticIcon: {
                selected: Colors.grey5,
                unSelected: Colors.grey2,
            },
        },
        filterBar: {
            boxShadow: 'rgba(0, 0, 0, 0.16)',
            closeButton: {
                text: Colors.white,
                background: Colors.blue2,
                border: Colors.blue2,
            },
        },
    },
    usbDevices: {
        dataArea: {
            background: Colors.grey5,
        },
        dataRow: {
            text: Colors.white,
            background: Colors.grey7,
        },
        resetButton: {
            text: Colors.white,
            background: Colors.blue2,
        },
        filterBar: {
            boxShadow: 'rgba(0, 0, 0, 0.16)',
            closeButton: {
                text: Colors.white,
                background: Colors.blue2,
                border: Colors.blue2,
            },
        },
    },
    softwareInventory: {
        dataArea: {
            background: Colors.grey5,
        },
        dataRow: {
            text: Colors.white,
            background: Colors.grey7,
            selectedRow: Colors.grey2,
        },
        resetButton: {
            text: Colors.white,
            background: Colors.blue2,
        },
        filterBar: {
            boxShadow: 'rgba(0, 0, 0, 0.16)',
            closeButton: {
                text: Colors.white,
                background: Colors.blue2,
                border: Colors.blue2,
            },
            titleSeparator: Colors.grey8,
        },
    },
    pagingButton: {
        clickable: {
            default: {
                background: 'inherit',
                text: Colors.blue6,
                border: Colors.blue6,
            },
            hover: {
                background: Colors.blue4,
                text: Colors.white,
                border: Colors.white,
            },
            active: {
                background: Colors.blue3,
                text: Colors.blue2,
                border: Colors.blue2,
            },
        },
        disabled: {
            background: 'inherit',
            text: Colors.grey8,
            border: Colors.grey8,
        },
        selected: {
            background: Colors.blue2,
            text: Colors.white,
        },
    },
    refreshButton: {
        default: {
            borderColor: 'transparent',
            backgroundColor: 'default',
            textColor: Colors.blue4,
        },
        hover: {
            borderColor: 'transparent',
            backgroundColor: Colors.blue4,
            textColor: Colors.white,
        },
        active: {
            borderColor: Colors.blue5,
            backgroundColor: Colors.blue4,
            textColor: Colors.white,
        },
        disabled: {
            borderColor: 'transparent',
            backgroundColor: 'inherit',
            textColor: Colors.grey5,
        },
    },
    dashboard: {
        noData: {
            text: Colors.grey8,
        },
        graphButtons: {
            active: {
                text: Colors.white,
                background: 'inherit',
                indicator: Colors.blue2,
            },
            selectable: {
                text: Colors.blue2,
                background: 'inherit',
            },
            hover: {
                text: Colors.blue2,
                background: Colors.blue7,
            },
        },
        fleetRiskScore: {
            low: {
                color: Colors.blue3,
                opacity: 1,
            },
            medium: {
                color: Colors.orange2,
                opacity: 0.3,
            },
            high: {
                color: Colors.red3,
                opacity: 0.3,
            },
        },
        vesselList: {
            backgroundColor: Colors.grey8,
            vesselDetail: {
                default: {backgroundColor: Colors.grey5},
                expanded: {backgroundColor: Colors.grey3},
            },
            assetDetail: {
                default: {backgroundColor: Colors.grey5},
                expanded: {backgroundColor: Colors.grey3},
            },
        },
    },
    commonTableViewPage: {
        dataArea: {
            background: Colors.grey6,
        },
        dataRow: {
            text: Colors.grey5,
            background: Colors.white,
            selectedRow: Colors.grey2,
            boxShadow: 'rgba(0, 0, 0, 0.3)',
        },
    },
    font: {
        family: "'Open Sans', sans-serif",
        weight: {
            light: 300,
            normal: 400,
            semibold: 600,
            bold: 700,
            extrabold: 800,
        },
    },
}
