import {ReactNode, useReducer} from 'react'
import {cloneDeep} from 'lodash'
import {DEFAULT_INCIDENTS_WIDGET_STATE} from './types/default-incidents-widget-state'
import {incidentsWidgetReducer} from './state/reducer'
import {IncidentsWidgetState} from './types/incidents-widget-state'
import {IncidentsWidgetContext} from './incidents-widget-context'

type IncidentsWidgetProviderProps = {
    children: ReactNode | ReactNode[]
}

export function IncidentsWidgetProvider({children}: IncidentsWidgetProviderProps): JSX.Element {
    const initialState: IncidentsWidgetState = cloneDeep(DEFAULT_INCIDENTS_WIDGET_STATE)

    const [state, dispatch] = useReducer(incidentsWidgetReducer, initialState)

    return (
        <IncidentsWidgetContext.Provider value={{state, dispatch}}>
            {children}
        </IncidentsWidgetContext.Provider>
    )
}
