import {GuidType} from '../../../values/generic-type-defintions'

export interface LocationTagsSummaryReduxState {
    isFetchingTagSummary: boolean
    incidentResponsesCountsByLocationTags: LocationTagsSummaryMapType //Incidents // 0
    unknownAssetCountsByLocationTags: LocationTagsSummaryMapType // Asset Discovery // 0
    maxLocationScoresByLocationTags: LocationTagsSummaryMapType
    averageLocationScoresByLocationTags: LocationTagsSummaryMapType // risk score // N/A
}

export const DEFAULT_LOCATION_TAGS_SUMMARY_STATE: LocationTagsSummaryReduxState = {
    isFetchingTagSummary: false,
    incidentResponsesCountsByLocationTags: {} as LocationTagsSummaryMapType,
    unknownAssetCountsByLocationTags: {} as LocationTagsSummaryMapType,
    maxLocationScoresByLocationTags: {} as LocationTagsSummaryMapType,
    averageLocationScoresByLocationTags: {} as LocationTagsSummaryMapType,
}

export type LocationTagsSummaryMapType = Record<GuidType, number>
