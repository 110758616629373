import styled from 'styled-components'

interface Props {
    showingSelectedLocation: boolean
}

export const FixedPageSizeContainer = styled.div<Props>`
    background-color: ${(props) =>
        props.showingSelectedLocation
            ? props.theme.colors.background.selectedLocationPage
            : props.theme.colors.background.page};
    height: 100vh;
    max-height: 100vh;
    min-height: 200px;
    display: flex;
    flex-direction: column;
`
