import ActionType from './action-type'
import * as Actions from './actions'
import {ThunkAction} from 'redux-thunk'
import AppState from '../../types/app-state'
import {LocationTagArrayMapType, VesselTags} from './state'
import {Api} from '../../../api/Api'
import {REST} from '../../..'
import {ApiCreateOrAssign, ApiUpdateTag, VesselTagsAPI} from './helper'
import {GuidType} from '../../../values/generic-type-defintions'
import {fetchLocations} from '../locations/action-creators'
import {LocationIdType} from '../locations/state'

export const VESSEL_TAGS_REST_URL = '/api/v1/locationTags'

export function fetchAllLocationTagsByLocation(): ThunkAction<void, AppState, Api, Actions.Action> {
    return (dispatch): void => {
        dispatch(requestVesselTagsAction())

        REST.get(`${VESSEL_TAGS_REST_URL}/allLocationTagsByLocation`)
            .then((response) => {
                dispatch(
                    receiveVesselTagsByLocationAction(
                        new Map(Object.entries(response.data)) as LocationTagArrayMapType,
                    ),
                )
            })
            .catch((error) => {
                dispatch(setErrorMessage(error?.response?.data as ErrorObject))
                dispatch(
                    receiveVesselTagsByLocationAction(new Map<LocationIdType, VesselTagsAPI[]>()),
                )
            })
        REST.get(VESSEL_TAGS_REST_URL)
            .then((response) => {
                dispatch(receiveVesselTagsAction(response.data))
            })
            .catch((error) => {
                dispatch(setErrorMessage(error?.response?.data as ErrorObject))
                dispatch(receiveVesselTagsAction(new Array<VesselTags>()))
            })
    }
}

function requestVesselTagsAction(): Actions.RequestAllLocationTagsByLocationAction {
    return {
        type: ActionType.REQUEST_ALL_LOCATION_TAGS_BY_LOCATION,
    }
}

function receiveVesselTagsByLocationAction(
    vesselTagsByLocation: LocationTagArrayMapType,
): Actions.ReceiveAllLocationTagsByLocationAction {
    return {
        type: ActionType.RECEIVE_ALL_LOCATION_TAGS_BY_LOCATION,
        payload: vesselTagsByLocation,
    }
}

function receiveVesselTagsAction(vesselTags: VesselTags[]): Actions.ReceiveTagsAction {
    return {
        type: ActionType.RECEIVE_ALL_TAGS,
        payload: vesselTags,
    }
}

export function setTagToCreateOrAssign(
    tagToCreateOrAssign: ApiCreateOrAssign | null,
): Actions.SetTagToCreateOrAssignAction {
    return {
        type: ActionType.SET_TAG_TO_CREATE_OR_ASSIGN,
        payload: tagToCreateOrAssign,
    }
}

export function createOrAssignLocationTag(
    tagToCreateOrAssign: ApiCreateOrAssign,
): ThunkAction<void, AppState, Api, Actions.Action> {
    return (dispatch) => {
        REST.post(`${VESSEL_TAGS_REST_URL}`, tagToCreateOrAssign)
            .then(() => {
                dispatch(fetchAllLocationTagsByLocation())
                dispatch(fetchLocations(false))
                dispatch(setTagToCreateOrAssign(null))
                dispatch(setSearchTerm(''))
            })
            .catch((error) => {
                dispatch(setErrorMessage(error?.response?.data as ErrorObject))
                setTimeout(() => dispatch(setErrorMessage(undefined)), 5000)
            })
    }
}

export function deleteLocationTag(
    locationIdentity: GuidType,
    tagIdentityToDelete: GuidType,
): ThunkAction<void, AppState, Api, Actions.Action> {
    return (dispatch) => {
        REST.post(`${VESSEL_TAGS_REST_URL}/unassignLocationTagFromLocation`, {
            locationIdentity: locationIdentity,
            locationTagIdentity: tagIdentityToDelete,
        })
            .then(() => {
                dispatch(fetchAllLocationTagsByLocation())
                dispatch(fetchLocations(false))
                dispatch(setTagToUpdate(null))
            })
            .catch((error) => {
                dispatch(setErrorMessage(error?.response?.data as ErrorObject))
                setTimeout(() => dispatch(setErrorMessage(undefined)), 5000)
            })
    }
}
export function setErrorMessage(message: ErrorObject | undefined): Actions.SetErrorResultAction {
    return {
        type: ActionType.SET_ERROR_RESULT,
        payload: message,
    }
}

export function setSearchTerm(searchedTerm: string): Actions.SetSearchTermAction {
    return {
        type: ActionType.SET_SEARCH_TERM,
        payload: searchedTerm,
    }
}

export function setTagToUpdate(tagToUpdate: ApiUpdateTag | null): Actions.SetTagToUpdateAction {
    return {
        type: ActionType.SET_TAG_TO_UPDATE,
        payload: tagToUpdate,
    }
}

export function updateLocationTag(
    tagToUpdate: ApiUpdateTag,
): ThunkAction<void, AppState, Api, Actions.Action> {
    return (dispatch) => {
        REST.put(`${VESSEL_TAGS_REST_URL}`, tagToUpdate)
            .then(() => {
                dispatch(fetchAllLocationTagsByLocation())
                dispatch(setTagToUpdate(null))
                dispatch(setSearchTerm(''))
            })
            .catch((error) => {
                dispatch(setErrorMessage(error?.response?.data as ErrorObject))
                setTimeout(() => dispatch(setErrorMessage(undefined)), 5000)
            })
    }
}
export type ErrorObject = Record<'error', Record<string, string>>
